import { Pressable, StyleSheet, Text, View } from 'react-native'
import React, { useRef, useState } from 'react'
import { Ionicons } from '@expo/vector-icons';
import UseResponsiveSize from '../modules/UseResponsiveSize';
import UseResponsiveScreen from '../modules/UseResponsiveScreen';
import { useSelector } from 'react-redux';
import { t } from "i18n-js";

export default function TimeUnits({ setTimeUnits }) {

    const unitsBtnRef = useRef(null);
    const unitsPopupRef = useRef(null);

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);

    const [showUints, setShowUnits] = useState(false);
    const [showTypeUnits, setShowTypeUnits] = useState("minutes");

    const popup = (
        <View
            style={styles.popupContainer}
            ref={unitsPopupRef}
            nativeID="action-tooltip"
        >
            <View
                style={[styles.triangle, { borderBottomColor: background.infoBody }]}
            />
            <View
                style={[styles.unitsPicker, { backgroundColor: background.infoBody }]}
            >
                <Pressable
                    style={[
                        styles.selectItems,
                        {
                            paddingHorizontal: showTypeUnits !== "minutes" && widthS * 10,
                            paddingVertical: showTypeUnits !== "minutes" && heightS * 3,
                        },
                    ]}
                    onPress={() => setShowTypeUnits("minutes")}
                >
                    {showTypeUnits !== "minutes" && (
                        <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>
                            {t("minutes_units")}
                        </Text>
                    )}
                    {showTypeUnits === "minutes" && (
                        <View style={styles.selectItems}>
                            {["05", "10", "30"].map((typeTime, index) => {
                                return (
                                    <Pressable
                                        key={index}
                                        style={({ hovered }) => [
                                            styles.selectItemsUnits,
                                            { backgroundColor: hovered && background.infoBox },
                                        ]}
                                        onPress={() => {
                                            setTimeUnits(`${typeTime}m`)
                                            setShowUnits(false);
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styles.headers3,
                                                { color: color.infoHeaderNavText },
                                            ]}
                                        >
                                            {typeTime} {t("minutes_units")}
                                        </Text>
                                    </Pressable>
                                );
                            })}
                        </View>
                    )}
                </Pressable>
                <Pressable
                    style={[
                        styles.selectItems,
                        {
                            paddingHorizontal: showTypeUnits !== "hours" && widthS * 10,
                            paddingVertical: showTypeUnits !== "hours" && heightS * 3,
                        },
                    ]}
                    onPress={() => setShowTypeUnits("hours")}
                >
                    {showTypeUnits !== "hours" && (
                        <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>
                            {t("hours_units")}
                        </Text>
                    )}
                    {showTypeUnits === "hours" && (
                        <View style={styles.selectItems}>
                            {["1", "2", "3"].map((typeTime, index) => {
                                return (
                                    <Pressable
                                        key={index}
                                        style={({ hovered }) => [
                                            styles.selectItemsUnits,
                                            { backgroundColor: hovered && background.infoBox },
                                        ]}
                                        onPress={() => {
                                            setTimeUnits(`${typeTime}h`);
                                            setShowUnits(false);
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styles.headers3,
                                                { color: color.infoHeaderNavText },
                                            ]}
                                        >
                                            {typeTime} {t("hours_units")}
                                        </Text>
                                    </Pressable>
                                );
                            })}
                        </View>
                    )}
                </Pressable>
                <Pressable
                    style={[
                        styles.selectItems,
                        {
                            paddingHorizontal: showTypeUnits !== "days" && widthS * 10,
                            paddingVertical: showTypeUnits !== "days" && heightS * 3,
                        },
                    ]}
                    onPress={() => setShowTypeUnits("days")}
                >
                    {showTypeUnits !== "days" && (
                        <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>
                            {t("days_units")}
                        </Text>
                    )}
                    {showTypeUnits === "days" && (
                        <View style={styles.selectItems}>
                            {["1", "2", "3"].map((typeTime, index) => {
                                return (
                                    <Pressable
                                        key={index}
                                        style={({ hovered }) => [
                                            styles.selectItemsUnits,
                                            { backgroundColor: hovered && background.infoBox },
                                        ]}
                                        onPress={() => {
                                            setTimeUnits(`${typeTime}d`);
                                            setShowUnits(false);
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styles.headers3,
                                                { color: color.infoHeaderNavText },
                                            ]}
                                        >
                                            {typeTime} {t("days_units")}
                                        </Text>
                                    </Pressable>
                                );
                            })}
                        </View>
                    )}
                </Pressable>
            </View>
        </View>
    );
    return (
        <Pressable
            style={{
                justifyContent: "center",
                marginLeft: widthS * 5,
                position: "relative"
            }}
            onPress={() => setShowUnits(!showUints)}
            ref={unitsBtnRef}
        >
            <Text
                style={{
                    fontSize: widthS * 8,
                    fontWeight: "300",
                    color: color.infoHeaderNavText,
                    flexWrap: "wrap",
                }}
            >
                {t("time_units_flow").toUpperCase()}
                <Ionicons
                    size={widthS * 8}
                    name="caret-down"
                    color={color.infoHeaderNavText}
                />
            </Text>
            {showUints && popup}
        </Pressable>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        popupContainer: {
            top: heightS * 26,
            right: widthS * -18,
            position: "absolute",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 2,
        },
        triangle: {
            width: 0,
            height: 0,
            backgroundColor: "transparent",
            borderStyle: "solid",
            borderLeftWidth: widthS * 10,
            borderRightWidth: widthS * 10,
            borderBottomWidth: widthS * 10,
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            zIndex: 6,
        },
        unitsPicker: {
            width: widthS * 93,
            marginTop: heightS * 0.01,
            zIndex: 5,
            minHeight: heightS * 110,
            borderRadius: widthS * 10,
            paddingVertical: heightS * 12,
            display: "flex",
            flexDirection: "column",
        },
        selectItems: {
            display: "flex",
            position: "relative",
        },
        selectItemsUnits: {
            paddingHorizontal: widthS * 10,
            paddingVertical: heightS * 3,
            flex: 1,
            justifyContent: "flex-start",
        },
    });
} 