export const background1 = {
    linear: ["#a1e87d00", "#123504", "#0c3402"],
    popupLogin: "#003a00d9",
    bottomMenu: "#061002d4",
    bottom: "#0d3202",
    popup1: "#183a01e6",
    popup2: "#ebebeb",
    popupHeader: "#2f8e04",
    buttonLogin: "white",
    buttonLoginHover: "black",
    buttonMenuLeftHover: "#1d321c",
    rightMenu: "#1a531554",
    rightMenuSearch: "#4f7435cc",
    rightMenuCircle: "#168e04",
    rightMenuPopupMailAndSocial: "#33b70d4a",
    rightMenuPopupHeaderMailAndSocial: "#67676740",
    rightMenuPopupLogoMailAndSocial: "#168e04",
    rightMenuPopupXMailAndSocial: "#168e04",
    rightMenuPopup: "#003a00",
    rightMenuPopupItemHover: "#006100",
    gameInfoCard: "#ffffff1a",
    news: "#00897a",
    newsPopupHeader: "",
    actionItemHover: "#305c21bf",
    actionButtonAdd: "#2b8a00",
    gameInfoCardCircle: "#168e04",
    actionCircle: "#000000b8",
    actionCircleActive: "#d8fa01",
    actionProgress: "#000000b8",
    actionInput: "#294629bf",
    actionBtnBack: "#2b5225",
    mailBodyHeader: "#ced4da",
    mailBtnNew: "#1c2e10",
    newMailItemHover: "#63d738",
    filesDes: "#265a13",
    social: "#e4e7ee",
    socialSearch: "#3a5489",
    newsHeader: "#252525",
    newsLogo: "#730000",
    newsSearch: "#323232",
    chatSearch: "#1679aa",
    chatMsg: "#d9effc",
    chatMsgUser: "#f0f3fc",
    chatSend: "#e8ebf4"
}
export const color1 = {
    border: "#0bfa0059",
    buttonLogin: "black",
    buttonLoginHover: "white",
    buttonMenuLeft: "#055d16",
    buttonMenuBottom: "#168e04",
    rightMenuSearch: "#53f038",
    carouselDot: "#38ff00",
    carouselDotActive: "#168e04",
    rightMenuGameNameAndUserName: "#75ff6c",
    rightMenuClockAndDate: "#97ffbb",
    gameInfoGameStart: "#24b500",
    gameInfoStory: "#afafaf",
    gameInfoCardBorder: "#ffffff80",
    gameInfoNameS: "#989fa9",
    actionTh: "#00ff66",
    actionItemHover: "green",
    actionProgressDot1: "#b5ff00",
    actionProgressDot2: "#00a0a0",
    actionProgressDot3: "#007c7c",
    actionBtnBackBorder: "#ffffff",
    socialSearch: "#6182c7",
    socialShareWith: "#383838",
    socialNewPostIcons: "#3c3c3c",
    socialBorder: "#d5d7dc",
    newsSearch: "#444343"
}