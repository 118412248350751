import { StyleSheet, Text, View } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import { TINY_KPI } from "@env";
import { icons } from "../editor/myui/mailActionIcons";
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/media';
import 'tinymce/plugins/link';
import "./editor.css";
import { t } from 'i18n-js';
import EditorStyle from './EditorStyle';
import UseResponsiveScreen from '../modules/UseResponsiveScreen';
import { useSelector } from 'react-redux';

export default function EditorTiny({ refCurrent, textCurrent, onKeyPress, setText, placeholder, colorSkin, colorIcon, id, messageType, newMessage, setNewMessage, setImagesBody, disabled, actionType }) {

  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const [filesForBody, setFilesForBody] = useState([]);

  const editorValue = (actionType) => {
    if (actionType && actionType === "cfaChallenges") {
      return `<div>${t("cfa_challenges_msg")}</div>`;
    }
    return newMessage.body
  }

  useEffect(() => {
    const editorInstance = refCurrent?.current?.editor || toolbar?.current?.editor;
    const blurBG = () => {
      editorInstance.getBody().style.backgroundColor = '';
    }
    window.addEventListener("dragleave", blurBG);
    return () => window.removeEventListener("dragleave", blurBG);
  }, []);

  return (
    <EditorStyle colorSkin={colorSkin} direction={direction}>
      <Editor
        id={!newMessage ? id : `editor-body${id}`}
        style={{ minHeight: "5vw", padding: "1vw", width: "100%" }}
        apiKey={`${TINY_KPI}`}
        ref={refCurrent ? refCurrent : toolbar ? toolbar : null}
        inline={true}
        onKeyDown={onKeyPress}
        onDrop={(e) => {
          // מניעה מהדפדפן לטעון את התמונה
          e.preventDefault();
          // השגת הקובץ הראשון שהושלך
          const file = e.dataTransfer.files[0];
          let temp = [...filesForBody];
          temp.push(file);
          setFilesForBody(temp);
          // בדיקה אם הקובץ הוא תמונה
          if (file && file.type.startsWith('image/') && newMessage) {
            // יצירת FileReader לקריאת התמונה
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
              const imgSrc = readerEvent.target.result;
              // הוספת התמונה לעורך במקום הקורסור
              const editorInstance = refCurrent?.current?.editor || toolbar?.current?.editor;
              editorInstance?.insertContent(`<img src="${imgSrc}" alt="Uploaded image" style="max-width: 100%;"/>`);
            };

            reader.readAsDataURL(file);
          }
        }}
        onEditorChange={(text, editor) => {
          console.log(editor.getContent());
          text = text?.replace(/<p>&nbsp;<\/p>/gi, "<br>");
          if (newMessage) {
            setNewMessage(text);
          } else {
            setText(text)
          }
        }}
        onBlur={() => {
          if (newMessage) {
            setImagesBody(filesForBody);
          }
        }}
        value={newMessage ? editorValue(actionType) : textCurrent}
        init={{
          readonly: disabled || actionType === "cfaChallenges",
          setup: (editor) => {
            editor.on('keydown', function (event) {
              if ((event.keyCode == 13 && event.shiftKey) || event.keyCode == 13) {
                event.preventDefault();
                event.stopPropagation();
                editor.execCommand("InsertLineBreak", false, "");
              }
            });
            editor.on('dragover', function (e) {
              e.preventDefault();
              // שנה את הסגנון של העורך, לדוגמה הוסף גבול או שנה את צבע הרקע
              editor.getBody().style.backgroundColor = 'lightblue';
            });

            editor.on('dragleave', function (e) {
              // החזר את הסגנון למצבו הרגיל
              editor.getBody().style.backgroundColor = '';
            });

            // הוסף את האירוע dragend
            editor.on('dragend', function (e) {
              editor.getBody().style.backgroundColor = '';
            });

            editor.on('drop', function (e) {
              // ... קוד קיים שלך לטיפול בשחרור התמונה ...
              // החזר את הסגנון למצבו הרגיל
              editor.getBody().style.backgroundColor = '';
            });
          },
          plugins: ["paste", "autolink", "link", "image", "media"],
          image_title: true,
          automatic_uploads: true,
          paste_as_text: true,
          autolink_pattern: /(https?:\/\/[^\s]+)|(www\.[^\s]+)/gi,
          paste_preprocess: function (plugin, args) {
            var linkRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/gi;
            var content = args.content;
            var match;

            while ((match = linkRegex.exec(content)) !== null) {
              var url = match[0];
              var anchor = '<a href="' + (url.startsWith('www.') ? 'http://' + url : url) + '" target="_blank">' + url + '</a>';
              content = content.slice(0, match.index) + anchor + content.slice(match.index + url.length);
              linkRegex.lastIndex += anchor.length - url.length;
            }

            args.content = content;
          },
          // force_br_newlines: true,
          forced_root_block: newMessage ? true : false,
          placeholder: newMessage ? `${t("enter_text")}` : placeholder,
          fixed_toolbar_container: newMessage ? `#${id} ` : false,
          toolbar_persist: newMessage ? true : false,
          toolbar_mode: newMessage ? "floating" : false,
          toolbar_location: newMessage ? "bottom" : false,
          font_formats: `Catamaran=Catamaran;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats`,
          toolbar:
            newMessage ? "fontsizeselect fontselect color style alignment undo redo" : false,
          menubar: false,
          fontsize_formats:
            "8px 9px 10px 11px 12px 14px 18px 24px 30px 36px 48px 60px 72px 96px",
          toolbar_groups: {
            style: {
              icon: "format",
              tooltip: "Style",
              items: "italic bold strikethrough underline format",
            },
            alignment: {
              icon: "align-left",
              tooltip: "Alignment",
              items:
                "alignleft aligncenter alignright alignjustify outdent indent",
            },
            color: {
              icon: "text-color",
              tooltip: "Color",
              items: "backcolor forecolor",
            },
          },
          content_style:
            `
           @import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@500&family=Nerko+One&display=swap');
           body{ font-size: 14px !important; font-family: 'Catamaran' !important;}; 
          .tox .tox-silver-sink .tox-tinymce-aux { position: absolute; }; .tox-tbtn__select-chevron{ display: none;};
          .tox .tox-tbtn--bespoke .tox-tbtn__select-label {color: white; max-width: 3vw};`,
          icons: icons(colorIcon, 1),
        }}
      />
    </EditorStyle>
  )
}

const styles = StyleSheet.create({})
{/* <Editor
disabled={(!isEditing && !!existAction)}
apiKey={`${TINY_KPI}`}
ref={toolbar}
inline={true}
onEditorChange={(text, editor) => {
  setNewMessage({ ...newMessage, body: text });
}}
value={
  messageType === "forward" ||
    messageType === "reply" ||
    newMessage.body
    ? newMessage.body
    : ""
}
init={{
  auto_focus: true,
  placeholder: messageType !== "forward" && `${t("enter_text")}`,
  fixed_toolbar_container: `#mytoolbar-flow`,
  toolbar_persist: true,
  toolbar_drawer: "floating",
  toolbar_location: "bottom",
  toolbar:
    "fontsizeselect fontselect color style alignment undo redo",
  menubar: "",
  fontsize_formats:
    "8px 9px 10px 11px 12px 14px 18px 24px 30px 36px 48px 60px 72px 96px",
  toolbar_groups: {
    style: {
      icon: "format",
      tooltip: "Style",
      items: "italic bold strikethrough underline format",
    },
    alignment: {
      icon: "align-left",
      tooltip: "Alignment",
      items:
        "alignleft aligncenter alignright alignjustify outdent indent",
    },
    color: {
      icon: "text-color",
      tooltip: "Color",
      items: "backcolor forecolor",
    },
  },
  content_css: showDetails && [
    contectDark,
    skinDark
  ],
  // theme_url: "../../../../editor/myui/silver/",
  content_style: `body, .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {font-size: ${Math.floor(
    widthS * 9
  )}px}; 
                .tox .tox-silver-sink .tox-tinymce-aux { position: absolute; }; .tox-tbtn__select-chevron{ display: none;};
                ;.tox.tox-tinymce-aux .tox-toolbar__overflow {background-color: black;}`,
  icons: icons(color.infoHeaderNavText, 1),
}}
/> */}