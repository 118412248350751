import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Pressable,
  Image,
  ScrollView,
} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18n-js";
import { USER_GAME_NAME } from "../../../config.inc";
import { newChat, newMsgChat } from "../../../redux/actions";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { ChatContext } from "../../../ContextData/Chat";
import onClickEndGameCheck from "../../Elements/onClickEndGameCheck";
import EditorTiny from "../../editor/EditorTiny";

export default function ChatSendMessage({
  data,
  scrollTo,
  isGroup,
  setLoading,
  loading,
  dashboard
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);
  const { checkEnd } = onClickEndGameCheck();

  const dispatch = useDispatch();
  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);
  const { sendMessageData } = useContext(ChatContext);


  const messageRef = useRef(null);

  const [newMessage, setNewMessage] = useState({
    text: "",
    file: null,
  });
  const [rowsCount, setRowsCount] = useState(1);
  const [inputHeight, setInputHeight] = useState();
  const [addedNewLine, setAddedNewLine] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const openFilesBrowser = () => {
    DocumentPicker.getDocumentAsync({
      multiple: false,
    }).then((res) => {
      setNewMessage({
        ...newMessage,
        file: res,
      });
    });
  };

  const sendNewMessage = () => {
    if (newMessage.text?.trim().length > 0) {
      setDisabled(false);
      setLoading(true);
      let dataUser = { ...newMessage, ...data };
      if (isGroup?.group) {
        dataUser.group = true;
        dataUser.id = data.to
      }
      sendMessageData(dataUser).then(() => {
        setLoading(false);
        setDisabled(true);
      }).catch(() => {
        setLoading(false);
        setDisabled(true);
      });
      setRowsCount(1);
      setNewMessage({
        text: "",
        file: null,
      });
      scrollTo();
    }
    /// some message for error
  };

  useEffect(() => {
    if (inputHeight === 1) {
      setInputHeight(messageRef.current.scrollHeight);
    }
  }, [inputHeight]);
  return (
    <View
      nativeID="send-message"
      style={[
        styles.container,
        { backgroundColor: background.popup1, borderLeftColor: color.border },
      ]}
    >
      <View style={[styles.body, { backgroundColor: background.chatSearch }]}>
        <ScrollView
          nativeID="input"
          style={styles.inp_view_c}
          contentContainerStyle={styles.inp_view}
        >
          {newMessage.file && (
            <View style={[styles.show_file, { borderColor: color.border }]}>
              <Text
                selectable={false}
                style={[styles.show_file_text, { color: color.x }]}
              >
                {newMessage.file.name.length > 30
                  ? newMessage.file.name.slice(0, 30)
                  : newMessage.file.name}
              </Text>
              <Pressable
                disabled={!disabled}
                onPress={() => setNewMessage({ ...newMessage, file: null })}
                style={[
                  styles.show_file_x,
                  { backgroundColor: background.popup2 },
                ]}
              >
                <Text selectable={false} style={[styles.x, { color: color.x }]}>
                  X
                </Text>
              </Pressable>
            </View>
          )}
          <EditorTiny
            colorSkin={true}
            placeholder={""}
            id={`chat-editor${dashboard ? "-dashboard" : ""}`}
            setText={(txt) => {
              setNewMessage({
                ...newMessage,
                text: txt,
              });
            }}
            refCurrent={messageRef}
            disabled={loading}
            textCurrent={newMessage.text}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                sendNewMessage();
              }
            }}
          />
        </ScrollView>
      </View>
      <View style={styles.right}>
        {/* <Pressable onPress={() => openFilesBrowser()}>
          <Image source={{ uri: images.attachmentIcon }} style={styles.icon} />
        </Pressable> */}
        <Pressable
          style={[styles.btn, { backgroundColor: background.chatLogo }]}
          onPress={() => {
            checkEnd(() => {
              sendNewMessage();
              setInputHeight();
            })
          }}
        >
          <Text selectable={false} style={styles.btn_text}>
            {t("send")}
          </Text>
        </Pressable>
      </View>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    container: {
      position: "absolute",
      bottom: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      minHeight: heightS * 40.1,
      paddingVertical: heightS * 5,
      paddingLeft: widthS * 14,
      // paddingRight: widthS * 22,
      zIndex: 1,
      borderLeftWidth: 1,
      right: widthS * 8,
      borderBottomRightRadius: 8,
    },
    body: {
      borderRadius: widthS * 3,
      minHeight: heightS * 20,
      marginVertical: heightS * 4,
      flex: 4,
    },
    inp_view_c: {
      width: "100%",
      maxHeight: heightS * 50,
      marginRight: widthS * 5,
      borderRadius: widthS * 3,
    },
    inp_view: {
      minHeight: heightS * 20,
      borderRadius: widthS * 5,
      paddingVertical: heightS * 2,
      justifyContent: "center",
    },
    img: {
      width: widthS * 60,
      height: heightS * 35,
      resizeMode: "contain",
      alignSelf: "flex-start",
      marginLeft: widthS * 10,
    },
    inp: {
      paddingVertical: heightS * 2,
      paddingHorizontal: widthS * 5,
      fontSize: widthS * 8,
      fontFamily: "Catamaran_400Regular",
      outlineStyle: "none",
      color: "#ffffff",
    },
    right: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      flex: 1,
    },
    show_file: {
      alignSelf: "flex-start",
      marginLeft: widthS * 5,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      borderWidth: 1,
      borderRadius: widthS * 3,
      marginVertical: heightS * 3,
    },
    show_file_x: {
      width: widthS * 15,
      height: widthS * 15,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 20,
      marginLeft: widthS * 5,
      marginRight: widthS * 0.6,
    },
    x: {
      fontSize: widthS * 8,
      fontFamily: "Catamaran_600SemiBold",
    },
    show_file_text: {
      paddingLeft: widthS * 7,
      paddingBottom: heightS * 2,
      fontSize: widthS * 8,
      fontFamily: "Catamaran_400Regular",
    },
    icon: {
      width: widthS * 11,
      height: widthS * 11,
      resizeMode: "contain",
      marginLeft: widthS * 7,
    },
    btn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: widthS * 33,
      height: heightS * 19.5,
      borderRadius: widthS * 5,
      marginLeft: widthS * 6,
    },
    btn_text: {
      color: "white",
      fontSize: widthS * 8,
      fontFamily: "Catamaran_600SemiBold",
    },
  });
