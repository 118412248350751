import {
  View,
  Text,
  Modal,
  StyleSheet,
  Dimensions,
  Pressable,
  TextInput,
  Image,
  ScrollView,
} from "react-native";
import React, { useState, useRef, useContext, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18n-js";
import * as DocumentPicker from "expo-document-picker";
import { Ionicons } from "@expo/vector-icons";
import { GameInfoContext } from "../../../../ContextData/GameInfo";
import { useClickOutside } from "../../../modules/useClickOutside";
import UseResponsiveSize from "../../../modules/UseResponsiveSize";
import "./scroll.css";
import ShortenWord from "../../../modules/ShortenWord";
import UseResponsiveScreen from "../../../modules/UseResponsiveScreen";
import { convertByte } from "../../../modules/ConvertByte";
import { loading, setErrorMessage } from "../../../../redux/actions";
import { MAX_FILES_SIZE } from "@env";

export default function AddPlayer({ flag, setFlag, isRepresented, permission }) {

  const dispatch = useDispatch();

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);
  const players = useSelector((state) => state.gameInfoArr.gameInfoArr.players);

  const { addPlayerHandler } = useContext(GameInfoContext);

  const playerObj = useSelector((state) => state.players.player);
  const [player, setPlayer] = useState({
    represented: isRepresented,
    ...playerObj,
  });

  const [showTypesSelect, setShowTypesSelect] = useState(false);
  const [showObserveSelect, setShowObserveSelect] = useState(false);
  const [err, setErr] = useState("");

  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, background, color, showTypesSelect, err, direction);

  const typesSelectOptions = [
    { name: t("game_controller"), value: "1" },
    // { name: `${t("game_controller_observer")}`, value: "10" },
    { name: t("player"), value: "15" },
    // { name: `${t("player_observer")}`, value: "17" },
  ];

  const [typesSelectTitle, setTypesSelectTitle] = useState("");
  const [observeSelectTitle, setObserveSelectTitle] = useState(
    player?.player_observer || t("observer_select_title")
  );

  const addPlayerRef = useRef();
  const typesSelectRef = useRef();
  const observeSelectRef = useRef();

  const openFilesBrowser = () => {
    let types = "image/png, image/jpg, image/jpeg";
    DocumentPicker.getDocumentAsync({
      type: types,
    }).then((res) => res.assets[0]).then((res) => {
      let size = convertByte(res.size);
      if (size > MAX_FILES_SIZE) {
        dispatch(setErrorMessage(true, false, t("err_fileSize_mail")));
      } else {
        if (types.indexOf(res.mimeType) < 0) {
          dispatch(setErrorMessage(true, false, t("add_player_image_type_err")));
        } else {
          setPlayer({ ...player, profile_image: res });
        }
      }
    });
  };

  const representedBtnPressHandler = () => {
    setPlayer({
      ...player,
      represented: player.represented === "on" ? false : "on",
    });
  };

  const saveInChatBtnPressHandler = () => {
    setPlayer({
      ...player,
      show_in_chat: !player.show_in_chat,
    });
  };

  const selectBtnPressHandler = (setShow) => {
    setShow((prev) => !prev);
  };

  const checkErrors = (obj) => {
    let errText = err;
    if (!obj?.name_for_game || obj?.name_for_game.replace(/[0-9,.?!@#$%&*]/gm, "")?.length < 2) {
      if (errText.indexOf("name,") < 0) {
        errText += "name,";
      }
    } else {
      errText = errText.replace("name,", "");
    }
    if (!player.represented) {
      if (!obj?.email || obj?.email?.indexOf("@") < 0) {
        if (errText.indexOf("email,") < 0) {
          errText = errText += "email,";
        }
      } else {
        errText = errText.replace("email,", "");
      }
      if (!obj?.permission_for_game || obj?.permission_for_game?.length === 0) {
        if (errText.indexOf("permission,") < 0) {
          errText += "permission,";
        }
      } else {
        errText = errText.replace("permission,", "");
      }
      if (obj.permission_for_game === "17" && !obj?.player_observer) {
        if (errText.indexOf("observer,") < 0) {
          errText += "observer,";
        }
      } else {
        errText = errText.replace("observer,", "");
      }
    } else {
      errText = errText.indexOf("name,") < 0 ? "" : errText;
    }
    if (isCompletedForm && errText.length === 0) {
      return true;
    }
    setErr(errText);
  }

  useEffect(() => {
    setPlayer;
  }, [player?.player_type]);

  // const selectOptionPressHandler = (type, option, setTitle, setShow) => {
  //   setPlayer({});
  //   setShow(false);
  // };

  const playerActive = (type) => {
    switch (type) {
      case "false":
        return "pending";
      case "true":
        return "registered";
      case "active":
        return "active";
      case "inactive":
        return "inactive"
    }
  }

  const observeSelect = (
    <View ref={observeSelectRef} disabled={!permission} style={[styles.select, { flex: 1, marginTop: heightS * 7 }, err.indexOf("observer") >= 0 && styles.mail_error]}>
      <Pressable
        style={[
          styles.input,
          styles.selectBtn,
          {
            borderBottomWidth: showObserveSelect ? widthS * 1 : 0,
            borderBottomRightRadius: showObserveSelect ? 0 : widthS * 12,
            borderBottomLeftRadius: showObserveSelect ? 0 : widthS * 12,
            backgroundColor: background.infoBody,
            color: color.infoHeaderNavText,
            borderBottomColor: color.border,
            marginTop: 0,
          },
        ]}
        onPress={() => selectBtnPressHandler(setShowObserveSelect)}
        disabled={
          player.permission_for_game !== "17" &&
          player.permission_for_game !== "10"
        }
      >
        <Text
          numberOfLines={1}
          style={[
            styles.selectBtnText,
            { color: permission ? color.infoHeaderNavText : color.placeholder },
            player.permission_for_game !== "17" &&
            player.permission_for_game !== "10" && { color: "grey" },
          ]}
        >
          {!player?.player_observer || (player?.permission_for_game !== "10" && player?.permission_for_game !== "17")
            ? t("observer_select_title")
            : ShortenWord(player.observer_name, 26)}
        </Text>
        <Ionicons
          name={showObserveSelect ? "caret-up" : "caret-down"}
          color={
            player.player_type !== "game_controller_observer" &&
              player.player_type !== "player_observer"
              ? "grey"
              : color.infoHeaderNavText
          }
        />
      </Pressable>
      {showObserveSelect && (
        <View
          style={[
            styles.selectContent,
            { backgroundColor: background.infoBody },
          ]}
        >
          <ScrollView style={{ maxHeight: heightS * 150 }} nativeID="chat">
            {(() => {
              return players
                .filter(
                  (p) =>
                    p.name_for_game !== observeSelectTitle &&
                    (player.permission_for_game !== "10" ||
                      p.permission_for_game === "1") && p.is_represented === "0"
                )
                .map((option, i) => (
                  <Pressable
                    key={i}
                    onPress={
                      () => {
                        setPlayer({
                          ...player,
                          player_observer: option.id,
                          observer_name: option.name_for_game
                        });
                        setShowObserveSelect(false);
                      }
                      // selectOptionPressHandler(
                      //   "player_observer",
                      //   option,
                      //   setObserveSelectTitle,
                      //   setShowObserveSelect
                      // )
                    }
                    style={styles.selectOption}
                  >
                    <Text
                      style={[
                        styles.selectBtnText,
                        { color: color.infoHeaderNavText },
                      ]}
                    >
                      {option.name_for_game}
                    </Text>
                  </Pressable>
                ))
            })()}
          </ScrollView>
        </View>
      )}
    </View>
  );

  const typesSelect = (err) => {
    return (
      <View
        ref={typesSelectRef}
        style={[styles.select, styles.email]}
      >
        <Pressable
          disabled={!permission}
          style={[
            styles.input,
            styles.selectBtn,
            styles.type_select_pressable,
            err?.indexOf("permission") >= 0 && styles.mail_error,
          ]}
          onPress={() => selectBtnPressHandler(setShowTypesSelect)}
        >
          <Text
            style={[styles.selectBtnText, { color: permission ? color.infoHeaderNavText : color.placeholder }]}
          >
            {player?.permission_for_game
              ? typesSelectOptions.find(
                (e) => e.value === player.permission_for_game
              )?.name
              : `${t("player_type")}`}
          </Text>
          <Ionicons
            name={showTypesSelect ? "caret-up" : "caret-down"}
            color={color.infoHeaderNavText}
          />
        </Pressable>
        {showTypesSelect && (
          <View
            style={[
              styles.selectContent,
              { backgroundColor: background.infoBody },
            ]}
          >
            {typesSelectOptions
              .map((option, i) => {
                if (option.value === player.permission_for_game) return;
                return (
                  <Pressable
                    key={i}
                    onPress={
                      () => {
                        setPlayer({
                          ...player,
                          permission_for_game: option.value,
                        });
                        setShowTypesSelect(false);
                      }
                    }
                    style={styles.selectOption}
                  >
                    <Text
                      style={[
                        styles.selectBtnText,
                        { color: color.infoHeaderNavText },
                      ]}
                    >
                      {option.name}
                    </Text>
                  </Pressable>
                );
              })}
          </View>
        )}
      </View>
    )
  };

  let isCompletedForm =
    (player.name_for_game?.trim() &&
      player.email?.trim() &&
      player.permission_for_game &&
      (player.permission_for_game === "17" ? player.player_observer : true) &&
      !player.represented) ||
    (player.name_for_game?.trim() && player.represented);

  // useClickOutside(addPlayerRef, flag, () => setFlag());
  useClickOutside(typesSelectRef, showTypesSelect, setShowTypesSelect);
  useClickOutside(observeSelectRef, showObserveSelect, setShowObserveSelect);

  const validatePlayer = (player, players, dispatch, t) => {
    // בדיקה אם השם כבר קיים ברשימה, תוך התעלמות מהמשתמש הנוכחי במקרה של עריכה
    const isNameTaken = players.some(p =>
      p.id !== player.id &&
      p.name_for_game?.toLowerCase().trim() === player.name_for_game?.toLowerCase().trim()
    );

    // בדיקה אם המייל כבר קיים ברשימה, תוך התעלמות מהמשתמש הנוכחי במקרה של עריכה
    const isEmailTaken = players.some(p =>
      player.is_represented !== "1" && p.id !== player.id &&
      p.email?.toLowerCase().trim() === player.email?.toLowerCase().trim()
    );

    if (isNameTaken) {
      // שגיאה אם השם כבר קיים
      dispatch(setErrorMessage(true, false, `${t("add_player_name_err1")} "${player.name_for_game}"  ${t("add_player_name_err2")}`));
      return false;
    }

    if (isEmailTaken) {
      // שגיאה אם המייל כבר קיים
      dispatch(setErrorMessage(true, false, `${t("add_player_email_err")}`));
      return false;
    }
    return true;
  }

  return (
    <Modal transparent={true} visible={true} animationType="none">
      <View style={styles.container}>
        <View
          ref={addPlayerRef}
          style={[
            styles.main,
            {
              backgroundColor: background.popupLogin,
              shadowColor: color.addPlayerPopupShadow,
            },
          ]}
        >
          <View
            style={[
              styles.header,
              {
                borderColor: color.border,
                borderBottomColor: color.addPlayerHeaderBorderColor,
              },
            ]}
          >
            <Image
              style={[
                styles.icon,
                { borderColor: background.addPlayerIconBorder },
              ]}
              source={{ uri: images.user_image }}
            />

            <Text
              style={[styles.logo_text, { color: color.infoHeaderNavText }]}
            >
              {`${player.represented ? t("add_new_rep_player") : t("add_new_real_player")}`.toUpperCase()}
            </Text>
            <Pressable style={styles.x_btn} onPress={() => setFlag()}>
              <Text style={[styles.x_text, { color: background.closeBtn }]}>
                X
              </Text>
            </Pressable>
          </View>
          <View style={styles.body}>
            <View style={styles.inputs}>
              <View style={styles.rowInputs}>
                <Pressable
                  disabled={!permission}
                  onPress={openFilesBrowser}
                  style={[
                    styles.photoInput,
                    {
                      borderColor: color.logoColor,
                      backgroundColor: background.infoBody,
                    },
                  ]}
                >
                  {player.profile_image ? (
                    <View
                      style={[styles.photoInput, styles.linearBorder]}
                      nativeID="linear-border"
                    >
                      <Image
                        style={[
                          styles.photoInput,
                          { backgroundColor: background.infoBody },
                        ]}
                        source={{ uri: player.profile_image.uri ? player.profile_image.uri : player.profile_image }}
                      />
                    </View>
                  ) : (
                    <>
                      <Image
                        source={{ uri: images.plusIcon }}
                        style={styles.plusIcon}
                      />
                      <Text
                        style={[
                          styles.selectBtnText,
                          { color: color.infoHeaderNavText },
                        ]}
                      >
                        {t("add_photo")}
                      </Text>
                    </>
                  )}
                </Pressable>
                <View style={{ flex: 1, paddingHorizontal: widthS * 15 }}>
                  {err?.indexOf("name,") >= 0 && <Text style={styles.errText}>{t("add_player_err_name")}</Text>}
                  <TextInput
                    disabled={!permission}
                    style={[
                      styles.input,
                      {
                        paddingVertical: heightS * 6,
                        backgroundColor: background.infoBody,
                        color: permission ? color.infoHeaderNavText : color.placeholder,
                        marginTop: 0,
                      },
                      err?.indexOf("name") >= 0 ? styles.mail_error : ""
                    ]}
                    placeholder={t("simulation_title")}
                    placeholderTextColor={color.infoHeaderNavText}
                    value={player.name_for_game || ""}
                    onChangeText={(e) =>
                      setPlayer({ ...player, name_for_game: e })
                    }
                  />
                  {player.represented ? (
                    <TextInput
                      disabled={!permission}
                      placeholder={t("department_optional")}
                      style={[
                        styles.input,
                        {
                          paddingVertical: heightS * 6,
                          flex: 1,
                          backgroundColor: background.infoBody,
                          color: color.infoHeaderNavText,
                        },
                      ]}
                      placeholderTextColor={color.infoHeaderNavText}
                      value={player.department || ""}
                      onChangeText={(e) =>
                        setPlayer({ ...player, department: e })
                      }
                    />
                  ) : (
                    <TextInput
                      disabled
                      style={[
                        styles.input,
                        {
                          paddingVertical: heightS * 6,
                          backgroundColor: background.infoBody,
                          color: "grey",
                          marginTop: heightS * 7,
                        },
                      ]}
                      placeholder={t("name")}
                      placeholderTextColor={"grey"}
                      value={
                        player.real_name ? player.real_name
                          : ""
                      }
                    // onChangeText={(e) =>
                    //   setPlayer({ ...player, name: e })
                    // }
                    />
                  )}
                </View>
                <View
                  style={{
                    paddingTop: heightS * 15,
                    paddingRight: widthS * 8,
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={[
                      styles.checkBoxContainer,
                      { opacity: permission ? 1 : 0.6 }
                      // player.represented && { paddingBottom: heightS * 47 },
                    ]}
                  >
                    <Pressable
                      disabled={!permission}
                      onPress={representedBtnPressHandler}
                      style={[
                        styles.checkBox,
                        { backgroundColor: background.infoBody },
                      ]}
                    >
                      {player.represented && (
                        <View
                          style={[
                            styles.checkBoxInner,
                            { backgroundColor: background.count },
                          ]}
                        ></View>
                      )}
                    </Pressable>
                    <Text
                      style={[
                        styles.checkBoxText,
                        { color: color.infoHeaderNavText },
                      ]}
                    >
                      {t("represented").toUpperCase()}
                    </Text>
                  </View>
                  {/*{player.represented ? (*/}
                  {/*    <View*/}
                  {/*        style={[*/}
                  {/*          styles.checkBoxContainer,*/}
                  {/*     */}
                  {/*        ]}*/}
                  {/*    >*/}
                  {/*      <Pressable*/}
                  {/*          onPress={saveInChatBtnPressHandler}*/}
                  {/*          style={[*/}
                  {/*            styles.checkBox,*/}
                  {/*            { backgroundColor: background.infoBody },*/}
                  {/*          ]}*/}
                  {/*      >*/}
                  {/*        <Text*/}
                  {/*            style={[*/}
                  {/*              styles.checkBoxText,*/}
                  {/*              { color: color.infoHeaderNavText },*/}
                  {/*            ]}*/}
                  {/*        >*/}
                  {/*          {t("show in chat").toUpperCase()}*/}
                  {/*        </Text>*/}
                  {/*      </Pressable>*/}
                  {/*    </View>*/}
                  {/*):(null)}*/}
                  {
                    <View
                      style={{
                        marginBottom: heightS * 7,
                        opacity: player.represented ? 0 : 1,
                      }}
                    >
                      <Text
                        style={[
                          styles.statusText,
                          { color: color.infoHeaderNavText },
                        ]}
                      >{`${t("status").toUpperCase()}:`}</Text>
                      <View style={styles.statusContainer}>
                        <View
                          style={[
                            styles.status,
                            { backgroundColor: player.color_status },
                          ]}
                        ></View>
                        {(player?.status || player?.active) &&
                          <Text
                            style={styles.statusTextValue}
                          >
                            {t(playerActive(player?.status || player?.active))}
                          </Text>}
                      </View>
                    </View>
                  }
                </View>
              </View>
              {!player.represented && (
                <View style={{ zIndex: 5 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "90%",
                      alignItems: "center",
                      marginHorizontal: "auto",
                    }}
                  >
                    <TextInput
                      disabled={!permission}
                      placeholder={t("email")}
                      style={[
                        styles.input,
                        styles.email,
                        {
                          backgroundColor: background.infoBody,
                          color: permission ? color.infoHeaderNavText : color.placeholder,
                        },
                        err?.indexOf("mail") >= 0 ? styles.mail_error : ""
                      ]}
                      placeholderTextColor={color.placeholder}
                      value={player.email || ""}
                      onChangeText={(e) => setPlayer({ ...player, email: e })}
                    />
                    <TextInput
                      disabled={!permission}
                      placeholder={t("department_optional")}
                      style={[
                        styles.input,
                        {
                          paddingVertical: heightS * 6,
                          flex: 1,
                          backgroundColor: background.infoBody,
                          color: permission ? color.infoHeaderNavText : color.placeholder,
                        },
                      ]}
                      placeholderTextColor={color.placeholder}
                      value={player.department || ""}
                      onChangeText={(e) =>
                        setPlayer({ ...player, department: e })
                      }
                    />
                  </View>
                  <View
                    style={[styles.type_select_view]}
                  >
                    {typesSelect(err)}
                    {!player.represented && player.permission_for_game === "17" && observeSelect}
                  </View>
                </View>
              )}
              <View style={styles.description}>
                <TextInput
                  nativeID="scroll-flow"
                  disabled={!permission}
                  multiline={true}
                  style={[
                    styles.input,
                    {
                      height: heightS * 80,
                      padding: widthS * 7,
                      backgroundColor: background.infoBody,
                      color: permission ? color.infoHeaderNavText : color.placeholder,
                      fontFamily: "Catamaran_200ExtraLight",
                    },
                  ]}
                  placeholder={`${t("description")}`}
                  value={player.player_description || ""}
                  onChangeText={(e) =>
                    setPlayer({ ...player, player_description: e })
                  }
                  maxLength={75}
                  placeholderTextColor={color.infoHeaderNavText}
                />
              </View>
              <View style={styles.saveBtnContainer}>
                <Text
                  style={{
                    fontSize: widthS * 7,
                    fontFamily: "Catamaran_200ExtraLight",
                    color: color.infoHeaderNavText,
                    paddingTop: heightS * 2,
                  }}
                >
                  {player.player_description?.length || 0}/75
                </Text>
                {permission && <View>
                  <Pressable
                    disabled={!permission}
                    onPress={() => {
                      if (checkErrors(player)) {
                        if (validatePlayer(player, players, dispatch, t)) {
                          dispatch(loading(true));
                          addPlayerHandler(player)
                            .then(() => dispatch(loading(false))).catch(() => dispatch(loading(false)));
                        }
                      }
                    }}
                    style={[
                      styles.saveBtn,
                      isCompletedForm
                        ? {
                          backgroundColor: background.infoSaveBtn,
                          borderColor: "transparent",
                        }
                        : { borderColor: color.infoHeaderNavText },
                    ]}
                  >
                    <Text
                      style={[
                        styles.saveBtn_text,
                        isCompletedForm
                          ? { color: color.text2 }
                          : { color: color.infoHeaderNavText },
                      ]}
                    >
                      {t("save")}
                    </Text>
                  </Pressable>
                </View>}
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const stylesR = (width, height, heightS, widthS, background, color, showTypesSelect, err, direction) => {
  return StyleSheet.create({
    container: {
      alignItems: "center",
      paddingTop: heightS * 80,
      height: "100%",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      backgroundColor: "#00000057",
    },
    main: {
      width: widthS * 370,
      // minHeight: "78%",
      borderRadius: widthS * 25,
      shadowRadius: 6,
      paddingBottom: heightS * 15,
    },
    header: {
      borderBottomWidth: widthS,
      flexDirection: "row",
      // borderTopLeftRadius: widthS * 4,
      // borderTopRightRadius: widthS * 4,
      paddingTop: heightS * 10,
      paddingBottom: heightS * 7,
      paddingLeft: widthS * 20,
      width: "100%",
      alignItems: "center",
    },
    icon: {
      width: widthS * 20,
      height: widthS * 20,
      borderRadius: "50%",
      borderWidth: widthS * 1,
      marginRight: widthS * 5,
    },
    logo_text: {
      fontSize: widthS * 11,
      fontFamily: "Catamaran_600SemiBold",
    },
    x_btn: {
      position: "absolute",
      right: widthS * 16,
      top: widthS * 5,
    },
    x_text: {
      fontSize: widthS * 16,
      fontWeight: "300",
    },
    body: {
      justifyContent: "space-between",
      flex: 1,
    },
    inputs: {
      paddingTop: heightS * 10,
    },
    input: {
      paddingVertical: heightS * 8,
      fontSize: widthS * 10,
      fontFamily: "Catamaran_200ExtraLight",
      marginTop: heightS * 7,
      borderRadius: widthS * 10,
      paddingHorizontal: widthS * 10,
      writingDirection: direction
    },
    email: {
      paddingVertical: heightS * 6,
      flex: 1,
      marginRight: widthS * 7,
    },
    saveBtnContainer: {
      textAlign: "right",
      alignSelf: "center",
      // justifyContent: "flex-end",
      width: "89%",
      zIndex: 1,
    },
    saveBtn: {
      marginTop: heightS * 10,
      paddingHorizontal: widthS * 7,
      paddingVertical: heightS * 2,
      borderRadius: widthS * 7,
      alignItems: "center",
      alignSelf: "flex-end",
      justifyContent: "center",
      borderWidth: widthS * 1,
    },
    saveBtn_text: {
      fontSize: widthS * 11,
      fontFamily: "Catamaran_400Regular",
    },
    photoInput: {
      width: widthS * 80,
      height: widthS * 80,
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      paddingVertical: heightS * 10,
    },
    linearBorder: {
      width: widthS * 84,
      height: widthS * 84,
      padding: 0,
    },
    plusIcon: {
      width: widthS * 30,
      height: widthS * 30,
      resizeMode: "cover",
      marginBottom: heightS * 2,
    },
    rowInputs: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      paddingHorizontal: widthS * 10,
      alignItems: "center",
      paddingBottom: heightS * 10,
    },
    checkBoxContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    checkBox: {
      marginRight: widthS * 5,
      width: widthS * 13,
      height: widthS * 13,
      borderRadius: widthS * 3,
      justifyContent: "center",
      alignItems: "center",
    },
    checkBoxInner: {
      width: widthS * 8,
      height: widthS * 8,
      borderRadius: widthS * 2,
    },
    checkBoxText: {
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
    },
    centerInputs: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: heightS * 10,
      paddingHorizontal: widthS * 20,
    },
    statusContainer: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    status: {
      width: widthS * 8,
      height: widthS * 8,
      borderRadius: "50%",
      marginRight: widthS * 3,
    },
    statusText: {
      paddingTop: heightS * 10,
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
    },
    statusTextValue: {
      color: color.infoHeaderNavText,
      fontSize: widthS * 9,
      fontFamily: "Catamaran_200ExtraLight",
    },
    description: {
      width: "90%",
      borderRadius: widthS * 12,
      marginTop: heightS * 5,
      marginHorizontal: "auto",
      zIndex: 1,
    },
    select: {
      position: "relative",
      paddingVertical: 0,
    },
    selectBtn: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    selectBtnText: {
      fontSize: widthS * 10,
      fontWeight: 300
    },
    selectContent: {
      position: "absolute",
      zIndex: 5,
      borderBottomRightRadius: widthS * 12,
      borderBottomLeftRadius: widthS * 12,
      top: heightS * 34,
      width: "100%",
    },
    selectOption: {
      paddingHorizontal: widthS * 10,
      paddingVertical: heightS * 10,
    },
    mail_error: {
      borderColor: "red",
      borderWidth: "2px",
      borderRadius: widthS * 10,
    },
    type_select_view: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "90%",
      alignItems: "center",
      marginHorizontal: "auto",
      zIndex: 5,
      height: heightS * 39,
    },
    type_select_pressable: {
      borderBottomWidth: showTypesSelect && widthS * 1,
      borderBottomRightRadius: showTypesSelect ? 0 : widthS * 12,
      borderBottomLeftRadius: showTypesSelect ? 0 : widthS * 12,
      backgroundColor: background.infoBody,
      color: color.infoHeaderNavText,
      borderBottomColor: !err && "green",
    },
    errText: {
      position: "absolute",
      top: "-1.5vw",
      color: color.infoSaveBtn,
      fontSize: "0.8vw",
      fontFamily: "Catamaran_400Regular"
    },
  });
};
