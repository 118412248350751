import { Image, ScrollView, StyleSheet, Text, View } from 'react-native'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import Widget from '../Elements-btn/Widget';
import { t } from 'i18n-js';
import { useState } from 'react';
import { useEffect } from 'react';
import FullTextTooltip from '../../../Elements/FullTextTooltip';

export default function ActivePlayers() {

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(background, color, direction);
    const players = useSelector((state) => state.players.realPlayers);

    const [inactivePlayers, setInactivePlayers] = useState([]);

    useEffect(() => {
        if (players?.length > 0) {
            let arr = [];
            players.forEach((e) => {
                if (!e.status) {
                    arr.push(e);
                }
            });
            setInactivePlayers([...arr]);
        }
    }, [players]);

    const component = (
        <>
            <Text style={styles.headerText}>{t("inactive_widget_title")}</Text>
            <ScrollView nativeID='inactive-players'>
                {inactivePlayers?.map((player, index) => {
                    return (
                        <View key={index} style={styles.playerView}>
                            <View style={styles.playerLeft}>
                                <View style={styles.imgView}>
                                    <Image style={styles.img} source={{ uri: player?.profile_image ? player?.profile_image : images.user_image }} />
                                </View>
                                <View>
                                    <FullTextTooltip text={player.real_name}>
                                        <Text style={styles.realName} numberOfLines={1}>{player.real_name?.toUpperCase()}</Text>
                                    </FullTextTooltip>
                                    <FullTextTooltip text={player.name_for_game}>
                                        <Text style={styles.gameName} numberOfLines={1}>{player.name_for_game}</Text>
                                    </FullTextTooltip>

                                </View>
                            </View>
                            <View style={styles.statusView(player?.color_status)} />
                        </View>
                    )
                })}
            </ScrollView>
        </>
    )

    return (
        <View style={styles.container}>
            <Widget
                title={t("inactive_players")}
                icon={images.inactive}
                type={"single"}
                imgColor={true}
                component={component}
            />
        </View>
    )
}

const stylesR = (background, color, direction) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            height: "100%",
            writingDirection: direction,
        },
        headerText: {
            color: color.text2,
            fontSize: "0.8vw",
            lineHeight: "1.2vw",
            marginBottom: "0.2vw",
            fontFamily: "Catamaran_400Regular",
        },
        playerView: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginVertical: "0.2vw",
            writingDirection: "ltr",
        },
        playerLeft: {
            flexDirection: "row",
            alignItems: "center",
        },
        imgView: {
            width: "1.7vw",
            height: "1.7vw",
            borderRadius: "100%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: background.count,
            marginRight: "0.5vw"
        },
        img: {
            width: "1.6vw",
            height: "1.6vw",
            resizeMode: "cover",
            borderRadius: "100%"
        },
        realName: {
            color: color.text2,
            fontSize: "0.7vw",
            lineHeight: "0.7vw",
            fontFamily: "Catamaran_600SemiBold",
            width: "8vw",
            writingDirection: "ltr",
        },
        gameName: {
            color: color.text2,
            fontSize: "0.7vw",
            lineHeight: "0.7vw",
            fontFamily: "Catamaran_400Regular",
            width: "8vw",
            writingDirection: "ltr",
        },
        statusView: (color) => ({
            width: "0.5vw",
            height: "0.5vw",
            borderRadius: "100%",
            backgroundColor: color
        }),
    })
};