import { Pressable, ScrollView, View, StyleSheet, Text } from "react-native";
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Event from "./Event";
import UseResponsiveSize from "../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../modules/UseResponsiveScreen";
import { AntDesign } from "@expo/vector-icons";
import CreateAction from "./event-components/CreateAction";
import onClickEndGameCheck from "../../../Elements/onClickEndGameCheck";
import { pxToVw } from "../../../modules/ConvertPXToVw";
import FullTextTooltip from "../../../Elements/FullTextTooltip";

export default function EventsScroll({
  active,
  setActive,
  show,
  isPossibletoClose,
  setShow,
  addEvent,
  editEventFunc,
  deleteEventfunc,
  createNextEventHandler,
  onPressHandler,
  permission,
  index,
  setIndex,
  scrollRef,
  kpis,
  setLoad,
  eventsRef,
  scrollOffsetX,
  setScrollOffsetX,
  onArrowLeftPressHandler,
  onArrowRightPressHandler
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);
  const events = useSelector((state) => state.events.events);
  const color = useSelector((state) => state.style.color);

  const { checkEnd, endGame } = onClickEndGameCheck();

  const eventContentRef = useRef(null);
  const titleTextRef = useRef(null);

  const [disabledArrow, setDisabledArrow] = useState(false);
  const [descMarginLeft, setDescMarginLeft] = useState(null);
  const [eventDetails, setEventDetails] = useState({
    id: active?.id || Math.random(),
    title: active?.title || "",
    description: active?.description || "",
    color: active?.color || "",
    position: active?.position || null,
  });
  const [isScrolling, setIsScrolling] = useState(false);

  const checkLeft = () => {
    let width = "";
    if (
      (isNaN(show) &&
        scrollRef.current?.getBoundingClientRect()?.right -
        eventsRef?.current[index]?.ref?.getBoundingClientRect()?.right <
        widthS * 380) ||
      scrollRef.current?.getBoundingClientRect()?.right -
      eventsRef.current[index]?.ref?.getBoundingClientRect()?.right <
      widthS * 490
    ) {
      width =
        eventsRef.current[index]?.ref?.getBoundingClientRect().left -
        scrollRef.current?.getBoundingClientRect().left -
        widthS * 171.5;
      if (isNaN(show)) {
        // width -= eventsRef.current[index].getBoundingClientRect().width;
      } else {
        // width -= widthS * 86.5;
      }
    } else {
      width =
        eventsRef.current[index]?.ref?.getBoundingClientRect().left -
        scrollRef?.current?.getBoundingClientRect()?.left +
        widthS * 33.5;
    }
    return width;
  };

  let isCompletedDetailsForm =
    eventDetails.title?.length > 1 ? true : false;

  useEffect(() => {
    /* give the description the margin left of the title so the description
    will always be exactly under the title, no matter what is the width of the title */
    setDescMarginLeft(null);
    if (titleTextRef.current && show !== 1) {
      setDescMarginLeft(
        +getComputedStyle(titleTextRef.current).marginLeft.replace("px", "")
      );
    }
  }, [titleTextRef.current, show, index]);

  useEffect(() => {
    setEventDetails({
      id: active.id,
      title: active.title || "",
      description: active.description || "",
      color: active.color || "",
      position: active.position,
    });
  }, [active, events]);

  return (
    <>
      <View style={{ justifyContent: "center", flex: 1 }}>
        {eventsRef.current[0] && scrollOffsetX >= widthS * 20 && (
          <Pressable
            style={{
              position: "absolute",
              left: widthS * -20,
              height: heightS * 70,
              width: widthS * 70,
              justifyContent: "center",
              alignItems: "center",
              zIndex: 5,
            }}
            onPress={onArrowLeftPressHandler}
          >
            <AntDesign
              name="left"
              size={widthS * 26}
              color={color.infoBorder}
            />
          </Pressable>
        )}
        <ScrollView
          nativeID="scroll-flow"
          style={{ maxWidth: "100%" }}
          onScrollAnimationEnd={() => setDisabledArrow(false)}
          scrollEventThrottle={100}
          onScroll={(event) => {
            setScrollOffsetX(event.nativeEvent.contentOffset.x);
            // setIsScrolling(true);
            // setDisabledArrow(true);
          }}
          ref={scrollRef}
          horizontal
          contentContainerStyle={{
            alignItems: "center",
            paddingTop: kpis
              ? kpis.length > 6
                ? heightS * 15
                : 0
              : events.length > 6
                ? heightS * 15
                : 0,
            paddingHorizontal: kpis
              ? kpis.length > 6
                ? widthS * 15
                : 0
              : events.length > 6
                ? widthS * 15
                : 0,
            // paddingVertical: heightS * 10
          }}
        >
          {!kpis
            ? events.length > 0 &&
            events.map((event, i) => {
              if (!event.name && endGame) return;
              return (
                <Event
                  id={event.id}
                  eventsRef={eventsRef}
                  active={event.id === active.id}
                  setActive={setActive}
                  key={event.id}
                  permission={permission}
                  show={show}
                  setShow={setShow}
                  addEvent={addEvent}
                  title={event.title}
                  description={event.description}
                  events={events}
                  createNextEventHandler={createNextEventHandler}
                  onPressHandler={onPressHandler}
                  index={i}
                  color={event.color}
                  setIndex={setIndex}
                  eventContentRef={eventContentRef}
                  titleTextRef={titleTextRef}
                  eventBetween={event.eventBetween}
                />
              );
            })
            : kpis.map((action, i) => (
              <View
                ref={(ref) => (eventsRef.current[i] = ref)}
                style={[
                  styles.kpi,
                  {
                    backgroundColor: action.is_done ? action.eventColor : `${action.eventColor}90`,
                    marginRight:
                      i === kpis.length - 1 ? widthS * 15 : widthS * 30,
                    marginLeft: i === 0 ? widthS * 15 : 0,
                    borderWidth: action.is_done ? 0 : widthS * 2.5,
                    borderColor: action.eventColor
                  },
                ]}
              >
                <View
                  style={[
                    styles.kpiIcon,
                    { backgroundColor: color.infoSaveBtn },
                  ]}
                ></View>
                <Text
                  style={[
                    styles.kpiNumber,
                    { color: color.infoHeaderNavText },
                  ]}
                >{`KPI ${(i + 1).toString().padStart(2, "0")}`}</Text>
                <View
                  style={{
                    borderBottomWidth: widthS * 0.5,
                    borderColor: color.infoHeaderNavText,
                    marginBottom: heightS * 7,
                    width: "95%",
                    alignItems: "center",
                  }}
                >
                  <FullTextTooltip text={action.event}>
                    <Text
                      numberOfLines={1}
                      style={[
                        styles.kpiNumber,
                        { color: color.infoHeaderNavText },
                      ]}
                    >
                      {action.event}
                    </Text>
                  </FullTextTooltip>
                </View>
                <FullTextTooltip text={action.subject}>
                  <Text
                    numberOfLines={1}
                    style={[
                      styles.kpiNumber,
                      { color: color.infoHeaderNavText },
                    ]}
                  >
                    {action.subject}
                  </Text>
                </FullTextTooltip>
              </View>
            ))}
        </ScrollView>
        {(scrollOffsetX === 0 || Math.ceil(scrollOffsetX + scrollRef.current?.offsetWidth) <
          scrollRef.current?.scrollWidth) && (kpis ? kpis.length > 6 : events.length > 6) && (
            <Pressable
              disabled={disabledArrow}
              style={{
                position: "absolute",
                right: widthS * -20,
                height: heightS * 70,
                width: widthS * 70,
                justifyContent: "center",
                alignItems: "center",
                zIndex: 5,
              }}
              onPress={onArrowRightPressHandler}
            >
              <AntDesign
                name="right"
                size={widthS * 26}
                color={color.infoBorder}
              />
            </Pressable>
          )}
      </View>
      {!!show && active && !isScrolling && (
        <CreateAction
          isPossibletoClose={isPossibletoClose}
          index={index}
          editEventFunc={editEventFunc}
          deleteEventData={(event) => deleteEventfunc(event)}
          setLoad={setLoad}
          top={pxToVw(eventsRef.current[index]?.ref?.getBoundingClientRect().top) - 1}
          left={checkLeft()}
          right={
            eventsRef.current[index]?.ref?.getBoundingClientRect().left -
            scrollRef.current?.getBoundingClientRect().left +
            eventsRef.current[index]?.ref?.getBoundingClientRect().width
          }
          side={
            scrollRef.current?.getBoundingClientRect().right -
              eventsRef.current[index]?.ref.getBoundingClientRect().right <
              (isNaN(show) ? widthS * 380 : widthS * 490)
              ? "right"
              : "left"
          }
          eventContentRef={eventContentRef}
          show={show}
          setShow={setShow}
          titleTextRef={titleTextRef}
          title={active.title}
          active={active}
          events={events}
          id={active.id}
          description={active.description}
          addEventHandler={addEvent}
          createNextEventHandler={createNextEventHandler}
          setActive={setActive}
          // newAction
          descMarginLeft={descMarginLeft}
          eventDetails={eventDetails}
          setEventDetails={setEventDetails}
          isCompletedDetailsForm={isCompletedDetailsForm}
          setIndex={setIndex}
          eventBetween={active.eventBetween}
        />
      )}
    </>
  );
}

const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    kpi: {
      height: widthS * 85,
      width: widthS * 85,
      borderRadius: widthS * 15,
      paddingTop: heightS * 10,
      paddingHorizontal: widthS * 10,
      alignItems: "center",
    },
    kpiNumber: {
      fontSize: widthS * 11,
      fontFamily: "Catamaran_400Regular",
      maxWidth: widthS * 77,
      paddingLeft: widthS * 3
    },
    kpiIcon: {
      borderRadius: "50%",
      width: widthS * 15,
      height: widthS * 15,
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: widthS * -5,
      right: widthS * -5,
    },
  });
