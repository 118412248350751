import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { View, Pressable, StyleSheet, TextInput, Image } from 'react-native';
import TextCustomize from '../../../../Elements/TextCustomize';
import Checkbox from '../../../../Elements/CheckBox';
import { t } from 'i18n-js';
import PlayerSelect from '../../../../Elements/PlayerSelect';
import { listenerAdd } from '../../../../../AddListener';

const SocialBodyTable = ({ from, to_list, body, showPopup, setBody, setToList }) => {

    const popupRef = useRef(null);

    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = stylesR(background, color);

    const [realPlayers, setRealPlayers] = useState([]);
    const [repPlayers, setRepPlayers] = useState([]);
    const [all, setAll] = useState(false);
    const [names, setNames] = useState(t("all_players"));
    const [showPopupShere, setShowPopupShere] = useState(false);

    const findNameUser = (id) => {
        // Search for the user in the real players array
        const real = realPlayers.find(user => user.id === id);
        if (real) {
            return real.name_for_game;
        }

        // Search for the user in the rep players array
        const rep = repPlayers.find(user => user.id === id);
        if (rep) {
            return rep.name_for_game;
        }

        // User not found
        return null;
    }

    const toListPrint = (to_list_ids) => {
        if (to_list_ids?.length === 0) {
            setNames(t("all_players"));
            return setAll(false);
        }
        if ((to_list_ids?.length > 0 && to_list_ids.split(",").length === (realPlayers.length + repPlayers.length))) {
            setNames(t("all_players"));
            return setAll("all");
        } else if ((to_list_ids?.length > 0 && to_list_ids.split(",").length === (realPlayers.length))) {
            setNames(t("real_players"));
            setAll("real");
        } else {
            setNames(to_list_ids.split(",").map((e) => findNameUser(e)).join(", "));
            return setAll(true);
        }
    }

    useEffect(() => {
        if (gameInfo?.players?.length > 0) {
            setRealPlayers([...gameInfo?.players.filter((e) => e.is_represented === "0" && e.permission_for_game === "15")]);
            setRepPlayers([...gameInfo?.players.filter((e) => e.is_represented === "1" && e.permission_for_game === "15")]);
        }
    }, [gameInfo]);

    useEffect(() => {
        toListPrint(to_list);
    }, [realPlayers, repPlayers, to_list, showPopup]);

    useEffect(() => {
        window.addEventListener("mousedown", listenerAdd(popupRef, showPopupShere, () => setShowPopupShere(false)));
        return () => {
            window.removeEventListener("mousedown", listenerAdd(popupRef, showPopupShere, () => setShowPopupShere(false)));
        }
    }, [popupRef.current, showPopupShere]);

    return (
        <View style={styles.container}>
            <View style={styles.from}>
                <Image source={{ uri: images.user_image }} style={styles.userImg(direction)} />
                <TextCustomize text={findNameUser(from)} fontSize={"1vw"} fontWeight={300} />
            </View>
            <View style={styles.to}>
                <View style={styles.lable}>
                    <TextCustomize keyText={"share"} fontSize={"0.7vw"} fontWeight={400} />
                </View>
                <Pressable ref={popupRef} onPress={() => setShowPopupShere(true)} style={[showPopupShere ? styles.selectBtnOpen : styles.selectBtn, showPopupShere && styles.shadow]}>
                    <View style={styles._btn_head}>
                        <Checkbox
                            checked={all || all?.length > 0}
                            isDone={false}
                            rounded={true}
                            background={background.infoBody}
                            colorFill={background.count} />
                        <TextCustomize numberOfLines={1} text={showPopupShere ? to_list.split(",").map((e) => findNameUser(e)).join(", ") : names} style={{ marginLeft: "0.5vw" }} fontSize={"0.7vw"} fontWeight={400} />
                    </View>
                    {showPopupShere && <View style={styles._btn_body}>
                        <PlayerSelect
                            phraseToSearch={""}
                            allOrReal={all}
                            playersList={[...realPlayers || [], ...repPlayers || []]}
                            value={{
                                from_id: from,
                                to_list_ids: to_list,
                                to_list: to_list.split(",").map((e) => findNameUser(e)).join(", "),
                                cc_list_ids: "",
                            }}
                            setValue={(e) => { setToList(e.to_list_ids); }}
                            styleType={{ dark: true, type: "to", check: true }}
                            close={() => { setShowPopupShere(false) }}
                            setAllOrReal={() => { }}
                            // allOrReal={allOrReal}
                            // setAllOrReal={setAllOrReal}
                            field={"to_list"}
                            fieldId={"to_list_ids"}
                            styles={styles}
                            backgroundColor={background.infoBox}
                        />
                    </View>}
                </Pressable>
            </View>
            <View style={styles.body}>
                <View style={styles.lable}>
                    <TextCustomize keyText={"post"} fontSize={"0.7vw"} fontWeight={400} />
                </View>
                <View style={styles.inputView}>
                    <TextInput nativeID="social" multiline={true} dir={direction} numberOfLines={6} style={styles.input} value={body || ""} onChangeText={(txt) => setBody(txt)} />
                </View>
            </View>
        </View>
    )
};
const stylesR = (background, color) => StyleSheet.create({
    container: {
        paddingVertical: "1vw",
        paddingHorizontal: "1.5vw",
    },
    from: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "0.5vw"
    },
    userImg: (direction) => ({
        width: "2vw",
        height: "2vw",
        ...(direction === "rtl" ? {marginLeft: "1vw",} : {marginRight: "1vw",}),
        borderRadius: "100%",
        borderWidth: "0.2vw",
        borderColor: color.logoColor
    }),
    to: {
        flexDirection: "row",
        justifyContent: "flex-start",
        marginBottom: "0.5vw",
        zIndex: 3,
    },
    body: {
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    lable: {
        width: "3vw",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginTop: "0.5vw"
    },
    selectBtn: {
        backgroundColor: background.infoBox,
        borderRadius: "0.5vw",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        position: "relative"
    },
    selectBtnOpen: {
        backgroundColor: background.infoBox,
        borderTopLeftRadius: "0.5vw",
        borderTopRightRadius: "0.5vw",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        position: "relative"
    },
    inputView: {
        flex: 1
    },
    input: {
        backgroundColor: background.infoBox,
        borderRadius: "0.5vw",
        paddingVertical: "0.4vw",
        flex: 1,
        paddingHorizontal: "1vw",
        outlineStyle: "none",
        fontSize: "0.7vw",
        color: color.text,
        fontFamily: "Catamaran_400Regular"
    },
    //////
    // _btn: {
    //     borderRadius: "1.2vw",
    //     display: "flex",
    //     flexDirection: "column",
    //     zIndex: 3,
    //     width: "100%",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     position: "relative",
    // },
    // _btn_open: {
    //     borderTopLeftRadius: "0.5vw",
    //     borderTopRightRadius: "0.5vw",
    //     display: "flex",
    //     flexDirection: "column",
    //     zIndex: 3,
    //     width: "100%",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     position: "relative",
    // },
    _btn_head: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        minHeight: "1.3vw",
        alignItems: "center",
        paddingVertical: "0.4vw",
        paddingHorizontal: "0.5vw",
    },
    _btn_body: {
        top: "100%",
        position: "absolute",
        width: "100%",
        borderBottomLeftRadius: "0.5vw",
        borderBottomRightRadius: "0.5vw",
        borderTopWidth: 1,
        borderTopColor: color.border,
        shadowColor: "black",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        elevation: 10,
        borderBottomLeftRadius: "0.9vw",
        backgroundColor: background.infoBox
    },
    shadow: {
        shadowColor: "black",
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.8,
        shadowRadius: 3,
    },
    list_item: {
        zIndex: 13,
        width: "40vw",
    },
    list_item_text: {
        zIndex: 13,
        margin: "0.3vw",
        marginLeft: "0.7vw",
        fontSize: "0.8vw",
        fontFamily: "Catamaran_400Regular",
        display: "flex",
        alignItems: "center",
    },
    label_text_input: {
        fontSize: "0.9vw",
        fontFamily: "Catamaran_400Regular",
        //maxWidth: "96%",
        lineHeight: "1.3vw",
        maxHeight: "1.8vw",
        zIndex: 13,
        //margin: "0.3vw",
        marginLeft: "0.7vw",
        display: "flex",
        //alignItems: "center",
    },
    popup_list_content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    popup_list: {
        maxHeight: "7vw",
        minHeight: "7vw",
        zIndex: 100,
        borderBottomLeftRadius: "0.9vw",
    },
});
export default SocialBodyTable;