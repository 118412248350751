import React, { memo } from "react";
import { Pressable, View, Image, Text } from "react-native";
import FullTextTooltip from "../../Elements/FullTextTooltip";
import PlayerImages from "./PlayerImages";

const MemoizedMessageItem = memo(
  ({
    e,
    i,
    flag,
    index,
    onPress,
    dashboard,
    messagesLength,
    styles,
    background,
    color,
    images,
    type,
    inboxSentDrafts,
    printFlagOrReadCircle,
    printDetailes,
    t,
    checkIfIsEncodeText,
    editDate,
    widthS,
    containerRef,
    hover,
    setHover,
    players,
    tooltipRef,
    events,
    imageProfile,
  }) => {
    return (
      <Pressable
        onPress={() => onPress(e, i)}
        style={[
          styles.container_msg(dashboard, messagesLength - i),
          {
            backgroundColor:
              e?.id === index
                ? background.chatMsgUserActive
                : background.mailItemList,
            borderBottomWidth: 1,
            borderBottomColor: color.border,
          },
        ]}
      >
        {!dashboard ? (
          <View style={[type === "system" ? styles.message_system : styles.message()]}>
            {inboxSentDrafts !== "Drafts" && printFlagOrReadCircle(e, i)}
            {inboxSentDrafts === "Inbox" && (
              <Image
                style={[
                  e.read_or_not || e?.id === index
                    ? styles.img_open(dashboard)
                    : styles.img_close(dashboard),
                ]}
                source={
                  e.read_or_not || e?.id === index
                    ? images.mailReadIcon
                    : images.mailUnreadIcon
                }
              />
            )}
            <View style={styles.details_view}>
              {printDetailes(e, i)}
              {(() => {
                let text =
                  type === "mail" && e.subject
                    ? e?.is_survey === "1"
                      ? t("questionaire")
                      : checkIfIsEncodeText(e.subject, "html")
                    : e.body_search;
                return (
                  <FullTextTooltip text={text}>
                    <Text
                      selectable={true}
                      numberOfLines={1}
                      style={
                        inboxSentDrafts === "Inbox" &&
                          !e.read_or_not &&
                          e?.id !== index
                          ? styles.sub_mail()
                          : styles.sub_mail_read()
                      }
                    >
                      {text}
                    </Text>
                  </FullTextTooltip>
                );
              })()}
            </View>
            <View style={styles.time_view}>
              <Text
                selectable={false}
                style={
                  !e.read_or_not &&
                    inboxSentDrafts === "Inbox" &&
                    e?.id !== index
                    ? [styles.time, { color: color.mailText }]
                    : [styles.time_read, { color: color.mailItemRead }]
                }
              >
                {editDate(e.time, "mail", "clock")}
              </Text>
            </View>
            {e.has_attachments && (
              <Image
                source={{
                  uri:
                    e.read_or_not || e?.id === index
                      ? images.attachmentIcon
                      : images.attachmentIconUnread,
                }}
                style={styles.attach_icon}
              />
            )}
          </View>
        ) : (
          <View style={styles.message(true)}>
            {printFlagOrReadCircle(e, i, dashboard)}
            <Image
              style={[
                e.read_or_not || e?.id === index
                  ? styles.img_open(dashboard)
                  : styles.img_close(dashboard),
              ]}
              source={
                e.read_or_not || e?.id === index
                  ? images.mailReadIcon
                  : images.mailUnreadIcon
              }
            />
            {imageProfile(e?.sender?.id)}
            {(() => {
              let text = e.sender?.name
                ? e.sender.name
                : e.sender?.id ===
                  (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) ||
                    sessionStorage.getItem(USER_ID_IN_GAME))
                  ? sessionStorage.getItem(USER_GAME_NAME)
                  : players.find((player) => player.id === e.sender?.id)
                    ?.name_for_game;
              return (
                <FullTextTooltip text={text}>
                  <Text
                    numberOfLines={1}
                    selectable={false}
                    style={[
                      e.read_or_not || e?.id === index
                        ? styles.name_read(inboxSentDrafts !== "Inbox", dashboard)
                        : styles.name(inboxSentDrafts !== "Inbox", dashboard),
                    ]}
                  >
                    {text}
                  </Text>
                </FullTextTooltip>
              );
            })()}
            <PlayerImages
              index={i}
              onPress={() => onPress(e, i)}
              containerRef={containerRef}
              hover={hover}
              setHover={setHover}
              e={e}
              images={images}
              players={players}
              tooltipRef={tooltipRef}
            />
            <View
              style={styles.event_color_view(
                events.find((event) => event.id === e?.event?.id)?.color
              )}
            />
            <FullTextTooltip text={e?.event?.name}>
              <Text
                numberOfLines={1}
                selectable={false}
                style={styles.event_text(e.read_or_not)}
              >
                {e?.event?.name}
              </Text>
            </FullTextTooltip>
            <View style={styles.details_view}>
              <FullTextTooltip
                text={
                  e?.is_survey === "1"
                    ? t("questionaire")
                    : checkIfIsEncodeText(e.subject, "html")
                }
              >
                <Text
                  selectable={true}
                  numberOfLines={1}
                  style={[
                    {
                      width: widthS * 125,
                    },
                    !e.read_or_not && e?.id !== index
                      ? styles.subject_dashboard_read(dashboard)
                      : styles.subject_dashboard(dashboard),
                  ]}
                >
                  {e?.is_survey === "1"
                    ? t("questionaire")
                    : checkIfIsEncodeText(e.subject, "html")}
                </Text>
              </FullTextTooltip>
              <FullTextTooltip text={e.body_search}>
                <Text
                  selectable={true}
                  numberOfLines={1}
                  style={
                    !e.read_or_not && e?.id !== index
                      ? styles.sub_mail(false, true)
                      : styles.sub_mail_read(false, true)
                  }
                >
                  {e.body_search}
                </Text>
              </FullTextTooltip>
            </View>
            <View style={styles.time_view}>
              <Text
                selectable={false}
                style={
                  !e.read_or_not && e?.id !== index
                    ? [styles.time, { color: color.mailText }]
                    : [styles.time_read, { color: color.mailItemRead }]
                }
              >
                {editDate(e.time, "mail", "clock")}
              </Text>
            </View>
            {e.has_attachments && (
              <Image
                source={{
                  uri:
                    e.read_or_not || e?.id === index
                      ? images.attachmentIcon
                      : images.attachmentIconUnread,
                }}
                style={styles.attach_icon}
              />
            )}
          </View>
        )}
      </Pressable>
    );
  },
  (prevProps, nextProps) => {
    // החזרת false אם יש שינוי כדי לאפשר רענון
    return !(
      prevProps.e !== nextProps.e || // אם e השתנה
      prevProps.i !== nextProps.i || // אם i השתנה
      prevProps.dashboard !== nextProps.dashboard || // אם dashboard השתנה
      prevProps.index !== nextProps.index || // אם index השתנה
      prevProps?.flag !== nextProps?.flag // אם flag השתנה
    );
  }
);

export default MemoizedMessageItem;
