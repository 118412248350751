import {
  View,
  Text,
  StyleSheet,
  TextInput,
  Pressable,
  TouchableHighlight,
  ScrollView,
} from "react-native";
import React, { useRef, useState, useEffect, useMemo } from "react";
import UseResponsiveSize from "../../../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../../../modules/UseResponsiveScreen";
import { useSelector } from "react-redux";
import { useClickOutside } from "../../../../../modules/useClickOutside";
import { Ionicons } from "@expo/vector-icons";
import { t } from "i18n-js";

export const FilterSelect = ({
  id,
  title,
  all,
  options,
  active,
  setActive,
  filters,
  setFilters,
  pressAllOptionHandler,
  checkedFunc,
  names,
  type
}) => {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);

  const selectRef = useRef();
  const inputRef = useRef();

  const [filterOptions, setFilterOptions] = useState("");
  const [show, setShow] = useState(false);
  const [selectOptions, setSelectOptions] = useState(options);

  useClickOutside(selectRef, show, () => {
    setShow(false);
    setFilterOptions("");
  });

  useEffect(() => {
    console.log(inputRef.current);
    inputRef.current?.focus();
  }, [inputRef.current, show]);

  useEffect(() => {
    setSelectOptions([...options]);
  }, [options]);

  const multipleOptions = (type, all, checked) => {
    return (
      <Pressable
        onPress={() => {
          pressAllOptionHandler(type);
          inputRef.current?.focus();
        }}
      >
        {({ pressed, hovered, focused }) => (
          <View
            style={[
              styles.selectOption,
              hovered && { backgroundColor: color.infoBorder },
            ]}
          >
            <View style={[styles.checkBoxContainer]}>
              <TouchableHighlight
                style={[
                  styles.checkBox,
                  styles.selectCheckBox,
                  {
                    backgroundColor: background.infoBody,
                    borderColor: color.infoBorder,
                  },
                ]}
                onPress={() => {
                  pressAllOptionHandler(type);
                  inputRef.current?.focus();
                }}
              >
                {checked ? (
                  <View
                    style={[
                      styles.selectCheckBoxInner,
                      {
                        backgroundColor: background.count,
                      },
                    ]}
                  ></View>
                ) : (
                  <View />
                )}
              </TouchableHighlight>
            </View>
            <Text
              style={[
                styles.selectBtnText,
                {
                  color: color.infoHeaderNavText,
                },
              ]}
            >
              {all}
            </Text>
          </View>
        )}
      </Pressable>
    )
  }

  return (
    <View
      ref={selectRef}
      style={[styles.select, { flex: 1, marginRight: widthS * 10 }]}
    >
      <View
        style={[
          styles.input,
          styles.selectBtn,
          {
            backgroundColor: background.infoBody,
            color: color.infoHeaderNavText,
            borderBottomColor: color.border,
          },
        ]}
      >
        {/* button */}
        <View style={{ width: "100%" }}>
          <Pressable
            onPress={() => {
              setActive(id);
              setShow(true);
            }}
            style={styles.selectBtn}
          >
            <Text
              numberOfLines={1}
              style={{
                color: color.infoHeaderNavText,
                fontSize: widthS * 9,
                width: "90%",
              }}
            >
              {names?.filter((name) => name).join(",") || title}
            </Text>
          </Pressable>
          <Pressable
            style={{
              position: "absolute",
              right: 0.5 * widthS,
              top: heightS * 3.5,
            }}
            onPress={() => {
              setActive(id);
              setShow(true);
            }}
          >
            <Ionicons
              name={show && active === id ? "caret-up" : "caret-down"}
              color={color.infoHeaderNavText}
              size={widthS * 8}
            />
          </Pressable>
        </View>
        {/* select */}
        {show && active === id && selectOptions.length > 0 && (
          <View
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 5,
              width: "100%",
              shadowColor: "#000000",
              shadowOffset: { width: 0, height: 0 },
              shadowOpacity: 0.7,
              shadowRadius: 6,
              borderRadius: width * 12
            }}
          >
            <View
              style={[
                styles.input,
                styles.selectBtn,
                {
                  backgroundColor: background.infoBody,
                  color: color.infoHeaderNavText,
                  marginVertical: 0,
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                },
              ]}
            >
              <View style={{ width: "100%" }}>
                <TextInput
                  ref={inputRef}
                  onFocus={() => {
                    setActive(id);
                    setShow(true);
                  }}
                  placeholder={title}
                  placeholderTextColor={color.buildTitle}
                  style={[
                    styles.selectBtn,
                    {
                      outlineStyle: "none",
                      color: color.infoHeaderNavText,
                      fontSize: widthS * 9,
                    },
                  ]}
                  // value={picked}
                  onChangeText={(e) => {
                    setFilterOptions(e.toLowerCase());
                  }}
                />
                <Pressable
                  style={{
                    position: "absolute",
                    right: 0.5 * widthS,
                    top: heightS * 3.5,
                  }}
                  onPress={() => {
                    setActive(id);
                    setShow(true);
                  }}
                >
                  <Ionicons
                    name={show && active === id ? "caret-up" : "caret-down"}
                    color={color.infoHeaderNavText}
                    size={widthS * 8}
                  />
                </Pressable>
              </View>
            </View>
            <ScrollView
              nativeID="social"
              style={[
                styles.selectContent,
                {
                  borderColor: color.infoBorder,
                  backgroundColor: background.infoBody,
                },
              ]}
            >
              {/* multiple options */}
              {type === 'player' && <Text
                style={[
                  { margin: "0.5vw", color: color.infoHeaderNavText },
                ]}
              >
                {t("groups")}
              </Text>}
              {multipleOptions(null, all, filters.split(",").filter((id) => id).length >=
                options.length)}
              {type === 'player' && multipleOptions(true, t("real_players"), checkedFunc())}
              {type === 'player' && <Text
                style={[
                  { margin: "0.5vw", color: color.infoHeaderNavText },
                ]}
              >
                {t("players")}
              </Text>}
              {selectOptions
                .filter((option) =>
                  option.name_for_game
                    ? option.name_for_game.toLowerCase().includes(filterOptions)
                    : option.title
                      ? option.title.toLowerCase().includes(filterOptions)
                      : option.toLowerCase().includes(filterOptions)
                )
                .map((option, i) => (
                  <Pressable
                    key={i}
                    onPress={() => {
                      setFilters(option);
                      inputRef.current.focus();
                    }}
                  >
                    {({ pressed, hovered, focused }) => (
                      <View
                        style={[
                          styles.selectOption,
                          hovered && { backgroundColor: color.infoBorder },
                        ]}
                      >
                        <View style={[styles.checkBoxContainer]}>
                          <TouchableHighlight
                            style={[
                              styles.checkBox,
                              styles.selectCheckBox,
                              {
                                backgroundColor: background.infoBody,
                                borderColor: color.infoBorder,
                              },
                            ]}
                            onPress={() => {
                              setFilters(option);
                              inputRef.current.focus();
                            }}
                          >
                            {filters
                              .split(",")
                              .find(
                                (value) =>
                                  value.trim() === option?.id?.toString() ||
                                  value.trim() ===
                                  (option === t("mail")
                                    ? "mail"
                                    : option === t("news")
                                      ? "news"
                                      : "sm")
                              ) ? (
                              <View
                                style={[
                                  styles.selectCheckBoxInner,
                                  {
                                    backgroundColor: background.count,
                                  },
                                ]}
                              ></View>
                            ) : (
                              <View />
                            )}
                          </TouchableHighlight>
                        </View>
                        {type === "event" && <View style={{ backgroundColor: option.color, width: widthS * 2, height: heightS * 15, marginRight: widthS * 4 }} />}
                        <Text
                          numberOfLines={1}
                          style={[
                            styles.selectBtnText,
                            {
                              color: color.infoHeaderNavText,
                            },
                          ]}
                        >
                          {option.name_for_game || option.title || option}
                        </Text>
                      </View>
                    )}
                  </Pressable>
                ))}
            </ScrollView>
          </View>
        )}
      </View>
    </View>
  );
};

const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    select: {
      position: "relative",
    },
    selectBtn: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    selectBtnText: { fontSize: widthS * 9 },
    selectContent: {
      maxHeight: heightS * 200,
      paddingBottom: heightS * 10,
      borderBottomRightRadius: 12 * widthS,
      borderBottomLeftRadius: 12 * widthS,
    },
    selectOption: {
      paddingHorizontal: widthS * 10,
      paddingVertical: heightS * 4,
      flexDirection: "row",
    },
    input: {
      height: heightS * 35,
      fontSize: widthS * 9,
      marginVertical: heightS * 5,
      borderRadius: 12 * widthS,
      paddingHorizontal: 10 * widthS,
    },
    checkBoxContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    checkBox: {
      marginRight: 5 * widthS,
      width: 10 * widthS,
      height: 10 * widthS,
      borderRadius: widthS * 3,
      borderWidth: widthS,
      justifyContent: "center",
      alignItems: "center",
    },
    selectCheckBox: {
      width: 10 * widthS,
      height: 10 * widthS,
      borderRadius: widthS * 2,
    },
    selectCheckBoxInner: {
      width: 6 * widthS,
      height: 6 * widthS,
      borderRadius: widthS * 2,
    },
  });
