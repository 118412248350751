export const ZoomStatic = (num, stringNum) => {
    if(!stringNum){
        
    }
    return num * convertZoom(stringNum);
}
const convertZoom = (stringNum) => {
    switch (stringNum) {
        case "100":
            return 1;
        case "75":
            return 0.75;
        case "50":
            return 0.5;
        case "25":
            return 0.25;
    }
}