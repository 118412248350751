import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useMemo, useState, useEffect, } from 'react'
import TextCustomize from '../../../../Elements/TextCustomize';
import { AntDesign } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import TooltipQuestionnaire from './TooltipQuestionnaire';
import { t } from 'i18n-js';
import { getDateTimeObject } from '../../../../../EditDate';
import ExportPopup from '../ExportPopup';
import FullTextTooltip from '../../../../Elements/FullTextTooltip';

export default function QuestionnaireItem({ item, index, isShot, refShot, setIsShot }) {

    const colors = [
        "#520065",
        "#DE82D8",
        "#FCF000",
        "#D98000",
        "#007493",
        "#00B192",
        "#4D320F",
        "#724673",
        "#006ABC",
    ];

    const latters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const players = useSelector(state => state.gameInfoArr.gameInfoArr.players);
    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const styles = stylesR(background, color);

    const [flag, setFlag] = useState(false);

    const printDate = (timeD) => {
        const { date, time } = getDateTimeObject(`${timeD} utc`);
        const now = new Date();
        const providedDate = new Date(timeD);

        const isToday = now.toDateString() === providedDate.toDateString();

        if (isToday) {
            return `${t("today")} | ${time}`;
        } else {
            return `${date} | ${time}`;
        }
    }

    const questionItem = (item, index) => {
        return (
            <View key={index} style={styles.questionItemView}>
                <View style={styles.leftMain}>
                    <View style={styles.left}>
                        <View style={styles.countView}>
                            <Text style={styles.countText()}><Text style={styles.countText(window?.TrustedScript ? window?.TrustedScript : '')}>Q</Text>{index + 1}</Text>
                        </View>
                        <View style={styles.leftChild}>
                            <Text style={styles.questionText}>{item?.question}</Text>
                            {item?.answers?.map((answer, indexKey) => {
                                let total = 0;
                                item.answers.forEach((key, index) => {
                                    total += key?.players?.split(",")?.length || 0;
                                });
                                let labelText = (answer?.players?.split(",")?.length / total) * 100 || 0;
                                labelText = !Number.isInteger(labelText) ? `${labelText.toFixed(1)}%` : `${labelText}%`;
                                return (
                                    <View key={indexKey} style={styles.answer}>
                                        <Text style={styles.answerText}>{labelText}</Text>
                                        <View style={styles.colorCircle(colors[indexKey], true)} />
                                        <Text style={styles.latterText}>{latters[indexKey]}</Text>
                                        <Text style={styles.answerText}>{answer?.answer}</Text>
                                    </View>
                                )
                            })}
                        </View>

                    </View>
                    {isShot && <View style={{ marginHorizontal: "2.5em" }}>
                        <Text style={styles.questionText}>{t("answers")}</Text>
                        <View style={{}}>
                            {item?.answers?.map((item, index) => {
                                return <View style={styles.answerView}>
                                    <View key={index} style={styles.CircleView}>
                                        <View style={styles.colorCircle(colors[index], true)} />
                                        <Text style={styles.answerViewText}>{latters[index]}</Text>
                                    </View>

                                    <View style={{ flexWrap: "wrap", flexDirection: "row", }}>
                                        {item?.players?.split(",").map((id, index, arr) => {
                                            const isLast = index === arr.length - 1;
                                            const name = players?.find((e) => e.id === id)?.name_for_game;
                                            return (
                                                <Text style={styles.nameText} key={id}>{name}{!isLast && <Text style={styles.dot}>•</Text>}</Text>
                                            )
                                        })}
                                    </View>
                                </View>
                            })}
                        </View>
                    </View>}
                </View>
                <View style={styles.right}>
                    <TooltipQuestionnaire
                        colors={colors}
                        latters={latters}
                        tooltip={
                            {
                                data: item,
                                x: 270.81666666666666,
                                y: 164.140625,
                            }}
                    />
                    <View style={styles.circleContainer}>
                        {item?.answers?.map((key, index) => {
                            return <View key={index} style={styles.CircleView}>
                                <View style={styles.colorCircle(colors[index], false)} />
                                <Text style={styles.latterTextP}>{latters[index]}</Text>
                            </View>
                        })}
                    </View>
                </View>
            </View>
        )
    }

    useEffect(() => {
        if (isShot) {
            setFlag(true);
        }
    }, [isShot]);

    //     !isShot ? <View nativeID='chat-popup' ref={conatinerRef} style={styles.tableMain}>
    //     {Object.keys(questionnaireObj).map((questionnaire, index) => {
    //         return <QuestionnaireItem item={questionnaireObj[questionnaire]} styles={styles} index={index} key={index} />;

    //     })}
    // </View> :

    // {isShot === "questionnaire" && <View
    //     ref={(ref) => {
    //         if (!ref) {
    //             return;
    //         }
    //         if (!refShot.current) {
    //             refShot.current = [];
    //         }
    //         refShot.current[0] = ref;
    //     }}
    //     style={{ flex: 1, position: "absolute", width: "max-content", alignItems: "center", justifyContent: "center", height: "100%", zIndex: -1000, width: "100%", }}
    // >
    //     <View style={{ height: "100%", width: "100%", position: "absolute", zIndex: 1, backgroundColor: background.infoBody, }} />
    //     <View style={styles.mainShot} id='questionnaire'>
    //         {body(true)}
    //     </View>
    // </View>}

    return (
        <View style={styles.questionsContainer} nativeID={`questionnaire${index}`}>
            <TouchableOpacity
                onPress={() => {
                    setFlag(!flag);
                }}
                style={styles.questionsMain(flag)}
            >
                <View style={styles.questionsContainerRight}>
                    <TextCustomize fontWeight={600} style={{ color: background.count, lineHeight: "1vw" }} fontSize={"0.8vw"} text={`${t("questionnaire")}: ${String(index + 1).padStart(2, '0')}`} />
                    <TextCustomize numberOfLines={flag ? null : 1} maxLength={50} fontWeight={600} style={{ lineHeight: "1.2vw", maxWidth: "40vw" }} text={item?.title} />
                    <TextCustomize maxLength={50} fontWeight={600} fontSize={"0.8vw"} style={{ maxWidth: "40vw", color: color.placeholder, direction: direction, lineHeight: "1.2vw", }} text={printDate(item?.send_date)} />
                </View>
                <View >
                    <View style={styles.questionsContainerLeft}>
                        <TextCustomize fontWeight={400} text={`${t("num_of__quest")}: ${String(item.questions?.length).padStart(2, '0')}`} />
                        <ExportPopup
                            setIsShot={setIsShot}
                            isShot={isShot}
                            num={6}
                            nativeID={"exportA"}
                            inline={true}
                            type={"real"}
                            id={`questionnaire${index}`}
                            callback={() => `questionnaire${index}`}
                        />
                        <AntDesign
                            name="caretdown"
                            style={{ marginLeft: "0.5vw", transition: "all .5s", transform: [{ rotate: flag ? "180deg" : "0deg" }] }}
                            size={"0.6vw"}
                            color={color.text2}
                        />
                    </View>
                </View>
            </TouchableOpacity>
            {flag && <View style={styles.questionsContainerPopuop}>
                {item?.questions?.map((question, index) => {
                    return questionItem(question, index)
                })}
            </View>}
        </View>

    )
}


const stylesR = (background, color) => {
    return StyleSheet.create({
        questionsContainer: {
            width: "100%"
        },
        questionsMain: (flag) => ({
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            shadowColor: "black",
            shadowOffset: { height: 0, width: 0 },
            shadowOpacity: 0.4,
            shadowRadius: 3,
            ...(flag ? { borderTopLeftRadius: "0.7vw", borderTopRightRadius: "0.7vw" } : { borderRadius: "0.7vw", }),
            backgroundColor: background.infoBody,
            paddingHorizontal: "2.5vw",
            paddingVertical: "1vw",
            zIndex: 1,
            transition: "all .5s"
        }),
        questionsContainerLeft: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "2.5vw",
        },
        questionsContainerPopuop: {
            backgroundColor: background.infoBody,
            width: "100%",
            paddingVertical: "1vw",
            paddingHorizontal: "2vw",
            borderBottomLeftRadius: "0.7vw", borderBottomRightRadius: "0.7vw"
        },
        questionItemView: {
            backgroundColor: background.infoBox,
            width: "100%",
            borderRadius: "0.5vw",
            padding: "1vw",
            paddingHorizontal: "2vw",
            flexDirection: "row",
            marginBottom: "1vw"
        },
        leftMain: {
            flex: 1,
            alignItems: "flex-start"
        },
        left: {
            flex: 1,
            flexDirection: "row",
        },
        leftChild: {
            maxWidth: "90%",
        },
        right: {
            flex: 0.4,
            alignItems: "center"
        },
        countView: {
            width: "2.5vw",
            height: "2.5vw",
            backgroundColor: background.infoBody,
            alignItems: "center",
            marginRight: "1vw",
            justifyContent: "center",
            borderRadius: "100%"
        },
        countText: (flag) => ({
            color: color.infoHeaderNavText,
            fontFamily: "Catamaran_300Light",
            fontSize: flag ? "1.1vw" : "1.2vw",
        }),
        questionText: {
            color: color.infoHeaderNavText,
            fontFamily: "Catamaran_600SemiBold",
            fontSize: "0.8vw",
        },
        nameText: {
            color: color.infoHeaderNavText,
            fontFamily: "Catamaran_600SemiBold",
            fontSize: "0.8vw",
        },
        dot: {
            paddingHorizontal: "0.3em"
        },
        answerView: {
            alignItems: "flex-start"
        },
        answerViewText: {
            color: color.infoHeaderNavText,
            fontFamily: "Catamaran_600SemiBold",
            fontSize: "0.9vw",
        },
        answer: {
            marginBottom: "1vw",
            flexDirection: "row"
        },
        answerText: {
            minWidth: "2.5vw",
            textAlign: "center",
            fontSize: "0.8vw",
            marginRight: "0.5vw",
            color: "white",
            fontFamily: "Catamaran_300Light"
        },
        latterText: {
            textAlign: "center",
            fontSize: "0.8vw",
            marginRight: "0.5vw",
            color: "white",
            fontFamily: "Catamaran_300Light"
        },
        latterTextP: {
            textAlign: "center",
            fontSize: "0.7vw",
            color: "white",
            fontFamily: "Catamaran_300Light"
        },
        colorCircle: (color, flag) => ({
            backgroundColor: color,
            marginHorizontal: flag ? "0.3vw" : "0.1vw",
            marginTop: flag ? "0.2vw" : "",
            width: !flag ? "0.6vw" : "1vw",
            height: !flag ? "0.6vw" : "1vw",
            borderRadius: "100%",
        }),
        circleContainer: {
            flexDirection: "row",
            maxWidth: "70%",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.4vw"
        },
        CircleView: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
        }
    })
} 
