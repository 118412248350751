import { StyleSheet, Text, View, ScrollView, Image, Modal, TouchableOpacity } from 'react-native';
import { Pressable } from 'react-native-web';
import React, { useEffect } from 'react'
import { t } from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux';
import { StatisticsFuncContext } from '../analyticsFunction/StatisticsFunc';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import ShortenWord from '../../../../modules/ShortenWord';
import UseResponsiveSize from '../../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import { useRef } from 'react';
import TimeUnits from '../../../../Elements/TimeUnits';
import { ScrollSyncPane } from 'react-scroll-sync';
import TimesSlider from './TimesSlider';
import { AnalyticstimesContext } from '../analyticsFunction/Analyticstimes';
import TooltipStatistics from './TooltipStatistics';
import IconButton from "../../../../Elements/IconButton";
import AnimationLoading from "../../../../modules/AnimationLoading";
import FullTextTooltip from '../../../../Elements/FullTextTooltip';

const MyComponent = ({ type, real, stopPlayScroll, maximize, setMaximize, refShot, isShot }) => {

    const {
        arrScore,
        arrScoreReal,
        maxCount,
        maxCountReal,
        filterPlatform,
        setFilterPlatform,
        filterStatistics,
        refresh,
        setRefresh
    } = useContext(StatisticsFuncContext);

    const {
        timeUnitsState,
        setTimeUnitsState,
        resetPositionAndCount
    } = useContext(AnalyticstimesContext);

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);

    const scrollGraphRef = useRef(null);
    const playerBtnRef = useRef([]);

    const [maxCountState, setMaxCountState] = useState();
    const [arrScoreState, setArrScoreState] = useState([]);
    const [tooltip, setTooltip] = useState(false);
    const [tooltipTop, setTooltipTop] = useState();
    const [delay, setDelay] = useState(true);
    const [hoverType, setHoverType] = useState();
    const [position, setPosition] = useState(0);

    const scrollRef = useRef([]);
    const conatinerRef = useRef(null);
    const columnsRef = useRef([]);
    const tableRef = useRef(null);
    const filterRef = useRef(null);

    let countItems = !maximize ? type === "compared" ? 5 : 12 : type === "compared" ? 8 : 18;
    let id = "indicator-statistics";
    let flag = true;
    const { currentTime, times } = TimesSlider({ type, scrollRef, conatinerRef, countItems, id, flag, position, setPosition, real });


    const changeItemfilter = (type) => {
        let temp = filterPlatform;
        if (temp.indexOf(`${type},`) >= 0) {
            temp = temp.replace(`${type},`, "");
        } else if (temp.indexOf("all,") >= 0) {
            temp = "";
        }
        else {
            temp += `${type},`;
        }
        setFilterPlatform(temp);
    }

    const printColors = (type) => {
        switch (type) {
            case "mail":
                return "#6A0084";
            case "sent":
                return "#D98000";
            case "social":
                return "#FCF000";
            case "chat":
                return "#006ABC";
            case "challenges":
                return "#00DBAF";
        }
    }

    const printScorePlayer = (score) => {
        let newObj = { ...score };
        if (maxCountState > 0) {
            let heightScore = (heightS * 165) / maxCountState;
            // if (filterPlatform.length === 0 || filterPlatform.match(/,/g)?.length < 4) {
            //     Object.keys(newObj).forEach((e) => {
            //         if (filterPlatform.indexOf(e) < 0) {
            //             delete newObj[e];
            //         }
            //     });
            // };
            let ordered = Object.entries(newObj).sort((a, b) => a[1] - b[1]);
            ordered = Object.fromEntries(ordered);
            return Object.keys(ordered).map((e, i) => {
                if (ordered[e] > 0) {
                    return (
                        <View
                            key={i}
                            style={[{ opacity: hoverType && hoverType !== newObj.score[Object.keys(newObj.score)[i]] ? 0.2 : 1, width: widthS * 17, height: ordered[e] * heightScore, zIndex: i, backgroundColor: printColors(newObj.score[Object.keys(newObj.score)[i]]) }, i === 0 && { borderTopLeftRadius: widthS * 3, borderTopRightRadius: widthS * 3, transition: "all 0.5s" }]}
                        />
                    )
                }
            });
        }
    }

    const createIndexScore = (max) => {
        let arr = [0,];
        let a;
        if (max > 0 && max < 12) {
            a = max / max;
            for (let i = 1; i <= max; i++) {
                arr.push(i);
            }
        } else if (max >= 12) {
            a = max / 12;
            for (let i = 0; i < 12; i++) {
                arr.push(Math.floor(a * (i + 1)));
            }
        }
        return arr;
    }

    const sortScore = (arr) => {
        // sort the score 
        let newArr = [];
        if (arr.length > 0) {
            arr.forEach((playerItem) => {
                if (filterPlatform.length === 0 || filterPlatform.match(/,/g)?.length < 4) {
                    Object.keys(playerItem.score).forEach((e) => {
                        if (filterPlatform.indexOf(e) < 0) {
                            delete playerItem.score[e];
                        }
                    });
                };
                let newObj = {
                    score: {}
                };
                let ordered = Object.entries(playerItem.score).sort((a, b) => b[1] - a[1]);
                ordered = Object.fromEntries(ordered);
                Object.keys(ordered).forEach((e, i) => {
                    ordered[`${i + 1}`] = ordered[e];
                    newObj.score[`${i + 1}`] = e;
                    delete ordered[e];
                });
                newObj = { ...newObj, ...{ ...ordered, score: { ...newObj.score, id: playerItem.id, name: playerItem.name } } }
                newArr.push(newObj);
            });
        }
        setArrScoreState([...newArr]);
    }

    const setCurrentTimeFromScroll = (partOfScroll, scrollLeft, flag) => {
        // let count = Math.round(scrollLeft / partOfScroll);
        // count = count >= timesState.times.length ? timesState.times.length - 1 : count;
        // setPosition(count);
        // filterStatistics(timesState.count ? timesState.count + 1 : 1, type, count, real);
        createArrayTimeDate(timeUnits, timesState?.count + 1, type);
    }

    useEffect(() => {
        if (type === "expected") {
            sortScore(arrScore);
            if (maxCount > 0) {
                setMaxCountState(maxCount);
            }
        } else if (type === "real") {
            sortScore(arrScoreReal);
            if (maxCountReal > 0) {
                setMaxCountState(maxCountReal);
            }
        } else {
            if (real) {
                sortScore(arrScoreReal);
                if (maxCountReal > 0) {
                    setMaxCountState(maxCountReal);
                }
            } else {
                sortScore(arrScore);
                if (maxCount > 0) {
                    setMaxCountState(maxCount);
                }
            }
        }
    }, [arrScore, arrScoreReal, maxCount, maxCountReal]);

    useEffect(() => {
        setTimeout(() => {
            filterStatistics(type, currentTime, real, timeUnitsState);
            setDelay(false);
        }, 500);
    }, [filterPlatform, maxCountState, times, currentTime, refresh]);

    useEffect(() => {
        resetPositionAndCount();
        switch (type) {
            case "expected":
                return setFilterPlatform("mail,sent,social,challenges,");
            case "compared":
            case "real":
                return setFilterPlatform("mail,sent,chat,social,challenges,");
        }
    }, [type]);

    useEffect(() => {
        if (conatinerRef.current) {
            conatinerRef.current.addEventListener("mousemove", (e) => {
                stopPlayScroll(false);
            });
            conatinerRef.current.addEventListener("mouseleave", (e) => {
                stopPlayScroll(true);
            });
        }
    }, [conatinerRef.current]);

    const table = (isShot) => {
        return !delay ? (arrScoreState?.length > 0 && (
            <View ref={conatinerRef} style={[!real && { flex: 1 }, { position: "relative", height: maximize ? "100%" : "26vw", }, type === "compared" && { backgroundColor: background.popup1 }, real && { marginLeft: widthS * 10, borderTopRightRadius: widthS * 19, borderBottomRightRadius: widthS * 19, }]}>
                {/* <Component
                    // timeUnits={timeUnits}
                    // type={type}
                /> */}
                {/* // {Component} */}
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <TimesSlider
                        conatinerRef={conatinerRef}
                        countItems={Math.min(countItems || times.length - 1)}
                        flag={false}
                        id={id}
                        scrollRef={scrollRef}
                        real={real}
                        type={type}
                        position={position}
                        setPosition={setPosition}
                    />
                </View>
                <ScrollSyncPane
                    group={"g"}
                >
                    <View
                        nativeID='mail-messages'
                        style={[{ flex: 1, overflowY: "hidden", overflowX: "scroll", marginRight: widthS * 18, backgroundColor: background.popup1 }, isShot ? { minWidth: tableRef.current?.scrollWidth } : { maxWidth: maximize ? "100%" : type === "compared" ? widthS * 230 : widthS * 500, }]}
                    >
                        <View
                            ref={tableRef}
                            style={{ paddingLeft: widthS * 5, width: isShot ? "" : type === "compared" ? widthS * 231 : widthS * 501, flexDirection: "row" }}
                        >
                            {<View style={{ flexDirection: "column-reverse", height: heightS * 175, alignItems: "flex-end", justifyContent: "space-between", marginRight: widthS * 15, marginTop: heightS * 5 }}>
                                {[...createIndexScore(maxCountState)].map((e, i) => {
                                    return (
                                        <Text style={[styles.filterText, { color: color.infoHeaderNavText, fontSize: widthS * 7 }]} key={i} >{e}</Text>
                                    )
                                })}
                            </View>}
                            {arrScoreState.map((player, i) => {
                                return (
                                    <View key={i} style={{ zIndex: i === tooltip?.index ? 2 : 1, flexDirection: "column", height: "100%", }} ref={(ref) => !columnsRef.current.find((e) => e === ref) && columnsRef.current.push(ref)}>
                                        <View
                                            style={{ opacity: tooltip && tooltip?.index !== i ? 0.4 : 1, transition: "all .5s", height: heightS * 175, paddingHorizontal: widthS * 7, justifyContent: "flex-end", borderBottomColor: color.border, borderBottomWidth: widthS * 1 }}>
                                            <Pressable
                                                ref={(ref) => playerBtnRef.current.findIndex((e) => e === ref) < 0 && playerBtnRef.current.push(ref)}
                                                onHoverIn={(e) => {
                                                    setTooltip({
                                                        ...player,
                                                        index: i,
                                                        // x: columnsRef?.current[i]?.getBoundingClientRect().x - (conatinerRef?.current?.getBoundingClientRect().x - 75),
                                                        // y: e.clientY - playerBtnRef.current[i].getBoundingClientRect().y,
                                                    })
                                                }}
                                                onMouseMove={(e) => setTooltipTop(e.clientY - playerBtnRef.current[i].getBoundingClientRect().y)}
                                                onHoverOut={() => setTooltip(false)}
                                            >
                                                {printScorePlayer(player)}
                                                {i === tooltip?.index && tooltipTop && <TooltipStatistics filterPlatform={filterPlatform} btnRef={playerBtnRef.current[i]} tooltip={player} top={tooltipTop} tableRef={tableRef} />}
                                            </Pressable>
                                        </View>
                                        <View style={{ flexDirection: "row", paddingHorizontal: widthS * 6 }} ref={scrollGraphRef}>
                                            <View key={i} style={{ alignItems: "center", width: widthS * 22 }}>
                                                <View style={{ height: heightS * 48, marginTop: heightS * 4 }}>
                                                    <FullTextTooltip text={player.score.name}>
                                                        <Text style={[styles.playersName, { color: color.infoHeaderNavText, writingMode: "tb" }]}>{ShortenWord(player.score.name, 12)}</Text>
                                                    </FullTextTooltip>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                </ScrollSyncPane>
            </View>))
            :
            <AnimationLoading flag={true} widget={true} />
    }

    const body = (isMaximize, isShot) => (
        <View>
            <View style={{ flexDirection: "row", zIndex: 1, justifyContent: "space-between" }}>
                <Text style={[styles.headers, { color: color.infoHeaderNavText }, real && { visibility: "hidden" }]}>{t("statistics_map").toUpperCase()}</Text>
                <View style={[{ justifyContent: "space-between", flexDirection: "row", alignItems: "center" }, type === "compared" && { flex: 1 }]}>
                    {type === "compared" && <Text style={[styles.headers, { color: color.infoHeaderNavText, fontSize: widthS * 9, marginTop: heightS * 6, marginLeft: !real ? widthS * 55 : widthS * -40 }]}>{!real ? t("expected").toUpperCase() : t("real").toUpperCase()}</Text>}
                    <View style={{ alignItems: "center", flexDirection: "row" }}>
                        {(type !== "compared" || real) && !maximize &&
                            <IconButton
                                flag={isMaximize}
                                iconName1={images.maximize}
                                iconName2={images.xIcon}
                                setFlag={() => setMaximize(!maximize)}
                                style={{ width: "0.8vw", height: "0.8vw", resizeMode: "contain" }}
                                style2={{ width: "1vw", height: "1vw", resizeMode: "contain", }}
                            />
                        }
                        {(real || type === "real") && <TimeUnits setTimeUnits={(t) => { setTimeUnitsState(t); setRefresh(!refresh) }} />}
                    </View>
                </View>
            </View>
            {real && !isShot ? table(isShot) :
                <View style={[styles.table, { backgroundColor: background.infoBox, height: isMaximize ? "100%" : "26vw", }]} >
                    <View ref={filterRef} style={[styles.filterView, { backgroundColor: background.leftInfo, borderRightColor: color.border, height: isMaximize ? "100%" : "26vw" }]}>
                        <Text style={[styles.headers1, { color: color.infoHeaderNavText }]}>{t("filter_by").toUpperCase()}</Text>
                        <View style={[styles.itemFilterView, { paddingBottom: heightS * 4 }]}>
                            <Pressable
                                style={[
                                    styles.checkBox,
                                    { backgroundColor: background.lineHover },
                                ]}
                                onPress={() => {
                                    setFilterPlatform((filt) => filt = filt.match(/,/g)?.length === 5 ? "" : "mail,sent,chat,social,challenges,")
                                }}
                            >
                                {(() => {
                                    if (((type !== "real") && filterPlatform.match(/,/g)?.length > 2) || (type === "real" && filterPlatform.match(/,/g)?.length === 5)) {
                                        return (
                                            <View
                                                style={[
                                                    styles.checkBoxInner,
                                                    { backgroundColor: background.count },
                                                ]}
                                            />
                                        )
                                    }
                                })()}
                            </Pressable>
                            <Text style={[styles.filterText, { color: color.infoHeaderNavText, marginLeft: widthS * 0 }]}>{t("all").toUpperCase()}</Text>
                        </View>
                        <Pressable onHoverIn={() => setHoverType("mail")} onHoverOut={() => setHoverType()} style={styles.itemFilterView}>
                            <Pressable
                                style={[
                                    styles.checkBox,
                                    { backgroundColor: background.lineHover },
                                ]}
                                onPress={() => changeItemfilter("mail")}
                            >
                                {(filterPlatform.indexOf("mail") >= 0) && <View
                                    style={[
                                        styles.checkBoxInner,
                                        { backgroundColor: background.count },
                                    ]}
                                />}
                            </Pressable>
                            <View style={[styles.colorCircle, { backgroundColor: "#6A0084" }]} />
                            <Text style={[styles.filterText, { color: color.infoHeaderNavText }]}>{t("incoming_items")}</Text>
                        </Pressable>
                        <Pressable onHoverIn={() => setHoverType("sent")} onHoverOut={() => setHoverType()} style={styles.itemFilterView}>
                            <Pressable
                                style={[
                                    styles.checkBox,
                                    { backgroundColor: background.lineHover },
                                ]}
                                onPress={() => changeItemfilter("sent")}
                            >
                                {(filterPlatform.indexOf("sent") >= 0) && <View
                                    style={[
                                        styles.checkBoxInner,
                                        { backgroundColor: background.count },
                                    ]}
                                />}
                            </Pressable>
                            <View style={[styles.colorCircle, { backgroundColor: "#D98000" }]} />
                            <Text style={[styles.filterText, { color: color.infoHeaderNavText }]}>{t("sent_items")}</Text>
                        </Pressable>
                        {type !== "expected" &&
                            <Pressable onHoverIn={() => setHoverType("chat")} onHoverOut={() => setHoverType()} style={styles.itemFilterView}>
                                <Pressable
                                    style={[
                                        styles.checkBox,
                                        { backgroundColor: background.lineHover },
                                    ]}
                                    onPress={() => changeItemfilter("chat")}
                                >
                                    {(filterPlatform.indexOf("chat") >= 0) && <View
                                        style={[
                                            styles.checkBoxInner,
                                            { backgroundColor: background.count },
                                        ]}
                                    />}
                                </Pressable>
                                <View style={[styles.colorCircle, { backgroundColor: "#006ABC" }]} />
                                <Text style={[styles.filterText, { color: color.infoHeaderNavText }]}>{t("chat_messages")}</Text>
                            </Pressable>}
                        <Pressable onHoverIn={() => setHoverType("social")} onHoverOut={() => setHoverType()} style={styles.itemFilterView}>
                            <Pressable
                                style={[
                                    styles.checkBox,
                                    { backgroundColor: background.lineHover },
                                ]}
                                onPress={() => changeItemfilter("social")}
                            >
                                {(filterPlatform.indexOf("social") >= 0) && <View
                                    style={[
                                        styles.checkBoxInner,
                                        { backgroundColor: background.count },
                                    ]}
                                />}
                            </Pressable>
                            <View style={[styles.colorCircle, { backgroundColor: "#FCF000" }]} />
                            <Text style={[styles.filterText, { color: color.infoHeaderNavText }]}>{t("social")}</Text>
                        </Pressable>
                        <Pressable onHoverIn={() => setHoverType("challenges")} onHoverOut={() => setHoverType()} style={styles.itemFilterView}>
                            <Pressable
                                style={[
                                    styles.checkBox,
                                    { backgroundColor: background.lineHover },
                                ]}
                                onPress={() => changeItemfilter("challenges")}
                            >
                                {(filterPlatform.indexOf("challenges") >= 0) && <View
                                    style={[
                                        styles.checkBoxInner,
                                        { backgroundColor: background.count },
                                    ]}
                                />}
                            </Pressable>
                            <View style={[styles.colorCircle, { backgroundColor: "#00DBAF" }]} />
                            <Text style={[styles.filterText, { color: color.infoHeaderNavText }]}>{t("challenges")}</Text>
                        </Pressable>
                    </View>
                    {table(isShot)}
                </View>}
        </View>
    )
    return (
        <>
            <View style={[{ flex: 1, height: "100%" }, { marginBottom: heightS * 20 }, real && { flex: 0.8 }]}
            >
                {body(maximize)}
            </View>
            {isShot === "statistics" && <View
                nativeID='statistics' ref={(ref) => {
                    console.log("--------------------------------------------------------");
                    console.log(ref);
                    console.log("--------------------------------------------------------");
                    if (!ref) {
                        return; // אל תוסיף אם ה-ref הוא null
                    }

                    if (!refShot.current) {
                        refShot.current = [];
                    }

                    if (type === "compared" && real) {
                        refShot.current[1] = ref;
                    } else {
                        refShot.current[0] = ref;
                    }
                }}
                style={{ flex: 1, overflow: 'hidden', width: tableRef.current?.scrollWidth + filterRef.current?.offsetWidth, height: "100%", zIndex: -1000 }}
            > {body(maximize, true)}
            </View>}
        </>
    )
}

const Statistics = React.memo(MyComponent, (prevProps, nextProps) => {
    const keys = Object.keys(prevProps);
    let hasChanged = false;

    keys.forEach(key => {
        if (prevProps[key] !== nextProps[key]) {
            console.log(`${key} changed from ${prevProps[key]} to ${nextProps[key]}`);
            hasChanged = true;
        }
    });

    // Return true if props are equal, false if they are different
    return !hasChanged;
});

export default Statistics;

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        headers: {
            fontSize: widthS * 12,
            marginLeft: widthS * 7,
            marginBottom: heightS * 3,
            fontFamily: "Catamaran_300Light"
        },
        headers1: {
            fontSize: widthS * 9,
            marginBottom: heightS * 4,
            fontFamily: "Catamaran_300Light"
        },
        table: {
            width: "100%",
            borderRadius: widthS * 19,
            flexDirection: "row",
            alignItems: "center"
        },
        filterView: {
            borderTopLeftRadius: widthS * 19,
            borderBottomLeftRadius: widthS * 19,
            paddingHorizontal: widthS * 15,
            paddingTop: heightS * 20,
            borderRightWidth: widthS * 1
        },
        itemFilterView: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start"
        },
        filterText: {
            fontSize: widthS * 8,
        },
        playersName: {
            fontSize: widthS * 7,
            fontFamily: "Catamaran_300Light"
        },
        checkBox: {
            marginRight: widthS * 3,
            width: widthS * 10,
            height: widthS * 10,
            borderRadius: widthS * 3,
            justifyContent: "center",
            alignItems: "center",
        },
        checkBoxInner: {
            width: 6 * width,
            height: 6 * width,
            borderRadius: widthS * 2,
        },
        colorCircle: {
            width: 9 * width,
            height: 9 * width,
            borderRadius: "50%"
        }
    })
}
