import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  Pressable,
  Linking,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Ionicons } from '@expo/vector-icons';
import I18n from "i18n-js";
import Login from "./Login";
import Forgot from "./ForgotPassword";
import Register from "./Regiser";
import UpdatePassword from "./UpdatePassword";
import GameList from "./Game_List";
import GameTimer from "./GameTimer";
import TwoFactor from "./TwoFactor";
import CreatorOptions from "./CreatorOptions";
import CreateNewGame from "./CreateNewGame";
import ErrorMessagePopup from "../modules/ErrorMessagePopup";

import { useDispatch, useSelector } from "react-redux";
import {
  destroySession,
  setCreatorOptions,
  setErrorMessage,
  setFactor,
  setGameList,
  setLogin,
  setStartGame,
} from "../../redux/actions/index";
import {
  F_PHPSESSID,
  AUTH,
  FACTOR,
  CREATE_GAME_PERMISSION,
  GAME_ID
} from "../../config.inc";


import { SocketManager } from "../../utils/SocketManager";
import "./style.css";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import ShowFile from "../pop-ups/my-documents/ShowFile";
import EndGame from "./EndGame";
import { useRef } from "react";
import { WebSocketContext } from "../../WebSocketProvider";

export default function Index() {
  const dispatch = useDispatch();

  const refC = useRef(null);

  const { heightS, widthS } = UseResponsiveScreen();

  const styles = stylesR(heightS, widthS);

  const loginType = useSelector((state) => state.loginType.type);
  const startGame = useSelector((state) => state.loginType.startGame);
  const token = useSelector((state) => state.loginType.token);
  const lang = useSelector((state) => state.setting.setting.lang);
  const background = useSelector((state) => state.style.backgroundClient);
  const logoState = useSelector((state) => state.style.logoClient);
  const images = useSelector((state) => state.style.images);
  const color = useSelector((state) => state.style.colorClient);
  const showFileFlag = useSelector((state) => state.filesArr.fileShow);
  const errorMessageState = useSelector((state) => state.errorMessage.errorMessage);
  const DOMAIN = useSelector((state) => state.style.domain);
  const productNameForPrivacyState = useSelector((state) => state.style.productNameForPrivacy);

  const { leaveRoom } = useContext(WebSocketContext);

  const productNameForPrivacy = useMemo(() => productNameForPrivacyState, [productNameForPrivacyState]);

  const errorMessage = useMemo(() => errorMessageState, [errorMessageState]);

  const [load, setLoad] = useState(false);
  const [domain, setDomain] = useState("");
  const [logo, setLogo] = useState(null);

  const logOut = () => {
    // // const manager = new SocketManager();
    // window.sessionStorage.getItem("id_in_game") != null && window.sessionStorage.getItem(GAME_ID) != null ?
    //   manager.getSocketInstance().emit("leave", JSON.stringify({
    //     user_id: window.sessionStorage.getItem("id_in_game"),
    //     room: window.sessionStorage.getItem(GAME_ID)
    //   })) : "";
    leaveRoom();
    sessionStorage.clear();
    dispatch(destroySession());
    dispatch(setLogin());
    dispatch(setStartGame(true));
    setLoad(false);
  };

  window.onhashchange = () => {
    window.location.lasthash.push(window.location.hash);
    window.location.hash = curr;
  };

  useEffect(() => {
    if (
      window.sessionStorage.getItem(F_PHPSESSID) &&
      window.sessionStorage.getItem(FACTOR) && !window.sessionStorage.getItem("token_password")
    ) {
      if (loginType === "login") {
        if (sessionStorage.getItem(CREATE_GAME_PERMISSION) === "1") {
          dispatch(setCreatorOptions());
        } else {
          dispatch(setGameList());
        }
      }
    } else if (window.sessionStorage.getItem(F_PHPSESSID) && !window.sessionStorage.getItem("token_password")) {
      dispatch(setFactor());
    }
  }, []);

  const returnTermsName = (name) => {
    switch (name) {
      case "BeST": return "BeST";
      case "CyberJumanji": return "CyberJumanji";
      default: return "BeST";
    }
  }

  const handlePress = (pdfFilePath) => {
    Linking.openURL(pdfFilePath);
  };

  useEffect(() => {
    setDomain(DOMAIN);
  }, [DOMAIN]);

  useEffect(() => {
    setLogo(logoState);
  }, [logoState]);

  return logo && (
    <View
      ref={refC}
      style={[
        styles.container,
        {
          direction: lang === "he" ? "rtl" : "ltr",
          backgroundColor: background.login,
        },
      ]}
    >
      <View
        style={[styles.bg_image(color.logoColor, images.backgroundImageLogin),]}
      />
      <View style={[styles.main, { direction: lang === "he" ? "rtl" : "ltr" }]}>
        <View style={styles.scroll}>
          <View style={styles.body_top}>
            <Image
              style={[styles.logo_body]}
              resizeMode={domain !== "cinten" ? "contain" : "cover"}
              source={{ uri: logo }} />
            <Image
              source={{ uri: images.stickMan }}
              style={styles.stick_icon}
              resizeMode={"contain"}
            />
            {/* log - out  */}
            {((!startGame &&
              window.sessionStorage.getItem(AUTH) &&
              window.sessionStorage.getItem(FACTOR)) ||
              (loginType.indexOf("game_list")) >= 0 ||
              loginType.indexOf("creator_options") >= 0 ||
              loginType.indexOf("new_game_creator") >= 0) && (
                <Pressable
                  style={[
                    styles.out_btn,
                    { backgroundColor: "", borderColor: color.buildText },
                  ]}
                  onPressIn={() => logOut()}
                >
                  <Text
                    style={[styles.out_btn_text, { color: color.buildText }]}
                  >
                    {I18n.t("logout")}
                    <MaterialCommunityIcons
                      name="logout"
                      size={widthS * 11}
                      color={color.buildText}
                    />
                  </Text>
                </Pressable>
              )}
            {/* back */}
            {(
              (
                window.sessionStorage.getItem(CREATE_GAME_PERMISSION) === "1" &&
                window.sessionStorage.getItem(AUTH) &&
                window.sessionStorage.getItem(FACTOR)
              ) &&
              (
                loginType.indexOf("game_list") >= 0 ||
                loginType.indexOf("new_game_creator") >= 0)
            )
              && (
                <Pressable
                  style={[
                    styles.back_btn,
                    { backgroundColor: "", borderColor: color.buildText },
                  ]}
                  onPressIn={() => dispatch(setCreatorOptions())}
                >
                  <Text
                    style={[styles.out_btn_text, { color: color.buildText }]}
                  >
                    <Ionicons name="arrow-back-outline" size={widthS * 11} color={color.buildText} />
                    {I18n.t("back")}
                  </Text>
                </Pressable>
              )}
          </View>
          <View style={styles.body_main}>
            {window.location.pathname !== "/register" &&
              window.location.pathname !== "/forgotPassword" &&
              loginType === "login" &&
              window.location.pathname !== "/Register" &&
              window.location.pathname !== "/forgotPassword" && (
                <Login load={load} setLoad={setLoad} />
              )}
            {window.location.pathname !== "/register" &&
              window.location.pathname !== "/forgotPassword" &&
              loginType === "forgot" && <Forgot load={load} setLoad={setLoad} />}
            {window.location.pathname === "/register" &&
              window.location.pathname !== "/forgotPassword" && <Register />}
            {window.location.pathname === "/forgotPassword" && <UpdatePassword />}
            {window.location.pathname !== "/register" &&
              window.location.pathname !== "/forgotPassword" &&
              loginType === "creator_options" &&
              window.location.pathname !== "/forgotPassword" && <CreatorOptions />}
            {window.location.pathname !== "/register" &&
              window.location.pathname !== "/forgotPassword" &&
              loginType === "new_game_creator" &&
              window.location.pathname !== "/forgotPassword" && <CreateNewGame />}
            {window.location.pathname !== "/register" &&
              window.location.pathname !== "/forgotPassword" &&
              loginType.indexOf("game_list") >= 0 && (
                <GameList
                  load={load}
                  setLoad={setLoad}
                  real={loginType.indexOf("real") >= 0}
                  demo={loginType.indexOf("demo") >= 0}
                />
              )}
            {window.location.pathname !== "/register" &&
              loginType === "factor" && <TwoFactor />}
            {!startGame && loginType === "end" && window.sessionStorage.getItem(AUTH) ? <EndGame /> : !startGame && window.sessionStorage.getItem(AUTH) && loginType === "in" && <GameTimer />}
            {showFileFlag.link.length > 0 && <ShowFile />}
          </View>
        </View>
        <View
          style={[
            styles.body_footerB,
            { backgroundColor: background.loginFooter },
          ]}
        >
          <View style={styles.footerB_Left}>
            <Text style={[styles.link, { color: color.text2 }]}>
              © {domain}
            </Text>
          </View>
          <View style={styles.footerB_right}>
            <Pressable onPress={() => handlePress(require(`../../privacyAndTerms/Terms.pdf`))}>
              <Text style={[styles.link, { color: color.text2 }]}>
                Terms of Use
              </Text>
            </Pressable>
            <Text style={[styles.link, { color: color.text2 }]}>
              |
            </Text>
            <Pressable onPress={() => handlePress(require(`../../privacyAndTerms/${returnTermsName(productNameForPrivacy)}_p.pdf`))}>
              <Text style={[styles.link, { color: color.text2 }]}>
                Privacy
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
      {errorMessage?.map((errorMsg) => {
        if (errorMsg.flag) {
          return <ErrorMessagePopup
            flag={errorMsg.flag}
            setFlag={() => dispatch(setErrorMessage(false))}
            title={errorMsg.title}
            colorState={color}
            body={errorMsg.body}
            backgroundState={background}
            refC={refC}
            deleteDraftHandler={errorMsg.deleteDraftHandler}
            clickOkFunc={errorMsg.clickOkFunc}
            success={errorMsg.success}
          />
        }
      })}
    </View>
  );
}
const stylesR = (heightS, widthS) => {
  return StyleSheet.create({
    container: {
      position: "relative",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
    },
    main: {
      width: "100%",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    bg_image: (color, image) => ({
      resizeMode: "cover",
      minHeight: "100%",
      width: "100%",
      position: "absolute",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `linear-gradient(to right, rgb(14 130 176 / 0%) -22%, ${color}75 100%), url(${image})`,
    }),
    scroll: {
      flex: 1,
    },
    body_top: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    logo_body: {
      width: "15vw",
      height: "5vw",
      marginTop: heightS * 30,
      marginBottom: heightS * 30,
    },
    stick_icon: {
      position: "absolute",
      width: widthS * 60,
      height: heightS * 60,
      right: widthS * 305,
      top: heightS * 50,
    },
    out_btn: {
      position: "absolute",
      right: widthS * 200,
      top: widthS * 35,
      backgroundColor: "#00000000",
      borderWidth: widthS * 1,
      paddingHorizontal: widthS * 12,
      paddingVertical: heightS * 2,
      borderRadius: widthS * 15,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    back_btn: {
      position: "absolute",
      left: widthS * 220,
      top: widthS * 35,
      backgroundColor: "#00000000",
      borderWidth: widthS * 1,
      paddingHorizontal: widthS * 12,
      paddingVertical: heightS * 2,
      borderRadius: widthS * 15,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    out_btn_text: {
      fontWeight: "500",
      display: "flex",
      fontSize: widthS * 10,
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Catamaran_400Regular",
    },
    body_main: {
      alignSelf: "center",
      width: "100%",
      flex: 1,
      display: "flex",
      alignItems: "center",
    },
    body_footerB: {
      display: "flex",
      flexDirection: "row",
      height: heightS * 40,
      paddingLeft: widthS * 170,
      paddingRight: widthS * 170,
      justifyContent: "space-between",
      marginTop: heightS * 30,
      alignItems: "center",
      width: "100%",
      // paddingBottom: height * 6,
    },
    footerB_right: {
      display: "flex",
      flexDirection: "row",
      gap: "0.4vw"
    },
    link: {
      fontSize: widthS * 8.5,
      fontWeight: "400",
      color: "#b2b2b27d",
      fontFamily: "Catamaran_400Regular",
    },
  });
};
