import { combineReducers } from "redux";
import { actionsReducer } from "./Actions";
import { chatReducer } from "./Chat";
import { gameInfoReducer } from "./GameInfo";
import { mailReducer } from "./Mail";
import { docsReducer } from "./MyDocuments";
import { newsReducer } from "./News";
import { socialMediaReducer } from "./SocialMedia";
import { systemMessagesReducer } from "./SystemAdminMessages";
import { loginOrRegister } from "./LoginOrRegister";
import { setting } from "./setting";
import { user } from "./User";
import { manageStyle } from "./Style";
import { Position } from "./Psotions";
import { buildManage } from "./Build";
import { Players } from "./Players";
import { Swimlane } from "./SwimlaneChart";
import { errorReducer } from "./Error";
import { minimizePopups } from "./MinimizePopups";
import { events } from "./Events";
import { Analytics } from "./Analytics";
import {
  DESTROY_SESSION,
  SET_DRAFTS_MAIL,
  SET_DRAFTS_SYSTEM,
} from "../actions";
import { loadingAnimation } from "./loading";


const appReducer = combineReducers({
  gameInfoArr: gameInfoReducer,
  actionsArr: actionsReducer,
  systemMessages: systemMessagesReducer,
  filesArr: docsReducer,
  mailArr: mailReducer,
  socialMedia: socialMediaReducer,
  newsArr: newsReducer,
  chatArr: chatReducer,
  loginType: loginOrRegister,
  setting: setting,
  user: user,
  style: manageStyle,
  position: Position,
  build: buildManage,
  players: Players,
  swimlane: Swimlane,
  errorMessage: errorReducer,
  minimize: minimizePopups,
  events : events,
  analytics: Analytics,
  loadingAnimation: loadingAnimation
});

const rootReducer = (state, action) => {
  if (
    (action.type === SET_DRAFTS_MAIL || action.type === SET_DRAFTS_SYSTEM) &&
    !state.loginType.token
  )
    return;
    
  if (action.type === DESTROY_SESSION) {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
