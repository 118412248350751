import {
  StyleSheet,
  Text,
  View,
  Pressable,
  Image,
  TouchableHighlight,
  ScrollView,
  TextInput
} from "react-native";
import React, { useState, useRef, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AntDesign, Ionicons, Entypo } from "@expo/vector-icons";
import DraggableFlatList, { NestableScrollContainer, NestableDraggableFlatList, ScaleDecorator } from "react-native-draggable-flatlist"
import {
  useClickOutside,
  useClickWithBtnOutside,
} from "../../../modules/useClickOutside";
import { SwimlaneChartContext } from "../../../../ContextData/Swimlanechart";
import ImportExport from "./popups/ImportExport";
import { FilterPopup } from "./popups/filter/FilterPopup";
import ActionTooltip from "./popups/ActionTooltip";
import { t } from "i18n-js";
import { FontAwesome5 } from "@expo/vector-icons";
import {
  addEvent,
  changeBuildFlag,
  changeInfoFlag,
  deleteActions,
  deleteEvent,
  editActionCreator,
  setEventsCreator,
  setKpi,
  showAnalytics,
} from "../../../../redux/actions/creatorActions";
import {
  URL_PATH,
  SEND_DUPLICATE
} from "@env";
import UseResponsiveSize from "../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../modules/UseResponsiveScreen";
import EventsScroll from "./EventsScroll";
import CreateAction from "./event-components/CreateAction";
import { addAction, destroySession, loading, setCreatorOptions, setErrorMessage, setGameList, setToken } from "../../../../redux/actions";
import { useMemo } from "react";
import DraggedEvents from "../../../modules/DraggedEvents";
import onClickEndGameCheck from "../../../Elements/onClickEndGameCheck";
import Endbtn from "../Elements-btn/EndBtn";
import { F_PHPSESSID, _F_PHPSESSID_ } from "../../../../config.inc";
import { GAME_ID } from "../../../../config.inc";
import { USER_ID_IN_GAME } from "../../../../config.inc";
import { USER_GAME_NAME } from "../../../../config.inc";
import { CREATE_GAME_PERMISSION } from "../../../../config.inc";
import { SocketManager } from "../../../../utils/SocketManager";
import { listenerAddWith } from "../../../../AddListener";
import useExitGame from "../../../modules/ExitGame";

export default function BuildContainer({ infoObj, setInfoObj, listRef, permission, setLoad, showTable, setShowTable, handleAddRow, tableRef, saveNewRow, removeNewRow }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const styles = stylesR(width, height, heightS, widthS, background, color);

  const exitGame = useExitGame();

  const eventsBtnRef = useRef();
  const eventsPopupRef = useRef();
  const eventContentRef = useRef();
  const eventsRef = useRef([]);
  const eventsListRef = useRef(null);
  const importContainerRef = useRef(null);
  const importRef = useRef(null);
  const filterRef = useRef();
  const isPossibletoClose = useRef(true);


  const {
    addEventContext,
    deleteActionsByEvent,
    addAction: addActionData,
  } = useContext(SwimlaneChartContext);

  const { checkEnd } = onClickEndGameCheck();

  const dispatch = useDispatch();
  const images = useSelector((state) => state.style.images);
  const eventsState = useSelector((state) => state.events.events);
  const tooltip = useSelector((state) => state.swimlane.tooltip);
  const editAction = useSelector((state) => state.swimlane.editAction);
  const actionsArrState = useSelector((state) => state.swimlane.actions);
  const kpiActions = useSelector((state) => state.swimlane.kpis);

  const [showImportExport, setShowImportExport] = useState(false);///
  const [showReset, setShowReset] = useState(false);///
  const [showFilter, setShowFilter] = useState(false);
  const [showEventsList, setShowEventsList] = useState(false);
  const [indexEvent, setIndexEvent] = useState();
  const [showEvent, setShowEvent] = useState(0);
  const [events, setEvents] = useState([]);
  const [active, setActive] = useState(false);
  const [isKpiEvents, setIsKpiEvents] = useState(false);
  const [eventsListIndex, setEventListIndex] = useState(0);
  const [scrollOffsetX, setScrollOffsetX] = useState(0);
  const [asNewRowTable, setAsNewRowTable] = useState(false);


  const leaveGame = () => {
    const manager = new SocketManager();
    manager.getSocketInstance().emit("leave", JSON.stringify({
      user_id: window.sessionStorage.getItem(USER_ID_IN_GAME),
      room: window.sessionStorage.getItem(GAME_ID)
    }))
  }
  const setNewIndex = (arr, index, newIndex, flag) => {
    let temp = [...arr];
    if (!isKpiEvents) temp.pop();
    let current = temp[index];
    temp = temp.filter((e, i) => i !== index);
    temp.splice(newIndex, 0, current);
    if (isKpiEvents) dispatch(setKpi([...temp]));
    else {
      if (flag) {
        dispatch(setEventsCreator(temp))
      } else {
        setEvents([...temp]);
      }
    }
  };

  const createNextEventHandler = (index) => {
    index && setEventListIndex(index);
    setActive(
      index
        ? { eventBetween: `${index + 1}`, position: index, type: "event_between", color: "#DE82D8" }
        : events[events.length - 1]
    );
    setShowEvent(1);
  };

  const onPressHandler = async (id, step) => {
    const index = events.findIndex((event) => event.id === id);
    setEventListIndex(index);
    setActive(events[index]);
    if (showEvent > 1 && id === active) return;
    setShowEvent(step || 1);
  };

  const scrollToItem = (id, index) => {
    const { offsetWidth, scrollWidth } = eventsListRef.current;
    let eventsPositions = [];
    for (let i = eventsRef.current.length - 1; i >= 0; i--) {
      const position = eventsRef.current[i]?.ref?.getBoundingClientRect().x;
      if (eventsRef.current[i]?.id === id) {
        eventsPositions.push({ index: i, position });
        break;
      } else { continue };
    }
    if (eventsPositions[0].position > offsetWidth || eventsPositions[0].position < (scrollWidth - offsetWidth)) {
      eventsListRef.current.scrollTo({
        x: eventsPositions[0].position + scrollOffsetX - offsetWidth - widthS * 98,
        animated: true,
      });
    }
  }

  const onArrowRightPressHandler = () => {
    const { offsetWidth, scrollWidth } = eventsListRef.current;
    let eventsPositions = [];
    let output;
    for (let i = eventsRef.current.length - 1; i >= 0; i--) {
      const position = eventsRef.current[i]?.ref.getBoundingClientRect().x;
      if (Math.floor(position) > Math.floor(offsetWidth) + widthS * 35)
        eventsPositions.push({ index: i, position });
      else break;
    }
    eventsPositions = eventsPositions.reverse();

    if (eventsPositions.length >= 6) {
      output = eventsPositions[6];
    } else output = eventsPositions[1];
    if (!output?.index || output.position + scrollOffsetX > scrollWidth)
      eventsListRef.current.scrollToEnd();
    else if (output.index > -1) {
      eventsListRef.current.scrollTo({
        x: output.position + scrollOffsetX - offsetWidth - widthS * 98,
        animated: true,
      });
    }
  };

  const onArrowLeftPressHandler = () => {
    const eventsPositions = [];
    const first = eventsRef.current[0].ref.getBoundingClientRect().x;
    let output;
    for (let i = 0; i < eventsRef.current.length; i++) {
      const position =
        eventsRef.current[i].ref.getBoundingClientRect().x + Math.abs(first);
      if (Math.floor(position) < Math.floor(scrollOffsetX) - widthS * 35)
        eventsPositions.push({ index: i, position });
      else break;
    }
    if (eventsPositions.length >= 6) {
      output = eventsPositions[eventsPositions.length - 6];
    } else output = eventsPositions[eventsPositions.length - 1];
    if (!output?.index) {
      eventsListRef.current.scrollTo({
        x: 0,
        animated: true,
      });
    } else if (output.index > -1)
      eventsListRef.current.scrollTo({
        x: output.position,
        animated: true,
      });
  };

  const deleteEventFunc = (event) => {
    isPossibletoClose.current = false;
    dispatch(setErrorMessage(true, "", t("error_delete_event"), (flagEvent) => {
      if (flagEvent) {
        dispatch(
          setErrorMessage(
            true,
            undefined,
            t("error_delete_event_actions"),
            async (e) => {
              if (!isKpiEvents) {
                if (e) {
                  dispatch(loading(true));
                  deleteActionsByEvent(event.id, "delete", actionsArrState)
                    .then(() => {
                      dispatch(deleteActions(event.id));
                      addEventContext(event, true)
                        .then(() => {
                          dispatch(loading(false));
                          isPossibletoClose.current = true;
                          dispatch(deleteEvent(event.id));
                        })
                        .catch(() => {
                          dispatch(loading(false));
                          isPossibletoClose.current = true;
                          setErrorMessage(
                            true,
                            undefined,
                            t("alert_mail_error"),
                          )
                        })
                    }).catch(() => {
                      dispatch(loading(false));
                      setErrorMessage(
                        true,
                        undefined,
                        t("alert_mail_error"),
                      )
                    });
                } else {
                  deleteActionsByEvent(event.id, "event", actionsArrState).then(() => {
                    addEventContext(event, true).then(() => {
                      dispatch(deleteEvent(event.id));
                    }).catch(() => {
                      setErrorMessage(
                        true,
                        undefined,
                        t("alert_mail_error"),
                      )
                    })
                  }).catch(() => {
                    dispatch(loading(false));
                    isPossibletoClose.current = true;
                    setErrorMessage(
                      true,
                      undefined,
                      t("alert_mail_error"),
                    )
                  });
                }
              } else {
                dispatch(loading(true));
                addActionData(event, actionsArrState?.length, "delete").then(() => {
                  dispatch(deleteActions(event.id, true));
                  dispatch(loading(false));
                }).catch(() => {
                  dispatch(loading(false));
                  setErrorMessage(
                    true,
                    undefined,
                    t("alert_mail_error"),
                  )
                });
              }
            }
          )
        );
      }
    }));
  }

  const popupEvents = (
    <View
      style={styles.popupContainer}
      ref={eventsPopupRef}
      nativeID="action-tooltip"
    >
      <View
        style={[styles.triangle, { borderBottomColor: background.infoBody }]}
      />
      <View
        // showsVerticalScrollIndicator={false}
        style={[styles.eventListView, { backgroundColor: background.infoBody, maxHeight: heightS * 300 }]}
      >
        <TextInput
          onChangeText={(txt) => searchEvents(txt)}
          style={{
            backgroundColor: background.infoBox,
            width: "90%",
            alignSelf: "center",
            paddingVertical: heightS * 5,
            borderRadius: widthS * 5,
            outlineStyle: "none",
            paddingHorizontal: widthS * 7,
            color: color.text
          }}
        />
        <DraggableFlatList
          nativeID="popup-share"
          containerStyle={{ flex: 1, marginVertical: "0.5vw" }}
          data={!isKpiEvents
            ? events
            : kpiActions}
          onDragEnd={({ from, to }) => onDragEnd(from, to)}
          keyExtractor={(item) => item?.id}
          renderItem={({ item, drag, isActive }) => eventItem({ item, drag, isActive })}
        />
        <Pressable
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            paddingLeft: widthS * 13,
          }}
          onPress={() => {
            checkEnd(() => {
              if (!isKpiEvents) {
                eventsListRef.current?.scrollToEnd();
                setTimeout(() => {
                  createNextEventHandler(events.length - 1);
                }, 100);
              } else {
                setShowEvent(3);
                dispatch(
                  editActionCreator({
                    x: widthS * 65,
                    y: heightS * 72,
                    message_id: Math.random(),
                  })
                );
              }
              setShowEventsList(false);
            })
          }}
        >
          <Image source={{ uri: images.plusIcon }} style={styles.menuIcon} />
          <Text
            style={[
              styles.eventListText,
              { color: color.buildText, paddingVertical: heightS * 5 },
            ]}
          >
            {!isKpiEvents ? t("add_new_event") : t("add_new_action")}
          </Text>
        </Pressable>
      </View>
    </View>
  );

  const onDragEnd = (from, to) => {
    if (!isKpiEvents) {
      addEventContext({ position: to, ...events[from] }, false, true)
        .then((res) => {
        }).catch(() => { });
    } else {
      return
    }
  };

  const eventItem = ({ item: event, drag, isActive }) => {
    if (!isKpiEvents && !event?.name) return;
    return (
      <ScaleDecorator>
        <Pressable
          style={({ hovered, focused, pressed }) => [
            styles.eventListItem,
            { backgroundColor: hovered ? color.infoBorder : "" },
          ]}
          onPress={() => {
            scrollToItem(event.id, i);
            setShowEventsList(false);
            setEvents(eventsState);
            setShowEvent(2);
            setTimeout(() => {
              if (!isKpiEvents) {
                onPressHandler(event.id, 2);
              } else {
                dispatch(
                  editActionCreator({
                    platform_type: event.platform_type,
                    id: event.id,
                  })
                );
              }
            }, 500);
          }}
        >
          {({ hovered, focused, pressed }) => (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                flex: 1,
                height: heightS * 20,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <View
                  style={
                    !hovered && {
                      width: widthS * 8,
                      marginHorizontal: widthS * 5,
                    }
                  }
                >
                  {hovered && !isKpiEvents && (
                    <TouchableHighlight
                      underlayColor={""}
                      onPressIn={drag}
                    >
                      <Image
                        source={{ uri: images.menuIcon }}
                        style={styles.menuIcon}
                      />
                    </TouchableHighlight>
                  )}
                </View>
                <View
                  style={[
                    {
                      width: widthS * 2,
                      height: heightS * 17,
                      marginRight: widthS * 5,
                      backgroundColor: event.color || event.eventColor,
                    },
                  ]}
                />
                <Text
                  numberOfLines={1}
                  selectable={false}
                  style={[
                    styles.eventListText,
                    { color: color.buildText },
                  ]}
                >
                  {event.title || event.subject}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingRight: widthS * 5,
                }}
              >
                {hovered && (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      position: "absolute",
                      right: widthS * 7,
                    }}
                  >
                    <TouchableHighlight
                      underlayColor={""}
                      onPress={() => {
                        if (!isKpiEvents) {
                          onPressHandler(event.id);
                          scrollToItem(event.id, i);
                        } else {
                          scrollToItem(event.id, i);
                          dispatch(
                            editActionCreator({
                              platform_type: event.platform_type,
                              id: event.id,
                              edit: true,
                            })
                          );
                        }
                        setShowEventsList(false);
                      }}
                    >
                      <FontAwesome5
                        name="edit"
                        size={widthS * 9.5}
                        color={color.eventsIcons}
                        style={{ padding: widthS * 5 }}
                      />
                    </TouchableHighlight>
                    <TouchableHighlight
                      onPress={() => {
                        deleteEventFunc(event);
                      }}
                      underlayColor={""}
                    >
                      <Image
                        source={{ uri: images.deleteIconGray }}
                        style={styles.deleteIcon}
                      />
                    </TouchableHighlight>
                  </View>
                )}
              </View>
            </View>
          )}
        </Pressable>
      </ScaleDecorator>
    )
  }

  const searchEvents = (txt) => {
    let temp = eventsState.filter((e) => e.name && e.name.toLowerCase().indexOf(txt.toLowerCase()) === 0);
    setEvents(temp);
  }

  useMemo(() => {
    setEvents([...eventsState]);
  }, [eventsState]);

  useEffect(() => {
    importContainerRef?.current?.addEventListener(
      "mousedown",
      listenerAddWith(importRef, showImportExport, showReset, () => setShowImportExport(false))
    );
    return () => {
      importContainerRef?.current?.removeEventListener(
        "mousedown",
        listenerAddWith(importRef, showImportExport, showReset, () => setShowImportExport(false))
      );
    };
  }, [importRef.current, importContainerRef.current, showReset, showImportExport]);

  useClickOutside(filterRef, showFilter, () => setShowFilter(false));
  useClickWithBtnOutside(eventsBtnRef, eventsPopupRef, showEventsList, () =>
    setShowEventsList(false)
  );

  useEffect(() => {
    editAction?.platform_type &&
      setShowEvent(
        editAction.platform_type === "mail"
          ? "mail"
          : editAction.platform_type === "news"
            ? "news"
            : "sm"
      );
  }, [editAction]);

  const duplicateGame = () => {
    const url = `${URL_PATH}${SEND_DUPLICATE}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    try {
      fetch(url, {
        method: "POST",
        body: body,
      })
        .then((res) => res.text())
        .then((res) => {
          try {
            res = JSON.parse(res);
            if (res.duplicate !== "false") {
              exitGame();
            }
            dispatch(loading(false));
          } catch (es) {
            dispatch(loading(false));
            return;
          }
        })
    } catch (e) {
      dispatch(loading(false));
    }
  }

  return (
    <>
      <View
        style={[
          styles.container(showTable),
          {
            backgroundColor: background.infoBody,
          },
        ]}
      >
        {/* header - container */}
        <View style={{ flex: 2, zIndex: 2 }}>
          <View
            style={[styles.header, { backgroundColor: background.infoHeader }]}
          >
            {(!showTable && permission) && <View style={{ flexDirection: "row" }}>
              <Pressable
                style={[
                  styles.btnNav,
                  { flexDirection: "row", alignItems: "center" },
                ]}
                onPress={() => {
                  checkEnd(() => {
                    eventsListRef.current?.scrollToEnd();
                    setActive(events[events.length - 1]);
                    setEventListIndex(events.length - 1);
                    setShowEvent(1);
                  });
                }}
              >
                <Image
                  source={{ uri: images.addEventIcon }}
                  style={{
                    width: widthS * 14,
                    height: widthS * 14,
                    marginRight: widthS * 5,
                  }}
                />
                <Text
                  style={[
                    styles.leftHeaderText,
                    { color: color.infoHeaderNavText },
                  ]}
                >
                  {t("new_event").toUpperCase()}
                </Text>
              </Pressable>
              <Pressable
                style={[
                  styles.btnNav,
                  asNewRowTable && styles.btn_save,
                  { flexDirection: "row", alignItems: "center" },
                ]}
                onPress={() => {
                  checkEnd(() => {
                    if (!showTable) {
                      setShowEvent(3);
                      setActive(false);
                      dispatch(
                        editActionCreator({
                          x: widthS * 65,
                          y: heightS * 72,
                          message_id: Math.random(),
                        })
                      );
                    } else {
                      if (asNewRowTable) {
                        saveNewRow();
                        setAsNewRowTable(false);
                      } else {
                        handleAddRow(tableRef);
                        setAsNewRowTable(true);
                      }
                    }
                  })
                }}
              >
                <Ionicons
                  name="add"
                  size={widthS * 20}
                  color={color.infoHeaderNavText}
                />
                <Text
                  style={[
                    styles.leftHeaderText,
                    { color: color.infoHeaderNavText },
                  ]}
                >
                  {t("new_action").toUpperCase()}
                </Text>
              </Pressable>
            </View>}
            <View style={{ flexDirection: "row", alignItems: "center", marginLeft: "auto" }}>
              <Pressable
                style={styles.btnTable}
                onPress={() => setShowTable(!showTable)}
              >
                {!showTable ? <AntDesign name="table" style={styles.tableIconsize} /> : <Entypo name="flow-tree" style={styles.tableIconsize} />}
                <Text
                  style={[
                    styles.leftHeaderText,
                    {
                      color: color.infoHeaderNavText,
                      fontSize: 10 * widthS,
                    },
                  ]}
                >
                  {t(!showTable ? "table_view" : "flow_view").toUpperCase()}
                </Text>
              </Pressable>
              <Pressable
                style={styles.btnNav}
                onPress={() => setShowFilter(true)}
              >
                {showFilter ? (
                  <Image
                    source={{ uri: images.filterIconActive }}
                    style={{
                      width: widthS * 8,
                      height: widthS * 8,
                      marginRight: widthS * 2,
                    }}
                  />
                ) : (
                  <Image
                    source={{ uri: images.filterIcon }}
                    style={{
                      width: widthS * 8,
                      height: widthS * 8,
                      marginRight: widthS * 2,
                    }}
                  />
                )}
                <Text
                  style={[
                    styles.leftHeaderText,
                    {
                      color: showFilter
                        ? color.infoHeaderNavBorder
                        : color.infoHeaderNavText,
                      fontSize: 10 * widthS,
                    },
                  ]}
                >
                  {t("filter").toUpperCase()}{" "}
                  <Ionicons size={widthS * 7} name="caret-down" />
                </Text>
              </Pressable>
              <FilterPopup show={showFilter} ref={filterRef} />
              <View>
                <Pressable
                  style={[
                    styles.btnNav,
                    {
                      width: 20 * widthS,
                      height: 20 * widthS,
                      padding: 0,
                      borderRadius: "50%",
                      backgroundColor: background.infoBody,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  ]}
                  onPress={() => setShowImportExport(!showImportExport)}
                >
                  <Text
                    style={[
                      styles.leftHeaderText,
                      {
                        color: showImportExport
                          ? color.infoHeaderNavBorder
                          : color.infoHeaderNavText,
                      },
                    ]}
                  >
                    {"\u2022\u2022\u2022"}
                  </Text>
                </Pressable>
              </View>
              <View style={styles.headerRight}>
                {permission && <Endbtn setLoad={setLoad} sendToSocket={() => props.sentMessageWebSocket("game_info", "all")} swimlane={true} />}
              </View>
            </View>
          </View>
          <ImportExport
            show={showImportExport}
            arr={actionsArrState}
            showReset={showReset}
            permission={permission}
            setShowReset={setShowReset}
            duplicateGame={duplicateGame}
            importContainerRef={importContainerRef}
            refCurrent={importRef} />
          {/* evenet/kpi list */}
          {!showTable && <View style={{ flex: 3, flexDirection: "row" }}>
            {/* evenet/kpi buttons */}
            <View
              style={{
                borderRightWidth: 1,
                borderRightColor: color.infoBorder,
                width: widthS * 33.5,
                height: "100%",
                zIndex: 10,
              }}
            >
              <Pressable
                style={[
                  styles.actionKpiListBtn,
                  {
                    backgroundColor: isKpiEvents
                      ? color.infoBorder
                      : background.infoBody,
                  },
                ]}
                onPress={() => {
                  setIsKpiEvents(true);
                  eventsListRef.current.scrollTo(0, 0);
                }}
              >
                <Text
                  style={{
                    transform: [{ rotate: "-90deg" }],
                    color: color.buildTitle,
                    fontSize: widthS * 14,
                  }}
                >
                  {t("kpis")}
                </Text>
              </Pressable>
              <Pressable
                style={[
                  styles.actionKpiListBtn,
                  {
                    backgroundColor: isKpiEvents
                      ? background.infoBody
                      : color.infoBorder,
                  },
                ]}
                onPress={() => {
                  setIsKpiEvents(false);
                  eventsListRef.current.scrollTo(0, 0);
                }}
              >
                <Text
                  style={{
                    transform: [{ rotate: "-90deg" }],
                    color: color.buildTitle,
                    fontSize: widthS * 14,
                  }}
                >
                  {t("events")}
                </Text>
              </Pressable>
            </View>
            {/*  */}
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            ></View>
            {
              <EventsScroll
                isPossibletoClose={isPossibletoClose}
                onArrowLeftPressHandler={onArrowLeftPressHandler}
                onArrowRightPressHandler={onArrowRightPressHandler}
                setLoad={setLoad}
                active={active}
                setActive={setActive}
                show={showEvent}
                permission={permission}
                setShow={setShowEvent}
                deleteEventfunc={(event) => deleteEventFunc(event)}
                editEventFunc={(event, i) => {
                  onPressHandler(event.id);
                  scrollToItem(event.id, i);
                }}
                addEvent={(event) => {
                  dispatch(loading(true));
                  addEventContext(event)
                    .then((res) => {
                      dispatch(loading(false));
                      setActive({ ...event, id: res?.id });
                    }).catch(() => dispatch(loading(false)));
                }}
                createNextEventHandler={createNextEventHandler}
                onPressHandler={onPressHandler}
                index={eventsListIndex}
                setIndex={setEventListIndex}
                eventsRef={eventsRef}
                scrollRef={eventsListRef}
                kpis={isKpiEvents && kpiActions}
                scrollOffsetX={scrollOffsetX}
                setScrollOffsetX={setScrollOffsetX}
              />
            }
            <Pressable
              style={styles.viewEventBtn}
              ref={eventsBtnRef}
              onPress={() => setShowEventsList(true)}
            >
              <Text style={[styles.viewEventText, { color: color.buildTitle }]}>
                {isKpiEvents
                  ? t("view_all_kpis")
                  : t("view_all_event").toUpperCase()}
                <Ionicons
                  size={widthS * 7}
                  name="caret-down"
                  style={{ marginHorizontal: widthS * 2 }}
                  color={color.infoHeaderNavText}
                />
              </Text>
              {showEventsList && popupEvents}
            </Pressable>
          </View>}
        </View>
      </View>
      {tooltip && <ActionTooltip />}
      {
        editAction && showEvent && !active ?
          (
            <CreateAction
              isPossibletoClose={isPossibletoClose}
              setLoad={setLoad}
              key={editAction}
              show={showEvent}
              setShow={setShowEvent}
              events={events}
              eventContentRef={eventContentRef}
              setActive={setActive}
            />
          ) : (
            ""
          )}
    </>
  );
}

const stylesR = (width, height, heightS, widthS, background, color) =>
  StyleSheet.create({
    container: (flag) => ({
      flex: flag ? "" : 1.8,
      zIndex: 10
    }),
    header: {
      flexDirection: "row",
      alignItems: "center",
      minHeight: "4vw",
      maxHeight: "4vw",
      justifyContent: "space-between",
      paddingLeft: widthS * 50,
      paddingRight: widthS * 20,
      flex: 1,
    },
    btnNav: {
      paddingRight: widthS * 12,
      marginRight: widthS * 10,
      flexDirection: "row",
      alignItems: "center",
    },
    btn_save: {
      paddingVertical: "0.3vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "1vw",
      borderRadius: "0.6vw",
      backgroundColor: background.infoSaveBtn
    },
    leftHeaderText: {
      fontSize: widthS * 12,
      fontWeight: "300",
    },
    btnTable: {
      flexDirection: "row",
      alignItems: "center",
      marginRight: "1.5vw",
    },
    tableIconsize: {
      fontSize: "1.1vw",
      color: color.infoHeaderNavText,
      marginRight: "0.5vw",
    },
    endDate: {
      fontSize: "0.6vw",
      fontWeight: "Catamaran_400Regular",
    },
    body: {
      flex: 10,
    },
    actionKpiListBtn: {
      width: widthS * 33,
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: widthS * 15,
      flex: 1,
    },
    model: {
      position: "relative",
    },
    modelView: {
      position: "absolute",
      right: 10 * widthS,
      top: 50 * heightS,
      alignItems: "center",
      justifyContent: "center",
    },
    traingular: {
      position: "absolute",
      top: -11 * heightS,
      right: 20 * widthS,
      width: 0,
      height: 0,
      borderLeftWidth: widthS * 9,
      borderRightWidth: widthS * 9,
      borderBottomWidth: widthS * 9,
      borderStyle: "solid",
      backgroundColor: "transparent",
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      zIndex: 3,
    },
    popup: {
      marginTop: -1,
      paddingHorizontal: widthS * 20,
      paddingTop: heightS * 25,
      paddingBottom: heightS * 35,
      width: widthS * 180,
      zIndex: 1,
      borderRadius: 25,
      shadowColor: "#000000",
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.5,
      shadowRadius: 5,
    },
    popupPress: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: widthS * 4,
    },
    popupPressLang: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: widthS * 4,
      paddingVertical: heightS * 2,
    },
    popupText: {
      color: "#dcdcdc",
      fontSize: widthS * 8,
      marginLeft: widthS * 5,
    },
    viewEventBtn: {
      position: "absolute",
      right: widthS * 34,
      top: heightS * 5,
      color: "pink",
      fontSize: widthS * 20,
    },
    viewEventText: {
      fontSize: widthS * 9,
      fontWeight: "400",
    },
    popupContainer: {
      top: heightS * 13,
      right: widthS * -10,
      position: "absolute",
      flexDirection: "column",
      alignItems: "center",
    },
    triangle: {
      right: widthS * -120,
      zIndex: 6,
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      borderStyle: "solid",
      borderLeftWidth: widthS * 10,
      borderRightWidth: widthS * 10,
      borderBottomWidth: widthS * 10,
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
    },
    eventListView: {
      width: widthS * 300,
      marginTop: heightS * 0.01,
      zIndex: 5,
      borderRadius: widthS * 10,
      paddingVertical: heightS * 12,
      paddingHorizontal: widthS * 2,
      display: "flex",
      flexDirection: "column",
    },
    eventListItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingVertical: heightS * 3,
    },
    menuIcon: {
      width: widthS * 8,
      height: widthS * 8,
      resizeMode: "contain",
      marginHorizontal: widthS * 5,
    },
    eventListText: {
      fontSize: widthS * 10,
      fontWeight: "400",
      width: widthS * 230,
    },
    deleteIcon: {
      width: widthS * 11,
      height: widthS * 11,
      resizeMode: "contain",
    },
    headerRight: {
      alignItems: "center",
      flexDirection: "row",
    },
    btnSave: {
      width: widthS * 47,
      height: heightS * 25,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 8
    },
  });
