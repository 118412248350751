let types = [
    {name: "Message", value: "1"},
    {name: "Analysis", value: "3"},
    {name: "Decition", value: "3"},
    {name: "cfaChallenges", value: "2"},
    {name: "cfaSolutions", value: "2"},
    {name: "cfaDecition", value: "2"},
    {name: "cfaQuestionnaire", value: "2"},
]

export const ReturnTypeFlow = (type) => {
    let typeN = "";
    types.forEach((e) => {
        if(type === e.name){
            typeN = e.value;
        }
    });
    return typeN;
}