const colorsMain = [
    "#520065",
    "#DE82D8",
    "#007493",
    "#00B192",
    "#D98000",
    "#4D320F",
    "#724673",
    "#006ABC",

    "#290033",
    "#B049A7",
    "#003B49",
    "#006D6D",
    "#A85F00",
    "#38260A",
    "#4A2349",
    "#003A5E",

    "#7B3396",
    "#FFBBFF",
    "#33A6BD",
    "#33D4B5",
    "#FFB233",
    "#7F5A39",
    "#9C699D",
    "#33A6DE"
];

export const colorForEvent = (index) => {
    if (index >= (colorsMain.length - 1)) {
        return colorsMain[index - (colorsMain.length - 1)];
    } else {
        return colorsMain[index];
    }
}