import React, { useState, useEffect } from "react";
import { StyleSheet, View, Pressable, Image, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import {
  buildShowFlag,
  mailFlag,
  newsFlag,
  removeType,
  setChatFlag,
  setErrorMessage,
  setIndexCurrent,
  setNewMessageFlag,
  setTypeMinimize,
  socialFlag,
} from "../../redux/actions";
import { t } from "i18n-js";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";

export default function Bottom_Menu() {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const color = useSelector((state) => state.style.color);

  const styles = stylesR(width, height, heightS, widthS, color);

  const dispatch = useDispatch();

  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);
  const showMailBox = useSelector((state) => state.mailArr.flag);
  const showSocial = useSelector((state) => state.socialMedia.flag);
  const showNews = useSelector((state) => state.newsArr.flag);
  const showChat = useSelector((state) => state.chatArr.flag);
  const mailCount = useSelector((state) => state.mailArr.unReadMessages);
  const socialCount = useSelector((state) => state.socialMedia.unReadPosts);
  const newsCount = useSelector((state) => state.newsArr.unReadNews);
  const chatCount = useSelector((state) => state.chatArr.unReadMessages);
  const popupsCount = useSelector((state) => state.loginType.popupsCount);
  const minimizePopups = useSelector((state) => state.minimize.minimize);
  const minimizePopupsIndex = useSelector((state) => state.minimize.indexCurrent);
  const minimizeFlag = useSelector((state) => state.minimize.buildShow);

  const popupBtnClick = (flag, setFlag, type, num, setNewMailFlag = false) => {
    if (!flag && popupsCount >= 3) {
      return dispatch(
        setErrorMessage(
          true,
          t("oops"),
          t("popup_error")
        )
      );
    }
    if (minimizePopups.find((e) => e.name === type && !e.show)) {
      dispatch(setTypeMinimize({ name: type, type: 2, show: true, num: num }));
      dispatch(setIndexCurrent(num));
      if (minimizeFlag) {
        dispatch(buildShowFlag(false));
      }
      return;
    } else if (minimizePopups.find((e) => e.name === type && e.show && minimizePopupsIndex !== num)) {
      dispatch(setIndexCurrent(num));
      if (minimizeFlag) {
        dispatch(buildShowFlag(false));
      }
      return;
    } else if (minimizePopups.find((e) => e.name === type && e.show && minimizePopupsIndex === num)) {
      if (minimizeFlag) {
        return dispatch(buildShowFlag(false));
      }
      dispatch(setTypeMinimize({ name: type, type: 2, show: false, num: num }));
      return;
    } else {
      dispatch(setFlag(true));
      dispatch(setTypeMinimize({ name: type, type: 2, show: true, num: num }));
      dispatch(setIndexCurrent(num));
      if (minimizeFlag) {
        dispatch(buildShowFlag(false));
      }
      return;
    }
  };

  const printCountMessage = (count) => {
    if (count > 99) {
      return "99+";
    } else {
      return count;
    }
  };

  const printPopups = (arr) => {
    return Array.isArray(arr) && arr.map((btn) => {
      return createBtn(btn.name, btn.show);
    })
  }

  const createBtn = (type, show) => {
    switch (type) {
      case "simulation":
        return (
          <View style={[{ marginHorizontal: widthS * 2, flexDirection: "column", alignItems: "center", justifyContent: "center" }]}>
            <Pressable
              style={[styles.btn, { backgroundColor: color.buttonMenuBottom, marginHorizontal: widthS * 6, }, checkMinimize("simulation").popup && checkMinimize("simulation").show && minimizePopupsIndex === 4 && styles.btn_shadow]}
              onPress={() => {
                if (show && minimizePopupsIndex !== 4) return dispatch(setIndexCurrent(4))
                if (!show) return dispatch(setTypeMinimize({ name: type, type: 1, show: true, num: 4 }));
                dispatch(setTypeMinimize({ name: type, type: 1, show: !show, num: 4 }))
              }}
            >
              <Image style={styles.img} source={{ uri: images.simulationInfo }} />
            </Pressable>
            <View style={[styles.line, { backgroundColor: checkMinimize("simulation").popup ? color.border : color.bottomMenuBorder, width: checkMinimize("simulation").popup && checkMinimize("simulation").show ? "70%" : checkMinimize("simulation").popup ? "30%" : "0" }]} />
          </View>
        );
      case "challenges":
        return (
          <View style={[{ marginHorizontal: widthS * 2, flexDirection: "column", alignItems: "center", justifyContent: "center" }]}>
            <Pressable
              style={[styles.btn, { backgroundColor: color.buttonMenuBottom, marginHorizontal: widthS * 6, }, checkMinimize("challenges").popup && checkMinimize("challenges").show && minimizePopupsIndex === 5 && styles.btn_shadow]}
              onPress={() => {
                if (show && minimizePopupsIndex !== 5) return dispatch(setIndexCurrent(5))
                if (!show) return dispatch(setTypeMinimize({ name: type, type: 1, show: true, num: 5 }));
                dispatch(setTypeMinimize({ name: type, type: 1, show: !show, num: 5 }))
              }}
            >
              <Image style={styles.img} source={{ uri: images.action }} />
            </Pressable>
            <View style={[styles.line, { backgroundColor: checkMinimize("challenges").popup ? color.border : color.bottomMenuBorder, width: checkMinimize("challenges").popup && checkMinimize("challenges").show ? "70%" : checkMinimize("challenges").popup ? "30%" : "0" }]} />
          </View>
        )
      case "system":
        return (
          <View style={[{ marginHorizontal: widthS * 2, flexDirection: "column", alignItems: "center", justifyContent: "center" }]}>
            <Pressable
              style={[styles.btn, { backgroundColor: color.buttonMenuBottom, marginHorizontal: widthS * 6, }, checkMinimize("system").popup && checkMinimize("system").show && minimizePopupsIndex === 7 && styles.btn_shadow]}
              onPress={() => {
                if (show && minimizePopupsIndex !== 7) return dispatch(setIndexCurrent(7))
                if (!show) return dispatch(setTypeMinimize({ name: type, type: 1, show: true, num: 7 }));
                dispatch(setTypeMinimize({ name: type, type: 1, show: !show, num: 7 }))
              }}
            >
              <Image style={styles.img} source={{ uri: images.system }} />
            </Pressable>
            <View style={[styles.line, { backgroundColor: checkMinimize("system").popup ? color.border : color.bottomMenuBorder, width: checkMinimize("system").popup && checkMinimize("system").show ? "70%" : checkMinimize("system").popup ? "30%" : "0" }]} />
          </View>
        );
      case "files":
        return (
          <View style={[{ marginHorizontal: widthS * 2, flexDirection: "column", alignItems: "center", justifyContent: "center" }]}>
            <Pressable
              style={[styles.btn, { backgroundColor: color.buttonMenuBottom, marginHorizontal: widthS * 6, }, checkMinimize("files").popup && checkMinimize("files").show && minimizePopupsIndex === 8 && styles.btn_shadow]}
              onPress={() => {
                if (show && minimizePopupsIndex !== 8) return dispatch(setIndexCurrent(8))
                if (!show) return dispatch(setTypeMinimize({ name: type, type: 1, show: true, num: 8 }));
                dispatch(setTypeMinimize({ name: type, type: 1, show: !show, num: 8 }));
              }}
            >
              <Image style={styles.img} source={{ uri: images.files }} />
            </Pressable>
            <View style={[styles.line, { backgroundColor: checkMinimize("files").popup ? color.border : color.bottomMenuBorder, width: checkMinimize("files").popup && checkMinimize("files").show ? "70%" : checkMinimize("files").popup ? "30%" : "0" }]} />
          </View>
        );
    }
  }

  const checkMinimize = (typePopup) => {
    let popup = minimizePopups.find((e) => e.name === typePopup);
    if (popup) {
      return {
        popup: true,
        show: popup.show
      }
    } else {
      return {
        popup: false,
        show: false
      }
    }
  }

  return (
    <>
      <View style={styles.container}>
        <View style={[{ marginHorizontal: widthS * 2, flexDirection: "column", alignItems: "center", justifyContent: "center" }]}>
          <Pressable
            style={[styles.btn, { backgroundColor: background.mailLogoColor }, checkMinimize("mail").popup && checkMinimize("mail").show && minimizePopupsIndex === 9 && styles.btn_shadow]}
            onPress={() => popupBtnClick(showMailBox, mailFlag, "mail", 9, setNewMessageFlag)}
          >
            {mailCount > 0 && (
              <Text style={[styles.count, { backgroundColor: background.countBottom }]}>
                {printCountMessage(mailCount)}
              </Text>
            )}
            <Image style={styles.img} source={{ uri: images.mail }} />
          </Pressable>
          <View style={[styles.line, { backgroundColor: checkMinimize("mail").popup ? color.border : color.bottomMenuBorder, width: checkMinimize("mail").popup && checkMinimize("mail").show ? "70%" : checkMinimize("mail").popup ? "30%" : "0" }]} />
        </View>
        <View style={[{ marginHorizontal: widthS * 2, flexDirection: "column", alignItems: "center", justifyContent: "center" }]}>
          <Pressable
            style={[styles.btn, { backgroundColor: background.socialLogoColor }, checkMinimize("social").popup && checkMinimize("social").show && minimizePopupsIndex === 3 && styles.btn_shadow]}
            onPress={() => popupBtnClick(showSocial, socialFlag, "social", 3)}
          >
            {socialCount > 0 && !showSocial && (
              <Text style={[styles.count, { backgroundColor: background.countBottom }]}>
                {printCountMessage(socialCount)}
              </Text>
            )}
            <Image style={styles.img} source={{ uri: images.social }} />
          </Pressable>
          <View style={[styles.line, { backgroundColor: checkMinimize("social").popup ? color.border : color.bottomMenuBorder, width: checkMinimize("social").popup && checkMinimize("social").show ? "70%" : checkMinimize("social").popup ? "30%" : "0" }]} />
        </View>
        <View style={[{ marginHorizontal: widthS * 2, flexDirection: "column", alignItems: "center", justifyContent: "center" }]}>
          <Pressable
            style={[styles.btn, { backgroundColor: background.news }, checkMinimize("news").popup && checkMinimize("news").show && minimizePopupsIndex === 2 && styles.btn_shadow]}
            onPress={() => popupBtnClick(showNews, newsFlag, "news", 2)}
          >
            {newsCount > 0 && (
              <Text style={[styles.count, { backgroundColor: background.countBottom }]}>
                {printCountMessage(newsCount)}
              </Text>
            )}
            <Image style={styles.img} source={{ uri: images.news }} />
          </Pressable>
          <View style={[styles.line, { backgroundColor: checkMinimize("news").popup ? color.border : color.bottomMenuBorder, width: checkMinimize("news").popup && checkMinimize("news").show ? "70%" : checkMinimize("news").popup ? "30%" : "0" }]} />
        </View>
        <View style={[{ marginHorizontal: widthS * 2, flexDirection: "column", alignItems: "center", justifyContent: "center" }]}>
          <Pressable
            style={[styles.btn, { backgroundColor: background.chatLogoColor }, checkMinimize("chat").popup && checkMinimize("chat").show && minimizePopupsIndex === 1 && styles.btn_shadow]}
            onPress={() => popupBtnClick(showChat, setChatFlag, "chat", 1)}
          >
            {chatCount > 0 && (
              <Text style={[styles.count, { backgroundColor: background.countBottom }]}>
                {printCountMessage(chatCount)}
              </Text>
            )}
            <Image style={styles.img} source={{ uri: images.chat }} />
          </Pressable>
          <View style={[styles.line, { backgroundColor: checkMinimize("chat").popup ? color.border : color.bottomMenuBorder, width: checkMinimize("chat").popup && checkMinimize("chat").show ? "70%" : checkMinimize("chat").popup ? "30%" : "0" }]} />
        </View>
        {minimizePopups.find((e) => e.type === 1) && minimizePopups.length > 0 && <View style={{ width: widthS * 0.5, height: widthS * 25, backgroundColor: color.border, marginVertical: widthS * 6, marginHorizontal: widthS * 6 }} />}
        {printPopups(minimizePopups)}
      </View>
    </>
  );
}
const stylesR = (width, height, heightS, widthS, color) => {
  return StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "row",
      paddingHorizontal: widthS * 6,
    },
    btn: {
      width: widthS * 25,
      height: widthS * 25,
      borderRadius: "50%",
      marginTop: widthS * 6,
      marginHorizontal: widthS * 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    btn_shadow: {
      transition: "all, 0.3s",
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.9,
      shadowRadius: 6
    },
    img: {
      height: widthS * 13,
      width: widthS * 13,
      resizeMode: "contain",
    },
    count: {
      position: "absolute",
      top: -heightS * 3,
      right: widthS * -3,
      color: color.text2,
      width: widthS * 10,
      height: heightS * 10,
      fontSize: widthS * 6,
      fontWeight: "bold",
      borderRadius: 25,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Catamaran_400Regular",
    },
    line: {
      transition: "all, 0.3s",
      height: heightS * 2.5,
      borderRadius: widthS * 3,
      marginTop: heightS * 4,
      marginBottom: heightS * 2,
    }
  });
};
