import {
  View,
  Text,
  Pressable,
  StyleSheet,
  Image,
  TouchableHighlight,
} from "react-native";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import I18n from "i18n-js";
import { useClickWithBtnOutside } from "../../../modules/useClickOutside";
import UseResponsiveSize from "../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../modules/UseResponsiveScreen";
import { addEventBetween } from "../../../../redux/actions/creatorActions";
import { useRef } from "react";
import onClickEndGameCheck from "../../../Elements/onClickEndGameCheck";
import FullTextTooltip from "../../../Elements/FullTextTooltip";

export default function Event({
  id,
  active,
  eventsRef,
  show,
  setShow,
  setActive,
  title,
  events,
  onPressHandler,
  index,
  permission,
  setIndex,
  eventContentRef,
  titleTextRef,
  color,
  createNextEventHandler,
  eventBetween,
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const { checkEnd, endGame } = onClickEndGameCheck();

  const dispatch = useDispatch();
  const colors = useSelector((state) => state.style.color);
  const backgrounds = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);

  const addBetweenRef = useRef(null);


  useClickWithBtnOutside(
    eventsRef.current[index],
    eventContentRef,
    show,
    () => {
      if (active) {
        setShow(false);
        setActive(false);
      }
    }
  );

  return (
    ((!title && permission) || (title)) && <>
      <View
        ref={(ref) => {
          eventsRef.current[index] = { ref: ref, id };
        }}
        style={[
          styles.select,
          { zIndex: show && active ? 2 : 1, marginRight: index === events.length - 1 ? widthS * 15 : 0, marginLeft: index === 0 ? widthS * 15 : 0 },
        ]}
      >
        {
          <View
            style={{
              alignItems: "flex-start",
              zIndex: show === 1 ? 2 : 4,
              height: widthS * 85,
            }}
          >
            <Pressable
              style={[
                styles.eventBtn,
                styles.selectBtn,
                {
                  backgroundColor: backgrounds.infoBody,
                  borderBottomColor: colors.border,
                  color: colors.infoHeaderNavText,
                  backgroundColor:
                    title || eventBetween || (show && active)
                      ? backgrounds.eventActive
                      : backgrounds.eventUnActive,
                  height: show && active ? heightS * 70 : widthS * 85,
                  alignItems: "flex-start",
                },
              ]}
              onPress={() => {
                setIndex(index);
                onPressHandler(id, title ? 2 : 1);
              }}
            >
              {!active && !title && !endGame && (
                <View
                  style={[
                    styles.iconContainer,
                    {
                      backgroundColor: backgrounds.popupLogin,
                    },
                  ]}
                >
                  <Image
                    source={{ uri: images.plusIcon }}
                    style={{ width: widthS * 10, height: widthS * 10 }}
                  />
                </View>
              )}
              <View style={{ width: "100%" }}>
                <View
                  style={{
                    borderBottomColor: color,
                    borderBottomWidth: widthS * 1.5,
                    textAlign: "center",
                    paddingTop: heightS * 10,
                    height: "3vw"
                  }}
                >
                  <Text
                    style={{
                      color:
                        title || eventBetween || (show && active)
                          ? colors.infoHeaderNavText
                          : colors.buildTitle,
                      paddingBottom: 5 * heightS,
                      fontSize: widthS * 10,
                    }}
                  >
                    {I18n.t("event").toUpperCase()}
                  </Text>
                </View>

                {(show !== 1 || !active) && (
                  <View>
                    <FullTextTooltip text={title || eventBetween ||
                      `${events.length}`.padStart(2, "0")}>
                      <Text
                        ref={title && titleTextRef}
                        style={{
                          color:
                            title || (show && active)
                              ? colors.infoHeaderNavText
                              : colors.buildTitle,
                          fontSize: title ? 12 * widthS : 28 * widthS,
                          paddingTop: title && heightS * 10,
                          marginHorizontal: "auto",
                          width: "90%",
                          textAlign: "center"
                        }}
                        numberOfLines={1}
                      >
                        {title || eventBetween ||
                          `${events.length}`.padStart(2, "0")}
                      </Text>
                    </FullTextTooltip>
                  </View>
                )}
              </View>
            </Pressable>
          </View>
        }
      </View>
      {index !== events.length - 1 && (
        <Pressable
          ref={addBetweenRef}
          pointerEvents={show && "none"}
          disabled={!permission}
          style={{
            width: widthS * 30,
            height: widthS * 40,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {({ hovered }) =>
            hovered && permission &&
            (
              <TouchableHighlight
                onPress={() => {
                  checkEnd(() => {
                    dispatch(addEventBetween(index + 1));
                    createNextEventHandler(index + 1);
                  })
                }}
                style={{
                  backgroundColor: backgrounds.popupLogin,
                  width: 20 * widthS,
                  height: 20 * widthS,
                  borderRadius: "50%",
                  position: "absolute",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  source={{ uri: images.plusIcon }}
                  style={{ width: widthS * 10, height: widthS * 10 }}
                />
              </TouchableHighlight>
            )
          }
        </Pressable>
      )}
    </>
  );
}

const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    select: {
      position: "relative",
      width: widthS * 85,
      marginHorizontal: 15 * widthS,
    },
    selectBtn: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
    },

    eventBtn: {
      width: widthS * 85,
      fontSize: widthS * 12,
      paddingHorizontal: widthS * 10,
      borderRadius: 15 * widthS,
    },

    iconContainer: {
      width: 20 * widthS,
      height: 20 * widthS,
      borderRadius: "50%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      right: -10 * widthS,
      top: heightS * 45,
    },
  });
