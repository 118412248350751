import React, { useMemo, useRef, useState, useEffect } from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';
import { Text as TextSvg, G, Line } from 'react-native-svg';
import { useSelector } from 'react-redux';
import { t } from 'i18n-js';
import { vwToPx } from "../../../../modules/ConvertPXToVw";
import { useClickOutside } from '../../../../modules/useClickOutside';
import PieChart from '../../../../modules/Pie_chart';
import ShortenWord from "../../../../modules/ShortenWord";

export default function TooltipQuestionnaire({ tooltip, colors, latters }) {
    const color = useSelector((state) => state.style.color);
    const background = useSelector((state) => state.style.background);
    const lang = useSelector((state) => state.setting.setting.lang);
    const players = useSelector(state => state.gameInfoArr.gameInfoArr.players);

    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const popupRef = useRef(null);

    // Centralized state management
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isSelected, setIsSelected] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseOver = (index) => {
        if (!isSelected) {
            setHoveredIndex(index);
            setCurrentIndex(index);
        }
    };

    const handleMouseOut = () => {
        if (!isSelected) {
            setHoveredIndex(null);
            setCurrentIndex(null);
        }
    };

    const handleClick = (index) => {
        if (isSelected && currentIndex === index) {
            // Deselecting current slice
            setIsSelected(false);
            setCurrentIndex(null);
            setHoveredIndex(null);
        } else {
            // Selecting new slice or first selection
            setIsSelected(true);
            setCurrentIndex(index);
        }
    };

    useClickOutside(popupRef, isSelected, () => {
        setIsSelected(false);
        setCurrentIndex(null);
        setHoveredIndex(null);
    });

    const pieData = useMemo(() => {
        return tooltip?.data?.answers?.map((answer, index) => ({
            value: answer?.players?.split(",")?.length || 0,
            svg: { fill: colors[index] },
            key: index,
            arc: {
                outerRadius: `${currentIndex === index ? 75 : 70}%`,
                padAngle: 0
            },
            hoverIn: ({ index }) => handleMouseOver(index),    // שונה
            hoverOut: () => handleMouseOut(),
            onClick: ({ index }) => handleClick(index)         // שונה
        }));
    }, [tooltip?.data?.answers, colors, currentIndex, isSelected]);

    const Labels = ({ slices }) => {
        const total = tooltip.data?.answers?.reduce((sum, e) =>
            sum + (e?.players?.split(",")?.length || 0), 0) || 0;

        return slices.map((slice, index) => {
            const { labelCentroid, pieCentroid, data } = slice;
            if (data.value === 0) return null;

            const labelText = ((data.value / total) * 100).toFixed(1);
            return (
                <G key={index}>
                    <Line
                        x1={labelCentroid[0]}
                        y1={labelCentroid[1]}
                        x2={pieCentroid[0]}
                        y2={pieCentroid[1]}
                        stroke="white"
                    />
                    <TextSvg
                        x={labelCentroid[0]}
                        y={labelCentroid[1]}
                        fill="white"
                        textAnchor="middle"
                        fontSize="0.75vw"
                        fontFamily="Catamaran_300Light"
                    >
                        {`${labelText}%`}
                    </TextSvg>
                </G>
            );
        });
    };

    const renderPopup = (index) => (
        <View
            ref={popupRef}
            style={styles.popupContainer(background, direction)}
            nativeID='filter-shadow'
        >
            <View style={styles.triangle(background, direction)} />
            <View style={styles.popupContent}>
                <Text style={styles.popupTitleText(color)}>
                    <View style={styles.keyCircle(colors[index])} />
                    {t("title_popup_pie_chart").toUpperCase()} {`"${latters[index]}"`}
                </Text>
                <ScrollView
                    nativeID='scrollPopup'
                    style={styles.scrollPopup}
                    contentContainerStyle={styles.scrollMainPopup}
                >
                    {tooltip?.data?.answers[index]?.players?.split(",").map((id, idx) => (
                        <Text key={idx} style={styles.popupItemText(color)}>
                            {ShortenWord(players?.find(e => e.id === id)?.name_for_game, 28)}
                        </Text>
                    ))}
                </ScrollView>
            </View>
        </View>
    );

    return (
        <View style={styles.container}>
            <View style={styles.pieView}>
                {pieData && (
                    <PieChart
                        style={{
                            height: vwToPx(10),
                            width: vwToPx(12),
                            position: "relative",
                        }}
                        data={pieData}
                        outerRadius="100%"
                        innerRadius="48%"
                        labelRadius="90%"
                        padAngle
                    >
                        <Labels />
                    </PieChart>
                )}
                <Text style={styles.centerText(color)} numberOfLines={1}>
                    {t("answers").toUpperCase()}
                </Text>
            </View>
            {currentIndex !== null && renderPopup(currentIndex)}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    pieView: {
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    centerText: (color) => ({
        fontSize: '0.8vw',
        color: 'white',
        maxWidth: '4vw',
        position: 'absolute',
        fontFamily: 'Catamaran_300Light'
    }),
    popupContainer: (background, direction) => ({
        padding: '0.5vw',
        alignItems: 'center',
        width: '14vw',
        position: 'absolute',
        top: '0',
        ...(direction === 'ltr' ? { right: '18vw' } : { left: '18vw' }),
        borderRadius: '1vw',
        backgroundColor: background.infoBox,
        zIndex: 1000
    }),
    popupContent: {
        width: '100%',
        alignItems: 'center'
    },
    triangle: (background, direction) => ({
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderLeftWidth: '0.8vw',
        borderRightWidth: '0.8vw',
        borderBottomWidth: '0.9vw',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: background.infoBox,
        position: 'absolute',
        ...(direction === 'ltr' ? { right: '-1vw' } : { left: '-1vw' }),
        top: '3vw',
        transform: [{ rotate: direction === 'ltr' ? '90deg' : '-90deg' }]
    }),
    keyCircle: (color) => ({
        backgroundColor: color,
        width: '0.6vw',
        height: '0.6vw',
        borderRadius: '100%',
        marginRight: '0.5vw'
    }),
    popupTitleText: (color) => ({
        fontSize: '0.8vw',
        color: 'white',
        fontFamily: 'Catamaran_300Light'
    }),
    popupItemText: (color) => ({
        fontSize: '0.8vw',
        color: 'white',
        fontFamily: 'Catamaran_300Light',
        marginLeft: '1.2vw'
    }),
    scrollPopup: {
        maxHeight: '12vw',
        minHeight: '5vw',
        width: '100%'
    },
    scrollMainPopup: {
        padding: '0.5vw'
    }
});