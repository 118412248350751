import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL_PATH, CHOOSE_GAME } from "@env";
import { F_PHPSESSID, GAME_ID, USER, USER_FIRST_NAME, USER_GAME_NAME, USER_ID_IN_GAME, USER_LAST_NAME, _F_PHPSESSID_, _ID_ } from "../config.inc";
import { destroySession, setInGame, setToken } from "../redux/actions";
import { checkIfIsEncodeText } from "../componnets/modules/removeSpacialCherectersAndSpaes";
export const GameListContext = createContext();

export default function GameListProvider(props) {

	const dispatch = useDispatch();

	const chooseGame = (id, func, is_demo, func1) => {
		try {
			const url = `${URL_PATH}${CHOOSE_GAME}`;
			const body = new FormData();
			body.append(_F_PHPSESSID_, window.sessionStorage.getItem(
				F_PHPSESSID
			));
			body.append(_ID_, id);
			const requestOptions = {
				method: "POST",
				body: body
			};
			if (func1) func1(true);
			fetch(url, requestOptions)
				.then((res) => res.text())
				.then((res) => {
					res = JSON.parse(res);
					if (func1) func1(false);
					if (res.status !== false) {
						dispatch(destroySession());
						sessionStorage.setItem("is_demo", is_demo === "1" ? true : false)
						if (res.name_in_game && res.id_in_game) {
							window.sessionStorage.setItem(USER_GAME_NAME, checkIfIsEncodeText(res.name_in_game));
							window.sessionStorage.setItem(GAME_ID, id);
							window.sessionStorage.setItem(USER_ID_IN_GAME, res.id_in_game);
							func()
						} else {
							let user = JSON.parse(sessionStorage.getItem(USER));
							let name = `${window.sessionStorage.getItem(USER_FIRST_NAME)} ${window.sessionStorage.getItem(USER_LAST_NAME)}`
							window.sessionStorage.setItem(USER_GAME_NAME, checkIfIsEncodeText(name));
							window.sessionStorage.setItem(USER_ID_IN_GAME, user.id ? user.id : user.user.id);
							window.sessionStorage.setItem(GAME_ID, id);
							dispatch(setInGame());
							// func();
						}
					} else if (res.length === 0) {
						let name = `${window.sessionStorage.getItem(USER_FIRST_NAME)} ${window.sessionStorage.getItem(USER_LAST_NAME)}`
						window.sessionStorage.setItem(USER_GAME_NAME, checkIfIsEncodeText(name));
						window.sessionStorage.setItem(GAME_ID, id);
						dispatch(setToken(true));
						dispatch(setInGame());
						// func()
					}
				}).catch((e) => {
				})
		} catch (e) { }
	}

	return (
		<GameListContext.Provider
			value={{
				chooseGame
			}}
		>
			{props.children}
		</GameListContext.Provider>
	);
}
