import { StyleSheet, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export default function Zoom(size) {
    const zoomSize = useSelector(state => state.swimlane.zoom);

    const [zoomNum, setZoomNum] = useState();

    const convertZoom = (stringNum) => {
        switch (stringNum) {
            case "100":
                return 1;
            case "75":
                return 0.75;
            case "50":
                return 0.5;
            case "25":
                return 0.25;
        }
    }

    useMemo(() => {
        setZoomNum(convertZoom(zoomSize));
    }, [zoomSize, size]);
    if (zoomNum && !Number(size) && (size?.indexOf("vh") >= 0 || size?.indexOf("vw") >= 0)) {
        let currentSizeNum = size.indexOf(".") >= 0 ? parseFloat(size.match(/(\d+\.\d+)/)[1]) : Number(size.match(/\d+/)[0]);
        let typeSize = size.indexOf(".") >= 0 ? size.replace(/\d+\./g, '') : size.replace(/\d+/g, '');
        return `${currentSizeNum * zoomNum}${typeSize}`;
    } else if(!Number(size) && (size?.indexOf("vh") >= 0 || size?.indexOf("vw") >= 0)) {
        return size;
    }
    else {
        return size * zoomNum;
    }
}

const styles = StyleSheet.create({})