import React, { createContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_MAIL_PATH,
  GET_MAIL_PATH,
  GET_SENT_MAIL,
  GET_DRAFT_MAIL,
  SEND_DRAFT,
  MARK_AS_READ_MAIL_PATH,
  URL_PATH,
  CHANGE_MESSAGE_FLAG,
  GET_QUESTIONNAIRE,
  SEND_QUESTIONE
} from "@env";
import {
  F_PHPSESSID,
  GAME_ID,
  USER_GAME_NAME,
  USER_ID_IN_GAME,
  _CC_LIST_,
  _CREATOR_ID_,
  _EVENT_,
  _EVENT_ID_,
  _FILE0_,
  _FILE_COUNT_,
  _F_PHPSESSID_,
  _ID_,
  _MESSAGE_BODY_,
  _MESSAGE_ID_,
  _MESSAGE_PARENT_ID_,
  _SUBJECT_,
  _TO_LIST_,
} from "../config.inc";
import {
  addReplyMail,
  setMail,
  setSentMail,
  setSentSystem,
  setSystemMessages,
  msgFlagMail,
  markMail,
  setDraftsMail,
  deleteDraftsMail,
} from "../redux/actions/index";
import { mapMails, updateLastTime } from "./contextFunction";
import { generateRandomHash } from "../componnets/modules/HashGenerator";
export const MailContext = createContext();

export default function MailProvider(props) {
  const dispatch = useDispatch();
  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
  const events = useSelector((state) => state.events.allEvents);
  const lang = useSelector(state => state.setting.setting.lang);
  const playerPermission = useSelector((state) => state.gameInfoArr.permission);

  const [hasFetched, setHasFetched] = useState(false);

  const replaceUrlWithHash = (html, filesArr, body) => {
    const isBase64 = /^data:image\/.*;base64,/;
    const div = document.createElement('div');
    div.innerHTML = html;

    const images = div.querySelectorAll('img');

    const imageHashes = [];

    images.forEach((image, i) => {

      if (image.getAttribute('id') === 'img-post-share' || !image.getAttribute('src').match(isBase64)) {
        return;
      }

      const hash = generateRandomHash();
      image.setAttribute('src', hash);

      // Add file contents to object
      const file = filesArr[i];
      imageHashes[i] = hash;

      // Add file to form data
      body.append(`body_image${i}`, file);
    });

    return {
      html: div.innerHTML,
      imageHashes
    };
  }

  const getMailData = () => {
    let url = `${URL_PATH}${GET_MAIL_PATH}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    const requestOptions = {
      method: "POST",
      body: body,
    };
    try {
      fetch(url, requestOptions)
        .then((res) => res.text())
        .then((res) => {
          res = JSON.parse(res);
          if (res.status !== "false") {
            if (res?.messages?.length > 0) {
              updateLastTime(sessionStorage.getItem("last_update_time"), res?.last_update_time);
              const playerId = sessionStorage.getItem(USER_ID_IN_GAME);
              let messages = res.messages;
              const { mailMessages, systemMessages } = mapMails(
                messages,
                gameInfo.players,
                playerId,
                playerPermission
              );
              dispatch(setMail(mailMessages, playerId, playerPermission, lang));
              dispatch(setSystemMessages(systemMessages, playerId, playerPermission));
            }
            // mailMessages = getQuestionnaireData(mailMessages);

            getSentMailData();
            getDraftMailData();
          }
        })
        .catch((err) => {
          // Expo Tel-Aviv
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getSentMailData = () => {
    return new Promise((resolve, reject) => {
      let url = `${URL_PATH}${GET_SENT_MAIL}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      const requestOptions = {
        method: "POST",
        body: body,
      };
      try {
        fetch(url, requestOptions)
          .then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res.status !== "false") {
              const playerId = sessionStorage.getItem(USER_ID_IN_GAME);
              let messages = res.messages;
              const { mailMessages, systemMessages } = mapMails(
                messages,
                gameInfo.players,
                playerId,
                playerPermission
              );
              dispatch(setSentMail(mailMessages, playerId, playerPermission, lang));
              dispatch(setSentSystem(systemMessages, playerId, playerPermission));
              resolve(true);
            }
          })
          .catch((err) => {
            // Expo Tel-Aviv
            reject(false);
            console.log(err);
          });
      } catch (e) {
        reject(false);
      }
    })
  };

  const getDraftMailData = () => {
    return new Promise((resolve, reject) => {
      let url = `${URL_PATH}${GET_DRAFT_MAIL}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      if (sessionStorage.getItem("last_update_time_draft")) {
        body.append("last_update_time", sessionStorage.getItem("last_update_time_draft"));
      }
      const requestOptions = {
        method: "POST",
        body: body,
      };
      try {
        fetch(url, requestOptions)
          .then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res.status !== "false") {
              dispatch(setDraftsMail(res.drafts));
              sessionStorage.setItem("last_update_time_draft", res?.last_update_time);
              resolve(true);
            }
          })
          .catch((err) => {
            // Expo Tel-Aviv
            reject(false);
            console.log(err);
          });
      } catch (e) {
        reject(false);
      }
    })
  };

  const sentMail = (newSentMail, type, from) => {
    return new Promise((resolve, reject) => {
      const url = `${URL_PATH}${ADD_MAIL_PATH}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));


      body.append(
        _CREATOR_ID_,
        newSentMail.from?.id ||
        newSentMail.from_id || ""
      );
      body.append(_SUBJECT_, encodeURI(newSentMail.subject) || "");

      body.append(_TO_LIST_, newSentMail.to_list_ids || "");
      body.append(_EVENT_, newSentMail.event ? newSentMail.event : "");
      body.append(_EVENT_ID_, newSentMail.event_id ? newSentMail.event_id : "");
      body.append(_CC_LIST_, newSentMail.cc_list_ids || "");
      if (newSentMail.message_parent_id?.length > 0) {
        body.append(_MESSAGE_PARENT_ID_, newSentMail.message_parent_id);
      }
      if (newSentMail.old_files_list) {
        body.append("old_file_list", newSentMail?.old_files_list?.length > 0 ? newSentMail.old_files_list.map((e) => e.id).join(",") : "");
      }
      if (newSentMail?.body_images) {
        let { imageHashes, html } = replaceUrlWithHash(newSentMail.body, newSentMail.body_images, body);
        body.append("image_hashes", JSON.stringify(imageHashes));
        body.append(_MESSAGE_BODY_, encodeURI(html));
      } else {
        body.append(_MESSAGE_BODY_, encodeURI(newSentMail.body));
      }
      if (newSentMail.attachments) {
        body.append(_FILE_COUNT_, newSentMail.attachments?.length);
        newSentMail.attachments.forEach((file, i) => {
          body.append(`file${i}`, file.file);
        });
      }
      if (newSentMail?.is_draft_message) {
        body.append("is_draft_message", "1");
      }
      if (newSentMail?.id) {
        body.append("id", newSentMail?.id);
      }
      if (newSentMail?.is_delete) {
        body.append("is_delete", "1");
      }
      try {
        fetch(url, { method: "POST", body: body })
          .then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res.message_sent == "true") {
              if (playerPermission === "15" && !newSentMail?.is_draft_message) {
                // getSentMailData();
              }
              if (newSentMail?.is_draft_message) {
                getDraftMailData();
              } else {
                // props.sentMessageWebSocket(
                //   "mail",
                //   `${newSentMail.to_list_ids}, ${newSentMail.cc_list_ids}`
                // );
              }
              resolve(true);
            } else if (newSentMail?.is_delete) {
              dispatch(deleteDraftsMail(newSentMail));
              resolve(true);
            }
          })
          .catch((e) => {
            reject(false);
          });
      } catch (e) { reject(false); }
      // }
    })
  };

  const sentDraft = (newSentMail, type, from) => {
    return new Promise((resolve, reject) => {
      const url = `${URL_PATH}${SEND_DRAFT}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      if (newSentMail?.id) {
        body.append("message_id", newSentMail?.id);
      }
      try {
        fetch(url, { method: "POST", body: body })
          .then((res) => res.text())
          .then(async (res) => {
            res = JSON.parse(res);
            if (res?.message_sent !== "false") {
              // newSentMail["is_delete"] = true;
              // await sentMail(newSentMail);
              // props.sentMessageWebSocket(
              //   "mail",
              //   `${newSentMail?.to_list_ids}, ${newSentMail?.cc_list_ids}`
              // );
              resolve(true);
            } else {
              newSentMail["is_delete"] = true;
              await sentMail(newSentMail);
              reject(false);
            }
          })
          .catch((e) => {
            reject(false);
          });
      } catch (e) { reject(false); }
      // }
    })
  };

  const markMailAsReadMail = (id) => {
    const url = `${URL_PATH}${MARK_AS_READ_MAIL_PATH}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    body.append(_MESSAGE_ID_, id);
    const requestOptions = { method: "POST", body: body };
    try {
      fetch(url, requestOptions)
        .then((res) => res.text())
        .then((res) => {
          res = JSON.parse(res);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) { }
  };

  const changeFlagData = (id, type, flag) => {
    return new Promise((reslove, reject) => {
      const url = `${URL_PATH}${CHANGE_MESSAGE_FLAG}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      body.append(_MESSAGE_ID_, id);
      body.append("is_flaged", flag ? "0" : "1");
      if (type === "Sent") {
        body.append("page", "sentItems");
      }
      try {
        fetch(url, {
          method: "POST",
          body: body,
        }).then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res.flag_change !== "false") {
              if (type === "Drafts") {
                getDraftMailData();
              }
              reslove(true);
            }
          }).catch((e) => {
            reject(false);
          })
      }
      catch (e) {
        reject(false);
      }
    })
  }

  ///////////////////////////////////////////////////////////////////////////
  const getQuestionnaireData = (arr) => {
    // create arr with all massage_parent_id
    let messagesParentIdsArr = [];
    arr.forEach((msg) => {
      if (msg?.is_survey === "1") {
        messagesParentIdsArr.push(msg.message_parent_id);
      }
    });
    if (messagesParentIdsArr.length > 0) {
      const url = `${URL_PATH}${GET_QUESTIONNAIRE}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      body.append("survey_messages_ids", JSON.stringify(messagesParentIdsArr));
      try {
        fetch(url, {
          method: "POST",
          body: body,
        }).then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res.error !== "false") {
              messagesParentIdsArr.forEach((id) => {
                let item = arr.find((e) => e.message_parent_id == id);
                item = { ...item, ...res.surveys[id] };
              });
              return arr;
            }
          }).catch((e) => {
            console.log(e);
          })
      }
      catch (e) {
        console.log(e);
      }
    } else {
      return arr;
    }

  }

  const sendQuestionnaire = (question) => {
    return new Promise((resolve, reject) => {
      const url = `${URL_PATH}${SEND_QUESTIONE}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      let answers_positions = [];
      let questions_ids = [];
      question.questions.forEach((e) => {
        e.answers.forEach((ans, i) => {
          if (ans.checked) {
            questions_ids.push(e.id);
            answers_positions.push(i);
          }
        })
      });
      body.append("answers", answers_positions.join(","));
      body.append("questions", questions_ids.join(","));
      try {
        fetch(url, {
          method: "POST",
          body: body,
        }).then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res.save !== "false") {
              markMailAsReadMail(question?.id);
              dispatch(markMail(question?.id, true));
              resolve(true);
              // props.sentMessageWebSocket("mail", "all");

            } else {
              reject(false);
            }
          }).catch((e) => {
            reject(false);
          })
      }
      catch (e) {
        reject(false);
      }
    })
  }
  useEffect(() => {
    if (props.loginType !== "in") {
      dispatch(setMail([], ""));
    }
  }, [props.loginType]);

  useEffect(() => {
    if (gameInfo?.game_name?.length > 0 && !hasFetched) {
      setHasFetched(true);
      getMailData();
    }
  }, [gameInfo, lang, hasFetched]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      sessionStorage.removeItem("last_update_time_draft");
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, []);


  return (
    <MailContext.Provider
      value={{
        getMailData,
        getSentMailData,
        getDraftMailData,
        sentDraft,
        sentMail,
        markMailAsReadMail,
        changeFlagData,
        sendQuestionnaire
      }}
    >
      {props.children}
    </MailContext.Provider>
  );
}
