import { memo, useCallback, useMemo } from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import TextCustomize from "../../../Elements/TextCustomize";
import FullTextTooltip from "../../../Elements/FullTextTooltip";
import { showFile } from '../../../../redux/actions';

// Extracted to component level for better memoization
const TableCell = memo(({ content, containerStyle, style }) => (
  <FullTextTooltip text={content} style={containerStyle}>
    <TextCustomize
      style={style}
      text={content}
      fontSize={"0.8vw"}
    />
  </FullTextTooltip>
));

// Separate FileItem component with its own memoization
const FileItem = memo(({ file, styles, direction, onPress }) => {
  const fileData = useMemo(() => {
    if (!file?.name && !file?.link) return { fileName: '', fileType: '' };
    const fullName = file?.name || file?.link;
    const lastDotIndex = fullName.lastIndexOf('.');
    return {
      fileName: lastDotIndex > -1 ? fullName.slice(0, lastDotIndex) : fullName,
      fileType: lastDotIndex > -1 ? fullName.slice(lastDotIndex) : ''
    };
  }, [file?.name, file?.link]);

  return (
    <Pressable
      style={styles.fileView}
      onPress={() => onPress(file)}
    >
      <FullTextTooltip text={fileData.fileName} style={{ flex: 1 }}>
        <TextCustomize
          fontSize={"0.7vw"}
          style={styles.attachText(direction)}
          text={fileData.fileName}
          numberOfLines={1}
        />
      </FullTextTooltip>
      <TextCustomize
        fontSize={"0.7vw"}
        style={styles.attachText(direction)}
        text={fileData.fileType}
      />
    </Pressable>
  );
}, (prev, next) => 
  prev.file?.name === next.file?.name && 
  prev.file?.link === next.file?.link && 
  prev.direction === next.direction
);

// Main Row Component
const RawDataTableRow = memo(({ tableHead, e, index, optionsPlatform }) => {
  const dispatch = useDispatch();
  const { background, color, lang } = useSelector((state) => ({
    background: state.style.background,
    color: state.style.color,
    lang: state.setting.setting.lang
  }));

  const direction = useMemo(() => 
    lang === "he" ? "rtl" : "ltr", 
  [lang]);

  const styles = useMemo(() => 
    stylesR(background, color, direction),
  [background, color, direction]);

  const handleFileClick = useCallback((file) => {
    const fileUrl = file?.link || file?.uri;
    if (fileUrl) {
      dispatch(showFile({
        name: file.name,
        type: file.type,
        link: fileUrl,
        size: file.size,
        uri: fileUrl,
        file: file
      }));
    }
  }, [dispatch]);

  const formatToLocalTime = useCallback((dateString) => {
    if (!dateString) return '';
    
    const isUTC = /Z|UTC|GMT/i.test(dateString);
    const formattedDateString = isUTC ? dateString : dateString + "Z";
    const date = new Date(formattedDateString);

    if (isNaN(date.getTime())) return "Invalid date";

    const options = {
      hour: "numeric",
      minute: "2-digit",
      hour12: !Intl.DateTimeFormat().resolvedOptions().locale.startsWith("en-US"),
    };

    return date.toLocaleTimeString(undefined, options);
  }, []);

  // Pre-compute all cell values
  const cellValues = useMemo(() => ({
    date: formatToLocalTime(e?.date),
    from: e?.from || '',
    to: Array.isArray(e?.to_list) ? e.to_list.join(', ') : e?.to_list || '',
    cc: Array.isArray(e?.cc_list) ? e.cc_list.join(', ') : e?.cc_list || '',
    bcc: Array.isArray(e?.bcc_list) ? e.bcc_list.join(', ') : e?.bcc_list || '',
    type: e?.platform_type || '',
    platform: optionsPlatform?.find(op => op.value === e?.platform_type)?.name || '',
    event: e?.event_name || '',
    title: e?.title || '',
    body: e?.bodyText || e?.body || '',
  }), [e, optionsPlatform, formatToLocalTime]);

  // Memoize common styles
  const cellContainerStyle = useMemo(() => ({
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: "0.4vw",
    paddingHorizontal: "0.5vw",
  }), []);

  const cellTextStyle = useMemo(() => ({
    textAlign: "right",
    paddingHorizontal: 8,
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }), []);

  const renderCell = useCallback((key) => {
    if (key.value === "attachments") {
      if (!e?.has_attachments || !e?.files?.length) return null;
      return (
        <View style={styles.filesContainer}>
          {e.files.map((file, fileIndex) => (
            <FileItem
              key={fileIndex}
              file={file}
              styles={styles}
              direction={direction}
              onPress={handleFileClick}
            />
          ))}
        </View>
      );
    }

    return (
      <TableCell
        content={cellValues[key.value]}
        containerStyle={cellContainerStyle}
        style={cellTextStyle}
      />
    );
  }, [e?.files, e?.has_attachments, styles, direction, handleFileClick, cellValues, cellContainerStyle, cellTextStyle]);

  return (
    <View style={styles.row(index % 2 === 0)}>
      {tableHead?.map((key) => (
        <View key={key.value} style={styles.cell(key?.width)}>
          {renderCell(key)}
        </View>
      ))}
    </View>
  );
}, (prev, next) => {
  // Proper deep comparison for memoization
  if (prev.index !== next.index) return false;
  if (prev.e?.id !== next.e?.id) return false;
  if (prev.tableHead?.length !== next.tableHead?.length) return false;
  
  // Compare tableHead contents
  if (prev.tableHead && next.tableHead) {
    for (let i = 0; i < prev.tableHead.length; i++) {
      if (prev.tableHead[i].value !== next.tableHead[i].value) return false;
    }
  }
  
  // Compare optionsPlatform only if platform type changes
  if (prev.e?.platform_type !== next.e?.platform_type) {
    return false;
  }

  return true;
});

export default RawDataTableRow;

const stylesR = (background, color, direction) =>
  StyleSheet.create({
    row: (flag) => ({
      flexDirection: "row",
      height: "3.4vw",
      cursor: "auto",
      backgroundColor: !flag ? background.leftInfo : background.infoBox,
    }),
    cell: (width) => ({
      width: width,
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      borderRightWidth: 1,
      borderStyle: "solid",
      borderRightColor: color.border,
      direction: direction,
    }),
    filesContainer: {
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      rowGap: "0.3vw",
      columnGap: "0.3vw",
      paddingVertical: "0.4vw",
      paddingHorizontal: "0.5vw",
    },
    fileView: {
      maxWidth: "fit-content",
      flex: 1,
      height: "1.4vw",
      paddingHorizontal: "1vw",
      paddingVertical: "0.1vw",
      borderRadius: "0.4vw",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      minWidth: "4vw",
      backgroundColor: background.lineHover
    },
    attachText: (direction) => ({
      writingDirection: direction,
    })
  });