import { Image, Pressable, StyleSheet, Text, View, Dimensions } from 'react-native'
import React, { useEffect, useRef, useState } from 'react';
import { SimpleLineIcons, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { changeBuildFlag, changeBuildSimulationFlag, changeInfoFlag, showAnalytics, changeDashboardFlag } from '../../../redux/actions/creatorActions';
import { t } from "i18n-js";
import UseResponsiveSize from '../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../modules/UseResponsiveScreen';
import { setErrorMessage, } from '../../../redux/actions';
import { useRawDataContext } from '../../../ContextData/RawData';

export default function LeftNav({ checkIfThereIsChanges, resetChanges }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const dispatch = useDispatch();

    const images = useSelector(state => state.style.images);
    const color = useSelector(state => state.style.color);
    const background = useSelector(state => state.style.background);

    const infoFlag = useSelector(state => state.build.infoFlag);
    const buildFlag = useSelector(state => state.build.buildFlag);
    const analyticsFlag = useSelector(state => state.analytics.flags.flag);
    const dashboardFlag = useSelector(state => state.build.dashboardFlag);
    const { rawDataFlag, setRawDataFlag } = useRawDataContext();

    const [infoFlagS, setInfoFlagS] = useState(infoFlag);
    const [buildFlagS, setBuildFlagS] = useState(buildFlag);
    const [dashboardFlagS, setDashboardFlagS] = useState(dashboardFlag);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setInfoFlagS(infoFlag);
    }, [infoFlag]);
    useEffect(() => {
        setBuildFlagS(buildFlag);
    }, [buildFlag]);
    useEffect(() => {
        setDashboardFlagS(dashboardFlag);
    }, [dashboardFlag]);

    /// components

    const btn = (onPress, img, imgDark, flag, text) => {
        return (
            <Pressable
                style={[styles.btn, { backgroundColor: flag ? background.buildActive : "" }]}
                onPress={onPress}
            >
                <View style={[styles.icon, { width: open ? "25%" : "100%" }]}>
                    <Image source={{ uri: img }} style={styles.img(flag)} />
                </View>
                {open && <Text style={[styles.navName, { color: flag ? "#fff" : "#ab9ca9" }]}>{text}</Text>}
            </Pressable>
        )
    }

    return (
        <View style={[open ? styles.container_open : styles.container, { backgroundColor: background.buildNavLeft }]}>
            <View style={{ width: "100%" }}>
                <Pressable
                    style={[styles.btn, { justifyContent: open ? "flex-end" : "flex-start" }]}
                    onPress={() => setOpen(!open)}
                >
                    <View style={[styles.icon, { width: open ? "25%" : "100%" }]}>
                        <SimpleLineIcons name="menu" size={widthS * 20} color={color.buildNav} />
                    </View>
                </Pressable>
                {/* dashboard */}
                {btn(async () => {
                    if (!dashboardFlagS) {
                        await dispatch(showAnalytics(false));
                        dispatch(changeBuildFlag(false));
                        dispatch(changeInfoFlag(false));
                        setRawDataFlag(false);
                        dispatch(changeDashboardFlag(true));
                    }
                }, images.grid, images.gridDark, dashboardFlagS, t("menu_dashboard"))}
                {/* info */}
                {btn(async () => {
                    if (!infoFlagS) {
                        await dispatch(showAnalytics(false));
                        dispatch(changeBuildFlag(false));
                        dispatch(changeDashboardFlag(false));
                        setRawDataFlag(false);
                        dispatch(changeInfoFlag(true));
                    }
                }, images.simulationInfo, images.simulationInfoDark, infoFlagS, `${t("menu_info")}`)}
                {/* build */}
                {btn(async () => {
                    if (checkIfThereIsChanges()) {
                        dispatch(setErrorMessage(true, t("oops"), t("reset_game_info1"), async (e) => {
                            if (e) {
                                resetChanges();
                                await dispatch(changeBuildFlag(true));
                                dispatch(changeInfoFlag(false));
                                dispatch(changeDashboardFlag(false));
                                setRawDataFlag(false);
                                dispatch(showAnalytics(false));
                            }
                        }));
                    } else {
                        if (!buildFlagS) {
                            await dispatch(changeBuildFlag(true));
                            dispatch(changeInfoFlag(false));
                            dispatch(changeDashboardFlag(false));
                            setRawDataFlag(false);
                            dispatch(showAnalytics(false));
                        }
                    }
                }, images.build, images.buildDark, buildFlagS, `${t("menu_swim")}`)}
                {/* analitycs */}
                {btn(
                    async () => {
                        if (checkIfThereIsChanges()) {
                            dispatch(setErrorMessage(true, t("oops"), t("reset_game_info1"), async (e) => {
                                if (e) {
                                    resetChanges();
                                    await dispatch(changeBuildFlag(false));
                                    dispatch(showAnalytics(true));
                                    dispatch(changeDashboardFlag(false));
                                    setRawDataFlag(false);
                                    dispatch(changeInfoFlag(false));
                                }
                            }));
                        } else {
                            if (!analyticsFlag) {
                                await dispatch(changeBuildFlag(false));
                                dispatch(showAnalytics(true));
                                dispatch(changeInfoFlag(false));
                                setRawDataFlag(false);
                                dispatch(changeDashboardFlag(false));
                            }
                        }
                    }, images.analyticsIcon, images.analyticsIconDark, analyticsFlag, t("analytics")
                )}
                {btn(
                    async () => {
                        if (checkIfThereIsChanges()) {
                            dispatch(setErrorMessage(true, t("oops"), t("reset_game_info1"), async (e) => {
                                if (e) {
                                    resetChanges();
                                    await dispatch(changeBuildFlag(false));
                                    dispatch(showAnalytics(false));
                                    dispatch(changeDashboardFlag(false));
                                    dispatch(changeInfoFlag(false));
                                    setRawDataFlag(true);
                                }
                            }));
                        } else {
                            if (!rawDataFlag) {
                                await dispatch(changeBuildFlag(false));
                                dispatch(showAnalytics(false));
                                dispatch(changeInfoFlag(false));
                                dispatch(changeDashboardFlag(false));
                                setRawDataFlag(true);
                            }
                        }
                    }, images.rawDataIcon, images.rawDataIcon, rawDataFlag, t("rawData")
                )}
            </View>
            <Pressable style={styles.btn}
                onPress={() => {
                    if (checkIfThereIsChanges()) {
                        dispatch(setErrorMessage(true, t("oops"), t("reset_game_info1"), (e) => {
                            if (e) {
                                resetChanges();
                                dispatch(changeBuildSimulationFlag(false));
                                dispatch(changeBuildFlag(false));
                                dispatch(changeDashboardFlag(false));
                                dispatch(showAnalytics(false));
                                setRawDataFlag(false);
                                dispatch(changeInfoFlag(true));
                            }
                        }));
                    } else {
                        dispatch(changeBuildSimulationFlag(false));
                        dispatch(changeBuildFlag(false));
                        dispatch(changeDashboardFlag(false));
                        dispatch(showAnalytics(false));
                        setRawDataFlag(false);
                        dispatch(changeInfoFlag(true));
                    }
                }}
            >
                <View style={[styles.icon, { width: open ? "25%" : "100%", transform: [{ rotate: "180deg" }] }]}>
                    <Ionicons name="exit-outline" size={widthS * 23} color={color.buildNav} />
                </View>
                {open && <Text style={[styles.navName, { color: "#ab9ca9" }]}>{t("exit")}</Text>}
            </Pressable>

        </View>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: heightS * 10
        },
        container_open: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "400%",
            flex: 1,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: heightS * 10,
            shadowColor: "black",
            shadowOffset: { width: 1, height: 0 },
            shadowOpacity: 1,
            shadowRadius: 15,
        },
        img: (flag) => ({
            width: widthS * 25,
            height: heightS * 20,
            resizeMode: "contain",
            tintColor: flag ? "" : "#ab9ca9"
        }),
        btn: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
        },
        icon: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingVertical: heightS * 8,
        },
        navName: {
            flex: 1,
            textAlign: "start",
            paddingLeft: widthS * 7,
            fontSize: widthS * 12,
            fontWeight: "400"
        }
    })
}