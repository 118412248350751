import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18n-js";
import { URL_PATH } from "@env";
import { F_PHPSESSID, GAME_ID, USER_GAME_NAME, USER_ID_IN_GAME, _CREATOR_ID_, _FILE0_, _F_PHPSESSID_, _MESSAGE_BODY_, _MESSAGE_ID_, _MESSAGE_PARENT_ID_, _SUBJECT_, _TO_LIST_ } from "../config.inc";
import { addNewSystem, setSystemMessages, addReply, markSystem } from "../redux/actions/index";

export const SystemContext = createContext();

export default function SystemProvider(props) {
      const dispatch = useDispatch();
      const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

      const getSystemData = () => {
            // const url = `${URL_PATH}${GET_MAIL_PATH}?id=${sessionStorage.getItem(
            // 	GAME_ID
            // )}`;
            // let body = new FormData();
            // body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
            // const requestOptions = {
            // 	method: "POST",
            //       body: body
            // };
            // try {
            // 	fetch(url, requestOptions)
            // 		.then((res) => res.text())
            // 		.then((res) => {
            // 			res = JSON.parse(res);
            // 			if (res.status !== "false") {
            // dispatch(setSystemMessages(res.messages, sessionStorage.getItem(USER_ID_IN_GAME)));
            // 			}
            // 		});
            // } catch (e) {}
            // dispatch(setSystemMessages(messages, sessionStorage.getItem(USER_ID_IN_GAME)));
      };
      const sentSystemMail = (newSentMail) => {
            // const url = `${URL_PATH}sendMessage`;
            // const body = new FormData();
            // body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
            // body.append(_CREATOR_ID_, window.sessionStorage.getItem("g_id"));
            // body.append(_SUBJECT_, newSentMail.subject);
            // body.append(_TO_LIST_, newSentMail.to_list_ids);
            // body.append(_MESSAGE_PARENT_ID_, newSentMail.message_parent_id);
            // body.append(_MESSAGE_BODY_, newSentMail.body);
            // if (newSentMail.attachments) {
            // 	body.append(_FILE0_, newSentMail.attachments);
            // }
            // try {
            // 	fetch(url, { method: "POST", body: body })
            // 		.then((res) => res.text())
            // 		.then((res) => {
            // 			res = JSON.parse(res);
            // 			if (res.message_sent == "true") {
            // 				getMailData();
            // 				props.sentMessageWebSocket("system");
            //                         return true;
            // 			}
            // 		})
            // 		.catch((e) => {
            // 			console.log(e);
            // 		});
            // } catch (e) {}
            //
            if (newSentMail.message_parent_id && newSentMail.message_parent_id.length > 0) {
                  dispatch(addReply(newSentMail, sessionStorage.getItem(USER_GAME_NAME)));
                  return;
            }
            dispatch(addNewSystem(newSentMail));
            return true;
      };
      const markSystemAsRead = (id) => {
            // const url = `${URL_PATH}markAsReadMessages`;
		// const body = new FormData();
            // body.append(_F_PHPSESSID_, sessionStorage.getItem(
            //       F_PHPSESSID
            //   ));
            //       body.append(_ID_, sessionStorage.getItem(GAME_ID));
            // const requestOptions = { method: "POST", body: body };
            // try {
            // 	fetch(url, requestOptions)
            // 		.then((res) => res.text())
            // 		.then((res) => {
            // 			res = JSON.parse(res);
            // 			getMailData();
            // 		})
            // 		.catch((e) => {
            // 			console.log(e);
            // 		});
            // } catch (e) {}
            dispatch(markSystem(id));
      };
      useEffect(() => {
            if (props.typeRefresh === "system") {
                  console.log("refreshing system")
                  getSystemData();
            }
      }, [props.typeRefresh]);
      useMemo(() => {
            if (props.loginType !== "in") {
                  dispatch(setSystemMessages([], ""));
            }
      }, [props.loginType]);
      useEffect(() => {
            if (gameInfo.game_name) {
                  getSystemData();
            }
      }, [gameInfo]);
      return <SystemContext.Provider value={{
            getSystemData,
            sentSystemMail,
            markSystemAsRead
      }}>
            {props.children}
      </SystemContext.Provider>;
}
