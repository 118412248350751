import { StyleSheet, Modal, View, Dimensions } from 'react-native';
import React from 'react';
import { useSelector } from "react-redux";
import IconButton from "./IconButton";

export default function FullScreenPopup({ flag, setFlag, children }) {

  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);

  return (
    <Modal transparent={true} animationType="fade" visible={flag}>
      <View style={{
        width: "100%",
        height: Dimensions.get("window").height,
        backgroundColor: "#00000075",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <View style={{
          width: "90%",
          height: "90%",
          backgroundColor: background.infoBody,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "1.5vw"
        }}>
          <View style={{ position: "absolute", right: "1vw", zIndex: 10, top: "1vw"  }}> 
          <IconButton
            flag={flag}
            iconName1={images.minimize}
            iconName2={images.xIcon}
            setFlag={() => setFlag()}
            style={{ width: "0.8vw", height: "0.8vw", resizeMode: "contain", }}
            style2={{ width: "1vw", height: "1vw", resizeMode: "contain", }}
          />
          </View>
          <View style={{ width: "95%", height: "90%" }}>
            {children}
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({})