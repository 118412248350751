import { SocketManager } from '../../utils/SocketManager'
import { CREATE_GAME_PERMISSION, GAME_ID, USER_GAME_NAME, USER_ID_IN_GAME, USER_ID_IN_GAME_OBSERVER } from '../../config.inc'
import { destroySession, setCreatorOptions, setGameList, setLogin, setToken } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import { WebSocketContext } from '../../WebSocketProvider';
import { useContext } from 'react';

export default function useExitGame() {

    const dispatch = useDispatch();
    const { leaveRoom } = useContext(WebSocketContext);

    const exitGame = (signOut) => {
        leaveRoom();
        window.sessionStorage.removeItem(GAME_ID);
        window.sessionStorage.removeItem(USER_ID_IN_GAME);
        window.sessionStorage.removeItem(USER_ID_IN_GAME_OBSERVER);
        window.sessionStorage.removeItem(USER_GAME_NAME);
        window.sessionStorage.removeItem("last_update_time_draft");
        dispatch(destroySession());
        dispatch(setToken(false));
        if (signOut) {
            window.sessionStorage.clear();
            dispatch(destroySession());
            dispatch(setToken(false));
            dispatch(setLogin());
        }
        if (sessionStorage.getItem(CREATE_GAME_PERMISSION) === "1") {
            return dispatch(setCreatorOptions());
        }
        dispatch(setGameList());

        location.reload();
    }
    return exitGame;
}