import { Image, Pressable, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18n-js';
import { AntDesign } from '@expo/vector-icons';
import { useRef } from 'react';
import { createQuestionnaireObject } from '../../../../modules/createQuestionArr';
import { useImperativeHandle } from 'react';
import SearchInput from '../../../../Elements/SearchInput';

export default function Questionaire({ inject, table, questionaireRef, isPossibletoClose, isEditing, checkActionFields, setActionPost }) {

    const questionsArr = [
        {
            type: null,
            question: "",
            answers: [{ text: "" }]
        }
    ]

    const images = useSelector((state) => state.style.images);
    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = stylesR(background, color, table, direction);

    const players = [...useSelector((state) => state.players.realPlayers), ...useSelector((state) => state.players.repPlayers)];
    const fromPlayers = useMemo(() => [...players].filter(player => player.permission_for_game !== "1"), [players]);

    const popupRef = useRef(null);
    const scrollView = useRef(null);

    const optiontypes = [
        { name: t("single_choice"), value: "single" },
        { name: t("multiple_choice"), value: "multiple" }
    ]

    const [question, setQuestion] = useState({
        subject: "",
        to_list: "",
        to_list_ids: "",
        from: {
            name_for_game: sessionStorage.getItem("user_game_name"),
        },
        from_id: sessionStorage.getItem("id_in_game"),
        questionsArr: [
            {
                type: null,
                question: "",
                answers: [{ text: "" }]
            }
        ]
    });
    const [indexQuest, setIndexQuesr] = useState(0)
    const [flag, setFlag] = useState(false);

    const [err, setErr] = useState({ text: "", indexQ: 0, indexS: 0 });
    const maxLength = useMemo(() => encodeURI(question?.subject).length < 1000 ? 1024 : question?.subject.length, [question?.subject]);

    const checkFields = () => {
        let errText = err.text;
        let indexQ = err.indexQ;
        let indexS = err.indexS;
        if (!table) {
            if (!question.to_list_ids || question.to_list_ids?.length < 2) {
                if (errText.indexOf("to") < 0) {
                    errText += "to ";
                }
            } else {
                errText = errText.replace("to ", "");
            }
        }
        if (question.subject?.length < 2) {
            if (errText.indexOf("subject") < 0) {
                errText += "subject ";
            }
        } else {
            errText = errText.replace("subject ", "");
        }
        question.questionsArr.forEach((quest, index) => {
            if (quest.question?.length < 2) {
                if (errText.indexOf("quest") < 0) {
                    errText += "quest ";
                    indexQ = index;
                }
            } else {
                errText = errText.replace("quest ", "");
            }
            if (!quest.type) {
                if (errText.indexOf("type") < 0) {
                    errText += "type ";
                    indexQ = index;
                }
            } else {
                errText = errText.replace("type ", "");
            }
            if (quest.answers?.length === 0) {
                if (errText.indexOf("answers") < 0) {
                    errText += "answers ";
                    indexQ = index;
                }
            } else {
                errText = errText.replace("answers ", "");
            }
            quest.answers.forEach((answer, indexAnswer) => {
                if (answer.text?.length < 2) {
                    if (errText.indexOf("answer") < 0) {
                        errText += "answer ";
                        indexQ = index;
                        indexS = indexAnswer;
                    }
                } else {
                    errText = errText.replace("answer ", "");
                }
            });
        });
        if (errText?.length > 0) {
            setErr({ text: errText, indexQ: indexQ, indexS: indexS });
            setIndexQuesr(indexQ);
        } else {
            setErr({ text: "", indexQ: 0, indexS: 0 });
            return true;
        }
    }

    const addQuest = () => {
        setIndexQuesr(question.questionsArr.length);
        setQuestion({
            ...question, questionsArr: [...question.questionsArr, {
                type: null,
                question: "",
                answers: [{ text: "" }]
            }]
        });
        return;
    }

    const saveSurvy = (table) => {
        if (checkFields()) {
            if (!table) {
                let completed_action = checkActionFields();
                if (completed_action) {
                    return setActionPost(createQuestionnaireObject(question), "mail", true);
                }
            } else {
                return createQuestionnaireObject(question);
            }
        } else {
            return false
        }
    }

    useImperativeHandle(questionaireRef, () => ({
        addQuest,
        saveSurvy: () => saveSurvy(true)
    }));

    useEffect(() => {
        if (inject?.inject?.questionArr && !isEditing) {

            setQuestion({
                ...question,
                id: inject?.inject?.id,
                subject: inject?.inject?.subject,
                questionsArr: inject?.inject?.questionArr?.length > 0 ? inject?.inject?.questionArr : questionsArr,
                to_list: players.filter((e) => inject?.inject?.to_list_ids?.indexOf(e.id) >= 0).map((player) => player.name_for_game).join(","),
                to_list_ids: inject?.inject?.to_list_ids,
                from: {
                    name_for_game: sessionStorage.getItem("user_game_name"),
                },
                from_id: sessionStorage.getItem("id_in_game"),
            });
        }
    }, [inject?.inject?.questionArr, isEditing]);

    // components

    const toListComponent = () => {
        return (
            <View style={styles.toListContainer}>
                <Text style={styles.headers}>{t("to")}</Text>
                <SearchInput
                    playersList={fromPlayers}
                    styleType={{ dark: true, type: "to", check: true, showCaret: true, table: true }}
                    value={question}
                    setValue={(message) => { setQuestion({ ...question, to_list: message.to_list, to_list_ids: message.to_list_ids }); }}
                    err={err.text.indexOf("to") >= 0}
                    styles={styles}
                    isEditing={isEditing}
                />
            </View>)
    }

    const titlecomponent = () => {

        return (
            <View style={styles.subjectContainer}>
                <Text style={styles.headers}>{t("title")}</Text>
                <TextInput
                    style={[styles.titleInput, err.text.indexOf("subject") >= 0 && styles.error]}
                    nativeID={direction === "rtl" ? "social-he" : "social"}
                    onChangeText={(txt) => setQuestion({ ...question, subject: txt.slice(0, maxLength) })}
                    value={question.subject}
                    numberOfLines={1}
                    multiline={true}
                    disabled={!isEditing}
                    dir={direction}
                    placeholder={t("questionaire_subject_placeholder")}
                />
            </View>)
    }

    const body = (index) => {
        let questionItem = question.questionsArr[index];
        return (
            <View style={styles.bodyContainer}>
                <View key={index} style={styles.zIndex}>
                    <View style={styles.top}>
                        <Text style={styles.bodyHeader}>{t("question")} {`${index + 1}`.padStart(2, '0')}</Text>
                        {question.questionsArr?.length > 0 &&
                            <View style={styles.countQuestions}>
                                <TouchableOpacity onPress={() => setIndexQuesr(indexQuest > 0 ? indexQuest - 1 : 0)}>
                                    <AntDesign name="left" style={styles.headers} />
                                </TouchableOpacity>
                                <Text style={styles.bodyHeader}>{index + 1}<Text style={styles.bodyHeader}>/</Text>{question.questionsArr?.length}</Text>
                                <TouchableOpacity onPress={() => setIndexQuesr(indexQuest < question?.questionsArr?.length - 1 ? indexQuest + 1 : question?.questionsArr?.length - 1)}>
                                    <AntDesign name="right" style={styles.headers} />
                                </TouchableOpacity>
                            </View>
                        }
                    </View>
                    <View style={[styles.typeView, styles.zIndex]}>
                        <Text style={styles.headers}>{t("type")}</Text>
                        <View ref={popupRef} style={[flag ? [styles.typeDivOpen] : styles.typeDiv, err.text.indexOf("type") > 0 && err.indexQ === index && styles.error]}>
                            <Pressable style={styles.typeBtn} onPress={() => setFlag(!flag)} disabled={!isEditing}>
                                <Text style={styles.headers}>{optiontypes.find((e) => e.value === questionItem?.type)?.name}</Text>
                                <AntDesign
                                    name="caretdown"
                                    style={styles.caretdown(flag)}
                                />
                            </Pressable>
                            {flag &&
                                <View style={styles.popup}>
                                    {optiontypes.map((type, indexType) => {
                                        if (questionItem?.type === type.value) return;
                                        return (
                                            <Pressable onPress={() => {
                                                questionItem.type = type.value;
                                                setQuestion({ ...question });
                                                setFlag(false);
                                            }} key={indexType} style={({ hovered }) => styles.typeItem(hovered)}>
                                                <Text style={styles.headers}>{type.name}</Text>
                                            </Pressable>)
                                    })}
                                </View>}
                        </View>
                    </View>
                    <View style={styles.typeView}>
                        <Text style={styles.headers}>{t("question")}</Text>
                        <TextInput
                            disabled={!isEditing}
                            style={[styles.titleInput, err.text.indexOf("quest") >= 0 && err.indexQ === index && styles.error]}
                            nativeID={direction === "rtl" ? "social-he" : "social"}
                            value={questionItem?.question}
                            multiline={true}
                            dir={direction}
                            numberOfLines={1}
                            onChangeText={(txt) => {
                                questionItem.question = txt;
                                setQuestion({ ...question });
                            }}
                        />
                    </View>
                    {questionItem?.answers?.length > 0 &&
                        <View style={styles.answerView}>
                            <Text style={styles.headerAnswer}>{t("answers")}</Text>
                            <ScrollView
                                ref={ref => { scrollView.current = ref }}
                                onContentSizeChange={() => scrollView.current?.scrollToEnd({ animated: true })}
                                showsVerticalScrollIndicator={false}
                                style={styles.scroll}
                            >
                                {questionItem?.answers.map((answer, i) => {
                                    return answerComponent(answer, index, i);
                                })}
                            </ScrollView>
                        </View>
                    }
                </View>
                <TouchableOpacity style={styles.btnAddAnswer} disabled={!isEditing} onPress={() => {
                    question.questionsArr[indexQuest].answers.push({ text: "" });
                    setQuestion({ ...question });
                }}>
                    <Text style={styles.headers}> + {t("add_answer")}</Text>
                </TouchableOpacity>
            </View>)
    }

    const footerBtns = () => {
        return (
            <View style={styles.footerContainer(!isEditing)}>
                <TouchableOpacity style={styles.btnAddQuest} disabled={!isEditing} onPress={() => addQuest()}>
                    <Text style={styles.btnText}>{t("add_question")}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => saveSurvy()}
                    disabled={!isEditing}
                    style={styles.btnSave}>
                    <Text style={styles.btnText}>{t("save")}</Text>
                </TouchableOpacity>
            </View>
        )
    }

    const answerComponent = (answer, indexQuest, indexAnswer) => {
        return (
            <View key={indexAnswer} style={[styles.typeView, styles.answerItem]}>
                <TextInput
                    disabled={!isEditing}
                    multiline={true}
                    numberOfLines={1}
                    dir={direction}
                    style={[styles.titleInput, styles.answerInput,
                    err.text.indexOf("answer") >= 0 && err.indexQ === indexQuest && err.indexS === indexAnswer && styles.error]}
                    nativeID={direction === "rtl" ? "social-he" : "social"}
                    value={(typeof answer) == "string" ? answer : ""}
                    onChangeText={(txt) => {
                        question.questionsArr[indexQuest].answers[indexAnswer] = txt;
                        setQuestion({ ...question });
                    }}
                />
                <TouchableOpacity style={styles.btnTrash} disabled={!isEditing} onPress={() => {
                    question.questionsArr[indexQuest].answers.splice(indexAnswer, 1);
                    setQuestion({ ...question });
                }}>
                    <Image source={{ uri: images.deleteIcon }} style={styles.iconTrash} />
                </TouchableOpacity>
            </View>
        )
    }

    useEffect(() => {
        if (popupRef.current) {
            window.addEventListener("click", ({ target }) => {
                if (flag && !popupRef.current?.contains(target)) {
                    setFlag(false);
                }
            })
        }
    }, [flag, popupRef.current]);

    return (
        <View style={styles.container(inject?.inject?.is_done, !inject?.inject?.id || inject?.inject?.id?.length === 0, table)}>
            {!table && toListComponent()}
            {titlecomponent()}
            {body(indexQuest)}
            {!table && footerBtns()}
        </View>
    )
}

const stylesR = (background, color, table, direction) => {
    return StyleSheet.create({
        container: (flag, isNew, table) => ({
            paddingHorizontal: table ? "2vw" : "3vw",
            direction: direction,
            marginTop: table ? "1vw" : isNew ? "" : !flag ? "2.5vw" : "1.5vw"
        }),
        toListContainer: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottomColor: color.border,
            borderBottomWidth: "0.1vw",
            paddingBottom: "1vw",
            zIndex: 1
        },
        subjectContainer: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottomColor: color.border,
            borderBottomWidth: "0.1vw",
            paddingVertical: "1vw"
        },
        // Search-component-style \\
        _container: {
            width: "19vw",
            minHeight: "2.3vw",
        },
        search_bar_pressable: {
            flex: 1,
            minHeight: "2.3vw",
            borderRadius: "0.7vw",
            // marginVertical: "0.5vw",
            // marginLeft: "0.5vw",
            flexDirection: "inherit",
        },
        arrow: {
            position: "absolute",
            ...(direction === "ltr" ? { marginRight: "0.8vw", } : { marginLeft: "0.8vw", }),
            fontSize: "0.5vw",
        },
        list_item: {
            zIndex: 13,
            width: "100%",
        },
        list_item_text: {
            zIndex: 13,
            margin: "0.3vw",
            ...(direction === "ltr" ? { marginLeft: "0.7vw", } : { marginRight: "0.7vw", }),
            fontSize: "0.8vw",
            fontFamily: "Catamaran_400Regular",
            display: "flex",
            alignItems: "center",
        },
        label_text_input: {
            fontSize: "0.9vw",
            fontFamily: "Catamaran_400Regular",
            //maxWidth: "96%",
            lineHeight: "1.5vw",
            maxHeight: "2vw",
            zIndex: 13,
            //margin: "0.3vw",
            ...(direction === "ltr" ? { marginLeft: "0.7vw", } : { marginRight: "0.7vw", }),
            display: "flex",
            //alignItems: "center",
        },
        _btn: {
            borderRadius: "1.2vw",
            display: "flex",
            flexDirection: "column",
            zIndex: 3,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            position: "relative",
        },
        _btn_open: {
            borderTopLeftRadius: "0.5vw",
            borderTopRightRadius: "0.5vw",
            display: "flex",
            flexDirection: "column",
            zIndex: 3,
            minHeight: "2.3vw",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
        },
        _btn_head: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            minHeight: "2.3vw",
            alignItems: "center",
        },
        _btn_body: {
            width: "100%",
            borderBottomLeftRadius: "0.5vw",
            borderBottomRightRadius: "0.5vw",
            borderTopWidth: 1,
            shadowColor: "black",
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            elevation: 10,
        },
        shadow: {
            shadowColor: "black",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.8,
            shadowRadius: 3,
        },
        popup_list_content: {
            // backgroundColor: "#ebebeb",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            borderRadius: "0.9vw",
        },
        popup_list: {
            maxHeight: "10vw",
            minHeight: "2vw",
            zIndex: 100,
        },
        mail_error: {
            borderColor: "red",
            borderWidth: "2px",
            borderRadius: "0.5vw",
        },
        // 
        bodyContainer: {
            paddingVertical: "1vw",
            borderBottomColor: color.border,
            borderBottomWidth: table ? "0" : "0.1vw",
        },
        btnAddAnswer: {
            marginLeft: "4vw"
        },
        footerContainer: (flag) => ({
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1vw",
            opacity: flag ? 0.5 : 1
        }),
        btnAddQuest: {
            borderWidth: "0.1vw",
            borderColor: color.infoHeaderNavText,
            borderRadius: "0.5vw",
            paddingHorizontal: "0.8vw",
            paddingVertical: "0.2vw"
        },
        btnSave: {
            borderRadius: "0.5vw",
            backgroundColor: background.loginFooter,
            paddingHorizontal: "0.8vw",
            paddingVertical: "0.2vw"
        },
        btnText: {
            color: color.infoHeaderNavText,
            fontSize: "1vw",
            fontFamily: "Catamaran_300Light",
        },
        headers: {
            color: color.infoHeaderNavText,
            fontSize: "0.8vw",
            fontFamily: "Catamaran_300Light",
        },
        zIndex: {
            zIndex: 1
        },
        titleInput: {
            color: color.infoHeaderNavText,
            backgroundColor: table ? background.infoBox : background.infoBody,
            paddingVertical: "0.5vw",
            display: "flex",
            fontSize: "0.8vw",
            width: "19vw",
            borderRadius: "0.7vw",
            alignItems: "center",
            paddingHorizontal: "1vw",
            justifyContent: "center",
            outlineStyle: "none",
            fontFamily: "Catamaran_300Light",
            writingDirection: direction
        },
        typeDiv: {
            borderRadius: "0.7vw",
            backgroundColor: table ? background.infoBox : background.infoBody,
            // height: "4.8vh",
            fontSize: "0.8vw",
            width: "19vw",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1
        },
        typeDivOpen: {
            borderTopLeftRadius: "0.7vw",
            borderTopRightRadius: "0.7vw",
            backgroundColor: table ? background.infoBox : background.infoBody,
            // minHeight: "4.8vh",
            fontSize: "0.8vw",
            width: "19vw",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            borderBottomColor: color.border,
            borderBottomWidth: "0.1vw",
            position: "relative",
            filter: "drop-shadow(1px 1px 2px black)"
        },
        shadow: {
            shadowColor: "black",
            shadowOffset: { height: 0, width: 0 },
            shadowOpacity: 0.7,
            shadowRadius: 5
        },
        typeBtn: {
            flex: 1,
            width: "100%",
            minHeight: "4.8vh",
            justifyContent: "center",
            paddingHorizontal: "1vw",
        },
        popup: {
            position: "absolute",
            top: "103%",
            backgroundColor: table ? background.infoBox : background.infoBody,
            width: "100%",
            borderBottomLeftRadius: "0.7vw",
            borderBottomRightRadius: "0.7vw",
            paddingBottom: "0.5vw"
        },
        typeItem: (hovered) => ({
            paddingHorizontal: "0.5vw",
            paddingVertical: "0.5vw",
            backgroundColor: hovered ? background.infoBox : ""
        }),
        scroll: {
            maxWidth: "fit-content",
            maxHeight: "6vw"
        },
        answerInput: {
            width: "17vw",
            writingDirection: direction
        },
        top: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
        },
        countQuestions: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            writingDirection: "ltr"
        },
        bodyHeader: {
            color: color.infoSaveBtn,
            fontSize: "0.95vw",
            fontFamily: "Catamaran_400Regular",
        },
        typeView: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginVertical: "0.5vh"
        },
        answerView: {
            flexDirection: "row",
            justifyContent: "space-between",
            marginVertical: "0.5vh"
        },
        answerItem: {
            marginVertical: "0.3vh"
        },
        headerAnswer: {
            marginTop: "0.6vw",
            color: color.infoHeaderNavText,
            fontSize: "0.8vw",
            fontFamily: "Catamaran_300Light",
        },
        btnTrash: {
            width: "1.5vw",
            height: "1.5vw",
            borderRadius: "100%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: table ? background.infoBox : background.infoBody,
            ...(direction === "rtl" ? { marginRight: "0.5vw" } : { marginRight: "0.5vw" }),
        },
        iconTrash: {
            width: "0.7vw",
            height: "0.7vw",
            resizeMode: "contain",
        },
        caretdown: (flag) => ({
            position: "absolute",
            ...(direction === "rtl" ? { left: "0.8vw", } : { right: "0.8vw", }),
            fontSize: "0.5vw",
            color: color.infoHeaderNavText,
            transition: "all .5s",
            transform: [{ rotate: flag ? "180deg" : "0" }]
        }),
        error: {
            borderColor: "red",
            borderWidth: "2px",
            borderRadius: "0.5vw",
        },
    });
} 