import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Pressable,
  Image,
  FlatList,
  userId
} from "react-native";
import { USER_ID_IN_GAME } from "../../../config.inc";
import { useDispatch, useSelector } from "react-redux";
import { Entypo, AntDesign } from "@expo/vector-icons";
import { markChat, showFile } from "../../../redux/actions";
import { ShortenFile } from "../../modules/ShortenFile";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { editDate } from "../../../EditDate";
import { t } from "i18n-js";
import { checkIfIsEncodeText } from "../../modules/removeSpacialCherectersAndSpaes";
import { ChatContext } from "../../../ContextData/Chat";
import FullTextTooltip from "../../Elements/FullTextTooltip";

export default function ChatFullMessages({
  fullChatRef,
  fullChatViewRef,
  // data,
  fromAndTo,
  index,
  scrollTo,
  printDate,
  chatCurrent,
  dashboard
}) {
  const { widthS, heightS } = UseResponsiveScreen();
  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const styles = stylesR(heightS, widthS, background, color);

  const { markAsReadData } = useContext(ChatContext);

  const dispatch = useDispatch();

  const images = useSelector((state) => state.style.images);
  const gameInfoPermission = useSelector((state) => state.gameInfoArr.permission);
  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
  const data = useSelector((state) => state.chatArr.allMessages);

  const [dataToPrint, setDataToPrint] = useState([]);
  const [currentObject, setCurrentObject] = useState({});
  const [firstRender, setFirstRender] = useState(true);
  const [firstUnreadMessageIndex, setFirstUnreadMessageIndex] = useState(null);
  const [currentDate, setCurrentDate] = useState('');
  const viewConfigRef = useRef({ viewAreaCoveragePercentThreshold: 50 });

  const onViewableItemsChanged = useRef(({ viewableItems }) => {
    if (viewableItems.length > 0) {
      setCurrentDate(viewableItems[0].key);
    }
  });

  const findName = (id, players) => {
    return players?.find((e) => e.id === id);
  };

  const unreadMessagesCount = useMemo(() => {
    return dataToPrint.filter(message => {
      // בדיקה אם ההודעה לא נקראה בהתאם לתנאי של gameInfoPermission
      const isUnread = (gameInfoPermission === "15" || gameInfoPermission === "17") ? message.status !== "1" : message.is_admin_read !== "1";
      // הודעה תיכלל אם היא לא נקראה וגם לא נשלחה על ידי המשתמש הנוכחי
      return isUnread && message.sender_id !== fromAndTo.from;
    }).length;
  }, [dataToPrint, fromAndTo, gameInfoPermission]);

  const messagesByDay = useMemo(() => {
    const messagesByDay = {};
    dataToPrint.forEach(message => {
      const messageDate = new Date(message.time).toDateString(); // קבלת התאריך בפורמט של יום בלבד
      if (!messagesByDay[messageDate]) {
        messagesByDay[messageDate] = []; // אם היום עדיין לא קיים באובייקט, יצירת מערך חדש
      }
      messagesByDay[messageDate].push(message); // הוספת ההודעה למערך המתאים ליום
    });
    return messagesByDay;
  }, [dataToPrint]);

  useEffect(() => {
    if (typeof chatCurrent === "object" && Object.keys(chatCurrent)?.length > 0) {
      let newData = [];
      data.forEach((e, i, arr) => {
        if (arr.findIndex((m) => m.message_body === e.message_body && m.time === e.time) !== i) {
          return;
        }
        if (chatCurrent?.group && e.group_id === fromAndTo.to) {
          newData.push(e);
        } else if (!chatCurrent?.group &&
          e.group_id === "0" &&
          (e.sender_id === fromAndTo.from || e.sender_id === fromAndTo.to) &&
          (e.recipient_id === fromAndTo.to || e.recipient_id === fromAndTo.from)
        ) {
          newData.push(e);
        }
      });
      setDataToPrint([...newData]);
    } else {
      setDataToPrint([]);
    }
  }, [data, index, chatCurrent]);

  useEffect(() => {
    setCurrentObject(chatCurrent);
    if (chatCurrent?.count > 0 && fromAndTo?.from && fromAndTo?.to) {
      markAsReadData({ ...fromAndTo, group: chatCurrent.group ? true : false });
      dispatch(markChat(fromAndTo?.from, fromAndTo?.to, (gameInfoPermission === "1" || gameInfoPermission === "10"), chatCurrent.group));
    }
  }, [chatCurrent, fromAndTo?.from && fromAndTo?.to]);

  useEffect(() => {
    if (firstRender || dataToPrint.length > 0 && dataToPrint[dataToPrint.length - 1]?.sender_id === fromAndTo.from) {
      scrollTo();
      if (dataToPrint?.length > 0) {
        setFirstRender(false);
      }
    }
  }, [
    fullChatViewRef.current,
    dataToPrint,
    firstRender,
    fromAndTo
  ]);

  useEffect(() => {
    let foundUnread = false;
    for (let i = 0; i < dataToPrint.length && !foundUnread; i++) {
      const message = dataToPrint[i];
      const isUnread = (gameInfoPermission === "15" || gameInfoPermission === "17") ? message.is_read !== "1" : message.is_admin_read !== "1";
      const isReceived = message.sender_id !== fromAndTo.from;

      if (isUnread && isReceived) {
        setFirstUnreadMessageIndex(i);
        foundUnread = true;
      }
    }
  }, [dataToPrint, fromAndTo.from, gameInfoPermission]);


  return (
    <FlatList
      data={Object.keys(messagesByDay)}
      keyExtractor={(key) => key}
      ListHeaderComponent={() => (
        <View style={styles.labelDateView}>
          <Text style={styles.labelDateText}>{currentDate}</Text>
        </View>
      )}
      contentContainerStyle={styles.scroll_body}
      style={styles.scroll}
      onViewableItemsChanged={onViewableItemsChanged.current}
      stickyHeaderIndices={[0]}
      renderItem={({ item: key }) => (
        <View>
          {/* <View style={styles.labelDateView}>
            <Text style={styles.labelDateText}>{key}</Text>
          </View> */}
          <FlatList
            data={messagesByDay[key]}
            keyExtractor={(item, i) => item.id || i.toString()}
            maxToRenderPerBatch={100}
            updateCellsBatchingPeriod={2000}
            style={{
              paddingRight: widthS * 11.6,
              paddingLeft: widthS * 19,
            }}
            viewabilityConfig={viewConfigRef.current}
            renderItem={({ item, index }) => {
              let showUnreadMarker =
                index === firstUnreadMessageIndex && index > 0 && unreadMessagesCount > 0;

              const isSenderUser =
                (item.sender_id === fromAndTo.from && item.recipient_id === fromAndTo.to && item.group_id === "0") ||
                (currentObject.group && item.group_id === fromAndTo.to && item.sender_id === fromAndTo.from);

              const isReceiverUser =
                (item.sender_id === fromAndTo.to && item.recipient_id === fromAndTo.from && item.group_id === "0") ||
                (currentObject.group && item.group_id === fromAndTo.to);

              if (isSenderUser) {
                return (
                  <>
                    <View style={styles.msg_user_container(dashboard)}>
                      <View
                        style={[
                          styles.trian_left,
                          { borderLeftColor: background.chatMsgUser },
                        ]}
                      />
                      <View
                        style={[
                          styles.msg_user,
                          { backgroundColor: background.chatMsgUser },
                        ]}
                      >
                        <View style={styles.top(dashboard)}>
                          {item.group_id !== "0" && (
                            (() => {
                              let name = findName(item.sender_id, gameInfo?.players)?.name_for_game;
                              return <FullTextTooltip text={name} style={{ flexShrink: 1 }}>
                                <Text style={styles.name_msg} numberOfLines={1}>
                                  {name}
                                </Text>
                              </FullTextTooltip>
                            })()
                          )}
                          <Text style={styles.time}>{printDate(item.time)}</Text>
                        </View>
                        <Text style={styles.body_text(dashboard)}>
                          {
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `<div>${checkIfIsEncodeText(item.message_body, "string")}</div>`,
                              }}
                            />
                          }
                        </Text>
                      </View>
                    </View>
                  </>
                );
              } else if (isReceiverUser) {
                return (
                  <>
                    {showUnreadMarker && (
                      <View
                        style={[styles.unreadView, { backgroundColor: background.popup1 }]}
                      >
                        <Text style={styles.unreadText}>
                          {`${t("messages_unread")} - ${unreadMessagesCount}`}
                        </Text>
                      </View>
                    )}
                    <View style={styles.msg_container(dashboard)}>
                      <View
                        style={[styles.msg, { backgroundColor: background.chatMsg }]}
                      >
                        <View style={styles.top(dashboard)}>
                          {item.group_id !== "0" && (
                            (() => {
                              let name = findName(item.sender_id, gameInfo?.players)?.name_for_game;
                              return <FullTextTooltip text={name} style={{ flexShrink: 1 }}>
                                <Text style={styles.name_msg} numberOfLines={1}>
                                  {name}
                                </Text>
                              </FullTextTooltip>
                            })()
                          )}
                          <Text style={styles.time}>{printDate(item.time)}</Text>
                        </View>
                        <Text style={styles.body_text(dashboard)}>
                          {
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `<div>${checkIfIsEncodeText(item.message_body, "string")}</div>`,
                              }}
                            />
                          }
                        </Text>
                      </View>
                      <View
                        style={[styles.trian_right, { borderRightColor: background.chatMsg }]}
                      />
                    </View>
                  </>
                );
              }
            }}
          />
        </View>
      )}
    />

  );
}
const stylesR = (heightS, widthS, background, color) =>
  StyleSheet.create({
    scroll: {
      width: "100%",
      maxHeight: heightS * 325,
      borderBottomRightRadius: 25,
      zIndex: 1
    },
    scroll_body: {
      minHeight: heightS * 327,
      borderBottomRightRadius: 25,
      paddingBottom: "4em"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      zIndex: 1,
    },
    header: {
      minHeight: heightS * 29,
      borderBottomWidth: "0.1vw",
      paddingLeft: widthS * 19,
      paddingRight: widthS * 5,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      zIndex: 2,
      shadowColor: "black",
      shadowOffset: { width: 0, height: -1 },
      shadowOpacity: 0.6,
      shadowRadius: 5,
    },
    headerNamesHover: {
      position: "absolute",
      borderWidth: 1,
      marginRight: widthS * 10,
      shadowColor: "black",
      shadowOffset: { width: 0, height: -1 },
      shadowOpacity: 0.6,
      shadowRadius: 5,
      padding: widthS * 3,
      borderRadius: widthS * 6,
    },
    body_msg: {
      display: "flex",
      flexDirection: "column",
      paddingTop: heightS * 10,
      paddingBottom: heightS * 40.1,
    },
    labelDateView: {
      alignSelf: "center",
      paddingHorizontal: "0.6vw",
      paddingVertical: "0.3vw",
      borderRadius: "0.7vw",
      backgroundColor: background.infoBox
    },
    labelDateText: {
      color: color.text2,
      fontSize: "0.8vw",
      fontFamily: "Catamaran_400Regular"
    },
    msg_container: (flag) => ({
      marginBottom: flag ? "0.3vw" : "0.5vw",
      // marginLeft: widthS * 115,
      alignSelf: "flex-start",
    }),
    trian_right: {
      position: "absolute",
      top: heightS * 6,
      left: widthS * -4,
      width: 0,
      height: 0,
      borderTopColor: "transparent",
      borderBottomColor: "transparent",
      borderRightWidth: widthS * 4,
      borderTopWidth: widthS * 3,
      borderBottomWidth: widthS * 3,
      zIndex: 1004,
    },
    trian_left: {
      position: "absolute",
      top: heightS * 6,
      right: widthS * -4,
      width: 0,
      height: 0,
      borderTopColor: "transparent",
      borderBottomColor: "transparent",
      borderLeftWidth: widthS * 4,
      borderTopWidth: widthS * 3,
      borderBottomWidth: widthS * 3,
      zIndex: 1004,
    },
    msg_user_container: (flag) => ({
      marginBottom: flag ? "0.3vw" : "0.5vw",
      alignSelf: "flex-end"
    }),
    msg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      maxWidth: widthS * 150,
      borderRadius: widthS * 2.5,
    },
    msg_user: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      maxWidth: widthS * 150,
      width: "fit-content",
      borderRadius: widthS * 2.5,
    },
    top: (flag) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      paddingTop: heightS * 4,
      paddingRight: widthS * 6,
      paddingLeft: widthS * 12.5,
      height: flag ? "1vw" : "1.5vw",
    }),
    name: {
      fontSize: widthS * 9,
      fontFamily: "Catamaran_600SemiBold",
      color: "white",
    },
    name_players: {
      fontSize: widthS * 8,
      fontFamily: "Catamaran_400Regular",
      color: "white",
    },
    name_msg: {
      color: "#262129",
      fontSize: widthS * 9,
      fontFamily: "Catamaran_800ExtraBold",
      paddingRight: widthS * 7,
    },
    time: {
      // position: "absolute",
      // right: widthS * 5.5,
      // top: heightS * 5,
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 6.5,
      color: "#333333",
    },
    file_view: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginHorizontal: widthS * 2,
      marginVertical: heightS * 3,
      width: "99%",
    },
    img: {
      // width: widthS * 100,
      height: heightS * 70,
      // resizeMode: "contain",
      // marginVertical: heightS * 2,
      // marginLeft: widthS * 10
      width: "90%",
    },
    file: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      marginHorizontal: widthS * 13,
      marginVertical: heightS * 3,
    },
    body_text: (flag) => ({
      paddingBottom: flag ? "0.3vw" : "0.7vw",
      paddingHorizontal: widthS * 15,
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
      color: "#262129",
      wordBreak: "word-break",
      width: "100%"
    }),
    unreadView: {
      alignSelf: "center",
      paddingHorizontal: "1vw",
      paddingVertical: "0.2vw",
      borderRadius: "1.5vw",
      marginBottom: "0.5vw",
    },
    unreadText: {
      fontSize: "0.8vw",
      fontFamily: "Catamaran_400Regular",
      color: "white",
    },
  });
