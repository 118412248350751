import React, { useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { USER_GAME_NAME, USER_ID_IN_GAME } from '../../../../../config.inc';
import { CheckTimeZone } from '../../../../modules/CheckTimeZone';
import { setTimeUnits } from '../../../../modules/createTimeColumns';
import { blackList } from "../analyticsTabels/blackList";

export const WordMapFuncContext = createContext();

export default function WordMapFuncProvider(props) {
    // selector
    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

    const sendMailsSelector = useSelector(state => state.mailArr.sentMails);
    const mailsSelector = useSelector(state => state.mailArr.messages);
    const socialSelector = useSelector(state => state.socialMedia.socialMediaArr);
    const chatSelector = useSelector(state => state.chatArr.allMessages);
    const challengesSelector = useSelector(state => state.actionsArr.allChallenges);

    const actionsArrSelector = useSelector((state) => state.swimlane.actions);
    const eventsSelector = useSelector((state) => state.events.events);
    // select filter selector \\
    const actionSelectSelector = useSelector(state => state.analytics.filter.actions);
    const playersSelectSelector = useSelector(state => state.analytics.filter.players);
    const eventsSelectSelector = useSelector(state => state.analytics.filter.events);
    // end - selector\\
    // state - data
    const [mails, setMails] = useState([]);
    const [social, setSocial] = useState([]);
    const [chat, setChat] = useState([]);
    const [players, setPlayers] = useState([]);
    const [events, setEvents] = useState([]);
    const [actions, setActions] = useState([]);
    const [challenges, setChallenges] = useState([]);
    //\\
    // state - data to show \\
    // expected \\
    const [arrScore, setArrScore] = useState([]);
    const [maxCount, setMaxCount] = useState(0);
    const [messages, setMessges] = useState([]);
    const [currentDate, setCurrentDate] = useState();
    // real \\
    const [arrScoreReal, setArrScoreReal] = useState([]);
    const [maxCountReal, setMaxCountReal] = useState(0);
    const [messagesReal, setMessagesReal] = useState([]);
    const [currentDateReal, setCurrentDateReal] = useState();
    //\\
    const [refresh, setRefresh] = useState(false);
    const [filterPlatform, setFilterPlatform] = useState("mail,sent,chat,social,challenges,");

    const filteredMails = useMemo(() => {
        if (Array.isArray(sendMailsSelector) && sendMailsSelector.length > 0) {
            return sendMailsSelector.filter((e) => {
                if (e?.event?.name) {
                    if (eventsSelectSelector.indexOf(e?.event?.id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            });
        }
        return [];
    }, [sendMailsSelector, eventsSelectSelector]);

    const filteredMailsSelector = useMemo(() => {
        if (Array.isArray(mailsSelector) && mailsSelector.length > 0) {
            return mailsSelector.filter((e) => {
                if (e?.event?.name) {
                    if (eventsSelectSelector.indexOf(e?.event?.id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            });
        }
        return [];
    }, [mailsSelector, eventsSelectSelector]);

    const filteredPlayers = useMemo(() => {
        if (gameInfo?.players?.length > 0) {
            return gameInfo.players.filter((e) => playersSelectSelector.indexOf(e.id) >= 0 && e.permission_for_game === "15");
        }
        return [];
    }, [gameInfo?.players, playersSelectSelector]);


    const filterActions = useMemo(() => {
        return actionsArrSelector?.filter(action => eventsSelectSelector?.indexOf(action.event_id) >= 0) || [];
    }, [actionsArrSelector, eventsSelectSelector])
    // select filter
    useEffect(() => {
        setActions([...filterActions]);
    }, [actionsArrSelector, eventsSelectSelector]);

    useEffect(() => {
        setRefresh(prev => !prev);
    }, [actionsArrSelector]);

    useEffect(() => {
        if (Array.isArray(filteredMails)) {
            setMails([...filteredMails]);
            setRefresh(prev => !prev);
        }
    }, [filteredMails]);

    useEffect(() => {
        if (Array.isArray(filteredMailsSelector)) {
            setMails([...filteredMailsSelector]);
            setRefresh(prev => !prev);
        }
    }, [filteredMailsSelector]);

    useEffect(() => {
        if (Array.isArray(filteredPlayers)) {
            setPlayers(filteredPlayers);
            setRefresh(prev => !prev);
        }
    }, [filteredPlayers]);
    //\\

    useEffect(() => {
        if (challengesSelector.length > 0) {
            setChallenges([...challengesSelector.filter((e) => {
                if (e?.action?.event_id) {
                    if (eventsSelectSelector.indexOf(e?.action?.event_id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })]);
            setRefresh(prev => !prev);
        }
    }, [challengesSelector, eventsSelectSelector]);

    useEffect(() => {
        if (socialSelector.length > 0) {
            setSocial(socialSelector);
            setRefresh(prev => !prev);
        }
    }, [socialSelector]);

    useEffect(() => {
        if (chatSelector.length > 0) {
            setChat(chatSelector);
            setRefresh(prev => !prev);
        }
    }, [chatSelector]);

    useEffect(() => {
        if (eventsSelector.length > 0) {
            setEvents(eventsSelector);
            setRefresh(prev => !prev);
        }
    }, [eventsSelector]);

    useEffect(() => {
        setRefresh(prev => !prev);
    }, [filterPlatform, actionSelectSelector]);

    const createArrStatistics = (players, events, actions, mails, social, chat, challenges, type, time, timeUnits, lang) => {
        switch (type) {
            case "expected":
                if (actions.length > 0 && players.length > 0) {
                    return addScoreToPlayerExpected(actions, time?.step || time?.time, players, lang);
                } else {
                    setMaxCount(0);
                    return setArrScore([]);
                }
            case "real":
                if (players.length > 0) {
                    return addScoreToPlayers(mails, social, chat, challenges, time?.date, timeUnits, players, lang);
                }
        }
    }

    const checkDate = useCallback((timeUnits, itemDate, date1) => {
        let itemDateCurrent = CheckTimeZone(itemDate);
        if (timeUnits.indexOf("h") >= 0) {
            itemDateCurrent.setMinutes(0);
        }
        if (timeUnits.indexOf("d") >= 0) {
            itemDateCurrent.setMinutes(0);
            itemDateCurrent.setHours(0);
        }
        if (date1) {
            if (itemDateCurrent > date1) {
                return false;
            }
        }
        return true;
    }, []);

    const filterwordsByList = useCallback((word, lang) => {
        let words = blackList(lang);
        word = word.replace(/\s/g, "").replace(/['"’`]/g, "");
        if (/^\d+$/.test(word)) {
            return false;
        }
        if (word?.length > 0 && words.length > 0) {
            let wordExsist = words.find((e) => e.toLocaleLowerCase() === word.toLocaleLowerCase()) ? false : true;
            return wordExsist;
        }
        return true;
    }, []);

    const findName = useCallback((id) => {
        let names = "";
        if (id && id.length > 0) {
            if (id.indexOf(",") >= 0) {
                id.split(",").forEach((idItem) => {
                    if (idItem === sessionStorage.getItem(USER_ID_IN_GAME)) {
                        names += `${sessionStorage.getItem(USER_GAME_NAME)}, `;
                    } else {
                        gameInfo?.players.forEach((e) => {
                            if (e.id === idItem) {
                                names += `${e.name_for_game}, `;
                            };
                        });
                    }
                })
            } else {
                if (id === sessionStorage.getItem(USER_ID_IN_GAME)) {
                    names = sessionStorage.getItem(USER_GAME_NAME);
                } else {
                    gameInfo?.players.forEach((e) => {
                        if (e.id === id) {
                            names = e.name_for_game;
                        };
                    });
                }
            }
        }
        return names;
    }, [gameInfo]);


    const addScoreToPlayerExpected = useCallback((arr, currentDateState, players, lang) => {
        let currentDate = currentDateState;
        let mail = [];
        let social = [];
        let wordsMap = new Map();
        let messagesMap = new Map();
        arr = arr?.filter((m) => (currentDate && +m.time <= currentDate || !currentDate) && players.find((e) => e.id === m.from_id))
        if (Array.isArray(arr) && arr.length > 0) {
            arr.forEach((e) => {
                if (e.platform_type === "sm") {
                    social.push(e);
                } else if (e.platform_type === "mail" || e.platform_type === "") {
                    mail.push(e);
                }
            });

            if (mail.length > 0) {
                mail.forEach((mail, index) => {
                    if (filterPlatform.indexOf("sent") >= 0) {
                        let wordsString = stripHtml(mail.body);

                        wordsString.split(" ").forEach((e) => {
                            e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "").trim();
                            if (e.length > 1 && filterwordsByList(e, lang)) {
                                let wordKey = e.toLowerCase();
                                if (wordsMap.has(wordKey)) {
                                    let wordObj = wordsMap.get(wordKey);
                                    wordObj.value += 1;
                                    if (!wordObj.messageIds.includes(mail.id)) {
                                        wordObj.messageIds.push({ id: mail.id, time: mail.time });
                                    }
                                } else {
                                    wordsMap.set(wordKey, {
                                        text: e,
                                        value: 1,
                                        id: Math.random(),
                                        messageIds: [{ id: mail.id, time: mail.time }],
                                    });
                                }
                            }
                        });
                        if (!messagesMap.has(mail.id)) {
                            messagesMap.set(mail.id, {
                                id: mail.id,
                                from: mail.from,
                                to: mail.to_list,
                                body: mail.body,
                            });
                        }
                    }
                });
            }
            if (social.length > 0) {
                social.forEach((post) => {
                    if (filterPlatform.indexOf("social") >= 0) {
                        let wordsString = stripHtml(post.body);

                        wordsString.split(" ").forEach((e) => {
                            e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "").trim();
                            if (e.length > 1 && filterwordsByList(e, lang)) {
                                let wordKey = e.toLowerCase();
                                if (wordsMap.has(wordKey)) {
                                    let wordObj = wordsMap.get(wordKey);
                                    wordObj.value += 1;
                                    if (!wordObj.messageIds.includes(post.id)) {
                                        wordObj.messageIds.push({ id: post.id, time: post.time });
                                    }
                                } else {
                                    wordsMap.set(wordKey, {
                                        text: e,
                                        value: 1,
                                        id: Math.random(),
                                        messageIds: [{ id: post.id, time: post.time }],
                                    });
                                }
                            }
                        });
                        if (!messagesMap.has(post.id)) {
                            messagesMap.set(post.id, {
                                id: post.id,
                                from: post.from,
                                to: post.to_list,
                                body: post.body,
                            });
                        }
                    }
                });
            }
            let words = Array.from(wordsMap.values());
            let messages = Array.from(messagesMap.values());
            setMessges(messages);
            words.sort((a, b) => b.value - a.value);
            return setArrScore([...words.slice(0, 30)]);
        }
    }, [filterPlatform, filterwordsByList, eventsSelectSelector]);

    const stripHtml = (html) => {
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    const addScoreToPlayers = useCallback((mails, social, chat, challenges, currentDate, timeUnits, players, lang) => {
        let datefilterCurrent1 = currentDate ? new Date(currentDate) : null;
        let wordsMap = new Map();
        let messagesMap = new Map();

        // פונקציה לעיגול השעה כלפי מעלה לפי timeUnits
        const roundTimeUp = (date, timeUnits) => {
            const unitsMap = {
                '05m': 5,
                '10m': 10,
                '30m': 30,
                '1h': 60,
                '2h': 120,
                '3h': 180,
                '1d': 1440,
                '2d': 2880,
                '3d': 4320
            };
            let unitMinutes = unitsMap[timeUnits] || 1;
            let newDate = new Date(date);
            newDate.setMinutes(Math.ceil(newDate.getMinutes() / unitMinutes) * unitMinutes);
            return newDate;
        };

        mails = mails?.filter(m => checkDate(timeUnits, m.time, datefilterCurrent1) && players.some((e) => e.id === m.sender.id));
        if (Array.isArray(mails) && mails.length > 0 && filterPlatform.includes("sent") && actionSelectSelector.indexOf("Sent Items") >= 0 && actionSelectSelector.indexOf("Incoming Items") >= 0) {
            mails.forEach((mail) => {
                let wordsString = mail.body;
                let fromIndexEng = wordsString.indexOf("<br><br><br><hr>");
                let fromIndex = -1;

                if (fromIndexEng >= 0) {
                    fromIndex = fromIndexEng;
                }
                if (fromIndex >= 0) {
                    wordsString = wordsString.slice(0, fromIndex);
                }

                wordsString = stripHtml(wordsString);
                wordsString.split(" ").forEach((e) => {
                    e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "").trim();
                    if (e.length > 1 && filterwordsByList(e, lang)) {
                        let wordKey = e.toLowerCase();
                        if (wordsMap.has(wordKey)) {
                            let wordObj = wordsMap.get(wordKey);
                            wordObj.value += 1;
                            if (!wordObj.messageIds.includes(mail.id)) {
                                wordObj.messageIds.push({ id: mail.id, time: roundTimeUp(new Date(mail.time), timeUnits) });
                            }
                        } else {
                            wordsMap.set(wordKey, {
                                text: e,
                                value: 1,
                                id: Math.random(),
                                messageIds: [{ id: mail.id, time: roundTimeUp(new Date(mail.time), timeUnits) }],
                            });
                        }
                    }
                });
                if (!messagesMap.has(mail.id)) {
                    messagesMap.set(mail.id, {
                        id: mail.id,
                        from: mail.sender.name,
                        to: mail.to_list_names,
                        body: mail.body,
                    });
                }
            });
        }
        social = social?.filter((m) => checkDate(timeUnits, m.time, datefilterCurrent1) && players.find((e) => e.id === m.sender_by.id))
        if (Array.isArray(social) && social.length > 0 && filterPlatform.indexOf("social") >= 0 && actionSelectSelector.indexOf("Sent Items") >= 0 && actionSelectSelector.indexOf("Incoming Items") >= 0) {
            social.forEach((post, index) => {
                let wordsString = stripHtml(post.text);
                wordsString.split(" ").forEach((e) => {
                    e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "").trim();
                    if (e?.length > 1 && filterwordsByList(e, lang)) {
                        let wordKey = e.toLowerCase();

                        if (wordsMap.has(wordKey)) {
                            let wordObj = wordsMap.get(wordKey);
                            wordObj.value += 1;
                            if (!wordObj.messageIds.includes(post.id)) {
                                wordObj.messageIds.push({ id: post.id, time: roundTimeUp(new Date(post.time), timeUnits) });
                            }
                        } else {
                            wordsMap.set(wordKey, {
                                text: e,
                                value: 1,
                                id: Math.random(),
                                messageIds: [{ id: post.id, time: roundTimeUp(new Date(post.time), timeUnits) }],
                            });
                        }
                    }
                });
                if (!messagesMap.has(post.id)) {
                    messagesMap.set(post.id, {
                        id: post.id,
                        from: post.sender_by.name,
                        to: post.to_list ? post.to_list : "all",
                        body: post.text,
                    });
                }
            });
        }
        chat = chat?.filter((m) => checkDate(timeUnits, m.time, datefilterCurrent1) && players.find((e) => e.id === m.sender_id));
        if (Array.isArray(chat) && chat.length > 0 && filterPlatform.indexOf("chat") >= 0 && actionSelectSelector.indexOf("Chat Messages") >= 0) {
            chat.forEach((chatItem) => {
                let wordsString = stripHtml(chatItem.message_body);
                wordsString.split(" ").forEach((e) => {
                    e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "").trim();
                    if (e?.length > 1 && filterwordsByList(e, lang)) {
                        let wordKey = e.toLowerCase();

                        if (wordsMap.has(wordKey)) {
                            let wordObj = wordsMap.get(wordKey);
                            wordObj.value += 1;
                            if (!wordObj.messageIds.includes(chatItem.id)) {
                                wordObj.messageIds.push({ id: chatItem.id, time: roundTimeUp(new Date(chatItem.time), timeUnits) });
                            }
                        } else {
                            wordsMap.set(wordKey, {
                                text: e,
                                value: 1,
                                id: Math.random(),
                                messageIds: [{ id: chatItem.id, time: roundTimeUp(new Date(chatItem.time), timeUnits) }],
                            });
                        }
                    }
                });
                if (!messagesMap.has(chatItem.id)) {
                    messagesMap.set(chatItem.id, {
                        id: chatItem.id,
                        from: findName(chatItem.sender_id),
                        to: findName(chatItem.recipient_id),
                        body: chatItem.message_body,
                    });
                }
            });
        }
        challenges = challenges?.filter((m) => checkDate(timeUnits, m.action.time, datefilterCurrent1) && players.find((e) => e.id === m.action.creator_id));
        if (Array.isArray(challenges) && challenges.length > 0 && filterPlatform.indexOf("challenges") >= 0 && actionSelectSelector.indexOf("Decisions") >= 0) {
            challenges.forEach((action, index) => {
                let wordsString = stripHtml(action.action.description);
                wordsString.split(" ").forEach((e) => {
                    e = e.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, "").trim();
                    if (e?.length > 1 && filterwordsByList(e, lang)) {
                        let wordKey = e.toLowerCase();

                        if (wordsMap.has(wordKey)) {
                            let wordObj = wordsMap.get(wordKey);
                            wordObj.value += 1;
                            if (!wordObj.messageIds.includes(action.action.id)) {
                                wordObj.messageIds.push({ id: action.action.id, time: roundTimeUp(new Date(action.action.time), timeUnits) });
                            }
                        } else {
                            wordsMap.set(wordKey, {
                                text: e,
                                value: 1,
                                id: Math.random(),
                                messageIds: [{ id: action.action.id, time: roundTimeUp(new Date(action.action.time), timeUnits) }],
                            });
                        }
                    }
                });
                if (!messagesMap.has(action.action.id)) {
                    messagesMap.set(action.action.id, {
                        id: action.action.id,
                        from: findName(action.action.creator_id),
                        to: findName(action.action.share_list),
                        body: action.action.description,
                    });
                }
            });
        }
        // כאן ניתן להוסיף קוד נוסף לעיבוד הודעות מסוג social, chat, challenges לפי אותו עיקרון

        let words = Array.from(wordsMap.values());
        let messages = Array.from(messagesMap.values());
        setMessagesReal(messages);
        words.sort((a, b) => b.value - a.value);
        return setArrScoreReal([...words.slice(0, 30)]);
    }, [checkDate, eventsSelectSelector, filterPlatform, actionSelectSelector]);

    const createtimes = (times, arr, type) => {
        let arrN = [...times];
        if (arr.length > 0) {
            if (type === "chat") {
                arr?.forEach((e) => {
                    e.newMsg?.forEach((e) => {
                        arrN.push(e.time);
                    });
                    e.msgHistory?.forEach((e) => {
                        arrN.push(e.time);
                    });
                });
            } else if (type === "challenges") {
                arr?.forEach((e) => {
                    arrN.push(e.action.time);
                });
            } else {
                arr?.forEach((e) => {
                    arrN.push(e.time);
                });
            }
        }
        return arrN;
    }

    const returnIfIsThefirstOrLast = (timeCurrent, time, type) => {
        switch (type) {
            case "start":
                if (!timeCurrent) {
                    timeCurrent = new Date(time);
                } else if (time < timeCurrent) {
                    timeCurrent = new Date(time);
                }
                return timeCurrent;
            case "end":
                if (!timeCurrent) {
                    timeCurrent = new Date(time);
                } else if (time > timeCurrent) {
                    timeCurrent = new Date(time);
                }
                return timeCurrent;
        }
    }

    const findLastTime = (chatP, challengesP, mailsP, socialP) => {
        let firstTime;
        let endTime;
        if (chatP?.length > 0) {
            chatP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time}`), 'end');
            });
        }
        if (challengesP?.length > 0) {
            challengesP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.action.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.action.time}`), 'end');
            });
        }
        if (mailsP?.length > 0) {
            mailsP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time}`), 'end');
            });
        }
        if (socialP?.length > 0) {
            socialP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time} gmt`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time} gmt`), 'end');
            });
        }
        return {
            firstTime,
            endTime
        };
    }

    const filterStatistics = (timeUnits, type, time, lang) => {
        if (type === "expected") {
            if (actions?.length > 0) {
                createArrStatistics(
                    players,
                    events,
                    actions,
                    mails,
                    social,
                    chat,
                    challenges,
                    type,
                    time,
                    timeUnits,
                    lang
                )
            } else {
                setMaxCount(0);
                setArrScore([]);
            }
        } else if (type === "real") {
            if ((mails?.length > 0 || chat.length > 0 || social.length > 0 || challenges.length > 0)) {
                createArrStatistics(
                    players,
                    events,
                    actions,
                    mails,
                    social,
                    chat,
                    challenges,
                    type,
                    time,
                    timeUnits,
                    lang
                )
            } else {
                setMaxCountReal(0);
                setArrScoreReal([]);
            }
        }
    }

    return (
        <WordMapFuncContext.Provider value={{
            arrScore,
            arrScoreReal,
            maxCount,
            maxCountReal,
            filterPlatform,
            setFilterPlatform,
            filterStatistics,
            setCurrentDate,
            setCurrentDateReal,
            currentDate,
            currentDateReal,
            refresh,
            setRefresh,
            messages,
            messagesReal
        }}>
            {props.children}
        </WordMapFuncContext.Provider>
    )
}
