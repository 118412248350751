import { BUILD_FLAG, BUILD_SIMULATION_FLAG, INFO_FLAG, INFO_BODY, DASHBOARD_FLAG } from "../actions/creatorActions";

export const buildManage = (state = { flag: false, dashboardFlag: false, infoFlag: false, buildFlag: true, infoBody: false }, action) => {
    switch (action.type) {
        case BUILD_FLAG: {
            return { ...state, buildFlag: action.flag };
        }
        case BUILD_SIMULATION_FLAG: {
            return { ...state, flag: action.flag };
        }
        case INFO_FLAG: {
            return { ...state, infoFlag: action.flag };
        }
        case INFO_BODY: {
            return { ...state, infoBody: !state.infoBody };
        }
        case DASHBOARD_FLAG: {
            return { ...state, dashboardFlag: action.flag };
        }
        default: {
            return state;
        }
    }
}
