import { CHANGE_LANG, CHANGE_LANG_FLAG, HELP_FLAG, CHANGE_LANG_DOMAIN } from "../actions/index";

export const setting = (state = { setting: { lang: "en", domainLang: "en" }, flags: { changeLang: false, help: false } }, action) => {
    switch (action.type) {
        case CHANGE_LANG_FLAG: {
            state.flags.changeLang = action.flag
            return { ...state, flags: { ...state.flags } };
        };
        case HELP_FLAG: {
            state.flags.help = !state.flags.help
            return { ...state, flags: { ...state.flags } };
        };
        case CHANGE_LANG: {
            return { ...state, setting: { ...state.setting, lang: action.lang } };
        };
        case CHANGE_LANG_DOMAIN: {
            return { ...state, setting: { ...state.setting, domainLang: action.lang } };
        };
        default: {
            return state;
        }
    }
}