import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { View, Pressable, StyleSheet, TextInput, ScrollView } from 'react-native';
import EditorTiny from "../../../../editor/EditorTiny";
import TextCustomize from '../../../../Elements/TextCustomize';

const MailBody = ({ index, subject, body, setSubject, actionType, setBody, disabled, isNew }) => {
    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = stylesR(background, color);

    return (
        <View style={styles.container}>
            <View style={styles.top}>
                <TextCustomize colorCurrent={"black"} fontSize={"0.9vw"} keyText={"subject"} fontWeight={700} />
                <TextCustomize colorCurrent={"black"} fontSize={"0.9vw"} text={":"} fontWeight={700} />
                <TextInput style={styles.input(direction)} value={subject} onChangeText={(txt) => {if (!disabled){setSubject(txt);}}} />
            </View>
            <View style={styles.body}>
                <ScrollView style={styles.scroll_m}>
                    <EditorTiny
                        actionType={actionType}
                        disabled={disabled}
                        colorSkin={false}
                        colorIcon={"black"}
                        id={`table-${isNew ? "new" : index}`}
                        newMessage={{ body: body }}
                        setImagesBody={(images) => {
                            // setNewMessage({ ...newMessage, body_images: images })
                        }}
                        setNewMessage={(body) => setBody(body)}
                    />
                </ScrollView>
                <View style={styles.toolbarView}>
                    <View
                        style={styles.toolbar}
                        nativeID={`table-${isNew ? "new" : index}`}
                    />
                </View>
            </View>
        </View>
    )
};
const stylesR = (background, color) => StyleSheet.create({
    container: {
        backgroundColor: background.mailBody,
        borderRadius: "0.8vw",
        margin: "0.5vw",
        paddingHorizontal: "1vw",
        marginTop: "1vw",
        height: "20vw"
    },
    top: {
        paddingVertical: "1vw",
        flexDirection: "row",
        borderBottomColor: color.border,
        borderBottomWidth: "0.1vw",
        alignItems: "center"
    },
    input: (direction) => ({
        flex: 1,
        writingDirection: direction,
        fontSize: "0.9vw",
        paddingHorizontal: "0.6vw",
        paddingVertical: "0.4vw",
        outlineStyle: "none",
        fontFamily: "Catamaran_400Regular",
    }),
    body: {
        paddingVertical: "0.6vw",
        alignItems: "center",
        justifyContent: "center",
        flex: 1
    },
    scroll_m: {
       minHeight: "13vw",
       maxHeight: "13vw",
       width: "100%",
    },
    toolbarView: {
        borderTopColor: color.border,
        borderTopWidth: "0.1vw",
        width: "100%",
        maxWidth: "100%",
        justifyContent: "flex-start"
    },
    toolbar: {
        width: "fit-content",
    }
});
export default MailBody;