import { View, Text, Pressable, StyleSheet, TextInput, Dimensions, Image } from "react-native";
import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { SwimlaneChartContext } from "../../../../../ContextData/Swimlanechart";
import { useDispatch, useSelector } from "react-redux";
import I18n, { t } from "i18n-js";
import EventSelect from "./EventSelect";
import ActionIcon from "./ActionIcon";
import EmailAction from "./EmailAction";
import SocialAction from "./SocialAction";
import NewsAction from "./NewsAction";
import UseResponsiveSize from "../../../../modules/UseResponsiveScreen";
import UseResponsiveScreen from "../../../../modules/UseResponsiveScreen";
import AnimationLoading from "../../../../modules/AnimationLoading";
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import Feather from '@expo/vector-icons/Feather';
import Ionicons from '@expo/vector-icons/Ionicons';
import AntDesign from '@expo/vector-icons/AntDesign';
import onClickEndGameCheck from "../../../../Elements/onClickEndGameCheck";
import { checkIfIsEncodeText } from '../../../../modules/removeSpacialCherectersAndSpaes';

import { createFileObject } from "../../../../modules/CreateFileObject";
import {
  addActionCreator,
  deleteEvent,
  editActionCreator,
} from "../../../../../redux/actions/creatorActions";
import ActionPlayers from "./action-players/ActionPlayers";
import { setAction, setErrorMessage } from "../../../../../redux/actions";
import { file } from "@babel/types";
import Questionaire from "./Questionaire";

export default function CreateAction({
  deleteEventData,
  editEventFunc,
  isPossibletoClose,
  index,
  eventContentRef,
  show,
  setShow,
  title,
  descMarginLeft,
  active,
  events,
  description,
  addEventHandler,
  createNextEventHandler,
  setActive,
  top,
  left,
  right,
  side,
  eventDetails,
  setEventDetails,
  isCompletedDetailsForm,
  id,
  setIndex,
  eventBetween,
  setLoad,
  dashboard
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const realPlayers = useSelector((state) => state.players.realPlayers);
  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const showFileFlag = useSelector((state) => state.filesArr.fileShow);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, background, color);

  const { checkEnd } = onClickEndGameCheck();

  const { addAction, sendAction, editActionData, recallFlowObject } = useContext(SwimlaneChartContext);

  const eventChildLeftRef = useRef(null);
  const eventChildRightRef = useRef(null);
  const isPossibletoClosePlayers = useRef(true);
  const saveRef = useRef(false);

  const dispatch = useDispatch();

  const images = useSelector((state) => state.style.images);
  const existAction = useSelector((state) => state.swimlane.editAction);
  const actionsArrState = useSelector((state) => state.swimlane.actions);
  const flowTimeColumns = useSelector(
    (state) => state.swimlane.flowTimeColumns
  );
  const errorMessageFlag = useSelector(
    (state) => state.errorMessage.errorMessage.flag
  );

  const componentWillUnmount = useRef(false);

  const playerPermissionstate = useSelector((state) => state.gameInfoArr.permission);
  const permission = useMemo(() => playerPermissionstate !== "17" && playerPermissionstate !== "10", [playerPermissionstate]);

  const typesLocation = [
    { name: t("Exact"), value: "Exact" },
    { name: t("After"), value: "After" },
    { name: t("Before"), value: "Before" }
  ]

  const [action, setActionState] = useState({
    is_kpi: false,
    type_action: "",
    type_action_name: "",
    event_id: "",
    eventColor: "",
    event: eventDetails?.title || "",
    body: "",
    from_id: "",
    from: "",
    to_list_ids: "",
    to_list: "",
    cc_list_ids: "",
    cc_list: "",
    subject: "",
    platform_type: "",
    message_id: "",
    is_done: false,
    time: actionsArrState.length > 0 ? +actionsArrState[actionsArrState.length - 1].time + 1 : 1
  });

  const [typeLocation, setTypeLocation] = useState({ name: t("Exact"), value: "Exact" });
  const [showTypeLocation, setShowTypeLocation] = useState(false);
  const [actionContentBorderBottomLeft, setActionContentBorderBottomLeft] =
    useState();
  const [isEditing, setIsEditing] = useState(existAction?.edit || false);
  const [showDetails, setShowDetails] = useState(true);
  const [newAction, setNewAction] = useState(
    !eventDetails && !existAction?.platform_type
  );
  const [err, setErr] = useState("");
  const [inject, setInject] = useState({
    inject: existAction,
  });
  const [deleted, setDeleted] = useState(false);

  const editAction = (action, inject) => {
    let keysTypes = ["is_kpi", "type_action", "event_id", "from_id", "to_list_ids", "cc_list_ids", "time", "subject", "platform_type", "file", "body"];
    let keys = Object.keys(action);
    let types = [];
    keys.forEach((e) => {
      if (action[e] !== inject[e] && keysTypes.findIndex((k) => e === k) >= 0) {
        if (e === "file" && typeof action.file?.file === "object") {
          types.push(e);
        } else if (e !== "file") {
          types.push(e);
        }
      }
    });
    setLoad(true);
    editActionData(action, types).then(() => {
      setIsEditing(false);
      setLoad(false);
      setShow(0);
    }).catch(() => {
      setLoad(false);
      setIsEditing(false);
      setShow(0);
    });
  }

  const setFilesFromEmail = (files, type) => {
    if (type) {
      let types = ["jpg", "jpeg", "png", "mp4"];
      return files.find((e) => types.indexOf(e.type.slice(e.type.indexOf("/") + 1) || e.mimeType.slice(e.mimeType.indexOf("/") + 1)) >= 0);
    } else {
      let types = ["jpg", "jpeg", "png"];
      return files.find((e) => types.indexOf(e.type.slice(e.type.indexOf("/") + 1) || e.mimeType.slice(e.mimeType.indexOf("/") + 1)) >= 0);
    }
  }

  let isCompletedForm =
    action.subject?.length > 1 && action.type_action && action.time && action.event_id
      ? true
      : false;
  let isCompletedFormMail =
    action.type_action && action.time && action.event_id
      ? true
      : false;

  const checkActionFields = () => {
    let errText = err;
    if (action.platform_type !== "mail" && action.type_action !== "cfaQuestionnaire" && action.subject?.length < 3) {
      if (errText.indexOf("subject,") < 0) {
        errText += "subject,";
      }
    } else {
      errText = errText.replace("subject,", "");
    }
    if (!action.type_action.length > 0) {
      if (errText.indexOf("type,") < 0) {
        errText += "type,";
      }
    } else {
      errText = errText.replace("type,", "");
    }
    if (!action.time) {
      if (errText.indexOf("time,") < 0) {
        errText += "time,";
      }
    } else {
      errText = errText.replace("time,", "");
    }
    if (!action.event_id.length > 0) {
      if (errText.indexOf("event,") < 0) {
        errText += "event,";
      }
    } else {
      errText = errText.replace("event,", "");
    }
    setErr(errText);
    if (errText?.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  const checkIfIsFromReal = (action) => {
    let flag = false;
    if (action && realPlayers?.length > 0) {
      realPlayers.forEach((player) => {
        if (player.id === action.from_id) {
          flag = true;
        }
      })
    }
    return flag
  }

  let isCompletedPost =
    (action.platform_type !== "mail" && action.subject) && action.type_action && action.time && action.event_id && action.body && action.to_list_ids.length > 0
      && ((action.platform_type === "news" && (action.file?.file || action.file?.link)) || action.platform_type !== "news")
      ? true
      : false;
  // 
  const optionTypes = [
    { name: t("message"), value: "Message" },
    { name: t("analysis"), value: "Analysis" },
    { name: t("decision"), value: "Decision" },
    {
      name: `${t("call_for_action_challenges")}`,
      value: "cfaChallenges",
    },
    {
      name: `${t("call_for_action_questionnaire")}`,
      value: "cfaQuestionnaire",
    },
  ];

  const sendInject = (action) => {
    setLoad(true);
    sendAction(action).then((res) => {
      if (res) {
        setLoad(false);
        setActionState({ ...action, is_done: true });
        setIsEditing(false);
      }
    }).catch((err) => {
      setLoad(false);
      setIsEditing(false);
      dispatch(loading(false));
      dispatch(
        setErrorMessage(
          true,
          t("oops"),
          t("alert_mail_error")
        )
      );
    });
  };

  const kpiCheckBox = (
    <View
      style={[
        [
          styles.checkBoxContainer,
          {
            justifyContent: existAction?.platform_type
              ? "flex-start"
              : "space-between",
            paddingLeft: existAction?.platform_type ? widthS * 3 : 0,
            paddingTop: existAction?.platform_type ? heightS * 3 : 0,
          },
        ],
      ]}
    >
      <Text
        style={[
          styles.checkBoxText,
          {
            marginRight: widthS * 2,
            color: color.infoHeaderNavText,
          },
        ]}
      >
        KPI
      </Text>
      <Pressable
        disabled={!isEditing && existAction?.platform_type}
        onPress={() => setActionState({ ...action, is_kpi: !action.is_kpi })}
        style={[
          styles.checkBox,
          {
            borderColor: color.infoBorder,
            backgroundColor: background.infoBody,
          },
        ]}
      >
        {action.is_kpi && (
          <View
            style={[
              styles.checkBoxInner,
              {
                backgroundColor: background.count,
              },
            ]}
          />
        )}
      </Pressable>
    </View>
  );

  const setActionPost = (post, type, close) => {
    return new Promise(async (reslover, reject) => {
      let newAction = {
        ...post,
        ...action,
        from: post.from.name_for_game || post.from,
        from_id: post.from_id,
        to_list: post.to_list,
        to_list_ids: post.to_list_ids,
        cc_list: post.cc_list,
        cc_list_ids: post.cc_list_ids,
        bcc_list: post.bcc_list,
        bcc_list_ids: post.bcc_list_ids,
        platform_type: type || action.platform_type,
        message_id: post.message_id || action.message_id,
        subject: post.subject ? post.subject : action.subject,
        body: post.body,
        file: post.file
      };

      if (post.subject) {
        newAction.subject = post.subject;
      }
      if (typeLocation.value !== "Exact") {
        if (typeLocation.value === "Before") {
          newAction.phase_location = -1;
        } else {
          newAction.phase_location = 1;
        }
      } else {
        newAction.phase_location = 0;
      }
      setLoad(true);
      await addAction(newAction, actionsArrState.length || "0").then((res) => {
        reslover(true);
        if (close) {
          setActive(false);
          dispatch(editActionCreator(false));
          setShow(0);
        } else {
          setActionState(post);
          setShowDetails(false);
        }
        setLoad(false);
      }).catch((err) => {
        setLoad(false);
        dispatch(
          setErrorMessage(
            true,
            t("oops"),
            t("alert_mail_error")
          )
        );
      });
    })
  };

  const deleteAction = async () => {
    setLoad(true);
    await dispatch(editActionCreator(false));
    setShow(0);
    setActive(false);
    if (action.is_done) {
      await recallFlowObject(action);
    }
    addAction(action, actionsArrState.length, "delete").then(() => {
      setLoad(false);
    }).catch(() => {
      setLoad(false);
    })
  }

  const setEditMode = (platform) => {
    setIsEditing(true);
    setShow(platform);
  };

  const iconPlatformCheck = (type) => {
    switch (type) {
      case "mail":
        return images.mail;
      case "sm":
        return images.social;
      case "news":
        return images.news;
    }
  }

  const headerRight = (show, newF) => {
    switch (show) {
      case "mail":
        return newF ? `${I18n.t("new_email_message")}` : `${I18n.t("email_message")}`;
      case "news":
        return `${I18n.t("news_alerts")}`;
      case "sm":
        return newF ? `${I18n.t("new_social_network_post")}` : `${I18n.t("social_network_post")}`;
      case "qs":
        return newF ? `${I18n.t("create_questionaire")}` : `${I18n.t("questionaire")}`;
    }
  }

  const closePopup = () => { setShow(0); setActive(false); dispatch(editActionCreator(false)); };

  useEffect(() => {
    if (action.time.length === 0) {
      setActionState({ ...action, time: actionsArrState.length > 0 ? +actionsArrState[actionsArrState.length - 1].time + 1 : 1 })
    }
  }, [actionsArrState, show]);

  useEffect(() => {
    document.addEventListener("mousedown", async (e) => {
      if (
        show && isPossibletoClose.current &&
        eventChildLeftRef?.current &&
        (!eventChildRightRef?.current || (eventChildRightRef.current && !eventChildRightRef.current.contains(e.target))) &&
        !eventChildLeftRef?.current?.contains(e.target)
      ) {
        await dispatch(editActionCreator(false));
        setShow(0);
        setActive(false);
      }
    });

  }, [show, isPossibletoClose.current]);

  useEffect(() => {
    if (showFileFlag?.link?.length === 0) {
      isPossibletoClose.current = true;
    }
    return () => {
      componentWillUnmount.current = true;
    };
  }, [showFileFlag?.link?.length > 0]);

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current && eventBetween && !title && !deleted)
        dispatch(deleteEvent(id));
    };
  }, [eventBetween, title, deleted]);

  useMemo(() => {
    if (active) {
      action["event_id"] = active?.id;
      action["eventColor"] = active?.color;
      action["event"] = active?.name;
      //setActionState({ ...action, event_id: active?.id, eventColor: active.color, event: active.name });
    }
  }, [active]);

  useEffect(() => {
    if (existAction) {
      setActionState({ ...action, ...existAction });
    }
  }, [existAction]);
  let ifIsFromReal = checkIfIsFromReal(action);

  const leftSide = (
    <View
      ref={eventChildLeftRef}
      style={[
        styles.eventContentChild,
        {
          maxHeight: newAction
            ? heightS * 280
            : existAction?.platform_type
              ? "55vh"
              : "70vh",
          flexDirection: "row",
          height: "fit-content",
          transtion: "all .5s",
          flex: 1,
          backgroundColor: background.eventActive,
        },
        side === "left" || !side
          ? {
            borderTopRightRadius: isNaN(show) ? 0 : widthS * 30,
            borderBottomRightRadius: isNaN(show) ? 0 : widthS * 30,
            borderTopLeftRadius:
              !existAction?.platform_type && !newAction ? 0 : widthS * 30,
          }
          : {
            borderTopLeftRadius: isNaN(show) ? 0 : widthS * 30,
            borderBottomRightRadius: isNaN(show) ? 0 : widthS * 30,
          },
      ]}
    >
      <View
        style={[
          styles.eventContentChild,
          {
            // maxHeight: newAction
            //   ? heightS * 280
            //   : existAction?.platform_type
            //     ? "55vh"
            //     : "70vh",
            height: "max-content",
            backgroundColor: background.eventActive,
            paddingBottom: show === 1 ? heightS * 20 : 0,
            paddingRight:
              isNaN(show) && !existAction?.platform_type ? widthS * 20 : 0,
            // paddingLeft: show !== 1 ? widthS * 10 : 0
            // paddingTop: 30 * heightS,
          },
          side === "left" || !side
            ? {
              borderTopRightRadius: isNaN(show) ? 0 : widthS * 30,
              borderBottomRightRadius: isNaN(show) ? 0 : widthS * 30,
              borderTopLeftRadius:
                !existAction?.platform_type && !newAction ? 0 : widthS * 20,
            }
            : {
              borderBottomRightRadius: isNaN(show) ? 0 : widthS * 30,
              borderTopLeftRadius: isNaN(show) ? 0 : widthS * 30,
            },
        ]}
      >
        {newAction && !dashboard && (
          <View
            style={[
              styles.traingular,
              { borderBottomColor: background.eventActive },
            ]}
          />
        )}
        {(show === 2) && (
          <View style={[styles.btnView, side === "left"
            ? { right: widthS * 15 }
            : { left: widthS * 15 },]}>
            {permission && <Pressable style={styles.x_btn} onPress={() => {
              deleteEventData(eventDetails);
              setShow(0);
              setActive(false);
              dispatch(editActionCreator(false));
            }}>
              <Image
                source={{ uri: images.deleteIcon }}
                style={styles.deleteIcon}
              />
            </Pressable>}
            {permission && <Pressable style={styles.x_btn} onPress={() => editEventFunc(eventDetails, index)}>
              <FontAwesome5
                name="edit"
                size={widthS * 9}
                color={color.infoHeaderNavText}
                style={{ padding: widthS * 5 }}
              />
            </Pressable>}
            <Pressable
              onPress={() => {
                setShow(0);
                setActive(false);
                dispatch(editActionCreator(false));
              }}
              style={[
                styles.x_btn,
              ]}
            >
              <Text
                style={[
                  {
                    color: color.infoHeaderNavText,
                    fontSize: widthS * 10,
                  },
                ]}
              >
                X
              </Text>
            </Pressable>
          </View>
        )}
        <View style={{ flex: 1 }}>
          {show !== 0 && !newAction && !existAction?.platform_type && (
            <View>
              <Text
                style={{
                  textAlign: side,
                  color:
                    title || (show && active)
                      ? color.infoHeaderNavText
                      : color.buildTitle,
                  fontSize: title ? 12 * widthS : 28 * widthS,
                  paddingTop: title && heightS * 10,
                  marginHorizontal: widthS * 10,
                  paddingHorizontal:
                    (title && descMarginLeft || active) ? widthS * 10 : 0,
                }}
              >
                {title || eventBetween || `${events.length}`.padStart(2, "0")}
              </Text>
            </View>
          )}
          <View>
            {(show > 1 || isNaN(show)) &&
              descMarginLeft !== null &&
              !newAction &&
              !existAction?.platform_type && (
                <View
                  style={{
                    borderBottomColor: color.infoBorder,
                    borderBottomWidth: permission ? widthS * 1 : 0,
                    height: "4.5vw",
                    paddingTop: heightS * 5,
                  }}
                >
                  <Text
                    style={{
                      color: color.infoHeaderNavText,
                      marginHorizontal: descMarginLeft,
                      paddingHorizontal: widthS * 18,
                      fontSize: description?.length > 100 || description?.split("\n").length > 2 ? widthS * 10 : widthS * 12,
                      lineHeight: description?.length > 100 || description?.split("\n").length > 2 ? widthS * 11 : widthS * 13,
                      textAlign: side,
                    }}
                    numberOfLines={4}
                  >
                    {description === null || description === "null" ? "" : checkIfIsEncodeText(description, "string")}
                  </Text>
                </View>
              )}
            {show === 1 ? (
              <>
                <View style={{ width: "85%", marginHorizontal: "auto" }}>
                  {err?.indexOf("name") >= 0 && eventDetails.title?.length < 2 && <Text style={styles.errText}>{t("add_player_err_name")}</Text>}
                  <TextInput
                    maxLength={25}
                    placeholder={I18n.t("title")}
                    placeholderTextColor={color.infoHeaderNavText}
                    value={eventDetails.title}
                    style={[
                      styles.input,
                      !isCompletedDetailsForm && err.indexOf("name") >= 0 && eventDetails.title?.length < 2 ? styles.mail_error : "",
                      {
                        color: color.infoHeaderNavText,
                        backgroundColor: background.infoBody,
                        fontSize: eventDetails.title?.length > 100 ? widthS * 8 : widthS * 10,
                      },
                    ]}
                    onChangeText={(e) =>
                      setEventDetails((details) => {
                        return { ...details, title: e };
                      })
                    }
                  />
                  <View style={styles.description}>
                    <TextInput
                      nativeID="social"
                      multiline={true}
                      value={eventDetails?.description === null || eventDetails?.description === "null" ? "" : eventDetails?.description?.replace(/[n\/][\n\n\n\/][\r\n]/g, " ")}
                      style={[
                        styles.input,
                        {
                          height: heightS * 90,
                          marginBottom: heightS * 12,
                          color: color.infoHeaderNavText,
                          backgroundColor: background.infoBody,
                          fontSize: eventDetails.description?.length > 100 || eventDetails.description?.split("\n").length > 2 ? widthS * 9 : widthS * 10,
                          lineHeight: eventDetails.description?.length > 100 || eventDetails.description?.split("\n").length > 2 ? widthS * 9.5 : widthS * 10.5,
                          paddingVertical: heightS * 7,
                        },
                      ]}
                      placeholder={`${I18n.t("add_description")}`}
                      onChangeText={(e) => {
                        let indexLast = e.lastIndexOf("\n");
                        let textLengthAfterNewLine = e.slice(indexLast).length;
                        if (e.split("\n").length < 5 && ((e.lastIndexOf("\n") > 0 && textLengthAfterNewLine < (252 / e.split("\n").length)) || e.length < 252)) {
                          setEventDetails((details) => {
                            return { ...details, description: e.replace(/[n\/][\n\n\n\/][\r\n]/g, " ") };
                          })
                        }
                      }
                      }
                      maxLength={252}
                      numberOfLines={4}
                      placeholderTextColor={color.infoHeaderNavText}
                    />
                  </View>
                  {permission && <View
                    style={{
                      flexDirection: "row",
                      marginTop: heightS * 10,
                      alignItems: "center",
                    }}
                  >
                    <>

                      <Pressable
                        onPress={() => {
                          if (isCompletedDetailsForm) {
                            addEventHandler(eventDetails);
                            setActive(eventDetails);
                            setShow(2);
                            setErr("");
                          } else {
                            setErr("name");
                          }
                        }}
                        style={[
                          styles.saveBtn,
                          isCompletedDetailsForm
                            ? {
                              backgroundColor: background.infoSaveBtn,
                              borderColor: "transparent",
                            }
                            : {
                              borderColor: color.infoHeaderNavText,
                            },
                        ]}
                      >
                        <Text
                          style={[
                            styles.saveBtn_text,
                            {
                              color: isCompletedDetailsForm
                                ? color.infoSaveBtn
                                : color.infoHeaderNavText,
                            },
                          ]}
                        >
                          {t("save")}
                        </Text>
                      </Pressable>
                      <Pressable
                        onPress={async () => {
                          if (eventBetween) {
                            dispatch(deleteEvent(id));
                            await setDeleted(true);
                          }
                          setActive(false);
                          dispatch(editActionCreator(false));
                          setShow(0);
                        }}
                      >
                        <Text
                          style={{
                            fontSize: widthS * 11,
                            color: color.buildTitle,
                            paddingLeft: widthS * 10,
                          }}
                        >
                          {t("cancel")}
                        </Text>
                      </Pressable></>
                  </View>}
                </View>
              </>
            ) : (show === 2 && permission) ? (
              <View
                style={{
                  flexDirection: "row",
                  paddingHorizontal: widthS * 20,
                  paddingVertical: heightS * 25,
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
              >
                <Pressable onPress={() => checkEnd(() => setShow(3))}>
                  {({ hovered }) => (
                    <View
                      style={[
                        styles.saveBtn,
                        styles.saveBtnUncompleted,
                        {
                          width: widthS * 120,
                          height: heightS * 30,
                          borderRadius: widthS * 10,
                          borderColor: hovered
                            ? color.logoColor
                            : color.infoHeaderNavText,
                          backgroundColor: hovered ? color.logoColor : "none",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          styles.saveBtn_text,
                          {
                            color: color.infoHeaderNavText,
                          },
                        ]}
                      >
                        {`${I18n.t("create_event_actions")}`}
                      </Text>
                    </View>
                  )}
                </Pressable>
                <Pressable
                  onPress={() => {
                    checkEnd(() => {
                      setIndex(events.length - 1);
                      createNextEventHandler();
                    })
                  }}
                >
                  {({ hovered }) => (
                    <View
                      style={[
                        styles.saveBtn,
                        styles.saveBtnUncompleted,
                        {
                          width: widthS * 120,
                          height: heightS * 30,
                          borderRadius: widthS * 10,
                          borderColor: hovered
                            ? color.logoColor
                            : color.infoHeaderNavText,
                          backgroundColor: hovered ? color.logoColor : "none",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          styles.saveBtn_text,
                          {
                            color: color.infoHeaderNavText,
                          },
                        ]}
                      >
                        {`${I18n.t("create_next_event")}`}
                      </Text>
                    </View>
                  )}
                </Pressable>
              </View>
            ) : show === 3 || isNaN(show) ? (
              <>
                {
                  existAction?.platform_type
                  && (
                    <View
                      style={[
                        styles.actionType,
                        {
                          borderColor: color.infoBorder,
                        },
                      ]}
                    >
                      <View
                        style={{
                          width: "85%",
                          marginHorizontal: "auto",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingBottom: heightS * 10,
                          height: heightS * 30,
                        }}
                      >
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                          <View style={[styles.iconView, {
                            backgroundColor: action?.is_done
                              ? `${action?.eventColor}`
                              : `${action?.eventColor}85`,
                            borderColor: `${action?.eventColor}`,
                            borderWidth: "0.2vw",
                          }]}>
                            <Image source={{ uri: iconPlatformCheck(action.platform_type) }} style={styles.iconImage} />
                          </View>
                          <Text
                            style={[
                              styles.checkBoxText,
                              {
                                color: color.infoHeaderNavText,
                              },
                            ]}
                          >
                            {headerRight(action?.type_action === "cfaQuestionnaire" ? "qs" : (show || action?.platform_type))?.toUpperCase()}
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                          {permission && <>
                            {!action.is_done && (
                              <Pressable
                                onPress={() => sendInject(action)}
                                disabled={isEditing || (action?.unfinished)}
                                style={[
                                  styles.sendBtn,
                                  {
                                    backgroundColor: isEditing || (action?.unfinished)
                                      ? color.infoBorder
                                      : "#201C21",
                                  },
                                ]}
                              >
                                <Ionicons
                                  name="send-outline"
                                  size={"1vw"}
                                  color={color.buildTitle}
                                />
                              </Pressable>
                            )}
                            {/* recall btn */}
                            {(action.is_done && !ifIsFromReal) &&
                              <Pressable
                                onPress={() => {
                                  dispatch(
                                    setErrorMessage(
                                      true,
                                      t("oops"),
                                      t("war_recall_action"),
                                      async (e) => {
                                        if (e) {
                                          setLoad(true);
                                          recallFlowObject(action).then(() => {
                                            setLoad(false);
                                          }).catch(() => {
                                            setLoad(false);
                                          });
                                        }
                                      }))
                                }}
                                style={[
                                  styles.sendBtn,
                                  {
                                    backgroundColor: "#201C21",
                                  },
                                ]}
                              >
                                <AntDesign name="back" size={"1vw"}
                                  color={color.buildTitle} />
                              </Pressable>}
                            {/* delete btn */}
                            <Pressable
                              onPress={() => {
                                dispatch(setErrorMessage(true, t("oops"), t("war_delete_action"), async (e) => {
                                  if (e) {
                                    deleteAction()
                                  }
                                }))
                              }}
                              style={[
                                styles.sendBtn,
                                {
                                  backgroundColor: "#201C21",
                                },
                              ]}
                            >
                              <Image source={{ uri: images.deleteIcon }} style={{ width: "1vw", height: "1vw", resizeMode: "contain" }} />
                            </Pressable>
                          </>}
                          {/* x btn on the right part */}
                          {!isNaN(show) && <Pressable
                            onPress={async () => {
                              await dispatch(editActionCreator(false));
                              setShow(0);
                              setActive(false);
                              dispatch(editActionCreator(false));
                            }}
                            style={[
                              styles.sendBtn,
                              {
                                backgroundColor: "#201C21",
                              },
                            ]}
                          >
                            <Text style={[
                              styles.closeBtnText,
                              {
                                color: color.buildTitle,
                              },
                            ]}>X</Text>
                          </Pressable>}
                        </View>
                      </View>
                    </View>
                  )}
                <View
                  style={[
                    newAction && {
                      paddingTop: heightS * 5,
                      paddingBottom: heightS * 10,
                      borderBottomWidth: widthS * 1,
                      borderColor: color.infoBorder,
                    },
                  ]}
                >
                  {!existAction?.platform_type && (
                    <View style={styles.actionsHeader}>
                      <Text
                        style={[
                          styles.checkBoxText,
                          {
                            color: color.infoHeaderNavText,
                          },
                        ]}
                      >
                        {newAction ? I18n.t("new_action").toUpperCase() :
                          I18n.t("action").toUpperCase()}
                      </Text>
                      {(action?.platform_type !== "news" && action.type_action !== "cfaQuestionnaire" && action.type_action !== "cfaChallenges") && kpiCheckBox}
                    </View>
                  )}
                </View>
                <View
                  style={[
                    styles.form,
                    {
                      paddingTop: !newAction ? heightS * 5 : heightS * 15,
                    },
                  ]}
                >
                  <View style={styles.actionInputs}>
                    <View style={[{ zIndex: 6 }]}>
                      <EventSelect
                        errField={err.indexOf("event") >= 0 && action.event_id?.length === 0}
                        disabled={!isEditing && existAction?.platform_type}
                        options={
                          events?.length
                            ? events.slice(0, events.length - 1)
                            : []
                        }
                        style={{ width: "100%", margin: 0, writingDirection: direction }}
                        placeholder={`${I18n.t("select_related_event")}`}
                        icon
                        type={"event"}
                        value={action?.event || ""}
                        setValue={(value) =>
                          setActionState((prev) => {
                            return {
                              ...prev,
                              event: value.title,
                              eventColor: value.color,
                              event_id: value.id,
                            };
                          })
                        }
                      />
                    </View>
                    {err?.indexOf("subject") >= 0 && action.subject?.length < 2 && <Text style={styles.errText}>{t("subject_action_err")}</Text>}
                    {action.platform_type !== "mail" && action?.type_action !== "cfaQuestionnaire" && <TextInput
                      disabled={!isEditing && existAction?.platform_type}
                      placeholder={`${I18n.t("add_subject")}`}
                      placeholderTextColor={color.infoHeaderNavText}
                      value={action.subject}
                      style={[
                        styles.input,
                        {
                          color: color.infoHeaderNavText,
                          backgroundColor: background.infoBody,
                          writingDirection: direction
                        },
                        err.indexOf("subject") >= 0 && action.subject?.length < 2 && styles.mail_error
                      ]}
                      onChangeText={(e) => {
                        setActionState((prev) => {
                          return { ...prev, subject: e };
                        }); setInject({ ...inject, subject: e })
                      }
                      }
                    />}
                    {/* action-type */}
                    <View style={[{ zIndex: 3 }]}>
                      <EventSelect
                        disabled={!isEditing && existAction?.platform_type}
                        options={optionTypes}
                        err={err}
                        style={{ width: "100%", margin: 0, writingDirection: direction }}
                        placeholder={`${I18n.t("type")}`}
                        icon
                        type={"type"}
                        value={action.type_action_name ? action.type_action_name : optionTypes.find((e) => e.value === action.type_action)?.name}
                        setValue={(value) => {
                          if (value.value === "cfaQuestionnaire") {
                            setShow("qs");
                            setActionState({
                              ...action,
                              type_action: value.value,
                              type_action_name: value.name,
                              is_kpi: false,
                            });
                          } else if (value.value === "cfaChallenges") {
                            setShow("mail");
                            setActionState({
                              ...action,
                              type_action: value.value,
                              type_action_name: value.name,
                              is_kpi: false,
                            });
                          } else {
                            setActionState({
                              ...action,
                              type_action: value.value,
                              type_action_name: value.name
                            });
                            setShow(action?.platform_type ? action?.platform_type : 3);
                          }
                        }}
                      />
                    </View>
                    {/* phase-time */}
                    <View style={{ zIndex: 2, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                      <TextInput
                        keyboardType="decimal-pad"
                        disabled={!isEditing && existAction?.platform_type}
                        placeholder={`${I18n.t("select_time_of_action")}`}
                        placeholderTextColor={color.infoHeaderNavText}
                        value={action.time}
                        style={[
                          styles.input,
                          {
                            color: color.infoHeaderNavText,
                            backgroundColor: background.infoBody,
                          },
                        ]}
                        onChangeText={(e) =>
                          setActionState((prev) => {
                            return { ...prev, time: e.replace(/[^0-9]/, "") };
                          })
                        }
                      />
                      <Pressable
                        onPress={() => setShowTypeLocation(true)}
                        disabled={actionsArrState.length === 0}
                        style={[styles.input, {
                          position: "relative",
                          flex: 1,
                          marginLeft: widthS * 5,
                          backgroundColor: background.infoBody,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          writingDirection: direction
                        }]}
                      >
                        <Text style={{ color: actionsArrState.length === 0 ? color.placeholder : color.infoHeaderNavText, fontFamily: "Catamaran_300Light", fontSize: widthS * 8, }}>{typeLocation.name}</Text>
                        <Ionicons
                          size={widthS * 7}
                          name="caret-down"
                          style={{ marginHorizontal: widthS * 2 }}
                          color={color.infoHeaderNavText}
                        />
                        {showTypeLocation &&
                          <View
                            style={[{
                              position: "absolute",
                              backgroundColor: background.infoBody,
                              top: 0,
                              width: "100%",
                              left: 0,
                              borderRadius: widthS * 9,
                            }]}
                          >
                            <Pressable
                              onPress={() => setShowTypeLocation(false)}
                              style={{
                                borderBottomColor: color.border,
                                borderBottomWidth: widthS * 1,
                                width: "100%",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingVertical: 7 * heightS,
                                paddingHorizontal: widthS * 10,
                              }}>
                              <Text style={{ color: color.infoHeaderNavText, fontFamily: "Catamaran_300Light", fontSize: widthS * 8, }}>
                                {typeLocation.name}
                              </Text>
                              <Ionicons
                                size={widthS * 7}
                                name="caret-down"
                                style={{ marginHorizontal: widthS * 2, transform: [{ rotate: "180deg" }] }}
                                color={color.infoHeaderNavText}
                              />
                            </Pressable>
                            {typesLocation.map((type, index) => {
                              if (type.value === typeLocation.value) return;
                              return (
                                <Pressable
                                  key={index}
                                  onPress={() => { setTypeLocation(type); setShowTypeLocation(false) }}
                                  style={({ hovered }) => [{ paddingVertical: 5 * heightS, paddingHorizontal: widthS * 10, backgroundColor: hovered ? "" : "" }]}>
                                  <Text style={{ color: color.infoHeaderNavText, fontFamily: "Catamaran_300Light", fontSize: widthS * 8, }}>{type.name}</Text>
                                </Pressable>
                              )
                            })}
                          </View>}
                      </Pressable>
                    </View>
                    {(existAction?.platform_type && existAction?.platform_type !== "news" && action.type_action !== "cfaQuestionnaire" && action.type_action !== "cfaChallenges") && kpiCheckBox}
                  </View>
                  <View style={styles.actionsBottom}>
                    <Text
                      style={[
                        styles.checkBoxText,
                        {
                          paddingRight: widthS * 15,
                          color: color.infoHeaderNavText,
                        },
                      ]}
                    >{`${!existAction?.platform_type ? I18n.t("select") : I18n.t("select_platform")
                      }:`}</Text>
                    <ActionIcon
                      uri={images.mail}
                      onPress={() => {
                        setErr("");
                        if (action.type_action === "cfaChallenges") {
                          isPossibletoClose.current = false;
                          dispatch(
                            setErrorMessage(
                              true,
                              t("oops"),
                              t("call_for_action_alert"),
                              false,
                              () => setTimeout(() => isPossibletoClose.current = true, 1000)
                            )
                          );
                        }
                        if (action.platform_type.length > 0 && action.platform_type !== "mail" && action?.file) {
                          setActionState({ ...action, platform_type: "mail", file: [(action.file?.link || action.file?.file) ? action.file : createFileObject(false, action.file.uri)] });
                        } else {
                          setActionState({ ...action, platform_type: "mail", file: null });
                        }
                        setShow("mail");
                      }}
                      disabled={action.type_action !== "cfaQuestionnaire"}
                      active={show === "mail"}
                    />
                    {action.type_action !== "cfaChallenges" && <>
                      <ActionIcon
                        uri={images.social}
                        onPress={() => {
                          setErr("");
                          if (action.platform_type.length > 0 && action.platform_type === "mail" && action.file?.length > 0) {
                            setActionState({ ...action, platform_type: "sm", file: setFilesFromEmail(action.file, "sm") });
                          }
                          else if (action.platform_type.length > 0 && action.platform_type === "news") {
                            setActionState({ ...action, platform_type: "sm", from: "", from_id: "", });
                          } else {
                            setActionState({ ...action, platform_type: "sm", file: null });
                          }
                          setShow("sm");
                        }}
                        disabled={(action.type_action === "Message" || action.type_action === "") && action.type_action !== "cfaQuestionnaire"}
                        active={show === "sm"}
                      />
                      <ActionIcon
                        uri={images.news}
                        onPress={() => {
                          setErr("");
                          if (action.platform_type.length > 0 && action.platform_type === "mail" && action.file?.length > 0) {
                            setActionState({ ...action, platform_type: "news", file: setFilesFromEmail(action.file) });
                          } else {
                            setActionState({ ...action, platform_type: "news" });
                          }
                          setShow("news");
                        }}
                        disabled={(action.type_action === "Message" || action.type_action === "") && !action?.is_kpi && action.type_action !== "cfaQuestionnaire"}
                        active={show === "news"}
                      />
                    </>}
                  </View>
                </View>
              </>
            ) : (
              ""
            )}
          </View>
        </View>
      </View>
      {(() => {
        return (isNaN(show) && (
          <View
            style={{
              height: existAction?.platform_type
                ? "40vh"
                : "55vh",
              alignSelf: "center",
              width: "0.08vw",
              backgroundColor: color.infoBorder,
            }}
          />
        ))
      })()}
    </View>
  );
  const rightside = (
    <View
      ref={eventChildRightRef}
      onLayout={(event) =>
        setActionContentBorderBottomLeft(
          event.nativeEvent.layout.height <=
            (newAction ? 290 : existAction?.platform_type ? 270 : 315) *
            heightS
            ? 0
            : widthS * 30
        )
      }
      style={[
        styles.eventContentChild,
        {
          borderTopRightRadius: widthS * 20,
          borderBottomRightRadius: widthS * 20,
          borderBottomLeftRadius: actionContentBorderBottomLeft,
          backgroundColor: background.eventActive,
          paddingVertical: "2vh",
          // paddingBottom: !isEditing && heightS * 20,
        },
      ]}
    >
      <View
        style={[
          styles.rightAction,
          { paddingHorizontal: !showDetails ? 0 : 0 },
        ]}
      >
        <View style={{ paddingHorizontal: "3vw" }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              minHeight: "7vh",
              paddingTop: existAction?.platform_type ? heightS * 13.5 : 0,
            }}
          >
            {
              existAction?.platform_type
                ? (
                  <View
                    style={[
                      styles.actionDetailsContainer,
                      { borderColor: color.infoBorder },
                    ]}
                  >
                    <Pressable
                      style={[
                        styles.detailsBtn,
                        {
                          borderColor: !showDetails
                            ? "transparent"
                            : color.infoHeaderNavBorder,
                        },
                      ]}
                      onPress={() => {
                        setShowDetails(true);
                      }}
                    >
                      <Text
                        style={[
                          styles.detailsBtnText,
                          {
                            color: !showDetails
                              ? color.buildTitle
                              : color.infoHeaderNavText,
                          },
                        ]}
                      >
                        {t("details").toUpperCase()}
                      </Text>
                    </Pressable>
                    <Pressable
                      style={[
                        styles.detailsBtn,
                        {
                          left: widthS * 60,
                          borderColor: showDetails
                            ? "transparent"
                            : color.infoHeaderNavBorder,
                        },
                      ]}
                      onPress={() => {
                        if (isEditing) {
                          isPossibletoClose.current = false;
                          dispatch(setErrorMessage(
                            true,
                            null,
                            t("err_details_editing"),
                            (e) => {
                              if (e) {
                                saveRef.current = true;
                              } else {
                                setShowDetails(false);
                                setIsEditing(false);
                              }
                              isPossibletoClose.current = false;
                            }
                          ))
                        } else {
                          setShowDetails(false)
                        }
                      }}
                    >
                      <Text
                        style={[
                          styles.detailsBtnText,
                          {
                            color: showDetails
                              ? color.buildTitle
                              : color.infoHeaderNavText,
                          },
                        ]}
                      >
                        {t("players").toUpperCase()}
                      </Text>
                    </Pressable>
                  </View>
                ) : (
                  <Text
                    style={[
                      styles.checkBoxText,
                      {
                        color: color.infoHeaderNavText,
                        paddingBottom: heightS * 10,
                      },
                    ]}
                  >
                    {headerRight(action?.type_action === "cfaQuestionnaire" ? "qs" : (show || action?.platform_type), !existAction?.type_action)?.toUpperCase()}
                  </Text>
                )}
            <Pressable
              style={{
                position: "absolute",
                right: "-1vw",
                top: 0,
              }}
              onPress={() => closePopup()}
            >
              <Text
                style={[
                  styles.closeBtnText,
                  {
                    color: color.buildTitle,
                  },
                ]}
              >
                X
              </Text>
            </Pressable>
          </View>
        </View>
        <View>
          {(() => {
            if (existAction?.platform_type && showDetails && !isEditing && !action.is_done && permission) {
              return (
                <View nativeID="Liraz" style={styles.editBtnContainer}>
                  <Pressable onPress={() => setEditMode(show)}>
                    <Feather
                      name="edit"
                      size={widthS * 9}
                      color={color.buildTitle}
                    />
                  </Pressable>
                </View>)
            }
          })()}
          {showDetails ?
            (
              action.type_action === "cfaQuestionnaire" ?
                <Questionaire
                  checkActionFields={checkActionFields}
                  isPossibletoClose={isPossibletoClose}
                  inject={{ inject: action }}
                  setActionPost={setActionPost}
                  isEditing={existAction?.platform_type ? isEditing : true}
                /> :
                show === "mail" ? (
                  <EmailAction
                    saveRef={saveRef}
                    setIsEditing={setIsEditing}
                    isPossibletoClose={isPossibletoClose}
                    checkActionFields={checkActionFields}
                    editAction={editAction}
                    isCompletedForm={isCompletedFormMail}
                    createNextEventHandler={createNextEventHandler}
                    setActionPost={(newPost, type, flag) =>
                      setActionPost(newPost, "mail", flag)
                    }
                    isEditing={isEditing}
                    inject={{ inject: action }}
                    closePopup={closePopup}
                    platform_type={existAction?.platform_type}
                    setPlatforme={(type) =>
                      setActionState({ ...action, platform_type: type })
                    }
                  />
                ) : show === "news" ? (
                  <NewsAction
                    moveToPlayers={() => { setShowDetails(false); setIsEditing(false) }}
                    saveRef={saveRef}
                    setIsEditing={setIsEditing}
                    isPossibletoClose={isPossibletoClose}
                    checkActionFields={checkActionFields}
                    isCompletedForm={isCompletedForm}
                    closePopup={closePopup}
                    setActionPost={(newPost, type, flag) =>
                      setActionPost(newPost, "news", flag)
                    }
                    editAction={editAction}
                    setAction={setActionState}
                    isEditing={isEditing}
                    inject={{ inject: action }}
                    platform_type={existAction?.platform_type}
                  />
                ) : (
                  <SocialAction
                    newAction={newAction}
                    moveToPlayers={() => { setShowDetails(false); setIsEditing(false) }}
                    saveRef={saveRef}
                    isPossibletoClose={isPossibletoClose}
                    checkActionFields={checkActionFields}
                    editAction={editAction}
                    isCompletedForm={isCompletedForm}
                    setActionPost={(newPost, platform, flag) =>
                      setActionPost(newPost, "sm", flag)
                    }
                    isEditing={isEditing}
                    inject={{ inject: action }}
                    platform_type={existAction?.platform_type}
                    setAction={setActionState}
                  />
                )
            ) : (
              <ActionPlayers
                disabled={action?.is_done}
                isEditing={true}
                isCompletedForm={isCompletedForm}
                permission={permission}
                isCompletedPost={isCompletedPost}
                inject={{ inject: action }}
                isPossibletoClosePlayers={isPossibletoClosePlayers}
                setActionPost={(newPost, platform, flag) =>
                  setActionPost(
                    newPost,
                    platform,
                    flag
                  )
                }
              />
            )}
        </View>
      </View>
    </View>
  );
  return (
    <>
      <View
        ref={eventContentRef}
        style={[
          styles.eventContent(dashboard),
          {
            width: isNaN(show) ? widthS * 580 : widthS * 290,
            top:
              show === 2
                ? ((top && `${top}vw`) || existAction?.y)
                : (top && `${top}vw`) || existAction?.y || heightS * 50,
            // direction: side === "left" ? "ltr" : "rtl",
            flexDirection: "row",
          },
          isNaN(show) && side === "right"
            ? {
              left:
                right -
                (eventContentRef.current?.getBoundingClientRect().width -
                  widthS * 33.5),
            }
            : { left: left || existAction?.x || widthS * 100 },
        ]}
      >
        {leftSide}
        {isNaN(show) && rightside}
      </View>
    </>
  );
}

const stylesR = (width, height, heightS, widthS, background, color) =>
  StyleSheet.create({
    eventContent: (flag) => ({
      position: flag ? "initial" : "absolute",
      zIndex: 100,
    }),

    eventContentChild: {
      flex: 1,
      // paddingBottom: 15 * heightS,
      borderBottomLeftRadius: widthS * 30,
      borderTopLeftRadius: 0,
    },

    input: {
      height: heightS * 30,
      fontSize: widthS * 8,
      marginVertical: heightS * 5,
      borderRadius: widthS * 9,
      paddingVertical: 5 * heightS,
      paddingHorizontal: widthS * 10,
      outlineStyle: "none",
    },
    saveBtn: {
      paddingHorizontal: widthS * 8,
      paddingVertical: heightS * 4,
      borderRadius: widthS * 5,
      alignItems: "center",
      justifyContent: "center",
      borderWidth: widthS * 1,
    },
    saveBtn_text: {
      fontSize: 10 * widthS,
    },
    actionsHeader: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "85%",
      marginHorizontal: "auto",
      paddingTop: heightS * 15,
    },
    checkBoxContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    checkBox: {
      marginRight: 5 * widthS,
      width: 16 * widthS,
      height: 16 * widthS,
      borderRadius: 3,
      borderWidth: 1,
      justifyContent: "center",
      alignItems: "center",
      marginLeft: widthS * 5,
    },
    checkBoxInner: {
      width: 9 * widthS,
      height: 9 * widthS,
      borderRadius: 2,
    },
    checkBoxText: {
      fontSize: 11 * widthS,
    },
    actionInputs: {
      marginTop: heightS * 4,
      zIndex: 2,
    },
    actionsBottom: {
      flexDirection: "row",
      paddingTop: heightS * 3,
      alignItems: "center",
      zIndex: 1,
    },
    rightAction: {
      flex: 1,
      paddingHorizontal: widthS * 30,
    },
    closeBtn: {
      top: 0,
    },
    closeBtnText: {
      fontSize: widthS * 12,
    },
    form: {
      width: "85%",
      marginHorizontal: "auto",
      paddingBottom: heightS * 15,
    },
    btnView: {
      position: "absolute",
      top: heightS * 6,
      zIndex: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    },
    x_btn: {
      width: "1.5vw",
      height: "1.5vw",
      alignItems: "center",
      justifyContent: "center"
    },
    deleteIcon: {
      width: widthS * 9,
      height: widthS * 9,
      resizeMode: "contain",
    },
    actionType: {
      borderBottomWidth: widthS * 1,
      width: "100%",
      paddingTop: heightS * 15,
    },
    iconView: {
      width: "2.7vw",
      height: "2.7vw",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      marginRight: "0.5vw"
    },
    iconImage: {
      width: "1.5vw",
      height: "1.5vw",
      resizeMode: "contain"
    },
    sendBtn: {
      borderRadius: "50%",
      width: "2.5vw",
      height: "2.5vw",
      justifyContent: "center",
      alignItems: "center",
      marginHorizontal: widthS * 2.5
    },
    actionDetailsContainer: {
      flexDirection: "row",
      borderBottomWidth: widthS * 1,
      width: "100%",
    },
    detailsBtn: {
      borderBottomWidth: widthS * 1,
      position: "absolute",
      bottom: 0
    },
    detailsBtnText: {
      fontSize: widthS * 9,
      paddingBottom: heightS * 2,
      fontFamily: "Catamaran_500Medium"
    },
    editBtnContainer: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      position: "absolute",
      right: "0.1vw",
      top: "0.1vw",
      width: "100%",
      paddingVertical: heightS * 5,
      paddingHorizontal: "3vw"
    },
    editBtnContainerQuestion: {
      top: "-2.5vw",
    },
    traingular: {
      position: "absolute",
      top: heightS * -10,
      left: widthS * 135,
      width: 0,
      height: 0,
      borderLeftWidth: widthS * 9,
      borderRightWidth: widthS * 9,
      borderBottomWidth: widthS * 9,
      borderStyle: "solid",
      backgroundColor: "transparent",
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      zIndex: 3,
    },
    mail_error: {
      borderColor: "red",
      borderWidth: "2px",
      borderRadius: "10px",
    },
    errText: {
      color: color.infoSaveBtn,
      fontSize: "0.8vw",
      fontFamily: "Catamaran_400Regular"
    },
  });
