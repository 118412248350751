import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Image,
  TouchableOpacity,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesome } from '@expo/vector-icons';
import { useFonts } from "expo-font";

import "./scroll.css";
import NewsBody from "./NewsBody";
import NewNewsPost from "./NewNewsPost";
import { t } from "i18n-js";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import { removeType, setTypeMinimize } from "../../../redux/actions";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";

export default function News({ flag, setFlag, setDrag }) {

  const [loaded] = useFonts({
    cnn_font: require('../../../style/CNN.TTF'),
  });

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const popupRef = useRef(null);
  const containerRef = useRef(null);
  const bluePopup = useRef(null);
  const dispatch = useDispatch();
  const news = useSelector((state) => state.newsArr.newsArr);
  const firstComeIn = useSelector((state) => state.newsArr.firstComeIn);
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);
  const minimizePopups = useSelector((state) => state.minimize.minimize);

  const [newsToPrint, setNewsToPrint] = useState([]);
  const [showNewPost, setShowNewPost] = useState(false);

  const search = (txt) => {
    let temp = news.filter(
      (e) => e.title.toLowerCase().indexOf(txt.toLowerCase()) >= 0
    );
    setNewsToPrint(temp);
  };
  useEffect(() => {
    setNewsToPrint(news);
  }, [news]);
  useEffect(() => {
    if (news && news.length > 0) {
      setNewsToPrint(news);
    }
  }, []);

  return (
    <>
      <View
        style={[styles.main, { backgroundColor: background.popup1 }]}
        ref={popupRef}
      >
        <View style={{ width: "100%", height: "100%" }}>
          <TouchableOpacity style={{cursor : "grabbing"}} onPressIn={() => setDrag(true)}>
          <View style={[styles.header, { borderBottomColor: color.border }]}>
            <View style={styles.headerLeft}>
              <View
                style={[
                  styles.logo_view,
                  { backgroundColor: background.newsLogo },
                ]}
              >
                <Image source={{ uri: images.news }} style={styles.logo} />
              </View>
              <Text selectable={false} style={styles.textLogo}>{t("news")}</Text>
            </View>
            <HeaderPopupButtons
            close={() => {setFlag(); dispatch(removeType("news"))}}
            minimize={() => dispatch(setTypeMinimize({name: "news", type: 1, show: false}))}
            />
          </View>
          </TouchableOpacity>
          <View style={styles.body}>
            <NewsBody
              news={newsToPrint}
              openNewNewsPost={() => setShowNewPost(true)}
              bluePopup={bluePopup}
              firstComeIn={firstComeIn}
              setFirstComeIn={() => setFirstComeIn(false)}
            />
          </View>
        </View>
      </View>
      <NewNewsPost flag={showNewPost} setFlag={() => setShowNewPost(false)} />
    </>
  );
}
const stylesR = (width, height, heightS, widthS) => {
  return StyleSheet.create({
    main: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#00000",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      shadowColor: "black",
      shadowOffset: {
        height: 0,
        width: 0.1,
      },
      shadowOpacity: 1,
      shadowRadius: 6.65,
      elevation: 3,
      height: "100%",
      borderRadius: widthS * 15,
      // ...style.popup4,
    },
    // header: {
    // 	display: "flex",
    // 	flexDirection: "row",
    // 	alignItems: "center",
    // },
    // logo_view: {
    // 	width: size * 38,
    // 	height: size * 38,
    // 	display: "flex",
    // 	alignItems: "center",
    // 	justifyContent: "center",
    // 	borderRadius: size * 30,
    // 	margin: size * 5,
    // 	marginLeft: size * 15,
    // 	marginRight: size * 25,
    // },
    // logo: {
    // 	width: size * 20,
    // 	height: size * 20,
    // 	resizeMode: "contain"
    // },
    // logo_text: {
    // 	color: "white",
    // 	fontSize: size * 15,
    // 	fontWeight: "600",
    // },
    // search_view: {
    // 	position: "absolute",
    // 	right: size * 50,
    // 	borderWidth: 1,
    // 	borderRadius: 2,
    // 	display: "flex",
    // 	flexDirection: "row",
    // 	alignItems: "center",
    // 	paddingRight: size * 8,
    // },
    // search_inp: {
    // 	padding: size * 3,
    // 	paddingLeft: size * 10,
    // 	color: "white",
    // 	fontSize: size * 12,
    // 	width: size * 160,
    // 	fontWeight: "600",
    // },
    // new_btn: {
    // 	position: "absolute",
    // 	right: size * 260,
    // 	minWidth: size * 45,
    // 	height: size * 25,
    // 	display: "flex",
    // 	alignItems: "center",
    // 	justifyContent: "center",
    // 	paddingHorizontal: size * 5,
    // 	borderRadius: 3,
    // },
    // new_btn_txt: {
    // 	color: "white",
    // 	fontWeight: "700",
    // },
    // x_btn: {
    // 	position: "absolute",
    // 	right: size * 15,
    // },
    // x_text: {
    // 	color: "white",
    // 	fontSize: size * 10,
    // 	fontWeight: "300",
    // },
    header: {
      paddingLeft: widthS * 16,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: heightS * 4,
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: widthS * 13,
      height: widthS * 13,
      resizeMode: "contain",
    },
    textLogo: {
      color: "white",
      fontWeight: "600",
      fontSize: widthS * 15,
      paddingLeft: widthS * 10,
      fontFamily: "Catamaran_700Bold",
    },
    x: {
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 14,
    },
    body: {
      flex: 1,
    },
  });

}