import React, { useState } from "react";
import {
	StyleSheet,
	View,
	Text,
	TextInput,
	Pressable,
	Linking,
} from "react-native";
import { connect, useDispatch, useSelector } from "react-redux";
import { setLogin } from "../../redux/actions";
import { URL_PATH, REQUEST_PASSWORD } from "@env";
import { _TOKEN_, _PASSWORD_, _EMAIL_, _DOMAIN_ } from "../../config.inc";
import i18n, { t } from 'i18n-js';
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import AnimationLoading from "../modules/AnimationLoading";

export default function ForgotPassword({ load, setLoad }) {

	const { width, height } = UseResponsiveSize();
	const { heightS, widthS } = UseResponsiveScreen();
	const color = useSelector(state => state.style.colorClient);
	const background = useSelector(state => state.style.backgroundClient);
	const styles = stylesR(color, background, heightS, widthS);

	const dispatch = useDispatch();

	const [email, setEmail] = useState();
	const [err, setErr] = useState();

	const requestToken = () => {
		const url = `${URL_PATH}${REQUEST_PASSWORD}`;
		const body = new FormData();
		let domian_name = "";
		setLoad(true);
		Linking.getInitialURL().then(domainUrl => {
			domian_name = domainUrl
			body.append(_EMAIL_, email);
			body.append(_DOMAIN_, domian_name);
			try {
				fetch(url, { method: "POST", body: body })
					.then((res) => res.text())
					.then((res) => {
						setLoad(false);
						res = JSON.parse(res);
						if (res.reset === "true") {
							alert(t("forgot_password_msg"))
							location.reload();
						} else {
							setErr(t("forgot_password_err"))
						}
					})
					.catch((err) => { setLoad(false); });
			} catch (e) { }
		});
	}
	return (
		<View style={[styles.body_main, { backgroundColor: background.popupLogin }]}>
			{load ? (
				<AnimationLoading />
			) : <>
				<View style={styles.body_main_top}>
					<Text style={[styles.body_main_textA, { color: color.text }]}>{i18n.t("forgot")}</Text>
					<Text style={[styles.body_main_textB, { color: color.text }]}>
						{i18n.t("welcome_forgot")}
					</Text>
				</View>
				<View style={[styles.body_main_bottom, { marginTop: !err ? heightS * 17.5 : 0 }]}>
					{err && <Text style={styles.err}>{err}</Text>}
					<View>
						<TextInput
							placeholder={i18n.t("email")}
							placeholderTextColor={color.loginPlaceholder}
							style={[styles.inputs, { backgroundColor: background.loginInput, color: color.text }]}
							textContentType="emailAddress"
							onChangeText={(e) => setEmail(e)}
						/>
						<Pressable
							onPress={() => requestToken()}
							style={[
								{
									backgroundColor: email && email.length > 0 ? background.buttonLoginHover : "",
									borderColor: color.text2,
									borderWidth: !email || email && email.length === 0 ? widthS * 1.5 : 0
								},
								styles.btn,
							]}
						>
							{
								<Text
									style={[
										{ color: color.text2 },
										styles.btn_text,
									]}
								>
									{i18n.t("submit")}
								</Text>
							}
						</Pressable>
					</View>
					<Pressable onPress={() => dispatch(setLogin())}>
						{({ pressed, hovered, focused }) => (
							<Text
								style={[
									{ textDecorationLine: hovered ? "underline" : "none" },
									styles.text_forgot,
								]}
							>
								{i18n.t("cancel")}
							</Text>
						)}
					</Pressable>
				</View>
			</>}
		</View>
	);
}

const stylesR = (color, background, heightS, widthS) => {
	return StyleSheet.create({
		body_main: {
			display: "flex",
			flexDirection: "column",
			width: widthS * 420,
			height: heightS * 300,
			borderRadius: widthS > 1600 ? 34 : 25,
		},
		body_main_top: {
			marginTop: heightS * 24,
			flex: 0.3
		},
		body_main_textA: {
			fontSize: widthS * 18,
			fontWeight: "normal",
			textAlign: "center",
			fontFamily: "Catamaran_400Regular",
		},
		body_main_textB: {
			marginTop: heightS * 6,
			fontWeight: "300",
			lineHeight: heightS * 12,
			fontSize: widthS * 10,
			textAlign: "center",
			fontFamily: "Catamaran_400Regular",
		},
		body_main_bottom: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			justifyContent: "space-between",
			flex: 1,
		},
		inputs: {
			width: widthS * 220,
			height: heightS * 28,
			borderRadius: widthS * 7.3,
			fontSize: widthS * 9.5,
			fontWeight: "300",
			outlineStyle: "none",
			fontFamily: "Catamaran_400Regular",
			paddingLeft: widthS * 15
		},
		btn: {
			padding: widthS * 4,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginVertical: heightS * 10.2,
			width: widthS * 220,
			height: heightS * 28,
			borderRadius: widthS * 7.3,
		},
		btn_text: {
			fontSize: widthS * 11,
			fontWeight: "normal",
			fontFamily: "Catamaran_400Regular",
		},
		text_forgot: {
			marginBottom: heightS * 31,
			alignSelf: "flex-end",
			color: color.text2,
			fontSize: widthS * 11.2,
			fontWeight: "normal",
			textDecorationStyle: "solid",
			textDecorationColor: "#a69ba6",
			textDecorationLine: "underline",
			fontFamily: "Catamaran_400Regular",
		},
		err: {
			fontWeight: "300",
			fontSize: widthS * 10,
			textAlign: "center",
			color: "#d2c935",
			paddingHorizontal: "2vw",
			fontFamily: "Catamaran_400Regular",
		},
	});
}
