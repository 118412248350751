import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

export default function CheckBox({ colorFill, rounded, checked, background, isDone, big }) {
    return (
        <View style={styles.container(isDone ? `${background}29` : background, big)}>
            <View style={styles.main(checked, colorFill, big, rounded)} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: (background, big) => ({
        width: big ? "1.1vw" : "0.9vw",
        height: big ? "1.1vw" : "0.9vw",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "0.2vw",
        backgroundColor: background
    }),
    main: (flag, colorFill, big, rounded) => ({
        width: big ? "0.7vw" : "0.5vw",
        height: big ? "0.7vw" : "0.5vw",
        borderRadius: rounded ? "100vw" : "0.1vw",
        backgroundColor: flag ? colorFill : "",
        transition: "backgroundColor .5s"
    })
})