import { StyleSheet, Text, View, Pressable, TextInput, ScrollView, Dimensions } from 'react-native'
import React, { useMemo } from 'react';
import { useState } from 'react';
import UseResponsiveSize from './UseResponsiveSize';
import UseResponsiveScreen from './UseResponsiveScreen';

export default function TimePicker({ colorText, value, colorTextCurrent, onChange, background, borderColor }) {
    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const [showTypeTime, setShowtypeTime] = useState(false);
    const [type, setType] = useState("24");
    const [time, setTime] = useState({
        type: value ? value.slice(value.length - 2) : "00",
        hour: value ? value.slice(0, value.indexOf(":")) : "00",
        minutes: value ? value.slice(value.indexOf(":") + 1, value.indexOf(":") + 3) : "00",
    });

    const typeTypes = ["24", "AM", "PM"];
    const hours = [...new Array(24)].map((e, i) => `${i}`.padStart(2, "0"));
    const min = [...new Array(60)].map((e, i) => `${i}`.padStart(2, "0"));

    useMemo(() => {
        if (time.type === "AM" || time.type === "PM") {
            onChange(`${time.hour}:${time.minutes} ${time.type}`)
        } else {
            onChange(`${time.hour}:${time.minutes}`)
        }
    }, [time]);
    return (
        <View style={styles.container}>
            <View style={[styles.timeView, { borderBottomColor: borderColor }]}>
                <Text style={[styles.time, { color: colorText }]}>{time.hour.length === 1 ? `0${time.hour}` : time.hour}:{time.minutes}{time.type === "AM" || time.type === "PM" ? ` ${time.type}` : null}</Text>
            </View>
            <View style={styles.child}>
                <ScrollView showsVerticalScrollIndicator={false} style={styles.scroll} contentContainerStyle={styles.main}>
                    {hours.map((timeItem, i) => {
                        if (i < 12 && type !== "24") {
                            return (
                                <Pressable key={i} style={time.hour === timeItem && [styles.btn, { backgroundColor: background }]} onPress={() => setTime({ ...time, hour: timeItem })}>
                                    <Text style={[styles.text, { color: colorText }]}>{timeItem}</Text>
                                </Pressable>
                            )
                        } else if (type === "24") {
                            return (
                                <Pressable key={i} style={time.hour === timeItem && [styles.btn, { backgroundColor: background }]} onPress={() => setTime({ ...time, hour: timeItem })}>
                                    <Text style={[styles.text, { color: colorText }]}>{timeItem}</Text>
                                </Pressable>
                            )
                        }
                    })}
                </ScrollView>
                <ScrollView showsVerticalScrollIndicator={false} style={styles.scroll} contentContainerStyle={styles.main}>
                    {min.map((minuteItem, i) => {
                        return (
                            <Pressable key={i} style={time.minutes === minuteItem && [styles.btn, { backgroundColor: background }]} onPress={() => setTime({ ...time, minutes: minuteItem })}>
                                <Text style={[styles.text, { color: colorText }]}>{minuteItem}</Text>
                            </Pressable>
                        )
                    })}
                </ScrollView>
                <ScrollView showsVerticalScrollIndicator={false} style={styles.scroll} contentContainerStyle={styles.main}>
                    {typeTypes.map((typeItem, i) => {
                        return (
                            <Pressable key={i} style={time.type === typeItem && [styles.btn, { backgroundColor: background }]} onPress={() => {
                                if (typeItem !== "24" && Number(time.hour) > 12) {
                                    setTime({ ...time, type: typeItem, hour: "00" }); setType(typeItem);
                                } else {
                                    setTime({ ...time, type: typeItem }); setType(typeItem);
                                }
                            }}>
                                <Text style={[styles.text, { color: colorText }]}>{typeItem}</Text>
                            </Pressable>
                        )
                    })}
                </ScrollView>
            </View>
        </View>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            paddingBottom: heightS * 5
        },
        child: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "70%"
    
        },
        scroll: {
            height: heightS * 100,
            width: widthS * 30,
        },
        main: {
            display: "flex",
            alignItems: "center",
        },
        btn: {
            paddingHorizontal: widthS * 5,
            paddingVertical: heightS * 1,
            borderRadius: widthS * 3,
            shadowColor: "#000000",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.7,
            shadowRadius: 4
        },
        timeView: {
            marginBottom: heightS * 5,
            borderBottomWidth: 1,
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: widthS * 10,
            height: heightS * 32,
            paddingTop: heightS * 5
        },
        time: {
            fontSize: widthS * 12,
            fontWeight: "300",
            textAlign: "center",
        },
        text: {
            fontSize: widthS * 9,
            fontWeight: "300"
        }
    });
}