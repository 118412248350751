import { StyleSheet, Text, View, Dimensions, TextInput } from 'react-native';
import React from 'react';
import { useSelector } from 'react-redux';
import { t } from "i18n-js";
import UseResponsiveSize from '../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../modules/UseResponsiveScreen';
import { useContext } from 'react';
import { GameInfoContext } from '../../../../ContextData/GameInfo';

export default function BackgroundStoryBox({ infoObj, setInfoObj, permission, setIsEdit }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const color = useSelector((state) => state.style.color);
    const background = useSelector((state) => state.style.background);
    const images = useSelector((state) => state.style.images);

    return (
        <View style={styles.container}>
            <Text style={[styles.headers, { color: color.infoHeaderNavText }]}>{t("background-story")}</Text>
            <View style={[styles.backgroundStoryBox, { backgroundColor: background.infoBox }]}>
                <TextInput
                    multiline={true}
                    numberOfLines={10}
                    editable={permission}
                    // maxLength={500} 
                    style={[styles.input, { backgroundColor: background.infoBody, color: permission ? color.buildText : color.placeholder }]}
                    value={infoObj.story ? infoObj.story : ""}
                    onChangeText={(text) => {
                        if (infoObj.story !== text) {
                            setIsEdit(true);
                            setInfoObj({ ...infoObj, story: text })
                        }
                    }}
                    placeholder={t("enter_free_text")}
                />
            </View>
        </View>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            height: "100%",
            width: "53%",
            zIndex: 2,
        },
        backgroundStoryBox: {
            flex: 1,
            borderRadius: widthS * 23,
            padding: widthS * 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        input: {
            borderRadius: widthS * 10,
            paddingHorizontal: widthS * 10,
            paddingVertical: heightS * 7,
            fontSize: widthS * 9,
            fontFamily: "Catamaran_500Medium",
            width: "100%",
            height: "100%",
            marginVertical: heightS * 3,
            outlineStyle: "none"
        },
        headers: {
            fontSize: widthS * 12,
            marginLeft: widthS * 20,
            marginBottom: heightS * 3,
            fontFamily: "Catamaran_500Medium"
        },
    });
};