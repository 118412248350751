import { StyleSheet, Text, View } from 'react-native';
import React, { Profiler, useMemo } from 'react'
import OptionsListBtn from '../../../Elements/OptionsListBtn';
import { useSelector } from 'react-redux';
import RawDataTable from './RawDataTable';
import { useRawDataContext } from '../../../../ContextData/RawData';
import ExportButton from './ExportButton';

const onRenderCallback = (
    id, // שם הקומפוננטה או הקבוצה
    phase, // "mount" או "update"
    actualDuration, // זמן רינדור בפועל ב-ms
    baseDuration, // הזמן האידיאלי הנדרש לרינדור
    startTime, // הזמן שבו התחיל הרינדור
    commitTime, // הזמן שבו בוצעה ההתחייבות (commit)
    interactions // אינטראקציות נלוות (אופציונלי)
) => {
    const totalRenderTime = commitTime - startTime; // זמן כולל של רינדור
    console.log({
        id,
        phase,
        actualDuration,
        baseDuration,
        startTime,
        commitTime,
        totalRenderTime, // הוספת הזמן הכולל
        interactions
    });
};


const RawData = () => {
    const images = useSelector((state) => state.style.images);
    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = stylesR(background);

    const { searchInData, dataPrint } = useRawDataContext();

    return (
        // <Profiler id="RawData" onRender={onRenderCallback}>
        <View style={{ gap: "0.5vw", backgroundColor: background.infoBody, flex: 1, direction: direction }}>
            <View style={styles.header}>
                <ExportButton data={dataPrint} fileName="ExportedData" />
            </View>
            <OptionsListBtn
                saveBtnShow={""}
                keyHeaderText={"rawData"}
                upperCase={true}
                dark={true}
                showSearchOpen
                onChangeText={(txt) => searchInData(txt)}
            />
            <RawDataTable />
        </View>
        // </Profiler>
    )
}

export default RawData;

const stylesR = (background) => StyleSheet.create({
    header: {
        flexDirection: "row",
        alignItems: "center",
        minHeight: "4vw",
        maxHeight: "4vw",
        justifyContent: "space-between",
        paddingLeft: "5vw",
        paddingRight: "2vw",
        backgroundColor: background.infoHeader,
        flex: 1,
    }
})