import { StyleSheet, Text, View, Pressable, Image, TextInput, ScrollView } from 'react-native'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import UseResponsiveSize from '../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../modules/UseResponsiveScreen';
import { t } from 'i18n-js';
import { AntDesign } from '@expo/vector-icons';
import { useState } from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import { useClickOutside } from '../../../modules/useClickOutside';
import ActionFilter from './filter/ActionFilter';
import EventFilter from './filter/EventFilter';
import PlayerFilter from './filter/PlayerFilter';
import { analyticsActions, analyticsEvents, analyticsPlayers } from '../../../../redux/actions/creatorActions';
import { useEffect } from 'react';

export default function AnalyticsFilter() {

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const actionsPopupRef = useRef(null);
  const playersPopupRef = useRef(null);
  const eventsPopupRef = useRef(null);

  const dispatch = useDispatch();

  const colors = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);
  const backgrounds = useSelector((state) => state.style.background);
  const logo = useSelector(state => state.style.logo);

  const playersState = useSelector(state => state.gameInfoArr.gameInfoArr);
  const eventsState = useSelector((state) => state.events.allEvents);
  const actionsState = [
    "Incoming Items", "Chat Messages", "Sent Items", "Decisions"
  ]

  const filterPlayers = (players) => {
    return players?.filter((e) => e.permission_for_game !== "17" && e.permission_for_game !== "10" && e.permission_for_game !== "1")
  }

  const playersOptions = useMemo(() => filterPlayers(playersState?.players), [playersState]);
  const eventsOptions = useMemo(() => eventsState?.filter((e) => e.name?.length > 0), [eventsState]);
  const actionsOption = useMemo(() => actionsState, [actionsState]);

  const [playersSearch, setPlayersSearch] = useState([]);
  const [eventsSearch, setEventsSearch] = useState([]);
  const [actionsSearch, setActionsSearch] = useState([]);

  const [showActions, setShowActions] = useState(true);
  const [showPlayers, setShowPlayers] = useState(true);
  const [showEvents, setShowEvents] = useState(true);

  const [showActionsList, setShowActionsList] = useState(false);
  const [showPlayersList, setShowPlayersList] = useState(false);
  const [showEventsList, setShowEventsList] = useState(false);

  const [playersSelect, setPlayersSelect] = useState("");
  const [eventsSelect, setEventsSelect] = useState("");
  const [actionsSelect, setActionsSelect] = useState("");

  const [logoState, setLogoState] = useState(logo);

  let options = [
    { name: t("incoming_items"), value: "Incoming Items" },
    { name: t("chat_messages"), value: "Chat Messages" },
    { name: t("sent_items"), value: "Sent Items" },
    { name: t("decision"), value: "Decisions" }
  ]

  const createString = async (arr, type) => {
    return [arr.map((e) => type ? e : e?.id)].join(",")
  }

  const reset = (type) => {
    switch (type) {
      case "action":
        return setActionsSelect("");
      case "player":
        return setPlayersSelect("");
      case "event":
        return setEventsSelect("");
    }
  }

  const removeItem = (id, type) => {
    let temp;
    switch (type) {
      case "action":
        temp = actionsSelect;
        temp = temp.replace(`${id},`, "").replace(`${id}`, "");
        return setActionsSelect(temp);
      case "player":
        temp = playersSelect;
        temp = temp.replace(`${id},`, "").replace(`${id}`, "");
        return setPlayersSelect(temp);
      case "event":
        temp = eventsSelect;
        temp = temp.replace(`${id},`, "").replace(`${id}`, "");
        return setEventsSelect(temp);
    }
  }

  const searchItem = (txt, type) => {
    let temp = [];
    switch (type) {
      case "action":
        temp = [...actionsState];
        temp = temp.filter((e) => e.toLowerCase().indexOf(txt.toLowerCase()) === 0);
        return setActionsSearch([...temp]);
      case "player":
        temp = [...filterPlayers(playersState?.players)];
        temp = temp.filter((e) => e.name_for_game?.toLowerCase().indexOf(txt?.toLowerCase()) === 0);
        return setPlayersSearch([...temp]);
      case "event":
        temp = [...eventsState?.filter((e) => e.name?.length > 0)];
        temp = temp.filter((e) => e.name?.toLowerCase().indexOf(txt?.toLowerCase()) === 0);
        return setEventsSearch([...temp]);
    }
  }

  const addItem = (id, type) => {
    let temp;
    let players;
    switch (type) {
      case "action":
        temp = actionsSelect;
        if (temp.indexOf(id) >= 0) {
          temp = temp.split(",").filter((e) => e !== id).join(",");
        } else {
          temp = temp.split(",");
          temp.push(id);
          temp = temp.join(",");
        }
        return setActionsSelect(temp);
      case "player":
        if (id === "rep") {
          players = filterPlayers(playersState?.players).filter((e) => e.is_represented === "1").map((e) => e.id).join(",");
          return setPlayersSelect(players);
        } else if (id === "all") {
          players = filterPlayers(playersState?.players).map((e) => e.id).join(",");
          return setPlayersSelect(players);
        } else if (id === "real") {
          players = filterPlayers(playersState?.players).filter((e) => e.is_represented === "0").map((e) => e.id).join(",");
          return setPlayersSelect(players);
        } else {
          temp = playersSelect;
          if (temp.indexOf(id) >= 0) {
            temp = temp.split(",").filter((e) => e !== id).join(",");
          } else {
            temp = temp.split(",");
            temp.push(id);
            temp = temp.join(",");
          }
          return setPlayersSelect(temp);
        }
      case "event":
        temp = eventsSelect;
        if (temp.indexOf(id) >= 0) {
          temp = temp.split(",").filter((e) => e !== id).join(",");
        } else {
          temp = temp.split(",");
          temp.push(id);
          temp = temp.join(",");
        }
        return setEventsSelect(temp);
    }
  }

  useEffect(() => {
    setPlayersSearch(filterPlayers(playersState?.players));
    if (playersState?.players?.length > 0) {
      createString(filterPlayers(playersState?.players)
      ).then((res) => {
        setPlayersSelect(res);
        dispatch(analyticsPlayers(res));
      });
    }
  }, [playersState]);

  useEffect(() => {
    setEventsSearch(eventsState?.filter((e) => e.name?.length > 0));
    createString(eventsState?.filter((e) => e.name?.length > 0)).then((res) => {
      setEventsSelect(res);
      dispatch(analyticsEvents(res));
    });
  }, [eventsState]);

  useEffect(() => {
    setActionsSearch(actionsState);
    createString(actionsState, true).then((res) => {
      setActionsSelect(res);
      dispatch(analyticsActions(res));
    });
  }, []);

  useEffect(() => {
    dispatch(analyticsActions(actionsSelect));
  }, [actionsSelect]);

  useEffect(() => {
    dispatch(analyticsPlayers(playersSelect));
  }, [playersSelect]);

  useEffect(() => {
    dispatch(analyticsEvents(eventsSelect));
  }, [eventsSelect]);

  useEffect(() => {
    setLogoState(logo);
  }, [logo]);

  useClickOutside(actionsPopupRef, showActionsList, () => setShowActionsList(false));
  useClickOutside(playersPopupRef, showPlayersList, () => setShowPlayersList(false));
  useClickOutside(eventsPopupRef, showEventsList, () => setShowEventsList(false));

  return (
    <View style={[styles.container, { borderRightColor: colors.infoBorder, borderLeftColor: colors.infoBorder }]}>
      <View>
        <Pressable style={[styles.logoContainer, { backgroundColor: colors.logoColor }]}>
          {logoState ? <Image style={styles.logo} source={{ uri: logoState }} /> : null}
        </Pressable>
      </View>
      <View style={{ width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", marginTop: heightS * 25, paddingLeft: widthS * 10, borderBottomColor: colors.border, borderBottomWidth: widthS * 1 }}>
        <Image source={{ uri: images.filterIcon }} style={{ width: widthS * 10, height: widthS * 10 }} />
        <Text style={{ color: colors.buildText, fontSize: widthS * 10, fontFamily: "Catamaran_400Regular", marginLeft: widthS * 5 }}>{t("filter").toUpperCase()}</Text>
      </View>
      <ScrollView showsVerticalScrollIndicator={false} style={styles.filters}>
        {/* actions-filter */}
        <ActionFilter
          flagFilter={showActions}
          setFlagFilter={setShowActions}
          flagSelect={showActionsList}
          setFlagSelect={setShowActionsList}
          arr={actionsOption}// state
          arrSearch={actionsSearch}// search state
          arrSelect={actionsSelect}// select state
          stateArr={actionsState}// redux state 
          options={options}
          addItem={addItem}
          removeItem={removeItem}
          searchItem={searchItem}
          reset={reset}
          refPopup={actionsPopupRef}
        />
        {/* players-filter */}
        <PlayerFilter
          flagFilter={showPlayers}
          setFlagFilter={setShowPlayers}
          flagSelect={showPlayersList}
          setFlagSelect={setShowPlayersList}
          arr={playersOptions}
          arrSearch={playersSearch}
          arrSelect={playersSelect}
          stateArr={playersState}
          addItem={addItem}
          removeItem={removeItem}
          searchItem={searchItem}
          reset={reset}
          refPopup={playersPopupRef}
          players={playersOptions}
        />
        {/* events-filter */}
        <EventFilter
          flagFilter={showEvents}
          setFlagFilter={setShowEvents}
          flagSelect={showEventsList}
          setFlagSelect={setShowEventsList}
          arr={eventsOptions}
          arrSearch={eventsSearch}
          arrSelect={eventsSelect}
          stateArr={eventsState?.filter((e) => e.name?.length > 0)}
          addItem={addItem}
          removeItem={removeItem}
          searchItem={searchItem}
          reset={reset}
          refPopup={eventsPopupRef}
        />
      </ScrollView>
    </View>
  )
}

const stylesR = (width, height, heightS, widthS) => {
  return StyleSheet.create({
    container: {
      alignItems: "center",
      borderRightWidth: widthS * 1,
      borderLeftWidth: widthS * 1,
      flex: 1,
    },
    logoContainer: {
      borderBottomLeftRadius: widthS * 15,
      borderBottomRightRadius: widthS * 15,
      paddingVertical: heightS * 5,
      paddingHorizontal: widthS * 14,
    },
    logo: {
      width: widthS * 65,
      height: widthS * 40,
      resizeMode: "contain",
    },
    filters: {
      width: "100%"
    },
  })
}