import React, { useEffect, useRef, useState } from "react";
import {
  SafeAreaView,
  ScrollView,
  Text,
  StyleSheet,
  View,
  Animated,
  Pressable,
} from "react-native";
import { useSelector } from "react-redux";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import UseResponsiveSize from "../modules/UseResponsiveSize";

export default function Carrousel({
  dataArr,
  index,
  setIndex,
  renderItem,
  widthP,
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const scrollRef = useRef(null);
  const scrollX = useRef(new Animated.Value(0)).current;

  const color = useSelector((state) => state.style.color);

  const [data, setData] = useState([]);

  const nextItem = (i) => {
    if ((i || i === 0) && i < data.length) {
      setIndex(i);
      scrollRef.current.scrollTo({
        y: 0,
        x: widthS * widthP * (i + 1),
        animated: true,
      });
    } else if (index < data.length - 1) {
      setIndex(index + 1);
      scrollRef?.current?.scrollTo({
        y: 0,
        x: widthS * widthP * (index + 1),
        animated: true,
      });
    } else {
      setIndex(0);
      scrollRef?.current?.scrollTo(0, 0);
    }
  };

  const nextTenItems = (index) => {
    if (data.length % 10 > 0) {
      if (
        index % 10 > 0 &&
        Math.floor(index / 10) === Math.floor(data.length / 10)
      ) {
        setIndex((index % 10) - 1);
      } else if (
        (index % 10 === 0 &&
          Math.floor(data.length / 10) - index / 10 === 0) ||
        data.length - index <= 9
      ) {
        setIndex(data.length - 1);
      } else setIndex(index + 9);
    } else {
      if (index === data.length) {
        setIndex(9);
      } else if (
        index % 10 > 0 &&
        Math.ceil(index / 10) === Math.floor(data.length / 10)
      ) {
        setIndex((index % 10) - 1);
      } else if (data.length - index === 10) {
        setIndex(data.length - 1);
      } else setIndex(index + 9);
    }
    return scrollRef.current.scrollTo(0, 0);
  };

  const previousIndex = (i) => {
    if (i > 0) {
      setIndex(i - 1);
      scrollRef.current.scrollTo({
        y: 0,
        x: widthS * widthP * (i - 1),
        animated: true,
      });
    } else {
      setIndex(data.length - 1);
      scrollRef.current.scrollTo({
        y: 0,
        x: widthS * widthP * (data.length - 1),
        animated: true,
      });
    }
  };

  const previousTenItems = (index) => {
    if (index > 9) {
      setIndex(index - 10);
      scrollRef.current.scrollTo({
        y: 0,
        x: widthS * widthP * (index + 10),
        animated: true,
      });
    } else {
      setIndex(
        index > (data.length - 1) % 10
          ? data.length - 1
          : Math.floor((data.length - 1) / 10) * 10 + index
      );
      scrollRef.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const setMyInterval = setInterval(() => nextItem(index + 1), 5000);
    return () => {
      clearInterval(setMyInterval);
    };
  }, [index, data]);

  useEffect(() => {
    setData(dataArr);
    setIndex(0);
  }, [dataArr]);

  return (
    data.length > 0 && (
      <SafeAreaView style={styles.container}>
        <View style={styles.scrollContainer}>
          <ScrollView
            ref={scrollRef}
            horizontal={true}
            // pagingEnabled
            style={{ width: widthS * 193 }}
            showsHorizontalScrollIndicator={false}
            onScroll={Animated.event(
              [
                {
                  nativeEvent: {
                    contentOffset: {
                      x: scrollX,
                    },
                  },
                },
              ],
              { useNativeDriver: true }
            )}
            scrollEventThrottle={1}
          >
            {data.map((image, imageIndex) => {
              return <View key={imageIndex}>{renderItem()}</View>;
            })}
          </ScrollView>
          <View style={styles.indicatorContainer}>
            {data.length > 10 && (
              <Pressable onPress={() => previousTenItems(index)}>
                <Text
                  style={[
                    styles.arrows,
                    styles.doubleArrows,
                    { color: color.x },
                  ]}
                >{`<<`}</Text>
              </Pressable>
            )}
            <Pressable onPress={() => previousIndex(index)}>
              <Text style={[styles.arrows, { color: color.x }]}>{`<`}</Text>
            </Pressable>
            {data
              .slice(
                Math.floor(index / 10) * 10,
                Math.floor(index / 10) * 10 + 10
              )
              .map((image, imageIndex) => {
                return (
                  <View key={imageIndex}>
                    <Pressable
                      onPress={() => nextItem(image.index)}
                      style={[
                        styles.normalDot,
                        {
                          backgroundColor:
                            image.index === index
                              ? color.carouselDotActive
                              : color.x,
                        },
                      ]}
                    />
                  </View>
                );
              })}
            <Pressable
              onPress={() => nextItem(index === data.length ? 0 : index + 1)}
            >
              <Text style={[styles.arrows, { color: color.x }]}>{`>`}</Text>
            </Pressable>
            {data.length > 10 && (
              <Pressable
                onPress={() =>
                  nextTenItems(index === data.length ? 0 : index + 1)
                }
              >
                <Text
                  style={[
                    styles.arrows,
                    styles.doubleArrows,
                    { color: color.x },
                  ]}
                >{`>>`}</Text>
              </Pressable>
            )}
            <Text style={styles.count}>{`${index + 1}/${data.length}`}</Text>
          </View>
        </View>
      </SafeAreaView>
    )
  );
}

const stylesR = (width, height, heightS, widthS) => {
  return StyleSheet.create({
    scrollContainer: {
      marginVertical: heightS * 6,
    },
    normalDot: {
      height: heightS * 4.5,
      width: widthS * 4.5,
      borderRadius: widthS * 40,
      marginHorizontal: widthS * 2,
    },
    indicatorContainer: {
      flexDirection: "row",
      width: widthS * 193,
      alignItems: "center",
      justifyContent: "center",
    },
    arrows: {
      alignSelf: "center",
      marginTop: heightS * -2,
      marginHorizontal: widthS * 1,
      fontFamily: "Catamaran_600SemiBold",
      fontSize: widthS * 10,
    },
    doubleArrows: {
      paddingHorizontal: widthS * 5,
    },
    count: {
      position: "absolute",
      right: widthS * 5,
      color: "#949494e6",
      fontFamily: "Catamaran_600SemiBold",
      fontSize: widthS * 8,
    },
  });
};
