// names of the sessionStorage\\
export const F_PHPSESSID = "F_PHPSESSID";
export const AUTH = "auth";
export const FACTOR = "factor";
export const USER_FIRST_NAME = "user_first_name";
export const USER_LAST_NAME = "user_last_name";
export const USER_GAME_NAME = "user_game_name";
export const GAME_ID = "game_id";
export const USER_ID_IN_GAME = "id_in_game";
export const USER_ID_IN_GAME_OBSERVER = "id_in_game_observer";
export const USER = "user";
export const CREATE_GAME_PERMISSION = "create_game_permission";

// names of url variables\\

export const _F_PHPSESSID_ = "PHPSESSID";

// login url variables
export const _EMAIL_ = "email";
export const _PASSWORD_ = "password";
export const _NEW_PASSWORD_ = "new_password";
export const _TOKEN_ = "token";
export const _DOMAIN_ = "domain";
export const _ID_ = "id";
export const _TEXT_NEW_GOALS = "game_goal";
export const _FILE0_ = "file0";
export const _VIDEO_ = "vid";
export const _NAME_ = "name";
export const _SUBJECT_ = "subject";
export const _CREATOR_ID_ = "creator_id";
export const _TO_LIST_ = "to_list";
export const _CC_LIST_ = "cc_list";
export const _BCC_LIST_ = "bcc_list";
export const _MESSAGE_PARENT_ID_ = "message_parent_id";
export const _MESSAGE_BODY_ = "message_body";
export const _MESSAGE_ID_ = "message_id";
export const _AUTH_CODE_ = "auth_code";
export const _FILE_COUNT_ = "file_count";
export const _FLOW_SUBJECT_ = "flow_subject";
export const _FLOW_TITLE_ = "flow_title";
export const _FROM_ID_ = "from_id";
export const _PHASE_NUMBER_ = "phase_number";
export const _PHASE_LOCATION_ = "phase_location";
export const _PHASE_MAX_ = "phase_max";
export const _IS_DELETE_ = "is_delete";
export const _EVENT_ID_ = "event_id";
export const _ACTIVE_ = "active";
export const _EVENT_ = "event";
export const _FLOW_TYPE_ = "flow_type";
export const _IS_KPI_ = "is_kpi";
export const _IS_DEMO_ = "is_demo";
export const _PLATFORME_TYPE_ = "platform_type";
export const _FLOW_BODY_ = "message_body";
export const _FLOW_FILE_ = "file0";
export const _SCHEDUALE_START_TIME_ = "schedule_start_time";
export const _LAST_ID_ = "last_id";
export const _SENDER_ID_ = "sender_id";
export const _USER_ID_ = "user_id";
export const _GROUP_ID_ = "group_id";
export const _CHAT_MESSAGE_ = "chat_message";
export const _CHAT_GROUP_ACTION_ = "action";
export const _GROUP_NAME_ = "group_name";
export const _GROUP_MEMBERS_ = "group_members";
export const _CURRENT_USER_ID_ = "current_user_id";
export const _GROUPS_COUNT_ = "groups_count"
export const _REPRESENTED_ = "represented"
export const _SHOW_IN_CHAT_ = "show_in_chat"
export const _PERMISSION_FOR_GAME_ = "permission_for_game"
export const _NAME_FOR_GAME_ = "name_for_game"
export const _PLAYER_DESC_ = "player_desc"
export const _OBSERVER_NAME_ = "observer_name"

// links
export const LINKEDIN = "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQH2koFAckbJ9AAAAX87GFIgbJnhqHba6ddC8oc9QXE_TrdJkQlHIb-zX1WaKkKPhZLaZ78zP3FSRVDaQ2km3NCSPrxX-L0ILZXnOyJGDTYaN97O_UD8xwexFd8C2xrzmTDR5I0=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fbest-be-strategic%2F";
export const FACEBOOK = "https://www.facebook.com/bestrategicsolutions/";
export const TWITTER = "https://twitter.com/BeST_Wargaming";

// Minimum and maximum characters for password
export const min_password_length = 8;
export const max_password_length = 30;
export const ignore_characters = /[.,;:'"?=/]/g;