import React, { useState, useEffect, useContext, useRef } from "react";
import {
	ScrollView,
	StyleSheet,
	View,
	Text,
	Modal,
	Pressable,
} from "react-native";
import NewsPost from "./NewsPost";
import { t } from "i18n-js";
import { NewsContext } from "../../../ContextData/News";
import { useDispatch, useSelector } from "react-redux";
import { firstComeInNews, loading, markNews } from "../../../redux/actions";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";

export default function NewsBody({ news, openNewNewsPost, bluePopup, firstComeIn, setFirstComeIn }) {

	const { width, height } = UseResponsiveSize();
	const { widthS, heightS } = UseResponsiveScreen();
	const styles = stylesR(width, height, heightS, widthS);

	const scroll = useRef(null);
	const dispatch = useDispatch();
	const { markNewsAsRead } = useContext(NewsContext);

	const background = useSelector(state => state.style.background);

	const [indexPost, setIndexPost] = useState();

	// const mapNews = () => {
	// 	let read = [];
	// 	let unread = [];
	// 	news.forEach((e) => {
	// 		if(e.is_read === "0" || !e.is_read){
	// 			unread.push(e);
	// 		}else{
	// 			read.push(e);
	// 		}
	// 	});
	// 	setNewsRead([...read]);
	// 	setNewsUnread([...unread]);
	// }

	const markAsReadNewsPost = (id) => {
		dispatch(markNews(id));
		dispatch(loading(true));
		markNewsAsRead(id)
			.then(() => dispatch(loading(false)))
			.catch(() => dispatch(loading(false)));
	}

	// useEffect(() => {
	// 	mapNews();
	// }, [news]);
	// useEffect(() => {
	// 	if(scroll.current){
	// 		scroll.current.scrollTo({x: 0, y: 0, animated: true});
	// 	}
	// }, [firstComeIn]);
	// useEffect(() => {
	// 	mapNews();
	// }, []);
	return (
		<>
			<View style={styles.container}>
				<ScrollView
					ref={scroll}
					nativeID="news"
					style={styles.scroll_container}
					contentContainerStyle={styles.scroll_View}
				>
					{news.length > 0 &&
						news.map((e, i) => {
							if (i === indexPost) {
								return (
									<NewsPost
										key={i}
										post={e}
										index={i}
										zIndex={100}
										setIndexPost={() => setIndexPost(i)}
										openNewNewsPost={openNewNewsPost}
										markAsReadNewsPost={markAsReadNewsPost}
									/>
								);
							} else {
								return (
									<NewsPost
										key={i}
										post={e}
										index={i}
										zIndex={1}
										setIndexPost={() => setIndexPost(i)}
										openNewNewsPost={openNewNewsPost}
										markAsReadNewsPost={markAsReadNewsPost}
									/>
								);
							}
						})}
				</ScrollView>
			</View>
			<Modal transparent={true} animationType="none" visible={firstComeIn} ref={bluePopup}>
				<View style={styles.modal_c}>
					<View style={styles.modal_m}>
						<View style={[styles.triangular, { borderBottomColor: background.newsTooltip }]} />
						<View style={styles.modal_b}>
							<View style={[styles.top, { backgroundColor: background.newsTooltip }]}>
								<Text selectable={false} style={styles.modal_txt}>{t("news_a")}</Text>
							</View>
							<View style={[styles.bottom, { backgroundColor: background.newsTooltipBottom }]}>
								<Pressable onPress={() => { dispatch(firstComeInNews()); sessionStorage.setItem("tooltip", true); }} style={[styles.modal_btn, { backgroundColor: background.newsRead }]}>
									<Text selectable={false} style={styles.modal_btn_txt}>{t("ok")}</Text>
								</Pressable>
							</View>
						</View>
					</View>
				</View>
			</Modal>
		</>
	);
}
const stylesR = (width, height, heightS, widthS) => {
	return StyleSheet.create({
		container: {
			display: "flex",
			flexDirection: "row",
			flex: 1,
		},
		scroll_container: {
			width: "100%",
			// maxHeight: size * 398,
			paddingTop: heightS * 17,
			marginBottom: heightS * 2,
			zIndex: 1001
		},
		scroll_View: {
			// minHeight: size * 399,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			zIndex: 1001
		},
		modal_c: {
			display: "flex",
			alignItems: "center",
			paddingTop: heightS * 10,
			backgroundColor: "#00000040",
			height: "100%",
		},
		modal_m: {
			position: "absolute",
			top: heightS * 305,
			right: widthS * 355,
			width: widthS * 130,
			minHeight: heightS * 100,
		},
		triangular: {
			width: 0,
			height: 0,
			backgroundColor: "transparent",
			borderStyle: "solid",
			borderLeftWidth: widthS * 12,
			borderRightWidth: widthS * 12,
			borderBottomWidth: widthS * 15,
			borderLeftColor: "transparent",
			borderRightColor: "transparent",
			position: "absolute",
			left: widthS * 50,
			zIndex: 5
		},
		modal_b: {
			minHeight: heightS * 80,
			marginTop: heightS * 8,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-between",
			zIndex: 6
		},
		top: {
			borderTopRightRadius: widthS * 11,
			borderTopLeftRadius: widthS * 11,
			flex: 1,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			paddingHorizontal: widthS * 13,
			paddingVertical: heightS * 10
		},
		modal_txt: {
			fontSize: widthS * 9,
			color: "black",
			lineHeight: heightS * 10,
			textAlign: "center",
			fontFamily: "Catamaran_400Regular",
		},
		bottom: {
			width: "100%",
			alignItems: "center",
			justifyContent: "center",
			borderBottomLeftRadius: widthS * 11,
			borderBottomRightRadius: widthS * 11
		},
		modal_btn: {
			marginVertical: heightS * 6,
			paddingHorizontal: widthS * 10.2,
			paddingTop: heightS * 2,
			paddingBottom: heightS * 3,
			display: "flex",
			alignItems: "center",
			borderRadius: widthS * 5
		},
		modal_btn_txt: {
			color: "white",
			fontFamily: "Catamaran_700Bold",
			fontSize: widthS * 8.2,
		},
		he: {
			marginRight: widthS * 15
		}
	});
}
