import { FlatList, StyleSheet, View } from 'react-native'
import React, { memo, useCallback, useMemo, useRef } from 'react'
import { useRawDataContext } from '../../../../ContextData/RawData';
import { useSelector } from 'react-redux';
import RawDataTableRow from './RawDataTableRow';
import TextCustomize from '../../../Elements/TextCustomize';
import { vwToPx } from '../../../modules/ConvertPXToVw';

// Memoized header component to prevent unnecessary re-renders
const TableHeader = memo(({ tableHead, styles }) => (
    <View style={styles.tableHeaderView}>
        {tableHead.map((e, index) => (
            <View style={styles.tableHeaderItem(e.width)} key={index}>
                <TextCustomize 
                    keyText={e.label} 
                    style={{ textAlign: "center" }} 
                    fontWeight={700} 
                    fontSize={"0.8vw"} 
                />
            </View>
        ))}
    </View>
));

export default function RawDataTable() {
    const { images, background, color, lang } = useSelector(state => ({
        images: state.style.images,
        background: state.style.background,
        color: state.style.color,
        lang: state.setting.setting.lang
    }));

    const styles = useMemo(() => 
        stylesR(background, color), 
    [background, color]);

    const { dataPrint, optionsPlatform } = useRawDataContext();

    const scrollRef = useRef(null);

    // Memoize table headers configuration
    const tableHead = useMemo(() => [
        { label: "date", value: "date", width: "10vw" },
        { label: "from", value: "from", width: "12vw" },
        { label: "to", value: "to", width: "16vw" },
        { label: "cc", value: "cc", width: "16vw" },
        { label: 'bcc', value: "bcc", width: "16vw" },
        { label: "event", value: "event", width: "11vw" },
        { label: "platform", value: "platform", width: "7vw" },
        { label: "title", value: "title", width: "18vw" },
        { label: "body", value: "body", width: "28vw" },
        { label: "attachments", value: "attachments", width: "17vw" }
    ], []);

    // Pre-calculate row height for virtualization
    const ROW_HEIGHT = useMemo(() => vwToPx(3.4), []);

    // Optimize getItemLayout with memoized row height
    const getItemLayout = useCallback((_, index) => ({
        length: ROW_HEIGHT,
        offset: ROW_HEIGHT * index,
        index,
    }), [ROW_HEIGHT]);

    // Memoize header component instance
    const headerComponent = useMemo(() => (
        <TableHeader tableHead={tableHead} styles={styles} />
    ), [tableHead, styles]);

    // Optimize render item function
    const renderItem = useCallback(({ item, index }) => {
        if (item?.type === "header") {
            return headerComponent;
        }
        
        return (
            <RawDataTableRow
                e={item}
                index={index - 1}
                scrollRef={scrollRef}
                tableHead={tableHead}
                optionsPlatform={optionsPlatform}
            />
        );
    }, [headerComponent, tableHead, optionsPlatform]);

    // Optimize key extractor
    const keyExtractor = useCallback((item, index) => 
        item?.id || `item-${index}`, 
    []);

    // Memoize list data to prevent unnecessary re-renders
    const listData = useMemo(() => 
        [{ type: "header" }, ...dataPrint], 
    [dataPrint]);

    return (
        <View style={{ flex: 1 }}>
            <View style={styles.main}>
                <FlatList
                    data={listData}
                    maxToRenderPerBatch={15}
                    windowSize={12}
                    keyboardShouldPersistTaps="handled"
                    initialNumToRender={20}
                    updateCellsBatchingPeriod={50}
                    removeClippedSubviews={true}
                    getItemLayout={getItemLayout}
                    style={styles.scroll_h_c}
                    nativeID="flow_table-horizontal"
                    showsVerticalScrollIndicator={true}
                    ref={scrollRef}
                    keyExtractor={keyExtractor}
                    stickyHeaderIndices={[0]}
                    renderItem={renderItem}
                    maintainVisibleContentPosition={{
                        minIndexForVisible: 0,
                        autoscrollToTopThreshold: 10
                    }}
                />
            </View>
        </View>
    )
}

const stylesR = (background, color) => StyleSheet.create({
    main: {
        backgroundColor: background.infoHeader,
        flex: 1,
        borderRadius: "1.5vw",
        margin: "1vw",
        paddingHorizontal: "1.2rem",
        paddingBottom: "0.05rem"
    },
    scroll_h_c: {
        borderRadius: "1.5vw",
        position: "relative",
        overflowX: "auto",
        overflowY: "auto",
        backgroundColor: background.infoHeader,
    },
    tableHeaderView: {
        flexDirection: "row",
        backgroundColor: background.infoHeader,
        height: "3.4vw",
    },
    headerCell: {
        position: "sticky",
        top: 0,
        zIndex: 1500
    },
    rowCell: (zIndex) => ({
        zIndex: zIndex
    }),
    tableHeaderItem: (width) => ({
        width: width,
        textAlign: "center",
        paddingVertical: "0.8vw"
    }),
    kpiCell: {
        alignItems: "center",
        justifyContent: "center",
    },
    menuBtn: {
        position: "absolute",
        top: "0.1vw",
        width: "1vw",
        height: "1vw",
        left: "0.6vw",
        alignItems: "center",
        justifyContent: "center",
    },
    menuIcon: {
        width: "0.6vw",
        height: "0.6vw",
        tintColor: color.placeholder
    },
});