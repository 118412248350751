import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Feather, FontAwesome, Ionicons } from '@expo/vector-icons'
import { useSelector } from 'react-redux';

export default function HeaderPopupButtons({
    minimize,
    maximize,
    maximizeFunc,
    close,
    type,
    smallSize
}) {
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);

    const styles = stylesR(smallSize);

    return (
        <View
            style={styles.container}
        >
            {!type && <Pressable
                onPress={minimize}
                style={styles.btn}
            >
                <FontAwesome
                    selectable={false}
                    name="window-minimize"
                    style={{ fontSize: "0.8vw", marginTop: "-0.5vw" }}
                    color={color.x}
                />
            </Pressable>}
            {maximizeFunc && <Pressable
                onPress={maximizeFunc}
                style={styles.btn}
            >

                <Image source={{ uri: !maximize ? images.maximize : images.minimize }} style={styles.sizeIcon} />
            </Pressable>}
            <Pressable
                style={styles.btn}
                onPress={close}
            >
                <Ionicons selectable={false} name='close' color={color.x} style={styles.x} />
            </Pressable>
        </View>
    )
}

const stylesR = (smallSize) => StyleSheet.create({
    container: {
        alignItems: "center",
        flexDirection: "row",
    },
    x: {
        fontSize: "1.4vw",
        paddingRight: smallSize ? "0.2vw" : "0.5vw",
    },
    btn: {
        width: "1.6vw",
        height: smallSize ? "1.5vw" : "3vw",
        alignItems: "center",
        justifyContent: "center"
    },
    sizeIcon: {
        width: "0.8vw",
        height: "0.8vw",
        resizeMode: "contain"
    }
})