import React, { useRef, useState } from "react";
import { StyleSheet, View, Pressable, Text, ScrollView } from "react-native";
import { useSelector } from "react-redux";
import { useClickOutside } from "../../../../modules/useClickOutside";
import { Ionicons } from "@expo/vector-icons";
import I18n from "i18n-js";
import UseResponsiveSize from "../../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../../modules/UseResponsiveScreen";
import ShortenWord from "../../../../modules/ShortenWord";

export default function EventSelect({
  style,
  options,
  err,
  placeholder,
  icon,
  type,
  multiple,
  value,
  setValue,
  social,
  pressAllPlayersOptionHandler,
  disabled,
  smaller,
  to_list_names,
  fromErr,
  setFromErr,
  noFromPlayers,
  errField
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);

  const selectRef = useRef();

  const [show, setShow] = useState(false);

  const pressOptionHandler = (option) => {
    setValue(option);
    if (!multiple) setShow(false);
  };

  useClickOutside(selectRef, show, () => setShow(false));
  return (
    <View style={[styles.select, style,]} ref={selectRef}>
      <Pressable
        disabled={disabled || smaller}
        style={[
          styles.input,
          styles.selectBtn,
          {
            color: color.infoHeaderNavText,
            backgroundColor: background.infoBody,
            paddingVertical: smaller ? heightS * 10 : heightS * 15,
            height: smaller ? heightS * 25 : heightS * 30,
            //borderColor: fromErr ? "red" : "auto",
            //borderWidth: fromErr ? widthS : 0,
          },
          err && !value?.length > 0 && styles.mail_error,
          errField && styles.mail_error,
        ]}
        onPress={() => {
          setFromErr && setFromErr(noFromPlayers);
          !noFromPlayers && setShow((prev) => !prev);
        }}
      >
        {social ? (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={[styles.checkBoxContainer]}>
              <Pressable
                disabled={disabled || smaller}
                style={[
                  styles.checkBox,
                  styles.selectCheckBox,
                  {
                    backgroundColor: background.infoBody,
                    borderColor: color.infoBorder,
                  },
                ]}
                onPress={pressAllPlayersOptionHandler}
              >
                {value.split(",").filter((id) => id).length >=
                  options.length && (
                    <View
                      style={[
                        styles.selectCheckBoxInner,
                        {
                          backgroundColor: background.count,
                        },
                      ]}
                    ></View>
                  )}
              </Pressable>
            </View>
            <Text
              style={[styles.selectBtnText, { color: color.infoHeaderNavText }]}
            >
              {value.split(",").filter((id) => id).length <
                options.length && value.split(",").filter((id) => id).length > 0 ? ShortenWord(to_list_names, 25) : `${I18n.t("all")} ${I18n.t("players")}`}
            </Text>
          </View>
        ) : (
          <Text
            style={[styles.selectBtnText, { color: color.infoHeaderNavText }]}
          >
            {(Array.isArray(value) && value.length > 0 && (
              <>
                {value.slice(0, value.length - 1).map((player) => (
                  <Text key={player.id}>{`${player.name_for_game}, `}</Text>
                ))}
                <Text>{value[value.length - 1].name_for_game}</Text>
              </>
            )) ||
              (value?.date instanceof Date && value?.time) ||
              value?.name_for_game ||
              value ||
              placeholder}
          </Text>
        )}
        {icon && (
          <Ionicons
            name={show ? "caret-up" : "caret-down"}
            size={widthS * 6}
            color={color.infoHeaderNavText}
          />
        )}
      </Pressable>
      {show && options.length > 0 && (
        <ScrollView
          nativeID="social"
          style={[
            styles.selectContent,
            {
              borderColor: color.infoBorder,
              backgroundColor: background.infoBody,
            },
          ]}
        >
          {multiple
            ? [
              !social && (
                <Pressable
                  onPress={pressAllPlayersOptionHandler}
                  style={styles.selectOption}
                >
                  <View style={{ flexDirection: "row" }}>
                    <View style={[styles.checkBoxContainer]}>
                      <View
                        style={[
                          styles.checkBox,
                          styles.selectCheckBox,
                          {
                            backgroundColor: background.infoBody,
                            borderColor: color.infoBorder,
                          },
                        ]}
                      >
                        {value.split(",").filter((id) => id).length ===
                          options.length && (
                            <View
                              style={[
                                styles.selectCheckBoxInner,
                                {
                                  backgroundColor: background.count,
                                },
                              ]}
                            ></View>
                          )}
                      </View>
                    </View>
                    <Text
                      style={[
                        styles.selectBtnText,
                        { color: color.infoHeaderNavText },
                      ]}
                    >
                      {`${I18n.t("all")} ${I18n.t("players")}`}
                    </Text>
                  </View>
                </Pressable>
              ),
              ...options.map((option, i) => (
                <Pressable
                  key={i}
                  onPress={() => pressOptionHandler(option)}
                  style={styles.selectOption}
                >
                  <View style={{ flexDirection: "row" }}>
                    <View style={[styles.checkBoxContainer]}>
                      <View
                        style={[
                          styles.checkBox,
                          styles.selectCheckBox,
                          {
                            backgroundColor: background.infoBody,
                            borderColor: color.infoBorder,
                          },
                        ]}
                      >
                        {value
                          .split(",")
                          .find(
                            (value) =>
                              value.trim() === option.id ||
                              value.trim() === option
                          ) && (
                            <View
                              style={[
                                styles.selectCheckBoxInner,
                                {
                                  backgroundColor: background.count,
                                },
                              ]}
                            ></View>
                          )}
                      </View>
                    </View>
                    <Text
                      style={[
                        styles.selectBtnText,
                        { color: color.infoHeaderNavText },
                      ]}
                    >
                      {option.name_for_game ||
                        option.time ||
                        option ||
                        option.name}
                    </Text>
                  </View>
                </Pressable>
              )),
            ]
            : options.map((option, i) => {
              return (
                <Pressable
                  key={i}
                  onPress={() => pressOptionHandler(option)}
                  style={styles.selectOption}
                >
                  <View style={{ backgroundColor: option.color, width: widthS * 2, height: heightS * 15, marginHorizontal: widthS * 4 }} />
                  <Text
                    style={[
                      styles.selectBtnText,
                      { color: color.infoHeaderNavText },
                    ]}
                  >
                    {option.name_for_game ||
                      option.title ||
                      option.name ||
                      option.time ||
                      option}
                  </Text>
                </Pressable>
              )
            })}
        </ScrollView>
      )}
    </View>
  );
}

const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    select: {
      position: "relative",
      width: widthS * 110,
      marginHorizontal: 20 * widthS,
    },
    selectBtn: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
    },
    selectContent: {
      maxHeight: "10vw",
      position: "absolute",
      zIndex: 5,
      borderBottomRightRadius: widthS * 12,
      borderBottomLeftRadius: widthS * 12,
      borderTopWidth: 1,
      top: 34 * heightS,
      width: "100%",
      shadowColor: "black",
      shadowOffset: {
        height: 0,
        width: 0,
      },
      shadowOpacity: 0.3,
      shadowRadius: widthS * 2,
    },
    selectBtnText: {
      fontSize: widthS * 8,
    },
    selectOption: {
      paddingHorizontal: widthS * 8,
      paddingVertical: heightS * 8,
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    input: {
      height: heightS * 30,
      fontSize: widthS * 12,
      marginVertical: heightS * 5,
      borderRadius: widthS * 9,
      paddingVertical: 15 * heightS,
      paddingHorizontal: widthS * 8,
      outlineStyle: "none",
    },
    checkBoxContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    checkBox: {
      marginRight: 5 * widthS,
      width: 10 * widthS,
      height: 10 * widthS,
      borderRadius: widthS * 3,
      borderWidth: widthS,
      justifyContent: "center",
      alignItems: "center",
    },
    selectCheckBox: {
      width: 10 * widthS,
      height: 10 * widthS,
    },
    selectCheckBoxInner: {
      width: 6 * widthS,
      height: 6 * widthS,
      borderRadius: widthS * 2,
    },
    mail_error: {
      borderColor: "red",
      borderWidth: "2px",
      borderRadius: "10px",
    },
  });
