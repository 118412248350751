import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, Pressable, Image } from "react-native";
import Carousel from "./Carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  newsFlag,
  setTypeMinimize,
  setIndexCurrent,
} from "../../redux/actions";
import { t } from "i18n-js";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import { getDateTimeObject } from "../../EditDate";

export default function NewsCarousel({ data }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [newsData, setNewsData] = useState(data);
  const colors = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const showNews = useSelector((state) => state.newsArr.flag);
  const popupsCount = useSelector((state) => state.loginType.popupsCount);
  const minimizePopups = useSelector((state) => state.minimize.minimize);
  const minimizePopupsIndex = useSelector(
    (state) => state.minimize.indexCurrent
  );

  const deleteItemNews = () => {
    const temp = [...newsData];
    const updatedData = [];
    temp.splice(index, 1);
    temp.forEach((item, i) => updatedData.push({ ...item, index: i }));
    setNewsData(updatedData);
    if (index === 0) {
      setIndex(newsData.length - 2);
    } else {
      setIndex(index - 1);
    }
  };

  const onPressHandler = (flag, setFlag, type, num) => {
    if (!flag && popupsCount >= 3) {
      return dispatch(
        setErrorMessage(true, t("oops"), t("popup_error"))
      );
    }
    if (minimizePopups.find((e) => e.name === type && !e.show)) {
      dispatch(setTypeMinimize({ name: type, type: 2, show: true, num: num }));
      dispatch(setIndexCurrent(num));
      return;
    } else if (
      minimizePopups.find(
        (e) => e.name === type && e.show && minimizePopupsIndex !== num
      )
    ) {
      dispatch(setIndexCurrent(num));
      return;
    } else {
      dispatch(setFlag(true));
      dispatch(setTypeMinimize({ name: type, type: 2, show: true, num: num }));
      dispatch(setIndexCurrent(num));
      return;
    }
  };

  const isVideo = (media) => {
    if (media && media.endsWith('.mp4')) {
      return <video
        style={{
          width: `${widthS * 55}px`,
          height: `${heightS * 32}px`,
          objectFit: "cover",
          marginBottom: `${heightS * 5}px`,
          marginRight: `${widthS * 5}px`,
        }}
      >
        <source src={media} />
      </video>
    } else {
      return <Image
        style={styles.img_item}
        source={{ uri: media }}
      />
    }
  };

  const renderItem = (data, index) => {
    if (data[index]) {
      let printTitle = data[index].title.toUpperCase();
      if (printTitle && printTitle.length > 33) {
        printTitle = `${printTitle.slice(0, 33)}...`;
      }
      return (
        <Pressable
          key={index}
          style={[styles.container_item]}
          onPress={() => onPressHandler(showNews, newsFlag, "news", 2)}
        >
          <View
            style={[
              styles.header_item,
              { backgroundColor: background.rightMenuPopupMailAndSocial },
            ]}
          >
            <View
              style={[styles.header_left, { backgroundColor: background.news }]}
            >
              <Text style={styles.header_txt}>{t("breaking-news")}</Text>
            </View>
            <View
              style={[styles.triangle, { borderBottomColor: background.news }]}
            />
            <Pressable style={styles.x_btn} onPress={() => deleteItemNews()}>
              <Text style={[styles.x_btn_text, { color: colors.x }]}>X</Text>
            </Pressable>
          </View>
          <View style={styles.body_item}>
            {isVideo(data[index].image)}
            <View style={{ display: "flex", flexDirection: "column" }}>
              <Text style={styles.title_item}>{printTitle}</Text>
              <Text style={[styles.time, { color: colors.x }]}>
                {(() => {
                  const { date, time } = getDateTimeObject(`${data[index].time} utc`);
                  return `${date} | ${time}`
                })()}
              </Text>
            </View>
          </View>
        </Pressable>
      );
    }
  };

  useEffect(() => {
    setNewsData(data);
  }, [data]);

  return (
    <View style={styles.container}>
      <View style={styles.header}></View>
      <Carousel
        dataArr={newsData}
        data={newsData[index]}
        index={index}
        setIndex={(i) => setIndex(i)}
        renderItem={() => renderItem(newsData, index)}
        widthP={193}
      />
    </View>
  );
}
const stylesR = (width, height, heightS, widthS) => {
  return StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: widthS * 20,
      paddingTop: heightS * 5,
    },
    container_item: {
      width: widthS * 190,
      height: heightS * 67,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: widthS * 7,
      margin: widthS * 1.5,
    },
    header_item: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      borderTopLeftRadius: widthS * 7,
      borderTopRightRadius: widthS * 7,
      paddingRight: widthS * 25,
    },
    header_left: {
      paddingLeft: widthS * 8,
      paddingRight: widthS * 10,
      zIndex: 4,
      height: widthS * 17
    },
    triangle: {
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      borderStyle: "solid",
      borderRightWidth: widthS * 18,
      borderBottomWidth: widthS * 17,
      borderRightColor: "transparent",
    },
    header_txt: {
      color: "white",
      fontFamily: "Catamaran_700Bold",
      fontSize: widthS * 11,
      lineHeight: heightS * 18,
    },
    x_btn: {
      position: "absolute",
      right: widthS * 2,
      top: heightS * 3.5,
      borderRadius: 15,
      paddingBottom: heightS * 1,
      width: widthS * 11,
      height: heightS * 11,
      dispaly: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    x_btn_text: {
      fontSize: widthS * 7,
      fontFamily: "Catamaran_400Regular",
    },
    body_item: {
      backgroundColor: "white",
      width: "100%",
      paddingHorizontal: widthS * 6,
      flex: 1,
      display: "flex",
      height: heightS * 42,
      flexDirection: "row",
      alignItems: "center",
      borderBottomLeftRadius: widthS * 7,
      borderBottomRightRadius: widthS * 7,
    },
    img_item: {
      width: widthS * 55,
      height: heightS * 32,
      resizeMode: "cover",
      marginBottom: heightS * 5,
      marginRight: widthS * 5,
    },
    title_item: {
      fontSize: widthS * 12,
      fontFamily: "Catamaran_700Bold",
      color: "#424141",
      marginRight: widthS * 10,
      lineHeight: widthS * 12,
      width: widthS * 120,
    },
    time: {
      fontSize: widthS * 8,
      fontFamily: "Catamaran_400Regular",
    },
  });
};
