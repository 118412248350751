export const getLang = (lang, isTime, newGame) => {
    if(lang){
        lang = lang.toLowerCase();
    }
    switch (lang) {
        case "hebrew":
        case "he":
            return newGame ? "hebrew" : !isTime ? "he" : "he-IL";
        case "en":
        case "english":
            return newGame ? "english" : !isTime ? "en" : "en-US";
        case "fr":
        case "french":
            return newGame ? "french" : !isTime ? "fr" : "fr-FR";
        case "spanish":
        case "es":
            return newGame ? "spanish" : !isTime ? "es" : "es-ES";
        case "german":
        case "de":
            return newGame ? "german" : !isTime ? "de" : "de-DE";
        case "thai":
        case "th":
            return newGame ? "thai" : !isTime ? "th" : "th-TH";
        case "italy":
        case "italiano":
        case "italian":
        case "it":
            return newGame ? "italian" : !isTime ? "it" : "it-IT";
        default:
            return newGame ? "english" : !isTime ? "en" : "en-US";
    }
}