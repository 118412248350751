import React, { useState, useEffect, useMemo } from "react";
import { StyleSheet, View, ScrollView, TextInput } from "react-native";
import ChatListItem from "./ChatListItem";
import { useDispatch, useSelector } from "react-redux";
import "../mailbox/scroll.css";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { t } from "i18n-js";
import { EvilIcons } from "@expo/vector-icons";
import { deleteChat } from "../../../redux/actions";

export default function ChatList({
  data,
  index,
  setIndex,
  setNewData,
  scrollTo,
  scrollChatsList,
  chatsListRef,
  chatData,
  dataToPrint,
  setTo,
  userData,
  printDate,
  dashboard
}) {

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(background, color, direction);

  const dispatch = useDispatch();

  const [dataArr, setDataArr] = useState({});
  const [search, setSearch] = useState("");

  const playerPermission = useSelector((state) => state.gameInfoArr.permission);


  useEffect(() => {
    setDataArr(chatData);
  }, [data, dataToPrint]);

  // useMemo(() => {
  //   if (index === 0) {
  //     scrollChatsList();
  //   }
  // }, [index]);

  const groups = (arr) => {
    let newArr = [];
    arr.map((e) => {
      if (e.group && e.players_id.includes(dataToPrint?.from)) {
        newArr.push(e);
      } else if (!e.group) {
        newArr.push(e);
      }
    })
    return newArr;
  }

  const searchComponent = (
    <View
      style={styles.main(dashboard)}
    >
      <View
        style={[
          styles.search_view(dashboard)
        ]}
      >
        <TextInput
          selectTextOnFocus={false}
          placeholderTextColor={color.chatSearch}
          style={[styles.search_inp, { outlineStyle: "none" }]}
          placeholder={`${t("search")}...`}
          onChangeText={(txt) => setSearch(txt)}
        />
        <EvilIcons
          selectable={false}
          style={styles.search_icon}
          name="search"
          size={"1.2vw"}
          color={color.chatSearch}
        />
      </View>
    </View>
  )


  return (
    <View
      style={[
        styles.container(dashboard),
      ]}
    >
      {dashboard && searchComponent}
      <ScrollView
        contentContainerStyle={[styles.scroll_body, { direction: "ltr" }]}
        nativeID={(playerPermission === "1" || playerPermission === "10") ? "chat-popup-g" : "mail-messages"}
        stickyHeaderIndices={dashboard ? null : [0]}
        ref={chatsListRef}
      >
        {!dashboard && searchComponent}
        {(() => {
          if (dataArr && dataToPrint.from && dataArr[dataToPrint.from]) {
            let newObj = { ...dataArr[dataToPrint.from] };
            delete newObj["count"];
            Object.keys(newObj).forEach((key) => {
              if (search?.length > 0 && newObj[key].name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                delete newObj[key];
              }
            })
            return Object.keys(newObj)?.map((key, ind) => {
              if (dataArr[dataToPrint.from][key]) {
                return (
                  <ChatListItem
                    dashboard={dashboard}
                    key={ind}
                    item={dataArr[dataToPrint.from][key]}
                    index={key}
                    setIndex={setIndex}
                    active={index}
                    setNewData={(d) => setNewData(d)}
                    scrollTo={scrollTo}
                    id={key}
                    setTo={setTo}
                    userData={userData(key)}
                    printDate={printDate}
                  />
                );
              }
            })
          }
        })()}
      </ScrollView>
    </View>
  );
}

const stylesR = (background, color, direction) =>
  StyleSheet.create({
    container: (flag) => ({
      flex: 1,
      borderTopWidth: flag ? 0 : 1,
      borderTopColor: color.border,
      direction: flag ? "ltr" : "rtl"
    }),
    scroll_body: {
      minHeight: "101%",
    },
    main: (flag) => ({
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: background.popup1,
      borderBottomWidth: 1,
      borderColor: color.border,
      borderRightWidth: flag ? 1 : 0,
      padding: "0.8vw",
    }),
    search_view: (flag) => ({
      backgroundColor: background.chatSearch,
      borderRadius: flag ? "5vw" : "0.5vw",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "0.5vw",
      width: "100%",
      height: "2vw",
      direction: direction,
    }),
    search_inp: {
      padding: "1vw",
      paddingLeft: "1vw",
      color: "white",
      fontSize: "0.9vw",
      width: "100%",
      fontFamily: "Catamaran_300Light",
      writingDirection: direction,
    },
  });
