import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  StyleSheet,
  Modal,
  View,
  Text,
  Pressable,
  TextInput,
  Image,
  TouchableOpacity
} from "react-native";
import "./post.css";
import { FontAwesome } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import SocialBody from "./SocialBody";
import { t } from "i18n-js";
import { getContent } from "../../modules/getContent";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import { removeType, setTypeMinimize } from "../../../redux/actions";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";

export default function Social({ flag, setFlag, setDrag }) {

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, direction);

  const dispatch = useDispatch();

  const popupRef = useRef(null);
  const posts = useSelector((state) => state.socialMedia.socialMediaArr);
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);
  const minimizePopups = useSelector((state) => state.minimize.minimize);

  const [postsToPrint, setPostsToPrint] = useState([]);

  const search = (txt) => {
    if (posts.length > 0) {
      const temp = posts.filter(
        (e) => e.sender_by.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0 || getContent(e.text).toLowerCase().indexOf(txt.toLowerCase()) >= 0
      );
      setPostsToPrint(temp);
    }
  };
  useMemo(() => {
    if (posts) {
      setPostsToPrint([...posts]);
    }
  }, [posts]);

  return (
    <View
      style={[styles.main, { backgroundColor: background.popup1 }]}
      ref={popupRef}
    >
      <View style={{ width: "100%", height: "100%" }}>
        <TouchableOpacity style={{ cursor: "grabbing" }} onPressIn={() => setDrag(true)}>
          <View style={[styles.header, { borderBottomColor: color.border }]}>
            <View style={styles.headerLeft}>
              <View
                style={[
                  styles.logo_view,
                  { backgroundColor: background.socialLogoColor },
                ]}
              >
                <Image source={{ uri: images.social }} style={styles.logo} />
              </View>
              <Text selectable={false} style={styles.textLogo}>{t("social-media")}</Text>
            </View>
            <View
              style={[
                styles.search_view,
                {
                  backgroundColor: background.socialSearch,
                  borderColor: color.socialSearch,
                  direction: direction,
                },
              ]}
            >
              <TextInput
                selectTextOnFocus={false}
                placeholderTextColor={color.socialSearch}
                style={[styles.search_inp, { outlineStyle: "none", color: color.socialSearch }]}
                placeholder={`${t("search")}...`}
                onChangeText={(txt) => search(txt)}
              />
              <Image
                source={{ uri: images.searchIconSocial }}
                style={styles.search_icon}
              />
            </View>
            <HeaderPopupButtons
              close={() => { setFlag(); dispatch(removeType("social")) }}
              minimize={() => dispatch(setTypeMinimize({ name: "social", type: 1, show: false }))}
            />
          </View>
        </TouchableOpacity>
        <View style={styles.body}>
          <SocialBody posts={postsToPrint} />
        </View>
      </View>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS, direction) => {
  return StyleSheet.create({
    main: {
      zIndex: 1001,
      alignItems: "center",
      backgroundColor: "#00000",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      display: "flex",
      flexDirection: "column",
      borderRadius: widthS * 8,
      height: "100%"
      // ...style.popup3,
    },
    header: {
      paddingLeft: widthS * 16,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    },
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: heightS * 4,
    },
    logo_view: {
      width: widthS * 26,
      height: heightS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: widthS * 13,
      height: widthS * 13,
      resizeMode: "contain",
    },
    textLogo: {
      color: "white",
      fontFamily: "Catamaran_700Bold",
      fontSize: widthS * 15,
      paddingLeft: widthS * 10,
    },
    search_view: {
      position: "absolute",
      right: "5vw",
      borderRadius: widthS * 5,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: widthS * 5,
    },
    search_inp: {
      padding: widthS * 1,
      paddingLeft: widthS * 10,
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 10,
      width: widthS * 115,
      height: heightS * 18.5,
      writingDirection: direction
    },
    search_icon: {
      width: widthS * 11,
      height: widthS * 12,
      resizeMode: "contain",
    },
    x: {
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 14,
    },
    body: {
      flex: 1
    },
  });
}