import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableHighlight,
} from "react-native";
import React, { useRef, useMemo, useEffect, useState } from "react";
import { Pressable } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n-js";
import { markPlayer, markPlayerHover, openRealPlayer, openRepPlayer } from "../../../../redux/actions/creatorActions";
import UseResponsiveSize from "../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../modules/UseResponsiveScreen";
import { Fontisto } from '@expo/vector-icons';
import DraggedColumn from "../../../modules/DraggedColumn";
import Zoom from "../../../modules/Zoom";
import { ZoomStatic } from "../../../modules/ZoomStatic";
import FullTextTooltip from "../../../Elements/FullTextTooltip";

export default function PlayersList({ listRef, indexPlayer, permission, buildFlag, setIndexPlayer, topPlayers }) {

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, Zoom, direction);

  const dispatch = useDispatch();

  const realPlayers = useSelector(state => state.players.realPlayers);
  const repPlayers = useSelector(state => state.players.repPlayers);
  const playerCurrent = useSelector(state => state.swimlane.playerCurrent);

  const zoomSize = useSelector(state => state.swimlane.zoom);

  const images = useSelector((state) => state.style.images);
  const colors = useSelector((state) => state.style.color);
  const backgrounds = useSelector((state) => state.style.background);


  const [idFlag, setIdFlag] = useState();

  const showOptions = (playerCurrent, player, index) => {
    return (
      <View style={{ position: "relative", paddingLeft: widthS * ZoomStatic(3, zoomSize), height: "100%", justifyContent: "center", alignItems: "center", paddingRight: widthS * ZoomStatic(6, zoomSize) }}>
        <TouchableHighlight
          underlayColor={""}
          style={{ position: "absolute", top: heightS * ZoomStatic(2, zoomSize) }}
          onPress={() => setIdFlag(index)}
        >
          <Image source={{ uri: images.menuIcon }} style={{ width: widthS * ZoomStatic(8, zoomSize), height: widthS * ZoomStatic(8, zoomSize), resizeMode: "contain", transform: [{ scale: player.id === idFlag ? 1.2 : 1 }] }} />
        </TouchableHighlight>
        <TouchableHighlight underlayColor={""} onPress={() => { dispatch(markPlayerHover("")); dispatch(markPlayer(player.id, playerCurrent.indexOf(player.id) >= 0 ? false : true, playerCurrent)); }}>
          <Fontisto name="locked" size={widthS * ZoomStatic(8, zoomSize)} color={playerCurrent.indexOf(player.id) >= 0 ? backgrounds.realPlayersBackground : colors.placeholder} />
        </TouchableHighlight>
      </View>
    )
  };

  return (
    <View style={[styles.container, { backgroundColor: backgrounds.realPlayersBackground, borderRightColor: colors.infoBorder }]} ref={listRef}>
      {/* header */}
      <View style={[styles.titleContainer, { backgroundColor: backgrounds.realPlayersBackground, }]}>
        <Text style={[styles.headerTitle, { color: colors.buildTitle }]}>
          {i18n.t("real").toUpperCase()}
        </Text>
        {permission && <Pressable
          style={[
            styles.iconContainer,
            { backgroundColor: backgrounds.iconBackground },
          ]}
          onPress={() => dispatch(openRealPlayer("", true))}
        >
          <Image source={{ uri: images.plusIcon }} style={{ width: widthS * 7, height: widthS * 7, resizeMode: "cover" }} />
        </Pressable>}
      </View>
      {/* real players list */}
      <View
        style={[
          styles.realPlayers,
          {
            borderColor: colors.infoBorder,
          },
        ]}
      >
        {
          Array.isArray(realPlayers) &&
          realPlayers
            // [new Array(100).fill({name_for_game: "jkisdjf", id: "kjhg", is_represented: "0"})][0]
            .map((player, playerIndex) => {
              if (+player?.permission_for_game !== 15) return; ///why is this needed??
              return (
                // real player item
                <DraggedColumn key={player.id} indexFlag={idFlag} index={playerIndex} setIndexflag={() => setIdFlag()} type={"real"} arrLength={realPlayers} y={heightS * 45}>
                  <Pressable
                    style={({ hovered }) => [styles.playerContainer, { backgroundColor: buildFlag && (indexPlayer === player.id || playerCurrent.indexOf(player.id) >= 0) ? "#3D3743" : "" }]}
                    key={player.id}
                    ref={(ref) => {
                      if (playerIndex === 0) {
                        topPlayers.current = ref
                      }
                    }}
                    onPress={() => !buildFlag && dispatch(openRealPlayer(player, true))}
                    onHoverOut={() => {
                      // dispatch(markPlayerHover(""));
                      if (buildFlag) {
                        setIndexPlayer();
                      }
                    }}
                    onHoverIn={() => {
                      // if (playerCurrent.indexOf(player.id) < 0) {
                      //   dispatch(markPlayerHover(player.id));
                      // }
                      if (indexPlayer !== player.id && buildFlag) {
                        setIndexPlayer(player.id);
                      }
                    }}
                  >
                    {({ hovered }) => {
                      return <>
                        <View style={styles.playerDetailsContainer}>
                          {buildFlag && (hovered || playerCurrent.indexOf(player.id) >= 0) && showOptions(playerCurrent, player, playerIndex)}
                          <View
                            style={[
                              styles.imageContainer,
                              { borderColor: colors.logoColor, shadowColor: colors.logoColor, marginLeft: buildFlag && (indexPlayer === player.id || playerCurrent.indexOf(player.id) >= 0) ? widthS * 5 : widthS * 12, },
                            ]}
                          >
                            <Image
                              style={[styles.icon]}
                              source={{ uri: player.profile_image ? player.profile_image : images.user_image }}
                            />
                          </View>
                          <View style={styles.playerDetails}>
                            <FullTextTooltip text={player.name_for_game}>
                              <Text
                                style={[
                                  styles.title,
                                  { color: colors.infoHeaderNavText },
                                ]}
                                numberOfLines={1}
                              >
                                {player.name_for_game}
                              </Text>
                            </FullTextTooltip>

                            {player.real_name && <FullTextTooltip text={player.real_name}>
                              <Text
                                numberOfLines={1}
                                style={[styles.name, { color: colors.infoHeaderNavText, width: hovered ? widthS * 65 : widthS * 75 }]}
                              >
                                {/* {`${player.first_name.slice(0, 1).toUpperCase()}${player.first_name.slice(1).toLowerCase()} ${player.last_name}`} */}
                                {player.real_name}
                              </Text></FullTextTooltip>}
                          </View>
                        </View>
                        <View>
                          <View
                            style={[
                              styles.status,
                              {
                                backgroundColor:
                                  player.color_status
                              },
                            ]}
                          />
                        </View>
                      </>
                    }}
                  </Pressable>
                </DraggedColumn>
              );
            })}
      </View>
      <View style={[styles.repTitleView, { backgroundColor: backgrounds.popupLogin }]}>
        <Text
          style={[styles.headerTitle, { color: colors.buildTitle }]}
        >
          {i18n.t("represented").toUpperCase()}
        </Text>
      </View>
      <View
        style={[
          styles.representedPlayers,
          { backgroundColor: backgrounds.popupLogin, paddingBottom: widthS * Zoom(20), },
        ]}
      >
        <View style={{}}>
          {
            Array.isArray(repPlayers) &&
            repPlayers
              // [new Array(100).fill({name_for_game: "jkisdjf", id: "kjhg", is_represented: "1"})][0]
              .map((player, playerIndex) => {
                if (player?.is_represented === "0") return;
                return (
                  // rep player item
                  <DraggedColumn key={player.id} indexFlag={idFlag} index={playerIndex} setIndexflag={() => setIdFlag()} type={"rep"} arrLength={repPlayers} y={heightS * 45}>
                    <Pressable
                      style={({ hovered }) => [styles.playerContainer, { backgroundColor: buildFlag && (hovered || playerCurrent.indexOf(player.id) >= 0) ? "#3D3743" : "" }]}
                      key={player?.id}
                      ref={(ref) => {
                        if (playerIndex === 0 && realPlayers?.length === 0) {
                          topPlayers.current = ref
                        }
                      }}
                      onPress={() => !buildFlag && dispatch(openRepPlayer(player, true))}
                      onHoverOut={() => {
                        // dispatch(markPlayerHover(""))
                        if (buildFlag) {
                          setIndexPlayer()
                        }
                      }}
                      onHoverIn={() => {
                        // if (playerCurrent.indexOf(player.id) < 0) {
                        //   dispatch(markPlayerHover(player.id));
                        // }
                        if (indexPlayer !== player.id && buildFlag) {
                          setIndexPlayer(player.id)
                        }
                      }}
                    >
                      {({ hovered }) => {
                        return <View style={styles.playerDetailsContainer}>
                          {buildFlag && (hovered || playerCurrent.indexOf(player.id) >= 0) && showOptions(playerCurrent, player, playerIndex)}
                          <View
                            style={[
                              styles.imageContainer,
                              { borderColor: colors.logoColor, shadowColor: colors.logoColor, marginLeft: buildFlag && (playerCurrent.indexOf(player.id) >= 0 || hovered) ? widthS * 5 : widthS * 12 },
                            ]}
                          >
                            <Image
                              style={[styles.icon]}
                              source={{ uri: player.profile_image ? player.profile_image : images.user_image }}
                            />
                          </View>
                          <View style={styles.playerDetails}>
                            <FullTextTooltip text={player.name_for_game}>
                              <Text
                                numberOfLines={1}
                                style={[
                                  styles.name_rep,
                                  {
                                    color: colors.infoHeaderNavText,
                                  },
                                ]}
                              >
                                {`${player?.name_for_game.slice(0, 1).toUpperCase()}${player?.name_for_game.slice(1).toLowerCase()}`}
                              </Text>
                            </FullTextTooltip>
                          </View>
                        </View>
                      }}
                    </Pressable>
                  </DraggedColumn>
                );
              })}
        </View>
      </View>
    </View >
  );
}

const stylesR = (width, height, heightS, widthS, Zoom, direction) => {
  return StyleSheet.create({
    container: {
      borderRightWidth: 1,
      flex: 1,
    },
    titleContainer: {
      flexDirection: "row",
      paddingTop: heightS * Zoom(15),
      paddingBottom: heightS * Zoom(25),
      height: "5.5vw",
      position: "sticky",
      zIndex: 1000,
      top: 0,
      justifyContent: "space-between",
    },
    headerTitle: {
      paddingLeft: widthS * 12,
      fontSize: widthS * 13,
    },
    iconContainer: {
      borderRadius: "50%",
      padding: widthS * 7,
      marginRight: widthS * 8,
      width: widthS * 12,
      height: widthS * 12,
      justifyContent: "center",
      alignItems: "center"
    },
    realPlayers: {
      borderBottomWidth: Zoom(1),
    },
    playerContainer: {
      height: heightS * Zoom(45),
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: "100%"
    },
    imageContainer: {
      borderRadius: "50%",
      borderWidth: widthS * Zoom(1.5),
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.8,
      shadowRadius: 15,
      transition: "all 0.9"
    },
    icon: {
      width: widthS * Zoom(24),
      height: widthS * Zoom(24),
      borderRadius: "50%",
    },
    playerDetailsContainer: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      height: "100%",
      maxWidth: "90%"
    },
    playerDetails: {
      paddingLeft: widthS * Zoom(5),
      justifyContent: "center",
      flex: 1,
      writingDirection: direction
    },
    title: {
      fontSize: widthS * Zoom(9),
      fontFamily: "Catamaran_700Bold",
      lineHeight: widthS * Zoom(9),
      writingDirection: direction
    },
    name: {
      fontSize: widthS * Zoom(8),
      lineHeight: widthS * Zoom(8),
      fontFamily: "Catamaran_200ExtraLight",
      writingDirection: direction
    },
    name_rep: {
      fontSize: widthS * Zoom(8),
      lineHeight: heightS * Zoom(12),
      width: widthS * 75,
      fontFamily: "Catamaran_400Regular",
      writingDirection: direction
    },
    status: {
      width: widthS * Zoom(7),
      height: widthS * Zoom(7),
      borderRadius: "50%",
      marginRight: widthS * Zoom(9),
    },
    representedPlayers: {
      flex: 1
    },
    repTitleView: {
      height: heightS * Zoom(45),
      zIndex: 1001,
      position: "sticky",
      justifyContent: "center",
      top: 0
    }
  });
}
