import React, { useState, useRef, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Pressable, Image, Text } from 'react-native';
import { useSelector } from 'react-redux';
// import UniversalFilter from './UniversalFilter';
import { t } from 'i18n-js';
import UniversalFilter from './UniversalFilter';
import { useRawDataContext } from '../../../../ContextData/RawData';

export default function Filter() {

    const colors = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);
    const backgrounds = useSelector((state) => state.style.background);
    const logo = useSelector(state => state.style.logo);

    const {
        reset,
        removeItem,
        searchItem,
        addItem,

        actionsFilter,
        actionsFilterSelect,
        actionsFilterSearch,

        platformFilter,
        platformFilterSelect,
        platformFilterSearch,

        eventsFilter,
        eventsFilterSelect,
        eventsFilterSearch,

        playersFilter,
        playersFilterSelect,
        playersFilterSearch
    } = useRawDataContext();

    const actionsPopupRef = useRef(null);
    const playersPopupRef = useRef(null);
    const eventsPopupRef = useRef(null);
    const platformPopupRef = useRef(null);

    const [flagSelect, setFlagSelect] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // רשימת ה-Refs
            const refs = [
                actionsPopupRef.current,
                playersPopupRef.current,
                eventsPopupRef.current,
                platformPopupRef.current,
            ];

            // בדיקה האם נלחץ בתוך אחד ה-Refs
            const clickedInsideAny = refs.some(ref => ref && ref.contains(event.target));

            console.log('Clicked element:', event.target);
            console.log('Is clicked inside any popup:', clickedInsideAny);

            // אם לא נלחץ בתוך אף אחד מה-Refs, עדכן את ה-state
            if (!clickedInsideAny) {
                setFlagSelect(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [actionsPopupRef, playersPopupRef, eventsPopupRef, platformPopupRef]);

    return (
        <View style={[styles.container, { borderRightColor: colors.infoBorder, borderLeftColor: colors.infoBorder }]}>
            <View>
                <Pressable style={[styles.logoContainer, { backgroundColor: colors.logoColor }]}>
                    {logo ? <Image style={styles.logo} source={{ uri: logo }} /> : null}
                </Pressable>
            </View>
            <View style={{ width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", marginTop: "2.4vw", paddingLeft: "1vw", borderBottomColor: colors.border, borderBottomWidth: "0.1vw" }}>
                <Image source={{ uri: images.filterIcon }} style={{ width: "1vw", height: "1vw" }} />
                <Text style={{ color: colors.buildText, fontSize: "1vw", fontFamily: "Catamaran_400Regular", marginLeft: "0.1vw" }}>{t("filter").toUpperCase()}</Text>
            </View>
            <ScrollView showsVerticalScrollIndicator={false} style={styles.filters}>
                {/* <UniversalFilter
                    addItem={addItem}
                    arr={actionsFilter}
                    arrSearch={actionsFilterSearch}
                    arrSelect={actionsFilterSelect}
                    keyType={"by_action"}
                    options={[]}
                    refPopup={actionsPopupRef}
                    removeItem={removeItem}
                    reset={reset}
                    searchItem={searchItem}
                    type={"action"}
                    zIndex={5}
                    flagSelect={flagSelect}
                    setFlagSelect={setFlagSelect}
                /> */}
                <UniversalFilter
                    addItem={addItem}
                    arr={playersFilter}
                    arrSearch={playersFilterSearch}
                    arrSelect={playersFilterSelect}
                    keyType={"by_players"}
                    options={[]}
                    refPopup={actionsPopupRef}
                    removeItem={removeItem}
                    reset={reset}
                    searchItem={searchItem}
                    type={"player"}
                    zIndex={4}
                    flagSelect={flagSelect}
                    setFlagSelect={setFlagSelect}
                />
                <UniversalFilter
                    addItem={addItem}
                    arr={eventsFilter}
                    arrSearch={eventsFilterSearch}
                    arrSelect={eventsFilterSelect}
                    keyType={"by_events"}
                    options={[]}
                    refPopup={actionsPopupRef}
                    removeItem={removeItem}
                    reset={reset}
                    searchItem={searchItem}
                    type={"event"}
                    zIndex={3}
                    flagSelect={flagSelect}
                    setFlagSelect={setFlagSelect}
                />
                <UniversalFilter
                    addItem={addItem}
                    arr={platformFilter}
                    arrSearch={platformFilterSearch}
                    arrSelect={platformFilterSelect}
                    keyType={"by_platform"}
                    options={[]}
                    refPopup={actionsPopupRef}
                    removeItem={removeItem}
                    reset={reset}
                    searchItem={searchItem}
                    type={"platform"}
                    zIndex={2}
                    flagSelect={flagSelect}
                    setFlagSelect={setFlagSelect}
                />

            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        borderRightWidth: "0.1vw",
        borderLeftWidth: "0.1vw",
        flex: 1,
    },
    logoContainer: {
        borderBottomLeftRadius: "1.5vw",
        borderBottomRightRadius: "1.5vw",
        paddingVertical: "0.5vw",
        paddingHorizontal: "1.4vw",
    },
    logo: {
        width: "6.5vw",
        height: "4vw",
        resizeMode: "contain",
    },
    filters: {
        width: "100%"
    },
});
