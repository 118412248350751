import { Pressable, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18n-js';
import { useSelector } from 'react-redux';
import QuestionnaireItem from './QuestionnaireItem';

export default function QuestionnaireMap({ stopPlayScroll, refShot, isShot, refShotAll, setIsShot }) {

    const conatinerRef = useRef(null);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(background, color, direction);

    const questionnaireState = useSelector(state => state.analytics.surveyArr);

    const questionnaireObj = useMemo(() => {
        const result = questionnaireState.reduce((acc, item) => {
            // Check if item has a message_id, title, and send_date (metadata entry)
            if (item.message_id && item.title && item.send_date) {
                if (!acc[item.message_id]) {
                    acc[item.message_id] = {
                        id: item.message_id,
                        title: item.title,
                        send_date: item.send_date,
                        questions: []
                    };
                }
            }
            // Check if item contains a question (question entry)
            Object.keys(item).forEach(key => {
                if (key !== 'message_id' && key !== 'title' && key !== 'send_date') {
                    const question = item[key];
                    if (!acc[item.message_id]) {
                        acc[item.message_id] = {
                            id: item.message_id,
                            title: item.title,
                            send_date: item.send_date,
                            questions: []
                        };
                    }
                    const newQuestion = {
                        all_answers: question.all_answers,
                        question_id: question.question_id,
                        question: question.question,
                        answers: question?.answers?.map(answer => ({
                            answer: answer.answer,
                            players: answer.players
                        })).concat(
                            // Add missing answers with null players
                            question.all_answers.filter(answer => !question.answers.find(a => a.answer === answer))
                                .map(answer => ({
                                    answer: answer,
                                    players: null
                                }))
                        )
                    };
                    // Add question only if it has a question_id and question text
                    if (newQuestion.question_id && newQuestion.question) {
                        acc[item.message_id].questions.push(newQuestion);
                    }
                }
            });

            return acc;
        }, {});

        // Remove entries without questions
        Object.keys(result).forEach(key => {
            if (result[key].questions.length === 0) {
                delete result[key];
            }
        });

        // Convert result object to array and sort by send_date
        const sortedResult = Object.values(result).sort((a, b) => new Date(a.send_date) - new Date(b.send_date));

        return sortedResult;
    }, [questionnaireState]);

    // useEffect
    //     (() => {
    //         if (conatinerRef.current) {
    //             conatinerRef.current.addEventListener("mousemove", (e) => {
    //                 stopPlayScroll(false);
    //             });
    //             conatinerRef.current.addEventListener("mouseleave", (e) => {
    //                 stopPlayScroll(true);
    //             });
    //         }
    //     }, [conatinerRef.current]);

    // components

    const body = () => (
        <View style={{ gap: "0.5vw" }}>
            {Object.keys(questionnaireObj).map((questionnaire, index) => {
                return <QuestionnaireItem item={questionnaireObj[questionnaire]} setIsShot={setIsShot} refShot={refShot} styles={styles} index={index} key={index} isShot={isShot} />;

            })}
        </View>
    );

    return (
        <>
            <View style={styles.container}
                nativeID='all-questionnaire'
            >
                <View style={styles.headerView}>
                    <Text style={styles.headers}>{t("questionnaire_map").toUpperCase()}</Text>
                </View>
                <View style={styles.main}>
                    {body()}
                </View>
            </View>
        </>

    )
}

const stylesR = (background, color, direction) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            maxWidth: "100%",
            writingDirection: direction
        },
        headerView: {
            flexDirection: "row",
            zIndex: 1,
            justifyContent: "space-between",
            alignItems: "center"
        },
        headers: {
            color: color.infoHeaderNavText,
            fontFamily: "Catamaran_300Light",
            fontSize: "1.1vw",
            marginLeft: "0.5vw",
            marginBottom: "0.3vw",

        },
        main: {
            padding: "1vw",
            backgroundColor: background.infoBox,
            borderRadius: "1.5vw",
            // maxHeight: "40vw"
        },
        mainShot: {
            width: "66vw",
            padding: "1vw",
            backgroundColor: background.infoBox,
            borderRadius: "1.5vw",
        },
        questionsContainer: {
            width: "100%",
            borderRadius: "0.7vw",
            backgroundColor: background.infoBody,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingHorizontal: "2.5vw",
            paddingVertical: "1vw",
        },
        questionsContainerLeft: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "2.5vw",
        },
        tableMain: {
            flexDirection: "column",
            alignItems: "center",
            gap: "1vw",
            width: "100%",
        },

    })
} 