import { View, StyleSheet, ScrollView, TextInput, Image } from "react-native";
import React, { useMemo } from "react";
import UseResponsiveSize from "../../../../../modules/UseResponsiveScreen";
import UseResponsiveScreen from "../../../../../modules/UseResponsiveScreen";
import { useSelector } from "react-redux";
import { t } from "i18n-js";
import PlayerCard from "./PlayerCard";
import { useRef } from "react";
import { useClickOutside } from "../../../../../modules/useClickOutside";
import { useState } from "react";

export default function ActionPlayersSelect({
  show,
  setShow,
  multiple,
  setActionPlayers,
  actionPlayers,
  options,
  permission
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const styles = stylesR(width, height, heightS, widthS, background, color);

  const selectRef = useRef(null);
  const inputRef = useRef(null);

  const images = useSelector((state) => state.style.images);

  const [filteredPlayers, setFilteredPlayers] = useState(options ? [...options] : []);
  const realPlayers = useMemo(() => filteredPlayers.filter((e) => e.is_represented === "0"), [filteredPlayers]);

  const search = (text) => {
    let temp = [...options];
    temp = temp.filter(
      (player) =>
        player.name_for_game.toLowerCase().indexOf(text.toLowerCase()) > -1
    );
    setFilteredPlayers(temp);
  };

  useClickOutside(selectRef, show, setShow);
  return (
    <View
      ref={selectRef}
      nativeID={show ? "filter-shadow" : ""}
      style={styles.container}
    >
      <View
        style={styles.search_view(show && filteredPlayers.length > 0)}
      >
        <TextInput
          ref={inputRef}
          editable={permission}
          onFocus={() => permission && setShow(true)}
          selectTextOnFocus={false}
          placeholderTextColor={color.infoHeaderNavText}
          style={styles.search_inp}
          placeholder={`${t("search_players")}`}
          onChangeText={(txt) => search(txt)}
        />
        <Image
          source={{ uri: images.searchIconSocial }}
          style={styles.search_icon}
        />
      </View>
      {show && filteredPlayers?.length > 0 && (
        <View
          style={styles.popup}
        >
          <ScrollView
            nativeID="action_players_dark"
            style={styles.scroll_popup(multiple)}
          >
            {multiple && <>
              {/* all players */}
              <PlayerCard
                to={t("all_players")}
                permission={permission}
                onPress={() => {
                  inputRef.current.focus();
                  !multiple && setShow(false);
                  setActionPlayers(filteredPlayers, true);
                }}
                select
                multiple={multiple}
                active={
                  multiple && actionPlayers.to_list_ids.split(",").filter((id) => id.trim()).length === filteredPlayers.length
                }
              />
              {/* real players */}
              <PlayerCard
                to={t("real_players")}
                permission={permission}
                onPress={() => {
                  inputRef.current.focus();
                  !multiple && setShow(false);
                  setActionPlayers(realPlayers, true);
                }}
                select
                multiple={multiple}
                active={
                  multiple && realPlayers.every(player => actionPlayers.to_list_ids.includes(player.id))
                }
              />
            </>}
            {filteredPlayers.map((player, i) => {
              return (
                <PlayerCard
                  to={player}
                  key={player.id}
                  permission={permission}
                  onPress={(player) => {
                    inputRef.current.focus();
                    !multiple && setShow(false);
                    setActionPlayers([player]);
                  }}
                  select
                  multiple={multiple}
                  active={
                    multiple && actionPlayers.to_list_ids.split(",").find((id) => id.trim() === player.id)
                  }
                />
              )
            })}
          </ScrollView>
        </View>
      )}
    </View>
  );
}

const stylesR = (width, height, heightS, widthS, background, color) =>
  StyleSheet.create({
    container: {
      marginBottom: heightS * 10,
      position: "relative"
    },
    search_view: (flag) => ({
      borderRadius: widthS * 7,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: widthS * 10,
      height: heightS * 32,
      backgroundColor: background.socialSearch,
      borderColor: color.infoBorder,
      borderBottomWidth: flag ? widthS * 1 : 0,
      borderBottomRightRadius: flag ? 0 : widthS * 7,
      borderBottomLeftRadius: flag ? 0 : widthS * 7,
    }),
    search_inp: {
      paddingVertical: widthS * 5,
      outlineStyle: "none",
      color: color.infoHeaderNavText,
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 9,
      width: "100%",
    },
    search_icon: {
      width: widthS * 11,
      height: widthS * 12,
      resizeMode: "contain",
    },
    popup: {
      paddingRight: widthS * 5,
      paddingTop: heightS * 5,
      backgroundColor: background.loginInput,
      borderBottomLeftRadius: widthS * 7,
      borderBottomRightRadius: widthS * 7,
      position: "absolute",
      top: "100%",
      width: "100%"
    },
    scroll_popup: (multiple) => ({
      maxHeight: !multiple ? heightS * 300 : heightS * 200,
      borderBottomLeftRadius: widthS * 7,
      borderBottomRightRadius: widthS * 7,
    })
  });
