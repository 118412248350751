import React, { useRef, useEffect, useState, useMemo, useCallback } from "react";
import {
    StyleSheet,
    ScrollView,
    Pressable,
    View,
    Text,
    BackHandler,
    FlatList,
    Image
} from "react-native";
import { useSelector } from "react-redux";
import { t } from "i18n-js";
import InputRadio from "../../InputRadio";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import {
    USER_GAME_NAME,
    USER_ID_IN_GAME,
    _NAME_FOR_GAME_,
} from "../../config.inc";
import HighlightText from '@sanar/react-native-highlight-text';
import FullTextTooltip from "./FullTextTooltip";

export default function PlayerSelect({
    playersList,
    value,
    system,
    setValue,
    styleType,
    popupRefList,
    allOrReal,
    setAllOrReal,
    close,
    field,
    fieldId,
    phraseToSearch,
    setPhraseToSearch,
    playersListProp,
    styles,
    newMail,
    focusInput,
    setSearchResult,
    setShowShearchPopup,
    setIndexMessage,
    backgroundColor
}) {
    const { widthS, heightS } = UseResponsiveScreen();

    const searchResultTextRef = useRef([]);
    const tooltipRef = useRef(null);

    const lang = useSelector((state) => state.setting.setting.lang);
    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);

    const [tooltip, setTooltip] = useState(false);


    const printCircle = (item) => {
        return (
            <InputRadio
                circle={newMail ? true : false}
                borderWidth={"0.6vw"}
                circleWidth={"0.4vw"}
                marginLeft={true}
                key={item.id}
                checkd={value[fieldId]?.includes(item.id) || (Array.isArray(value[fieldId]) && value[fieldId]?.find((e) => e.value === item.id))}
                background={styleType.social ? background.socialLogoColor : background.mailLogo}
                backgroundBorder={background.rightMenuPopupHeaderMailAndSocial}
            />
        );
    };

    const fromList = () => {
        return (
            <FlatList
                data={playersList}
                style={{ maxWidth: "100%", width: "100%" }}
                contentContainerStyle={{ maxWidth: "100%", width: "100%" }}
                maxToRenderPerBatch={50}
                updateCellsBatchingPeriod={5000}
                renderItem={({ item }) => {
                    return (
                        <Pressable
                            style={[styles.list_item, {
                                display: "flex", flexDirection: "row", alignItems: "center",
                                backgroundColor: styleType.social && background.socialInput
                            }]}
                            onPress={() => {
                                if ((value[fieldId] && value[fieldId]?.indexOf(item.id) < 0 || !value[fieldId])) {
                                    value[field] = item.name_for_game;
                                    value[fieldId] = item.id;
                                    setValue({ ...value });
                                    setPhraseToSearch("");
                                    close();
                                } else {
                                    setValue({ [field]: "", [fieldId]: "" });
                                }
                            }}
                        >
                            <FullTextTooltip text={item.name_for_game}>
                                <Text
                                    style={[
                                        styleType.dark ? styles.label_text_input : styles.list_item_text,
                                        value[fieldId]?.includes(item.id) && { fontFamily: "Catamaran_700Bold" },
                                        { direction: lang === "he" ? "rtl" : "ltr" },
                                        styleType.dark && { color: color.infoHeaderNavText },
                                    ]}
                                    numberOfLines={1}
                                >
                                    {item.name_for_game}
                                </Text>
                            </FullTextTooltip>
                        </Pressable>
                    );
                }}
                keyExtractor={(item) => item.id}
            />
        )
    }

    const addAllPlayers = (type, addOrRemove) => {
        let names = "";
        let ids = "";
        if (addOrRemove == "add") {
            playersList.map((e) => {
                if (e.id !== sessionStorage.getItem(USER_ID_IN_GAME)) {
                    if (field === "to_list" && (value.from_id === e.id || value?.cc_list_ids?.indexOf(e.id) >= 0)) return;
                    if (field === "cc_list" && (value.from_id === e.id || value?.to_list_ids.indexOf(e.id) >= 0)) return;
                    names += e.name_for_game + ",";
                    ids += e.id + ",";
                }
            })
            setValue({ [field]: names, [fieldId]: ids });
            setAllOrReal("all");
        } else {
            setValue({ [field]: "", [fieldId]: "" });
            setAllOrReal("");
        }
    }

    const addRealPlayers = (type, addOrRemove) => {
        let names = "";
        let ids = "";
        if (addOrRemove == "add") {
            playersList.map((e) => {
                if (e.is_represented == "0") {
                    if (field === "to_list" && (value.from_id === e.id || value?.cc_list_ids?.indexOf(e.id) >= 0)) return;
                    if (field === "cc_list" && (value.from_id === e.id || value?.to_list_ids.indexOf(e.id) >= 0)) return;
                    names += e.name_for_game + ",";
                    ids += e.id + ",";
                }
            })
            setValue({ [field]: names, [fieldId]: ids });
            setAllOrReal("real");
        } else {
            setValue({ [field]: "", [fieldId]: "" });
            setAllOrReal("");
        }

    }

    const toCcList = () => {
        return (
            <>
                {phraseToSearch == "" && (
                    <>
                        <Text
                            style={[
                                styles.label_text_input,
                                { margin: 5 },
                                styleType.dark && { color: color.infoHeaderNavText },
                            ]}
                        >
                            {t("groups")}
                        </Text>
                        <Pressable
                            style={[styles.list_item]}
                            onPress={() => {
                                if (allOrReal !== "all") {
                                    addAllPlayers(styleType.type, "add");
                                } else {
                                    addAllPlayers(styleType.type, "");
                                }
                                close();
                            }}
                        >
                            {
                                <Text
                                    style={[
                                        styles.list_item_text,
                                        styleType.dark && { color: color.infoHeaderNavText },
                                    ]}
                                >
                                    <InputRadio
                                        circle={newMail ? true : false}
                                        borderWidth={"0.6vw"}
                                        marginLeft={true}
                                        circleWidth={"0.4vw"}
                                        checkd={allOrReal === "all"}
                                        background={styleType.social ? background.socialLogoColor : background.mailLogo}
                                        backgroundBorder={
                                            background.rightMenuPopupHeaderMailAndSocial
                                        }
                                    />
                                    {t("all_players")}
                                </Text>
                            }
                        </Pressable>
                        <Pressable
                            style={[styles.list_item]}
                            onPress={() => {
                                if (allOrReal !== "real") {
                                    addRealPlayers(styleType.type, "add");
                                } else {
                                    addRealPlayers(styleType.type, "");
                                }
                                close();
                            }}
                        >
                            {playersList?.filter((x) => { return x.is_represented == "0" }).length > 0 && (
                                <Text
                                    style={[
                                        styles.list_item_text,
                                        styleType.dark && { color: color.infoHeaderNavText },
                                    ]}
                                >
                                    <InputRadio
                                        circle={newMail ? true : false}
                                        borderWidth={"0.6vw"}
                                        circleWidth={"0.4vw"}
                                        marginLeft={true}
                                        checkd={allOrReal === "real"}
                                        background={styleType.social ? background.socialLogoColor : background.mailLogo}
                                        backgroundBorder={
                                            background.rightMenuPopupHeaderMailAndSocial
                                        }
                                    />
                                    {t("real_players")}
                                </Text>
                            )}
                        </Pressable>
                    </>)}
                <Pressable style={{ maxWidth: "100%", width: "100%" }}>
                    <Text
                        style={[
                            styles.label_text_input,
                            { margin: 5 },
                            styleType.dark && { color: color.infoHeaderNavText },
                        ]}
                    >
                        {t("players")}
                    </Text>

                    <FlatList
                        data={playersList}
                        maxToRenderPerBatch={50}
                        style={{ maxWidth: "100%", width: "100%" }}
                        contentContainerStyle={{ maxWidth: "100%", width: "100%" }}
                        updateCellsBatchingPeriod={5000}
                        renderItem={({ item }) => {
                            if (field === "to_list" && (value.from_id === item.id || value?.cc_list_ids?.indexOf(item.id) > 0)) return;
                            if (field === "cc_list" && (value.from_id === item.id || value?.to_list_ids?.indexOf(item.id) > 0)) return;
                            return (
                                <Pressable
                                    style={[styles.list_item]}
                                    onPress={() => {
                                        if (!value[fieldId]?.includes(item.id)) {
                                            if ((value[field]?.length > 0 && value[field].lastIndexOf(",") >= value[field].length - 2) || value[field]?.length === 0) {
                                                value[field] += `${item.name_for_game},`;
                                                value[fieldId] += `${item.id},`;
                                            } else {
                                                value[field] += `,${item.name_for_game},`;
                                                value[fieldId] += `,${item.id},`;
                                            }
                                            setValue({ ...value });
                                            if (allOrReal !== "") {
                                                setAllOrReal("");
                                            }
                                        } else {
                                            value[field] = value[field].split(",").filter((e) => {
                                                return e !== item.name_for_game
                                            }).join(",");
                                            value[fieldId] = value[fieldId].split(",").filter((l) => { return l !== item.id }).join(",");
                                            setValue({ ...value });
                                            if (allOrReal !== "") {
                                                setAllOrReal("");
                                            }
                                        }
                                        focusInput && focusInput();
                                    }}
                                >
                                    {
                                        <FullTextTooltip text={item.name_for_game}>
                                            <Text
                                                style={[
                                                    styles.list_item_text,
                                                    { direction: lang === "he" ? "rtl" : "ltr" },
                                                    styleType.dark && { color: color.infoHeaderNavText },
                                                ]}
                                                numberOfLines={1}
                                            >
                                                {printCircle(item)}
                                                {item.name_for_game}
                                            </Text>
                                        </FullTextTooltip>
                                    }
                                </Pressable>
                            );
                        }}
                        keyExtractor={(item, index) => index}
                    />
                </Pressable>
            </>
        )
    }

    const eventList = () => {
        return (
            <FlatList
                data={playersList}
                maxToRenderPerBatch={50}
                updateCellsBatchingPeriod={5000}
                style={{ maxWidth: "100%", width: "100%" }}
                contentContainerStyle={{ maxWidth: "100%", width: "100%" }}
                renderItem={({ item }) => {
                    return (
                        <Pressable style={[styles.list_item]}>
                            <Pressable
                                style={[styles.list_item, {
                                    display: "flex", flexDirection: "row", alignItems: "center",
                                    backgroundColor: styleType.social && background.socialInput
                                }]}
                                onPress={() => {
                                    setValue({ [field]: item.name, [fieldId]: item.id });
                                    close();
                                }}
                            >
                                <View style={[styles.eventColor, { backgroundColor: item.color }]} />
                                <FullTextTooltip text={item.name}>
                                    <Text
                                        style={[
                                            styles.list_item_text_event,
                                            { direction: lang === "he" ? "rtl" : "ltr" },
                                            styleType.dark && { color: color.infoHeaderNavText },
                                        ]}
                                    >
                                        {item.name}
                                    </Text>
                                </FullTextTooltip>
                            </Pressable>
                        </Pressable>
                    );
                }}
                keyExtractor={(item) => item.id}
            />
        )
    }


    const searchList = () => {
        return (
            <>
                {playersList?.players?.length > 0 &&
                    <View style={styles.searchPlayers}>
                        <Text style={[styles.searchHeadersText, { color: color.mailSearch }]}>{t("players")}</Text>
                        <FlatList
                            data={playersList.players}
                            maxToRenderPerBatch={50}
                            updateCellsBatchingPeriod={5000}
                            renderItem={({ item }) => {
                                return (
                                    <Pressable
                                        onPress={() => { setSearchResult(playersList.arr, "player", item.id); }}
                                        style={({ hovered }) => [styles.itemSearch, { backgroundColor: hovered ? background.popup1 : "" }]}>
                                        <Image source={{ uri: item?.profile_image ? item?.profile_image : images.user_image }} style={styles.profileIcon} />
                                        <FullTextTooltip text={`${item?.name_for_game} ${item.real_name && item.real_name}`}>
                                            <Text style={styles.itemSearchRealName} numberOfLines={1}>{item?.name_for_game} {item.real_name && <Text style={styles.itemSearchRepName}>{item?.real_name}</Text>}</Text>
                                        </FullTextTooltip>
                                    </Pressable>
                                );
                            }}
                            keyExtractor={(item, i) => i}
                        />
                    </View>}
                {(playersList?.subject?.length > 0 || playersList?.to?.length > 0) &&
                    <View style={styles.searchMessages}>
                        <Text style={[styles.searchHeadersText, { color: color.mailSearch }]}>{t("messages")}</Text>
                        <FlatList
                            data={playersList.subject}
                            maxToRenderPerBatch={50}
                            updateCellsBatchingPeriod={5000}
                            renderItem={({ item, index }) => {
                                return (
                                    <Pressable
                                        onPress={() => { setSearchResult(playersList.subject, "subject", item.id); setIndexMessage(item?.id); }}
                                        style={({ hovered }) => [styles.itemSearch, { backgroundColor: hovered ? background.popup1 : "" }]}>
                                        <View style={[styles.messageIconView, styles.shadow]}>
                                            <Image source={{ uri: images.mail }} style={styles.messageIcon} />
                                        </View>
                                        <Text style={styles.itemSearchRealName} numberOfLines={1}>
                                            <HighlightText
                                                highlightStyle={{ backgroundColor: background.loginFooter }}
                                                searchWords={[playersList?.text]}
                                                textToHighlight={item.subject}
                                            />
                                        </Text>
                                    </Pressable>
                                );
                            }}
                            keyExtractor={(item, i) => i}
                        />
                        <FlatList
                            data={playersList.to}
                            maxToRenderPerBatch={50}
                            updateCellsBatchingPeriod={5000}
                            renderItem={({ item, index }) => {
                                let toText = "";
                                item.to_list_names?.split(",").forEach((e) => {
                                    if (e?.toLowerCase()?.indexOf(playersList?.text) >= 0) {
                                        toText += `${e}, `;
                                    }
                                });
                                return (
                                    <Pressable
                                        onHoverIn={(e) => {
                                            const { offsetWidth, scrollWidth } = searchResultTextRef.current[index];
                                            if (offsetWidth < scrollWidth) {
                                                setTooltip({
                                                    id: item.id,
                                                    top: searchResultTextRef.current[index].getBoundingClientRect().top,
                                                    subject: item.subject,
                                                    to: item.to_list_names
                                                })
                                            }
                                        }}
                                        onHoverOut={(e) => {
                                            setTooltip(false)
                                        }}
                                        onPress={() => { setSearchResult(playersList.to, "to"); setIndexMessage(item?.id); }}
                                        style={({ hovered }) => [styles.itemSearch, { backgroundColor: (hovered || item.id === tooltip?.id) ? background.popup1 : "" }]}>
                                        <View style={[styles.messageIconView, styles.shadow]}>
                                            <Image source={{ uri: images.mail }} style={styles.messageIcon} />
                                        </View>
                                        <Text numberOfLines={1} style={styles.itemSearchRealName} ref={(ref) => searchResultTextRef.current[index] = ref}>
                                            <HighlightText
                                                highlightStyle={{ backgroundColor: background.loginFooter }}
                                                searchWords={[playersList?.text]}
                                                textToHighlight={item.subject}
                                            />
                                            <HighlightText
                                                highlightStyle={{ backgroundColor: background.loginFooter }}
                                                searchWords={[playersList?.text]}
                                                textToHighlight={`(${toText})`}
                                                style={styles.itemSearchRepName}
                                            />
                                        </Text>
                                    </Pressable>
                                );
                            }}
                            keyExtractor={(item) => item.id}
                        />
                    </View>}
            </>
        )
    }

    const popupSearchResult = () => {
        return (
            <View ref={tooltipRef} style={[{
                position: "absolute",
                top: tooltip.top - popupRefList?.current?.getBoundingClientRect()?.top + 10,
                width: "max-content",
                maxWidth: "18vw",
                left: "4vw",
                borderRadius: "0.5vw",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: background.mailSearch
            }, styles.shadow]}>
                <Pressable
                    onPress={() => { setSearchResult(playersList.to, "to"); }}
                    style={styles.itemSearch}>
                    <Text style={styles.itemSearchRealName}>
                        <HighlightText
                            highlightStyle={{ backgroundColor: background.loginFooter }}
                            searchWords={[playersList?.text]}
                            textToHighlight={tooltip.subject}
                        />
                        <HighlightText
                            highlightStyle={{ backgroundColor: background.loginFooter }}
                            searchWords={[playersList?.text]}
                            textToHighlight={`(${tooltip.to})`}
                            style={styles.itemSearchRepName}
                        />
                    </Text>
                </Pressable>
            </View>
        )
    }

    const platformSelect = () => {
        return (
            <FlatList
                data={playersListProp?.filter((e) => e.value !== value?.platform_type)}
                maxToRenderPerBatch={50}
                updateCellsBatchingPeriod={5000}
                style={{ maxWidth: "100%", width: "100%" }}
                contentContainerStyle={{ maxWidth: "100%", width: "100%" }}
                renderItem={({ item }) => {
                    return (
                        <Pressable
                            style={[styles.list_item, {
                                display: "flex", flexDirection: "row", alignItems: "center",
                                backgroundColor: styleType.social && background.socialInput
                            }]}
                            onPress={() => {
                                setValue({ [field]: item?.value, });
                                close();
                            }}
                        >
                            <Image source={{ uri: item?.icon }} style={styles.logoIconPlatform()} />
                            <FullTextTooltip text={item?.label}>
                                <Text
                                    style={[
                                        styles.list_item_text,
                                        styleType.dark && { color: color.infoHeaderNavText },
                                    ]}
                                >
                                    {item?.label}
                                </Text>
                            </FullTextTooltip>
                        </Pressable>
                    );
                }}
                keyExtractor={(item) => item.id}
            />
        )
    }
    return (
        <>
            <ScrollView
                ref={popupRefList}
                nativeID={styleType.dark ? "mail-players-dark" : "mail-players"}
                style={[
                    styles.popup_list,
                    lang === "he"
                        ? { right: 0, width: "99.8%" }
                        : { left: 0, width: "99.8%" },
                ]}
                contentContainerStyle={[
                    styles.popup_list_content,
                    { backgroundColor: backgroundColor ? backgroundColor : styleType.dark ? background.infoBody : "" },
                    lang === "he" && { direction: "rtl" },
                ]}
            >
                {!styleType.check && styleType.type !== "event" && (
                    fromList()
                )}

                {styleType.check && styleType.type !== "event" && (
                    toCcList()
                )}
                {styleType.type === "event" && (
                    eventList()
                )}
                {styleType.type === "search" && (
                    searchList()
                )}
                {styleType.type === "platform" && (
                    platformSelect()
                )}
            </ScrollView>
            {tooltip && popupSearchResult()}
        </>
    )

}