const enWords = [
    "i", "Me", "My", "Ill",
    "you", "Your", "yours", "Youre", "Youll", 
    "She", "shell", "Shes", "Her", "Hers",
    "He", "Hell", "Hes", "His",
    "Us", "We", "Were", "Well",
    "Them", "They", "Theyre", "theyll",
    "It", "Its", "Its", "Itll",
    "This", "that", "Those", "These",
    "Is", "Was", "were", "Will", "Be",
    "A", "Ah", "Oh", "And",
    "Hey", "Hi", "Hello", "Dear", "are",
    "Undefined", "null", "true", "false", "The",
    "an", "with", "to", "there", "their", "or", "on",
    "of", "in", "have", "has", "from", "for", "by", "at", "as",
];
export const blackList = (lang) => {
    switch (lang) {
        case "en":
            return enWords;
        case "it":
            return [
                "A",
                "abbiamo",
                "ad",
                "ai",
                "al",
                "alla",
                "alle",
                "anche",
                "buongiorno",
                "cè",
                "cè",
                "ci",
                "ciao",
                "coi",
                "col",
                "come",
                "comunicazione",
                "con",
                "cordiali",
                "da",
                "dal",
                "dalla",
                "dalle",
                "degli",
                "dei",
                "del",
                "dell",
                "delle",
                "dentro",
                "di",
                "fra",
                "grz",
                "hai",
                "il",
                "in",
                "io",
                "la",
                "le",
                "lo",
                "ma",
                "mi",
                "nel",
                "nello",
                "ok",
                "per",
                "qua",
                "qualche",
                "qualcosa",
                "qualcuno",
                "quale",
                "quali",
                "quando",
                "quanto",
                "quanta",
                "quante",
                "saluti",
                "se",
                "sei",
                "senza",
                "si",
                "siamo",
                "sono",
                "sugli",
                "suo",
                "suoi",
                "sul",
                "sulla",
                "sulle",
                "tutto",
                "tutti",
                "un",
                "una",
                "uno",
                "verso",
                "vi",
                "vostra",
                "vostre",
                "&+grave"
            ];
        case "he":
            return [
                "את",
                "אתה",
                "אתן",
                "אתם",
                "על",
                "עם",
                "ואת",
                "ואתם",
                "ואני",
                "והיא",
                "והוא",
                "והם",
                "וכן",
                "ולא",
                "ולו",
                "ומה",
                "ונכוון",
                "ועל",
                "ועם",
                "ועצמה",
                "ועצמו",
                "ועצמם",
                "וקוראים",
                "ושוב",
                "זה",
                "זו",
                "זאת",
                "הוא",
                "היא",
                "הם",
                "הן",
                "הן",
                "הם",
                "הן",
                "הפעם",
                "הרי",
                "זאת",
                "זה",
                "יוכל",
                "יכול",
                "יכולה",
                "יכולות",
                "יכולים",
                "יכולים",
                "יכלו",
                "כאן",
                "כך",
                "ככה",
                "כי",
                "כן",
                "לא",
                "לה",
                "לו",
                "לי",
                "לכם",
                "לכן",
                "לנו",
                "מדוע",
                "מה",
                "מהם",
                "מהן",
                "מול",
                "מולו",
                "מולה",
                "מולם",
                "מולן",
                "מולם",
                "מחוץ",
                "מי",
                "מכאן",
                "מכיוון",
                "מכל",
                "מכם",
                "מכן"
            ];
        default: return enWords;
    }
}