import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { View, Pressable, StyleSheet, TextInput } from 'react-native';
import TextCustomize from '../../../../Elements/TextCustomize';
import { t } from 'i18n-js';

const NewsBody = ({ body, subject, setSubject, setBody }) => {
    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = stylesR(background, color);

    return (
        <View style={styles.container}>
            <View style={styles.newsTitleView}>
                <TextCustomize keyText={"breaking-news"} fontSize={"1vw"} fontWeight={800} />
                <View style={styles.traingle(direction)} />
            </View>
            <View style={styles.main}>
                <View style={styles.headlineView}>
                    <View style={styles.lable}>
                        <TextCustomize keyText={"headline"} fontSize={"0.7vw"} fontWeight={400} />
                    </View>
                    <View style={styles.inputView}>
                        <TextInput style={styles.input(direction)} value={subject || ""} onChangeText={(txt) => setSubject(txt)} />
                        <TextCustomize style={styles.count} text={`${subject?.length || 0}/75`} fontSize={"0.6vw"} fontWeight={300} />
                    </View>
                </View>
                <View style={styles.bodyView}>
                    <View style={styles.lable}>
                        <TextCustomize text={t("article")} fontSize={"0.7vw"} fontWeight={400} />
                    </View>
                    <View style={styles.inputView}>
                        <TextInput nativeID="social" dir={direction} multiline={true} numberOfLines={6} style={styles.input(direction)} value={body || ""} onChangeText={(txt) => setBody(txt)} />
                        <TextCustomize style={styles.count} text={`${body?.length || 0}/250`} fontSize={"0.6vw"} fontWeight={300} />
                    </View>
                </View>
            </View>
        </View>
    )
};
const stylesR = (background, color) => StyleSheet.create({
    container: {
        paddingVertical: "0.5vw",
    },
    main: {
        flex: 1,
        paddingVertical: "1vw",
        paddingHorizontal: "2vw",
    },
    newsTitleView: {
        flexDirection: "row",
        backgroundColor: background.newsLogo,
        paddingHorizontal: "1vw",
        width: "max-content"
    },
    traingle: (direction) => ({
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderLeftWidth: "1.5vw",
        borderRightWidth: "1.5vw",
        borderBottomWidth: "1.7vw",
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        ...(direction === "rtl" ? { marginLeft: "-2.45vw", } : { marginRight: "-2.45vw", }),
        borderBottomColor: background.newsLogo
    }),
    headlineView: {
        flexDirection: "row",
        justifyContent: "flex-start",
        marginBottom: "1vw"
    },
    bodyView: {
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    lable: {
        width: "5vw",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginTop: "0.5vw"
    },
    inputView: {
        flex: 1
    },
    input: (direction) => ({
        backgroundColor: background.infoBox,
        borderRadius: "0.5vw",
        paddingVertical: "0.4vw",
        flex: 1,
        paddingHorizontal: "1vw",
        outlineStyle: "none",
        fontSize: "0.7vw",
        color: color.text,
        fontFamily: "Catamaran_400Regular",
        writingDirection: direction
    }),
    count: {
        alignSelf: "flex-end",
        marginRight: "0.4vw"
    }
});
export default NewsBody;