import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Pressable } from 'react-native-web';
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react'
import { t } from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux';
import { HeatMapFuncContext } from '../analyticsFunction/HeatMapFunc';
import UseResponsiveSize from '../../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import { ScrollSyncPane } from 'react-scroll-sync';
import "./style.css";
import TimeUnits from '../../../../Elements/TimeUnits';
import TooltipHeatMap from './TooltipHeatMap';
import { AnalyticstimesContext } from '../analyticsFunction/Analyticstimes';
import TimesSlider from './TimesSlider';
import Rangeslider from '../../../../Elements/Rangeslider';
import IconButton from '../../../../Elements/IconButton';
import AnimationLoading from '../../../../modules/AnimationLoading';
import { MaterialIcons } from '@expo/vector-icons';
import FullTextTooltip from '../../../../Elements/FullTextTooltip';

export default function HeatMap({ type, real, stopPlayScroll, maximize, setMaximize, refShot, isShot }) {

    const {
        arrScore,
        arrScoreReal,
        players,
        maxCount,
        maxCountReal,
        filterStatistics,
        refresh,
        playersRef,
        playersRefMaximize,
        setRefresh,
    } = useContext(HeatMapFuncContext);

    const {
        timeUnitsState,
        setTimeUnitsState,
        // position,
        // setPosition,
        timesJson,
        timesRealJson,
        count: countTimes,
    } = useContext(AnalyticstimesContext);

    const { widthS, heightS } = UseResponsiveScreen();

    const leftRef = useRef(null);
    const rightRef = useRef(null);
    const cellRef = useRef([]);
    const tableRef = useRef(null);
    const timesRef = useRef([]);
    const viewAllCellRef = useRef(null);
    const viewAllCellRefCapture = useRef(null);

    const scoreScroll = useRef(null);

    let maxValue = 1002;

    const colors = [
        "#A5A2A5",
        "#64F4D2",// 1 - 5
        "#00DBAF",// 6 - 10
        "#02B284",// 11 - 15
        "#FCF497",// 16 - 20
        "#FCF000",// 21 - 25
        "#E8C600",// 26 - 30
        "#F25CA0",// 31 - 35
        "#CC0053"
    ]

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const styles = stylesR(heightS, widthS, background, color);

    const images = useSelector((state) => state.style.images);

    const [maxCountState, setMaxCountState] = useState();
    const [arrScoreState, setArrScoreState] = useState([]);
    const [tooltip, setTooltip] = useState(false);
    const [count, setCount] = useState({ start: 0 });
    const [countItems, setCountItems] = useState(type === "compared" ? 3 : 8);
    const [step, setStep] = useState(maxValue / countItems);
    const [value, setValue] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [delay, setDelay] = useState(true);
    const [position, setPosition] = useState(0);

    const scrollRef = useRef([]);
    let flag = true;
    const { times } = TimesSlider({ type, scrollRef, flag, real });

    useEffect(() => {
        let part;
        if (maximize) {
            part = Math.min(times.length, (type === "compared" ? 6 : 13));
        } else {
            part = Math.min(times.length, (type === "compared" ? 4 : 9));
        }
        setCountItems(part);
        setStep(maxValue / (part - 1));
    }, [maximize, times]);

    const changeRound = (typeRound) => {
        let newPosition;
        switch (typeRound) {
            case "+":
                let newCount = Math.min(count.start + countItems, (times.length) - countItems);
                newPosition = Math.round(newCount + (countItems / 2));
                setPosition(newPosition);
                return setCount({ start: newCount });
            case "-":
                let decreasedCount = Math.max(count.start - countItems, 0);
                newPosition = Math.round(decreasedCount + (countItems / 2));
                setPosition(newPosition);
                return setCount({ start: decreasedCount });
        }
    }

    const printBackground = (number) => {
        switch (true) {
            case (number >= 1 && number <= 5):
                return 1;
            case (number >= 6 && number <= 10):
                return 2;
            case (number >= 11 && number <= 15):
                return 3;
            case (number >= 16 && number <= 20):
                return 4;
            case (number >= 21 && number <= 25):
                return 5;
            case (number >= 26 && number <= 30):
                return 6;
            case (number >= 31 && number <= 35):
                return 7;
            case (number > 35):
                return 8;
            default:
                return 0;
        }
    }

    const setNewItemCurrent = (value, start) => {
        let position = Math.round(value / step) + start;
        setPosition(position ? position : 0);
    }

    const handleChange = (value, position) => {
        const maxValue = maxValue;
        let newCountstart;
        if (value === maxValue && count.start + countItems < times.length - 1) {
            setDisabled(false);
            newCountstart = count.start + (countItems);
            setCount({ ...count, start: count.start + 1, });

            setNewItemCurrent(step * (countItems - (newCountstart - count.start)), newCountstart);

            setTimeout(() => {
                setValue(step * (countItems - 1));
                setDisabled(true);
            }, 10);
        } else if (value > 0) {
            setValue(value);
            let newPosition = Math.round(value / step) + count.start;
            setPosition(newPosition ? newPosition : 0);
            // setNewItemCurrent(value, count.start);
        } else {
            setDisabled(false);
            newCountstart = count.start - 1 >= 0 ? count.start - 1 : 0
            setCount({ ...count, start: newCountstart });
            setNewItemCurrent(count.start - 1 >= 0 ? step * 1 : 0, newCountstart);
            setTimeout(() => {
                setValue(count.start - 1 >= 0 ? step * 1 : 0);
                setDisabled(true);
            }, 10);
        }
    }
    // elements \\

    // table
    const table = (isShot) => (!delay ? (arrScoreState?.length > 0 && (
        <View style={[styles.rightMapView, type === "compared" && !real ? { width: "65%" } : type === "compared" && { width: "40vw" }, real && { width: !maximize && !isShot ? "21vw" : "100%" }]} ref={rightRef}>
            <View style={{ width: "1.5vw", zIndex: 10, alignItems: "center", justifyContent: "center", position: "absolute", left: "1vw", top: "0.8vw" }}>
                <TouchableOpacity
                    disabled={count?.start === 0}
                    style={{ paddingLeft: "0.3vw" }} onPress={() => changeRound("-")}>
                    <MaterialIcons name='arrow-back-ios' color={count?.start === 0 ? color.border : color.text} size={"1vw"} />
                </TouchableOpacity>
            </View>
            <View style={{ width: "1.5vw", zIndex: 10, alignItems: "center", justifyContent: "center", position: "absolute", right: "1vw", top: "0.8vw" }}>
                <TouchableOpacity
                    disabled={(count.start + countItems) === (times?.length)}
                    style={{ paddingRight: "0.3vw" }} onPress={() => changeRound("+")}>
                    <MaterialIcons name='arrow-forward-ios' color={(count.start + countItems) === (times?.length) ? color.border : color.text} size={"1vw"} />
                </TouchableOpacity>
            </View>
            <View style={styles.scrollBarC}>
                <View style={[styles.scrollBar, { width: isShot ? viewAllCellRefCapture.current?.offsetWidth : viewAllCellRef.current?.offsetWidth }]}>
                    <Rangeslider
                        className={"slider-heat-map"}
                        disabled={!disabled}
                        id={"range-slider"}
                        indicator={"indicator-heat"}
                        max={maxValue}
                        min={0}
                        width={timesRef.current[0]?.getBoundingClientRect()?.width}
                        onChange={(e) => {
                            handleChange(e, position);
                        }}
                        step={step}
                        value={value}
                    />
                </View>
            </View>
            {/* <View style={[styles.scrollBar, type === "compared" && real && styles.scrollBarComparedReal, type === "compared" && !real && styles.scrollBarComparedExpected, type === "compared" && maximize && { width: "31vw", left: "1.9vw", }]}> */}
            {/* {(() => {
                if (viewAllCellRef.current) {
                    return (
                      
                    )
                }
            })()} */}

            <ScrollSyncPane
                group={"y"}
                ref={scoreScroll}
            >
                <View
                    nativeID='scroll-y-heat-map'
                    style={[styles.scrollYContainer, type !== "compared" && { width: !maximize && !isShot ? "50vw" : "100%" }]}>
                    <View
                        nativeID={"view-all-cell-heat-map"}
                        style={[styles.scrollYMain]}
                    >
                        <View
                            ref={isShot ? viewAllCellRefCapture : viewAllCellRef}
                            style={[styles.tableMain]}>
                            {times?.length > 0 && times?.map((e, i) => {
                                if (i > 100 * count) return;

                                if (!isShot) {
                                    cellRef.current.push([]);
                                }

                                let date1, data2;
                                if (type === "real" || (type === "compared" && real)) {
                                    date1 = new Date(times[i]?.date).toString();
                                    data2 = new Date(times[position >= 0 ? position : 0]?.date).toString();
                                } else {
                                    date1 = times[i]?.time;
                                    data2 = times[position >= 0 ? position : 0]?.time;
                                }
                                if ((i >= count.start && i < count.start + countItems) || isShot) {

                                    return (
                                        <View
                                            ref={(ref) => timesRef.current[i] = ref}
                                            key={i} style={styles.column}>
                                            {/* times scroll */}
                                            <View style={[styles.timesItemView, {
                                                backgroundColor: background.infoHeader,
                                            }]}>
                                                <Pressable
                                                    style={[styles.btnTimeItem, { backgroundColor: date1 === data2 ? background.loginFooter : "", }]}
                                                    onPress={() => {
                                                        setPosition(i);
                                                    }}
                                                    key={i}>
                                                    <Text style={[styles.filterTimeText, { color: color.infoHeaderNavText, fontFamily: date1 === data2 ? "Catamaran_700Bold" : "Catamaran_300Light" }]}>{type == "expected" && "T"}{e.time}</Text>
                                                </Pressable>
                                            </View>
                                            {/* score */}
                                            <View style={styles.scoreColumnView}>
                                                {(() => {
                                                    return players?.length > 0 && (maximize && playersRefMaximize.current?.length > 0 || !maximize && playersRef.current.length > 0) && arrScoreState[i]?.map((player, indexPlayer) => {
                                                        let count = 0;
                                                        Object.keys(player.score).forEach((e) => {
                                                            count += player.score[e];
                                                        });
                                                        if (!isShot) {
                                                            cellRef.current[i].push([]);
                                                        }
                                                        return (
                                                            <Pressable
                                                                key={indexPlayer}
                                                                // disabled={true} 
                                                                ref={(ref) => { if (!isShot) { cellRef.current[i][indexPlayer] = ref } }}
                                                                onHoverIn={() => {
                                                                    if (count > 0 && !isShot) {
                                                                        setTooltip({
                                                                            x: cellRef.current[i][indexPlayer].getBoundingClientRect().x -
                                                                                (tableRef.current?.getBoundingClientRect().x + widthS * 55) + (cellRef.current[i][indexPlayer].getBoundingClientRect().width / 3),
                                                                            y: cellRef.current[i][indexPlayer].getBoundingClientRect().y -
                                                                                (tableRef.current?.getBoundingClientRect().y - widthS * 25),
                                                                            data: player.score,
                                                                        })
                                                                    }
                                                                }}
                                                                onHoverOut={() => setTooltip(false)}
                                                                style={[styles.btnNum, {
                                                                    backgroundColor: colors[printBackground(count)],
                                                                    width: scrollRef.current[i]?.getBoundingClientRect()?.width + widthS * 10,
                                                                    height: (maximize && playersRefMaximize.current[indexPlayer]?.getBoundingClientRect().height || !maximize && playersRef.current[indexPlayer]?.getBoundingClientRect().height)
                                                                }]}>
                                                                {({ hovered }) => (<>
                                                                    <Text style={styles.scoreText} >{count}</Text>
                                                                </>)}
                                                            </Pressable>
                                                        )
                                                    })
                                                })()}
                                            </View>
                                        </View>
                                    )
                                }
                            })}
                        </View>
                    </View>
                </View>
            </ScrollSyncPane>
        </View>
    )) : <AnimationLoading flag={true} widget={true} />);

    // header - titles - timeUnits                       
    const header = (isMaximize) => (
        <View style={styles.headersView}>
            <Text style={[styles.headers, { color: color.infoHeaderNavText }, real && { visibility: "hidden" }]}>{t("HEAT").toUpperCase()}</Text>
            <View style={[{ justifyContent: "space-between", flexDirection: "row", alignItems: "center" }, type === "compared" && { flex: 1 }]}>
                {type === "compared" && <Text style={[styles.headers, { color: color.infoHeaderNavText, fontSize: widthS * 9, marginTop: heightS * 6, marginLeft: !real ? widthS * 55 : widthS * -40 }]}>{!real ? t("expected").toUpperCase() : t("real").toUpperCase()}</Text>}
                <View style={{ alignItems: "center", flexDirection: "row" }}>
                    {(type !== "compared" || real) && !maximize &&
                        <IconButton
                            flag={isMaximize}
                            iconName1={images.maximize}
                            iconName2={images.xIcon}
                            setFlag={() => setMaximize(!maximize)}
                            style={{ width: "0.8vw", height: "0.8vw", resizeMode: "contain" }}
                            style2={{ width: "1vw", height: "1vw", resizeMode: "contain", }}
                        />
                    }
                    {(real || type === "real") && <TimeUnits setTimeUnits={(t) => { setTimeUnitsState(t); setRefresh(!refresh) }} />}
                </View>
            </View>
        </View>
    )
    // left side players list
    const leftSidePlayersList = (
        <View style={styles.leftPlayerView} ref={leftRef}>
            <ScrollSyncPane
                // nativeID='scroll-heat'
                onScroll={(e) => {
                    // setScrollY(e.nativeEvent.contentOffset.y);
                    // scoreScroll.current?.scrollTo({x: 0, y: e.nativeEvent.contentOffset.y, animated: false});
                }}
                group={"y"}
            >
                <View
                    nativeID='heat-map-scroll'
                    style={styles.scrollPlayersC}>
                    <View style={{ minHeight: "101%", }}>
                        <View style={{ minHeight: "25vw" }}>
                            <View style={[styles.playersHeader, { backgroundColor: background.infoHeader, }]}>
                                <Text style={[styles.headers1, { color: color.infoHeaderNavText }]}>{t("players").toUpperCase()}</Text>
                            </View>
                            <View style={styles.keysColorsViewPlayers}>
                                <View style={[styles.colorsKeysView]}>
                                    <Text style={[styles.colorKeysText, { color: color.text }]}><View style={[styles.colorsCircleKeys, { backgroundColor: colors[2] }]} /> {t("low")} </Text>
                                    <Text style={[styles.colorKeysText, { color: color.text }]}> <View style={[styles.colorsCircleKeys, { backgroundColor: colors[5] }]} /> {t("moderate")}</Text>
                                    <Text style={[styles.colorKeysText, { color: color.text }]}><View style={[styles.colorsCircleKeys, { backgroundColor: colors[colors.length - 1] }]} /> {t("high")} </Text>
                                </View>
                            </View>
                            {players.map((player, indexPlayer) => {
                                return <View key={indexPlayer} style={{ minHeight: "2.5vw", justifyContent: "center", marginVertical: "0.07vw", }} ref={(ref) => ref && (maximize && !playersRefMaximize.current.find((e) => e === ref) && playersRefMaximize.current.push(ref) || !maximize && !playersRef.current.find((e) => e === ref) && playersRef.current.push(ref))}>
                                    <FullTextTooltip text={player.name_for_game}>
                                        <Text numberOfLines={1} style={[styles.playersName, { color: color.infoHeaderNavText }]}>{player.name_for_game}</Text>
                                    </FullTextTooltip>
                                </View>
                            })}
                        </View>
                    </View>
                </View>
            </ScrollSyncPane>
        </View>
    )
    const body = (isMaximize, isShot) => (
        <>
            {header(isMaximize)}
            {/* table */}
            <View ref={isShot ? null : tableRef} style={[styles.table, { height: isMaximize || isShot ? "100%" : "25vw", }, real && styles.tableReal, !real && styles.tableExpected]}>
                {/* async scroll */}
                <>
                    {/* header background */}
                    <View style={[styles.headerBackground, { backgroundColor: background.infoHeader, }, !real && { borderTopLeftRadius: widthS * 19, }]} />
                    {/* scroll - background */}
                    <View style={styles.scrollBackground} />
                    {/* left */}
                    {real && !isShot ? table(isShot) :
                        <>
                            {leftSidePlayersList}
                            {/* right */}
                            {table(isShot)}
                        </>
                    }
                </>
                {tooltip && <TooltipHeatMap tooltip={tooltip} />}
            </View>
        </>
    )
    /// end \\\
    useEffect(() => {
        playersRef.current = [];
        playersRefMaximize.current = [];
    }, [type, refresh]);

    useEffect(() => {
        if (type === "expected" || (type === "compared" && !real)) {
            setArrScoreState(arrScore);
            if (maxCount > 0) {
                setMaxCountState(maxCount);
            }
        } else if (type === "real" || (type === "compared" && real)) {
            setArrScoreState(arrScoreReal);
            if (maxCountReal > 0) {
                setMaxCountState(maxCountReal);
            }
        }
    }, [arrScore, arrScoreReal, maxCount, maxCountReal]);

    useEffect(() => {
        setTimeout(() => {
            filterStatistics(timeUnitsState, type, real, times);
            filterStatistics(timeUnitsState, type, real, times, true);
            setDelay(false);
        }, maxValue);
    }, [refresh, timeUnitsState, times, count]);

    const updateValues = useCallback(() => {
        let newCountStart = count.start;
        let newValue;

        if (position < 0 || step < 0 || countItems <= 0) return;

        if (position < newCountStart) {
            // לולאה להפחתת count.start
            while (position < newCountStart && newCountStart > 0) {
                newCountStart = Math.max(newCountStart - countItems, 0);
            }
        } else if (position >= newCountStart + countItems) {
            // לולאה להוספת count.start
            while (position >= newCountStart + countItems && newCountStart < times.length - countItems) {
                newCountStart = Math.min(newCountStart + countItems, times.length - countItems);
            }
        }

        setCount({ ...count, start: newCountStart });
        newValue = (position - newCountStart) * step;
        setValue(newValue);
    }, [position, step, countItems, count, times.length]);


    useEffect(() => {
        updateValues();
    }, [position]);


    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.addEventListener("mousemove", (e) => {
                stopPlayScroll(false);
            });
            tableRef.current.addEventListener("mouseleave", (e) => {
                stopPlayScroll(true);
            });
        }
    }, [tableRef.current]);

    return (
        <>
            <View
                // nativeID='heatMap'
                style={[styles.container, type !== "compared" && { flex: 1 }, type === "compared" && !real && { width: !maximize ? "38vw" : "55%" }, real && { width: !maximize ? "25vw" : "40%" }]}>
                {/* title */}
                {/* <Text style={[styles.headers, { color: color.infoHeaderNavText }]}>{t("HEAT")}</Text> */}
                {body(maximize)}
            </View >
            {isShot === "heat" && <View
                ref={(ref) => {
                    if (!ref) {
                        return; // אל תוסיף אם ה-ref הוא null
                    }

                    if (!refShot.current) {
                        refShot.current = [];
                    }

                    if (type === "compared" && real) {
                        refShot.current[1] = ref;
                    } else {
                        refShot.current[0] = ref;
                    }
                }}
                style={{ flex: 1, overflow: 'hidden', width: "max-content", height: "100%", zIndex: -maxValue }}
            >
                {body(maximize, true)}
            </View>}
        </>
    )
}

const stylesR = (heightS, widthS, background, color) => {
    return StyleSheet.create({
        container: {
            marginBottom: heightS * 20,
            zIndex: 4,
        },
        headerBackground: {
            width: "100%",
            top: 0,
            position: "absolute",
            height: "2.8vw",
            borderTopRightRadius: widthS * 19,
            paddingBottom: heightS * 10
        },
        scrollBackground: {
            backgroundColor: "#302B34",
            position: "absolute",
            height: "1.8vw",
            top: "2.8vw",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            zIndex: 2
        },
        keysColorsViewPlayers: {
            backgroundColor: "#302B34",
            position: "sticky",
            top: "2.8vw",
            marginBottom: "1.8vw",
            justifyContent: "center",
            zIndex: 3,
            height: "1.8vw",
        },
        colorsKeysView: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "2vw"
        },
        colorsCircleKeys: {
            width: "0.6vw",
            height: "0.6vw",
            borderRadius: "50%",
            marginRight: "0.2vw"
        },
        colorKeysText: {
            fontSize: "0.7vw",
            fontFamily: "Catamaran_300Light",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginRight: "0.8vw"
        },
        headersView: {
            flexDirection: "row",
            zIndex: 1,
            justifyContent: "space-between",
        },
        headers: {
            fontSize: widthS * 12,
            marginLeft: widthS * 6,
            marginBottom: heightS * 3,
            fontFamily: "Catamaran_300Light"
        },
        headers1: {
            fontSize: "1vw",
            fontFamily: "Catamaran_300Light"
        },
        table: {
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
            backgroundColor: background.infoBox
        },
        tableReal: {
            borderTopRightRadius: widthS * 19,
            borderBottomRightRadius: widthS * 19,
            justifyContent: "center"
        },
        tableExpected: {
            borderRadius: widthS * 19,
        },
        leftPlayerView: {
            width: "14vw",
            height: "100%",
            position: "relative",
            zIndex: 3
        },
        scrollPlayersC: {
            overflowY: "scroll",
            paddingBottom: "1vw",
            overflowX: "hidden",
            maxHeight: "100%",
            height: "100%",
            width: "100%"
        },
        playersHeader: {
            borderTopLeftRadius: "1.5vw",
            height: "2.8vw",
            top: 0,
            zIndex: 3,
            position: "sticky",
            paddingTop: "0.2vw",
            paddingLeft: "2vw",
            justifyContent: "center",
        },
        scrollYContainer: {
            overflow: "scroll",
            maxHeight: "100%",
            height: "100%",
            alignItems: "center"
        },
        scrollYMain: {
            minHeight: "101%",
            position: "relative",
            alignItems: "center",
        },
        tableMain: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
        },
        column: {
            flexDirection: "column",
            height: "fit-content",
        },
        rightMapView: {
            height: "100%",
            flex: 1,
            position: "relative",
            alignItems: "center",
            paddingBottom: "1vw",
            zIndex: 3
        },
        scrollBarC: {
            backgroundColor: "#302B34",
            width: "100%",
            height: "1.8vw",
            position: "absolute",
            alignItems: "center",
            top: "2.8vw",
            zIndex: 100,
        },
        scrollBar: {
            width: "44vw",
        },
        scrollBarComparedReal: {
            width: "20.5vw", left: "-0.3vw",
        },
        scrollBarComparedExpected: {
            left: "1.9vw",
            width: "20.5vw",
        },
        scrollBar2: {
            width: "45vw",
            paddingRight: "1.6vw",
        },
        timesItemView: {
            top: 0,
            padding: widthS * 2,
            height: "3vw",
            zIndex: 1,
            position: "sticky",
        },
        btnTimeItem: {
            height: "2.4vw",
            // margin: widthS * 2,
            zIndex: 1,
            width: widthS * 45,
            alignItems: "center",
            borderRadius: widthS * 5,
            justifyContent: "center"
        },
        filterTimeText: {
            minWidth: "2.5vw",
            fontSize: "0.9vw",
            textAlign: "center",
        },
        scoreColumnView: {
            flex: 1,
            marginTop: "3vw"
        },
        playersName: {
            paddingHorizontal: "2vw",
            fontSize: "1vw",
            fontFamily: "Catamaran_300Light"
        },
        btnNum: {
            marginHorizontal: widthS * 0.5,
            marginVertical: widthS * 0.5,
            borderRadius: "0.8vw",
            alignItems: "center",
            justifyContent: "center"
        },
        scoreText: {
            fontSize: "1.4vw",
            fontFamily: "Catamaran_900Black"
        }
    })
}
