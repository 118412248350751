import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { useEffect } from 'react';
import { createCanvasImage } from '../modules/GetColorPixel';

export default function SvgImageDisplay({src, width, height}) {
  useEffect(() => {
    document.getElementById("div").innerHTML = "";
    createCanvasImage(src, width, height);
  }, [src]);
  return (
    <div id='div'></div>
  )
}

const styles = StyleSheet.create({})