import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Dimensions } from 'react-native';

export const ResponsiveContext = createContext();

export default function ResponsiveProvider(props) {
    const { width: widthS, height: heightS } = Dimensions.get("screen");
    const { width: widthW, height: heightW } = Dimensions.get("window");
    const [sizeResWidth, setSizeWidth] = useState();
    const [sizeResHeight, setSizeHeight] = useState();

    const setSizeResponsiveWidth = (width) => {
        width = widthDynemic({width: width}, {width: widthS});
        if (widthS <= 1280) {
            return setSizeWidth(width / 720 * 0.77);
        }
        if (widthS <= 1366) {
            return setSizeWidth(width / 760 * 0.80);
        }
        if (widthS <= 1440) {
            return setSizeWidth(width / 800 * 0.83);
        }
        if (widthS <= 1536) {
            return setSizeWidth(width / 830 * 0.86);
        }
        if (widthS <= 1600) {
            return setSizeWidth(width / 850 * 0.89);
        }
        if (widthS <= 1680) {
            return setSizeWidth(width / 850* 0.92);
        }
        if (widthS <= 1792) {
            return setSizeWidth(width / 850 * 0.95);
        }
        if (widthS <= 1920) {
            return setSizeWidth(width / 900 * 0.98);
        }
        if (widthS <= 2560 || widthS >= 2560) {
            return setSizeWidth(width / 950 * 1.01);
        }
    }
    const setSizeResponsiveHeight = (height) => {
        if (height <= 720) {
            return setSizeHeight(height / 380 * 0.77);
        }
        if (height <= 768) {
            return setSizeHeight(height / 390 * 0.80);
        }
        if (height <= 800) {
            return setSizeHeight(height / 400 * 0.83);
        }
        if (height <= 864) {
            return setSizeHeight(height / 420 * 0.86);
        }
        if (height <= 900) {
            return setSizeHeight(height / 440 * 0.89);
        }
        if (height <= 1050) {
            return setSizeHeight(height / 450 * 0.92);
        }
        if (height <= 1080) {
            return setSizeHeight(height / 460 * 0.95);
        }
         if (height <= 1120) {
            return setSizeHeight(height / 480 * 0.98);
        }
        if (height <= 1440 || height >= 1440) {
            return setSizeHeight(height / 490 * 1.01);
        }
    }

    const widthDynemic = (window, screen) => {
        let persent = screen.width / 100;
        let widthNow = window.width / persent;
        let persent2 = window.width / 100;
        return persent2 * widthNow;
    }

    useEffect(() => {
        const subscription = Dimensions.addEventListener('change', ({ window, screen }) => {
            setSizeResponsiveWidth(window.width);
            setSizeResponsiveHeight(window.height);
        });
        return () => subscription?.remove();
    }, []);


    useEffect(() => {
        setSizeResponsiveHeight(heightW);
        setSizeResponsiveWidth(widthW);
    }, []);
    return (
        <ResponsiveContext.Provider value={{ sizeResHeight, sizeResWidth }}>
            {props.children}
        </ResponsiveContext.Provider>
    );
};