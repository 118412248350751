function roundDownToNearestFive(minutes) {
  var remainder = minutes % 5;
  return minutes - remainder;
}

const pushToColumn = (arr, date, type) => {
  date?.setSeconds(0);
  arr.push({
    time: type === "D" ? date.toDateString().slice(4, date.toDateString().length - 4) : date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
    }),
    date: date.toString(),
    id: `${Math.random().toString().slice(2)}`
  });
}

export const createArrayColumns = (countStart, type, count, firstDate, datesArr, typeArr, countSteps) => {
  typeArr = typeArr > 0 ? typeArr : 1;
  let columns = [];
  if (datesArr) {
    if (type === "M") {
      for (let i = 0; i < datesArr?.length; i++) {
        let date1 = new Date(datesArr[i]);
        if (i > 0) {
          let date = new Date(columns[columns.length - 1]?.date);
          if (date1.getTime() <= date.getTime()) {
            continue;
          } else {
            date.setMinutes(roundDownToNearestFive(date.getMinutes()) + Number(count));
            if (date1.getTime() < date.getTime()) {
              pushToColumn(columns, date);
              continue;
            } else {
              date1.setMinutes(roundDownToNearestFive(date1.getMinutes()) + Number(count));
              pushToColumn(columns, date1);
              continue;
            }
          }
        } else {
          date1.setMinutes(roundDownToNearestFive(date1.getMinutes()) + Number(count))
          pushToColumn(columns, date1);
        }
      }
    }
    else if (type === "H") {
      for (let i = 0; i < datesArr?.length; i++) {
        let date1 = new Date(datesArr[i]);
        if (i > 0) {
          let date = new Date(columns[columns.length - 1]?.date);
          if (date1.getTime() < date.getTime()) {
            continue;
          } else {
            date.setHours(date.getHours() + Number(count));
            date.setMinutes(roundDownToNearestFive(date.getMinutes()));
            if (date1.getTime() < date.getTime()) {
              pushToColumn(columns, date);
              continue;
            } else {
              date1.setHours(date1.getHours() + Number(count));
              date1.setMinutes(roundDownToNearestFive(date1.getMinutes()));
              pushToColumn(columns, date1);
              continue;
            }
          }
        } else {
          date1.setHours(date1.getHours() + Number(count));
          date1.setMinutes(roundDownToNearestFive(date1.getMinutes()));
          pushToColumn(columns, date1);
        }
      }
    } else if (type === "D") {
      for (let i = 0; i < datesArr?.length; i++) {
        let date1 = new Date(datesArr[i]);
        if (i > 0) {
          let date = new Date(columns[columns.length - 1]?.date);
          if (date1.getTime() < date.getTime()) {
            continue;
          } else {
            date.setDate(date.getDate() + Number(count));
            if (date1.getTime() < date.getTime()) {
              pushToColumn(columns, date, "D");
              continue;
            } else {
              date1.setDate(date1.getDate() + Number(count));
              pushToColumn(columns, date1, "D");
              continue;
            }
          }
        } else {
          date1.setDate(date1.getDate() + Number(count));
          pushToColumn(columns, date1, "D");
        }
      }
    }
  }
  return columns.reverse();
};

export const setTimeUnits = (count, infoObj, lastDate, typeArr, countSteps) => {
  if (lastDate) {
    if (infoObj.timeUnits) {
      if (infoObj.timeUnits.indexOf("m") >= 0) {
        return [
          ...createArrayColumns(
            count,
            "M",
            infoObj.timeUnits.replace("m", ""),
            infoObj.time,
            lastDate,
            typeArr,
            countSteps
          ),
        ];
      } else if (infoObj.timeUnits.indexOf("h") >= 0) {
        return [
          ...createArrayColumns(
            count,
            "H",
            infoObj.timeUnits.replace("h", ""),
            infoObj.time,
            lastDate,
            typeArr,
            countSteps
          ),
        ];
      } else {
        return [
          ...createArrayColumns(
            count,
            "D",
            infoObj.timeUnits.replace("d", ""),
            infoObj.time,
            lastDate,
            typeArr,
            countSteps
          ),
        ];
      }
    } else {
      return [
        ...createArrayColumns(
          count,
          "M",
          5,
          infoObj.time && infoObj.date && `${infoObj.date} ${infoObj.time}`,
          [],
          typeArr
        ),
      ];
    }
  }
};


const checkTime = (infoObj, times) => {
  let date1 = new Date(times[times.length - 1]);
  let date2 = new Date(infoObj.time);
  let date3 = new Date(times[0]);
  date2 = date2 > date3 ? date2 : date3;
  let time = date1.getTime() - date2.getTime();
  time = time / 100;
  time = time / 60;
  time = time / 24;
  return time;
}
