import { CREATE_GAME_PERMISSION, USER, USER_ID_IN_GAME, USER_ID_IN_GAME_OBSERVER } from "../../config.inc";
import {
  ADD_ACTION,
  SET_ACTIONS,
  SET_SHOW_ACTIONS,
  SHARE_ACTION,
  SET_ACTION_INFO,
  SHOW_ACTION_INFO,
  SHOW_NEW_ACTION,
  RESET_ACTION_CURRENT
} from "../actions/index";

import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';
import { addOrReplaceItemInArr } from "../function/addOrReplaceItemInArr";

// let actionEmpty = { action: null, solutions: [], decision: null };

const addToState = (action, newAction) => {
  if (action.payload[0].action_type === "1") {
    action.payload[0].action_body = checkIfIsEncodeText(action.payload[0].action_body, "string");
    action.payload[0].subject = checkIfIsEncodeText(action.payload[0].subject, "string");
    newAction.action = action.payload[0];
  } else if (action.payload[0].action_type === "3") {
    action.payload[0].action_body = checkIfIsEncodeText(action.payload[0].action_body, "string");
    action.payload[0].subject = checkIfIsEncodeText(action.payload[0].subject, "string");
    newAction.decision = action.payload[0];
  } else {
    action.payload.forEach((e, i) => {
      action.payload[i].action_body = checkIfIsEncodeText(action.payload[i].action_body, "string");
      action.payload[i].subject = checkIfIsEncodeText(action.payload[i].subject, "string");
    });
    newAction.solutions = action.payload;
  }
  return newAction;
};

const findEventNameById = (id, arr) => {
  let name = "";
  arr?.length > 0 && arr.forEach((e) => {
    if (e.id === id) {
      name = e.name;
    }
  });
  return name;
}

export const actionsReducer = (
  state = {
    actionsArr: [],
    actionsArr2: [],
    actionsArr3: [],
    // currentAction: actionEmpty,
    actionInfo: null,
    actionInfoFlag: false,
    newActionsFlag: false,
    flag: false,
    allChallenges: []
  },
  action
) => {
  switch (action.type) {
    case SET_ACTIONS: {
      let newActionsArr = [...state.actionsArr];
      let challenges2 = [];
      let challenges3 = [];
      action.payload.forEach((e) => {
        let actionItem = {
          id: e.id ? e.id : null,
          creator_id: e.creator_id ? e.creator_id : null,
          name: e.subject ? checkIfIsEncodeText(e.subject, "line") : null,
          description: e.action_body ? checkIfIsEncodeText(e.action_body, "html") : null,
          time: e.creation_date ? e.creation_date : null,
          action_type: e.action_type ? e.action_type : null,
          admin_order: e.admin_order ? e.admin_order : null,
          cc_list: e.cc_list ? e.cc_list : null,
          event: e.event ? e.event : e.event_id && findEventNameById(e.event_id, action.eventsArr),
          event_id: e.event_id ? e.event_id : null,
          game_id: e.game_id ? e.game_id : null,
          has_attachments: e.has_attachments ? e.has_attachments : 0,
          is_draft: e.is_draft ? e.is_draft : null,
          last_update_date: e.last_update_date ? e.last_update_date : null,
          real_event_id: e.real_event_id ? e.real_event_id : null,
          related_actions: e.related_actions ? e.related_actions : null,
          share_list: e.to_list ? e.to_list : null,
        };
        switch (e.action_type) {
          case "1":
            let newAction = {
              action: actionItem,
              solutions: [],
              decision: null,
              comments: [],
            };
            if (state.actionsArr?.length > 0) {
              newActionsArr.unshift(newAction);
            } else {
              newActionsArr.push(newAction);
            }
            return;
          case "2":
            challenges2.push(actionItem);
            return;
          case "3":
            challenges3.push(actionItem);
            return;
          default:
            return;
        }
      });
      newActionsArr.forEach((Ce) => {
        challenges2.forEach((Ae, Ai) => {
          if (Ce.action.id === Ae.related_actions) {
            Ce.solutions.push(Ae);
          }
        });
        Ce.solutions.sort((a, b) => {
          if (+a.admin_order < +b.admin_order) {
            return -1;
          } else {
            return 1;
          }
        })
      });
      newActionsArr.forEach((Ce) => {
        if (Ce.solutions.length > 0) {
          Ce.solutions.forEach((Se) => {
            challenges3.forEach((De, Di) => {
              if (Se.id === De.related_actions) {
                Ce.decision = De;
              }
            });
          });
        }
      });
      // const isCreator = sessionStorage.getItem(CREATE_GAME_PERMISSION) === "1";
      // const playerId = JSON.parse(sessionStorage.getItem(USER))?.user?.id;
      // const idInGame = sessionStorage.getItem(USER_ID_IN_GAME)
      // // let newArr = isCreator
      // //   ? newActionsArr
      // //   : newActionsArr.filter((e) =>
      // //     e.solutions.length > 0
      // //     &&
      // //       e.action.creator_id === playerId ||
      // //       e.action.share_list?.indexOf(idInGame) >= 0
      // //     );
      let id = sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME);
      let filterAction = newActionsArr.filter((e) => {
        if (e.action.creator_id === id || e.action?.share_list?.indexOf(id) >= 0 || action.permission === "1") {
          return true
        } else {
          return false;
        }
      });

      return { ...state, actionsArr: filterAction, allChallenges: newActionsArr };
    }
    // case ADD_ACTION: {
    //   let newAction = state.currentAction;
    //   if (action.payload) {
    //     newAction = addToState(action, newAction);
    //   } else {
    //     newAction = { action: null, solutions: [], decision: null };
    //   }
    //   return { ...state, currentAction: newAction };
    // }
    // case SHARE_ACTION: {
    //   state.currentAction.action.to_list = action.payload;
    //   return { ...state, currentAction: state.currentAction };
    // }
    case SET_SHOW_ACTIONS: {
      return { ...state, flag: action.flag };
    }
    case SHOW_ACTION_INFO: {
      return { ...state, actionInfoFlag: action.flag };
    }
    case SET_ACTION_INFO: {
      return { ...state, actionInfo: action.payload };
    }
    case SHOW_NEW_ACTION: {
      return { ...state, newActionsFlag: action.flag };
    }
    // case RESET_ACTION_CURRENT:
    //   return { ...state, currentAction: actionEmpty };
    default: {
      return state;
    }
  }
};
