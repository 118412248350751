import { Pressable, StyleSheet, Text, View } from 'react-native'
import React, { useMemo, useState } from 'react'
import Statistics from '../analyticsTabels/Statistics'
import HeatMap from "../analyticsTabels/HeatMap";
import { useDispatch, useSelector } from 'react-redux';
import InteractionsMap from '../analyticsTabels/InteractionsMap';
import WordsMap from '../analyticsTabels/WordsMap';
import WordMessages from "../analyticsTabels/WordMessages";
import { ScrollSync } from 'react-scroll-sync';
import FullScreenPopup from '../../../../Elements/FullScreenPopup';
import { AntDesign } from '@expo/vector-icons';
import { t } from 'i18n-js';
import QuestionnaireMap from '../analyticsTabels/QuestionnaireMap';

export default function ExpectedAnalytics({ stopPlayScroll, setIsShot, setMaximizeType, statisticsRef, isShot, heatMapRef, interactionRef, wordCloudRef, num, allQuestionnaireRef, questionnaireRef }) {

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const styles = stylesR(background, color);

  const dispatch = useDispatch();

  const images = useSelector((state) => state.style.images);

  const [wordsMessages, setWordsMessages] = useState([]);
  const [word, setWord] = useState("");
  const [maximize, setMaximize] = useState(false);
  const [hoverType, setHoverType] = useState();

  const [position, setPosition] = useState(0);


  const tables = {
    statistics: (flag) => <Statistics isShot={isShot} refShot={statisticsRef} hoverType={hoverType} setHoverType={setHoverType} maximize={flag} setMaximize={() => setMaximizeType(maximize, "statistics", setMaximize)} type={"expected"} position={position} setPosition={setPosition} stopPlayScroll={stopPlayScroll} />,
    heat: (flag) => <HeatMap isShot={isShot} refShot={heatMapRef} maximize={flag} setMaximize={() => setMaximizeType(maximize, "heat", setMaximize)} type={"expected"} position={position} setPosition={setPosition} stopPlayScroll={stopPlayScroll} />,
    interaction: (flag) => <InteractionsMap isShot={isShot} refShot={interactionRef} maximize={flag} setMaximize={() => setMaximizeType(maximize, "interaction", setMaximize)} type={"expected"} position={position} setPosition={setPosition} stopPlayScroll={stopPlayScroll} />,
    word: (flag) =>
      <View style={[{ width: "100%", flexDirection: "row", justifyContent: "space-between" }, flag && { height: "100%" }]}>
        <WordsMap isShot={isShot} refShot={wordCloudRef} wordsMessages={wordsMessages} word={word} maximize={flag} setMaximize={() => setMaximizeType(maximize, "word", setMaximize)} type={"expected"} setWordsMessages={setWordsMessages} setWord={setWord} position={position} setPosition={setPosition} stopPlayScroll={stopPlayScroll} />
        <WordMessages maximize={flag} wordsMessages={wordsMessages} word={word?.text} stopPlayScroll={stopPlayScroll} />
      </View>,
    questionnaire: (flag) => <QuestionnaireMap setIsShot={setIsShot} stopPlayScroll={stopPlayScroll} isShot={isShot} refShotAll={allQuestionnaireRef} refShot={questionnaireRef} />
  }

  const returnMap = (num) => {
    switch (num) {
      case 1: return tables.statistics()
      case 2: return tables.heat()
      case 3: return tables.interaction()
      case 4: return tables.word()
      case 5: return tables.questionnaire()
    }
  }

  return (
    <>
      <View>
        <ScrollSync>
          <>
            {returnMap(num)}
          </>
        </ScrollSync>
        {maximize &&
          <FullScreenPopup
            children={
              <ScrollSync>
                {tables[maximize](true)}
              </ScrollSync>
            }
            flag={true}
            setFlag={() => setMaximize(false)}
          />}
      </View>
    </>
  )
}

const stylesR = (background, color) => {
  return StyleSheet.create({

  })
} 