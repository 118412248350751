import React from "react";
import { StyleSheet, Modal, View, Text, Image, Pressable, Dimensions } from "react-native";
import { t } from "i18n-js";
import { useSelector } from "react-redux";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "./UseResponsiveScreen";

export default function ErrorMessagePopup({
  flag,
  setFlag,
  title,
  body,
  refC,
  clickOkFunc,
  deleteDraftHandler,
  backgroundState,
  colorState
}) {

  const { width: widthScreen, height: heightScreen} = Dimensions.get("screen");
  const { width: widthWindow, height: heightWindow} = Dimensions.get("window");

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const background = backgroundState || useSelector((state) => state.style.background);
  const color = colorState || useSelector((state) => state.style.color);

  const okBtnPressHandler = (e) => {
    clickOkFunc && clickOkFunc();
    deleteDraftHandler && deleteDraftHandler(e);
    setFlag();
  };

  return (
    <Modal transparent={true} visible={flag} animationType="none">
      <View style={styles.container}>
        <View
          style={[
            {
              width: widthWindow < widthScreen ? "100%" : refC.current?.getBoundingClientRect().width,
              height: heightWindow < heightScreen ? "100%" : refC.current?.getBoundingClientRect().height,
            },
            styles.refContainer,
          ]}
        >
          <View
            style={[
              styles.main,
              { backgroundColor: background.popup1, borderColor: color.border },
            ]}
          >
            <View style={styles.header}>
              {title && (
                <View
                  style={[
                    styles.title,
                    { borderColor: color.infoHeaderNavBorder },
                  ]}
                >
                  <Text
                    style={[
                      styles.titleText,
                      {
                        color: color.infoHeaderNavText,
                      },
                    ]}
                  >
                    {title.toUpperCase()}
                  </Text>
                </View>
              )}
              <View style={styles.bodyContainer}>
                <Text
                  style={[
                    styles.body,
                    {
                      color: color.infoHeaderNavText,
                    },
                  ]}
                >
                  {body}
                </Text>
              </View>
            </View>
            <View style={styles.footer}>
              <Pressable
                style={[
                  styles.btn_next,
                  {
                    backgroundColor: background.actionButtonAdd,
                    borderColor: background.actionButtonAdd,
                  },
                ]}
                onPress={() => okBtnPressHandler(true)}
              >
                <Text
                  style={[styles.btn_text, { color: color.infoHeaderNavText }]}
                >
                  {t(deleteDraftHandler ? "yes" : "ok").toUpperCase()}
                </Text>
              </Pressable>
              {deleteDraftHandler && (
                <Pressable
                  style={[
                    styles.btn_next,
                    {
                      backgroundColor: background.actionButtonAdd,
                      borderColor: background.actionButtonAdd,
                    },
                  ]}
                  onPress={() => okBtnPressHandler(false)}
                >
                  <Text
                    style={[
                      styles.btn_text,
                      { color: color.infoHeaderNavText },
                    ]}
                  >
                    {t("no").toUpperCase()}
                  </Text>
                </Pressable>
              )}
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#00000057",
      height: "100%",
      fontFamily: "Roboto",
    },
    main: {
      minHeight: heightS * 167,
      width: widthS * 235,
      borderRadius: widthS * 11,
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.2,
      shadowRadius: 2.6,
      elevation: 100,
      paddingHorizontal: widthS * 30,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
    },
    header: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: heightS * 20,
      flex: 2,
    },
    title: {
      paddingBottom: heightS * 3,
      paddingHorizontal: widthS * 5,
      borderBottomWidth: width,
      borderColor: "white",
    },
    titleText: {
      fontSize: widthS * 13,
      textAlign: "center",
      fontFamily: "Catamaran_400Regular",
    },
    bodyContainer: {
      justifyContent: "center",
      flex: 1,
    },
    body: {
      fontSize: widthS * 10,
      fontFamily: "Catamaran_300Light",
      textAlign: "center",
      marginTop: heightS * 10,
      lineHeight: heightS * 12,
    },
    logo: {
      width: widthS * 35,
      height: widthS * 35,
      marginVertical: heightS * 10,
      resizeMode: "contain",
    },
    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      flex: 0.6,
      borderStyle: "solid",
      paddingVertical: heightS * 20,
    },
    btn_next: {
      borderWidth: 1,
      width: widthS * 36,
      height: heightS * 22,
      margin: widthS * 5,
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 5,
    },
    btn_text: {
      color: "white",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
    },
    refContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      alignItems: "center",
      justifyContent: "center",
    },
  });
