import React, { useState, useEffect, useRef, useMemo } from "react";
import {
	StyleSheet,
	Modal,
	View,
	Text,
	Pressable,
	ScrollView,
	Image,
	TextInput,
	ImageBackground,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Foundation } from "@expo/vector-icons";
import { listenerAdd } from "../../../AddListener"
import * as DocumentPicker from "expo-document-picker";
import {t} from "i18n-js";
import { addNews, editNews } from "../../../redux/actions";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";

export default function NewNewsPost({ flag, setFlag }) {

	const { width, height } = UseResponsiveSize();
	const { widthS, heightS } = UseResponsiveScreen();
	const styles = stylesR(width, height, heightS, widthS);

	const popupRef = useRef(null);
	const dispath = useDispatch();

	const type = useSelector((state) => state.newsArr.typeP);
	const post = useSelector((state) => state.newsArr.post);
	const color = useSelector(state => state.style.color);
	const background = useSelector(state => state.style.background);
	const images = useSelector(state => state.style.images);

	const [newPost, setNewPost] = useState({
		logo: null,
		image: "",
		title: "",
		text: "",
	});
	const [rowsCount, setRowsCount] = useState(4);
	const [err, setErr] = useState("");

	const openImagesBrowser = (type) => {
		DocumentPicker.getDocumentAsync({
			type: "image/*",
		}).then((res) => {
			if (type === "img") {
				setNewPost({ ...newPost, image: res });
			} else {
				setNewPost({ ...newPost, logo: res });
			}
		});
	};

	useMemo(() => {
		if (
			newPost.text.match(/[\n]/g) &&
			newPost.text.match(/[\n]/g).length > 3
		) {
			setRowsCount(newPost.text.match(/[\n]/g).length + 1);
		}
	}, [newPost.text]);
	const createTime = () => {
		let hours = new Date().getHours();
		if (hours < 10) {
			hours = `0${hours}`;
		}
		let minutes = new Date().getMinutes();
		if (minutes < 10) {
			minutes = `0${minutes}`;
		}
		return `${hours}:${minutes}`;
	};

	const showImageBackground = () => {
		if (newPost.image) {
			return (
				<Pressable onPress={() => openImagesBrowser("img")}>
					<ImageBackground
						source={newPost.image}
						style={styles.background_img}
					>
						<View style={{backgroundColor: background.newsLogo}}>
							<Text selectable={false} style={styles.breaking_text}>{t("breaking-news")}</Text>
							<View style={[styles.breaking_triangular, {borderBottomColor: background.newsLogo}]} />
						</View>
					</ImageBackground>
				</Pressable>
			);
		} else {
			return (
				<Pressable
					style={[styles.background_img, { backgroundColor: "#c3c3c3", borderColor: err === "image" ? "red" : "", borderWidth: err === "image" ? 1 : 0 }]}
					onPress={() => openImagesBrowser("img")}
				>
					<View style={{backgroundColor: background.newsLogo}}>
						<Text selectable={false} style={styles.breaking_text}>{t("breaking-news")}</Text>
						<View style={[styles.breaking_triangular, {borderBottomColor: background.newsLogo}]} />
					</View>
				</Pressable>
			);
		}
	};

	const handleClick = () => {
		if(!newPost.text || newPost.text.length <= 0){
			setErr("text");
			return;
		}
		if(!newPost.title || newPost.title.length <= 0){
			setErr("title");
			return;
		}
		
		if(!newPost.image || newPost.image.length <= 0){
			setErr("image");
			return;
		}
		if(!newPost.logo || newPost.logo.length <= 0){
			setErr("logo");
			return;
		}
		setErr("");
		if(type === "edit"){
			dispath(editNews(newPost, post.is_read));
		}else{
			dispath(addNews(newPost, false));
		}
		setNewPost({
			logo: null,
			image: "",
			title: "",
			text: "",
		});
		setFlag();
	};

	useEffect(() => {
		if (type === "edit") {
			setNewPost(post);
		} else {
			setNewPost({
				logo: null,
				image: "",
				title: "",
				text: "",
			});
		}
	}, [type, post, flag]);

	useEffect(() => {
		window.addEventListener("mousedown", listenerAdd(popupRef, flag, setFlag));
		return () => {
			window.removeEventListener("mousedown", listenerAdd(popupRef, flag, setFlag));
		};
	}, [flag]);
	return (
		<Modal transparent={true} animationType="fade" visible={flag}>
			<View style={styles.container}>
				<View style={[styles.main, {backgroundColor: background.popup2}]} ref={popupRef}>
					<View style={[styles.header, {backgroundColor: background.newsHeader}]}>
						<View style={[styles.logo_view, {backgroundColor: background.newsLogo}]}>
							<Image
								source={{uri: images.news}}
								style={styles.logo}
							/>
						</View>
						<Text selectable={false} style={styles.logo_text}>{t("news")}</Text>
						<Pressable onPress={() => handleClick()} style={[styles.new_btn, {backgroundColor: background.newsLogo}]}>
							<Text selectable={false} style={styles.new_btn_txt}>
								{type && type === "edit" ? t("edit") : t("add")}
							</Text>
						</Pressable>
						<Pressable style={styles.x_btn} onPress={() => setFlag()}>
							<Text selectable={false} style={styles.x_text}>X</Text>
						</Pressable>
					</View>
					<View style={styles.body}>
						{/* post */}
						<ScrollView
							nativeID="news"
							style={styles.container_post_scroll}
							contentContainerStyle={styles.container_post}
						>
							<View style={styles.top}>
								<Pressable
									style={[styles.logo_btn, {borderColor: err === "logo" ? "red" : "", borderWidth: err === "logo" ? 1 : 0}]}
									onPress={() => openImagesBrowser("logo")}
								>
									{<Image
										source={newPost.logo}
										style={styles.logo_post}
									/>}
								</Pressable>
								{showImageBackground()}
							</View>
							<View style={[styles.bottom, {backgroundColor: color.actionBtnBackBorder}]}>
								<View style={styles.body_top}>
									<View style={styles.body_top_right}>
										<View style={styles.title}>
											<TextInput
												style={[styles.title_inp, {borderColor: err === "title" ? "red" : "", borderWidth: err === "title" ? 1 : 0}]}
												placeholder={`${t("title")}...`}
												value={newPost.title}
												onChangeText={(txt) =>
													setNewPost({
														...newPost,
														title: txt.toUpperCase(),
													})
												}
											/>
										</View>
										<Text selectable={false} style={styles.time}>{createTime()}</Text>
									</View>
									<View style={styles.body_top_left}>
										<Pressable>
											<Foundation
												name="share"
												size={30}
												color="#464646"
											/>
										</Pressable>
									</View>
								</View>
								<View style={styles.body_bottom}>
									<View style={styles.body_text}>
										<TextInput
											multiline={true}
											numberOfLines={rowsCount}
											style={[styles.body_text_inp, {borderColor: err === "text" ? "red" : "", borderWidth: err === "text" ? 1 : 0}]}
											placeholder={`${t("body")}...`}
											value={newPost.text}
											onChangeText={(txt) =>
												setNewPost({ ...newPost, text: txt })
											}
										/>
									</View>
								</View>
								<View style={[styles.flag_view, {backgroundColor: background.rightMenuGameNameAndUserName}]} />
								<Pressable style={styles.flag_btn}>
									<Image
										style={styles.flag_img}
										source={{uri: images.newsReadIcon}}
									/>
								</Pressable>
							</View>
						</ScrollView>
					</View>
				</View>
			</View>
		</Modal>
	);
}
const stylesR = (width, height, heightS, widthS) => {
	return StyleSheet.create({
		container: {
			display: "flex",
			alignItems: "center",
			paddingTop: heightS * 40,
			backgroundColor: "#00000057",
			height: "100%",
			fontFamily:
				"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
		},
		main: {
			width: widthS * 370,
			height: heightS * 380,
			borderTopLeftRadius: 4,
			borderTopRightRadius: 4,
			shadowColor: "black",
			shadowOffset: {
				height: 0,
				width: 0.1,
			},
			shadowOpacity: 1,
			shadowRadius: 6.65,
			elevation: 3,
			display: "flex",
			flexDirection: "column",
		},
		header: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		logo_view: {
			width: widthS * 35,
			height: widthS * 35,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: widthS * 30,
			margin: widthS * 5,
			marginLeft: widthS * 15,
			marginRight: widthS * 25,
		},
		logo: {
			width: widthS * 20,
			height: widthS * 20,
			resizeMode: "contain"
		},
		logo_text: {
			color: "white",
			fontSize: widthS * 15,
			fontFamily: "Catamaran_700Bold",
		},
		new_btn: {
			position: "absolute",
			right: widthS * 60,
			minWidth: widthS * 40,
			height: heightS * 20,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: 3,
			paddingHorizontal: widthS * 5,
		},
		new_btn_txt: {
			color: "white",
			fontFamily: "Catamaran_700Bold",
		},
		x_btn: {
			position: "absolute",
			right: widthS * 15,
		},
		x_text: {
			color: "white",
			fontSize: widthS * 20,
			fontFamily: "Catamaran_400Regular",
		},
		body: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flex: 1,
		},
		container_post_scroll: {
			maxHeight: heightS * 295,
		},
		container_post: {
			minHeight: heightS * 296,
			display: "flex",
			flexDirection: "column",
			width: widthS * 340,
			paddingRight: widthS * 10,
			paddingLeft: widthS * 10,
			paddingTop: heightS * 15,
		},
		top: {
			display: "flex",
		},
		logo_btn: {
			position: "absolute",
			top: heightS * -5,
			left: widthS * 10,
			zIndex: 3,
			backgroundColor: "white",
			height: heightS * 38,
			width: widthS * 55,
		},
		logo_post: {
			height: heightS * 38,
			width: widthS * 55,
			resizeMode: "cover",
		},
		background_img: {
			height: heightS * 130,
			display: "flex",
			alignItems: "flex-start",
			justifyContent: "flex-end",
		},
		breaking_triangular: {
			width: 0,
			height: 0,
			backgroundColor: "transparent",
			borderStyle: "solid",
			borderRightWidth: widthS * 25,
			borderBottomWidth: heightS * 30,
			borderRightColor: "transparent",
			position: "absolute",
			right: widthS * -25,
		},
		breaking_text: {
			color: "white",
			fontSize: widthS * 12,
			fontFamily: "Catamaran_800ExtraBold",
			padding: widthS * 2,
			paddingLeft: widthS * 8,
			paddingRight: widthS * 20,
		},
		bottom: {
			flex: 1,
			paddingLeft: widthS * 8,
			paddingTop: heightS * 3,
			paddingRight: widthS * 15,
		},
		body_top: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
		},
		body_top_left: {
			marginTop: 8,
		},
		body_bottom: {
			zIndex: 1,
		},
		title: {
			width: widthS * 250,
		},
		title_inp: {
			fontSize: widthS * 17,
			fontFamily: "Catamaran_700Bold",
			color: "#464646",
			// lineHeight: size * 20,
			width: widthS * 250,
			outlineStyle: "none",
		},
		time: {
			color: "#787878",
			fontSize: widthS * 11,
			fontFamily: "Catamaran_400Regular",
		},
		body_text: {
			fontSize: 16,
			marginTop: 10,
			marginBottom: 30,
			paddingRight: 35,
			position: "relative",
			fontFamily: "Catamaran_400Regular",
		},
		body_text_inp: {
			fontSize: widthS * 11,
			marginTop: heightS * 3,
			marginBottom: heightS * 10,
			paddingRight: widthS * 10,
			position: "relative",
			outlineStyle: "none",
			fontFamily: "Catamaran_400Regular",
		},
		flag_view: {
			width: 0,
			height: 0,
			backgroundColor: "transparent",
			borderStyle: "solid",
			borderLeftWidth: widthS * 28,
			borderBottomWidth: heightS * 28,
			borderLeftColor: "transparent",
			position: "absolute",
			right: 0,
			bottom: 0,
			zIndex: 1,
		},
		flag_btn: {
			position: "absolute",
			bottom: 0,
			right: widthS * 3,
			zIndex: 2,
		},
		flag_img: {
			width: widthS * 15,
			height: widthS * 15,
			resizeMode: "contain",
		},
	});
}
