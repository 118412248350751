import React, { useContext, useEffect, useMemo, useState } from "react";
import { StyleSheet, View, Text, TextInput, Pressable } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useNetInfo } from "@react-native-community/netinfo";
import {
  setGameList
} from "../../redux/actions";
import i18n from "i18n-js";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import { GAME_ID } from "../../config.inc";

export default function EndGame() {
  const { height, width } = UseResponsiveSize();
  const { heightS, widthS } = UseResponsiveScreen();
  const dispatch = useDispatch();

  const color = useSelector((state) => state.style.colorClient);
  const background = useSelector((state) => state.style.backgroundClient);

  const styles = stylesR(height, width, heightS, widthS, background,);

  return (
    <View
      style={[styles.body_main, { backgroundColor: background.popupLogin }]}
    >
        <Text style={[styles.body_main_textA, { color: color.text }]}>
          {i18n.t("end_game_login")}
        </Text>
        <Pressable
						style={
							styles.btn}
              onPress={() => {
                sessionStorage.removeItem(GAME_ID);
                dispatch(setGameList());
              }}
					>
						{
							<Text
								style={[
									{ color: color.text2 },
									styles.btn_text,
								]}
							>
								{i18n.t("back_to_list")}
							</Text>
						}
					</Pressable>
    </View>
  );
}
const stylesR = (height, width, heightS, widthS, background) => {
  return StyleSheet.create({
    body_main: {
      marginTop: "8vw",
      display: "flex",
      flexDirection: "column",
      // ...style.popup7,
      width: widthS * 420,
      borderRadius: widthS * 25,
      alignItems: "center",
      paddingVertical: "2vw",
      paddingHorizontal: "1vw"
    },
    body_main_textA: {
      fontSize: widthS * 18,
      fontWeight: "100",
      textAlign: "center",
      fontFamily: "Catamaran_400Regular",
    },
    btn: {
			padding: widthS * 4,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginVertical: heightS * 10.2,
			width: "22vw",
			height: "2.8vw",
			borderRadius: widthS * 7.3,
      backgroundColor: background.buttonLoginHover,
		},
		btn_text: {
			fontSize: widthS * 11,
			fontWeight: "normal",
			fontFamily: "Catamaran_400Regular",
		},
  });
};
