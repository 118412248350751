import { t } from "i18n-js";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const RawDataContext = createContext();
export const useRawDataContext = () => useContext(RawDataContext);

export default function RawDataProvider(props) {
    // for actions filter
    let optionsAction = [
        { name: t("incoming_items"), value: "Incoming Items" },
        { name: t("chat_messages"), value: "Chat Messages" },
        { name: t("sent_items"), value: "Sent Items" },
        { name: t("decision"), value: "Decisions" }
    ];
    // for platform filter
    let optionsPlatform = [
        { name: t("mail"), value: "mail" },
        { name: t("chat"), value: "chat" },
        { name: t("social"), value: "sm" },
        { name: t("news"), value: "news" },
    ];

    // for playes filter
    const gameInfoPlayers = useSelector(state => state.gameInfoArr.gameInfoArr?.players);
    const filteredAndFormattedPlayers = useMemo(() => {
        return gameInfoPlayers
            ?.filter(player => player.permission_for_game === "15") // סינון
            .sort((a, b) => a.is_represented.localeCompare(b.is_represented)) // מיון
            .map(player => ({
                value: player.id,
                name: player.name_for_game,
                is_represented: player.is_represented,
            })) || []; // מיפוי
    }, [gameInfoPlayers]);

    // for events filter
    const eventsstate = useSelector(state => state.events.events);
    const filteredAndFormattedEvents = useMemo(() => {
        return eventsstate?.filter(e => e.title)?.map(event => ({
            value: event?.id,
            name: event?.title
        }))
    }, [eventsstate]);

    // data from state to table
    const mailsState = useSelector((state) => state.mailArr.messages);
    const socialState = useSelector((state) => state.socialMedia.socialMediaArr);
    const chatsState = useSelector((state) => state.chatArr.allMessages);
    const groupsState = useSelector((state) => state.chatArr.groups);
    const newsState = useSelector((state) => state.newsArr.newsArr);
    // flag 
    const [rawDataFlag, setRawDataFlag] = useState(true);

    // 
    const [actionsFilter, setActionsFilter] = useState([]);
    const [playersFilter, setPlayersFilter] = useState([]);
    const [eventsFilter, setEventsFilter] = useState([]);
    const [platformFilter, setPlatformFilter] = useState([]);

    const [actionsFilterSearch, setActionsFilterSearch] = useState([]);
    const [playersFilterSearch, setPlayersFilterSearch] = useState([]);
    const [eventsFilterSearch, setEventsFilterSearch] = useState([]);
    const [platformFilterSearch, setPlatformFilterSearch] = useState([]);

    const [actionsFilterSelect, setActionsFilterSelect] = useState([]);
    const [playersFilterSelect, setPlayersFilterSelect] = useState([]);
    const [eventsFilterSelect, setEventsFilterSelect] = useState([]);
    const [platformFilterSelect, setPlatformFilterSelect] = useState([]);

    const [dataPrint, setDataPrint] = useState([]); // סטייט עבור הנתונים המודפסים

    const reset = (type) => {
        switch (type) {
            case "action":
                return setActionsFilterSelect([]);
            case "player":
                return setPlayersFilterSelect([]);
            case "event":
                return setEventsFilterSelect([]);
            case "platform":
                return setPlatformFilterSelect([]);
        }
    }

    const removeItem = (value, type) => {
        let temp;

        switch (type) {
            case "action":
                temp = actionsFilterSelect.filter((e) => e !== value);
                return setActionsFilterSelect(temp);

            case "event":
                temp = eventsFilterSelect.filter((e) => e !== value);
                return setEventsFilterSelect(temp);

            case "platform":
                temp = platformFilterSelect.filter((e) => e !== value);
                return setPlatformFilterSelect(temp);

            case "player":
                temp = playersFilterSelect.filter((e) => e !== value);
                return setPlayersFilterSelect(temp);

            default:
                return;
        }
    };

    const searchItem = (txt, type) => {
        let temp = [];
        switch (type) {
            case "action":
                temp = [...actionsFilter];
                temp = temp.filter((e) => e?.name.toLowerCase().indexOf(txt.toLowerCase()) === 0);
                return setActionsFilterSearch([...temp]);
            case "player":
                temp = [...playersFilter];
                temp = temp.filter((e) => e?.name?.toLowerCase().indexOf(txt?.toLowerCase()) === 0);
                return setPlayersFilterSearch([...temp]);
            case "event":
                temp = [...eventsFilter];
                temp = temp.filter((e) => e.name?.toLowerCase().indexOf(txt?.toLowerCase()) === 0);
                return setEventsFilterSearch([...temp]);
            case "platform":
                temp = [...platformFilter];
                temp = temp.filter((e) => e.name?.toLowerCase().indexOf(txt?.toLowerCase()) === 0);
                return setPlatformFilterSearch([...temp]);
        }
    }

    const addItem = (value, type) => {
        let temp;
        let players;

        switch (type) {
            case "action":
                temp = [...actionsFilterSelect];
                if (temp.includes(value)) {
                    temp = temp.filter((e) => e !== value); // הסרה אם קיים
                } else {
                    temp.push(value); // הוספה אם לא קיים
                }
                return setActionsFilterSelect(temp);

            case "event":
                temp = [...eventsFilterSelect];
                if (temp.includes(value)) {
                    temp = temp.filter((e) => e !== value); // הסרה אם קיים
                } else {
                    temp.push(value); // הוספה אם לא קיים
                }
                return setEventsFilterSelect(temp);

            case "platform":
                temp = [...platformFilterSelect];
                if (temp.includes(value)) {
                    temp = temp.filter((e) => e !== value); // הסרה אם קיים
                } else {
                    temp.push(value); // הוספה אם לא קיים
                }
                return setPlatformFilterSelect(temp);

            case "player":
                if (value === "rep") {
                    players = filteredAndFormattedPlayers
                        .filter((e) => e.is_represented === "1")
                        .map((e) => e.value);
                    return setPlayersFilterSelect(players);
                } else if (value === "all") {
                    players = filteredAndFormattedPlayers.map((e) => e.value);
                    return setPlayersFilterSelect(players);
                } else if (value === "real") {
                    players = filteredAndFormattedPlayers
                        .filter((e) => e.is_represented === "0")
                        .map((e) => e.value);
                    return setPlayersFilterSelect(players);
                } else {
                    temp = [...playersFilterSelect];
                    if (temp.includes(value)) {
                        temp = temp.filter((e) => e !== value); // הסרה אם קיים
                    } else {
                        temp.push(value); // הוספה אם לא קיים
                    }
                    return setPlayersFilterSelect(temp);
                }

            default:
                return;
        }
    };

    const initalValues = useCallback(() => {
        // אתחול פילטר פעולות
        setActionsFilter(optionsAction);
        setActionsFilterSelect(optionsAction?.map((e) => e.value)); // מערך של ערכים
        setActionsFilterSearch(optionsAction);

        // אתחול פילטר פלטפורמות
        setPlatformFilter(optionsPlatform);
        setPlatformFilterSelect(optionsPlatform?.map((e) => e.value)); // מערך של ערכים
        setPlatformFilterSearch(optionsPlatform);

        // אתחול פילטר שחקנים
        setPlayersFilter(filteredAndFormattedPlayers);
        setPlayersFilterSelect(filteredAndFormattedPlayers?.map((e) => e.value)); // מערך של ערכים
        setPlayersFilterSearch(filteredAndFormattedPlayers);

        // אתחול פילטר אירועים
        setEventsFilter(filteredAndFormattedEvents);
        setEventsFilterSelect(filteredAndFormattedEvents?.map((e) => e?.value)); // מערך של ערכים
        setEventsFilterSearch(filteredAndFormattedEvents);
    }, [filteredAndFormattedPlayers, filteredAndFormattedEvents]);

    useEffect(() => {
        initalValues();
    }, [rawDataFlag, initalValues]);

    // data for table
    const normalizeData = useMemo(() => {
        const unifiedArray = [];


        const extractTextFromHTML = (vhtml) => {
            if (!vhtml) return '';
            
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = vhtml;
            // החלפת כל תגי <br> ברווח אחד
            const textWithSpaces = tempDiv.innerHTML.replace(/<br\s*\/?>/gi, ' ');
            tempDiv.innerHTML = textWithSpaces;
            return tempDiv.textContent || '';
        };
        const getGroupName = (groupId) => {
            if (!groupId) return '';
            const group = groupsState.find(g => g.id === groupId);
            return group?.name || '';
        };
        const getPlayerName = (playerId) => {
            if (!playerId) return '';
            const player = filteredAndFormattedPlayers.find(p => p.value === playerId);
            return player?.name || '';
        };

        const getPlayerNames = (playerIds) => {
            if (!playerIds) return '';
            return playerIds?.split(',')
                .map(id => getPlayerName(id))
                .filter(name => name)
                .join(', ');
        };

        const getFileTypeFromLink = (link) => {
            if (!link) return '';
            const extMatch = link.match(/\.\w+$/); // התאמה לסיומת קובץ
            return extMatch ? extMatch[0] : ''; // החזרת הסיומת או מחרוזת ריקה
        };

        const formatToLocalTime = (dateString) => {
            const isUTC = /Z|UTC|GMT/i.test(dateString);
            const formattedDateString = isUTC ? dateString : dateString + "Z";
            const date = new Date(formattedDateString);
            return isNaN(date.getTime()) ? null : date;
        };

        // Normalize mails
        mailsState.forEach(mail => {
            unifiedArray.push({
                id: mail.id,
                title: mail.subject || '',
                date: mail.time || '',
                body: mail.body || '',
                bodyText: extractTextFromHTML(mail.body_search) || '',
                from_id: mail.sender?.id || '',
                from: mail.sender?.name || getPlayerName(mail.sender?.id) || '',
                to_list_ids: mail.to_list || '',
                to_list: mail.to_list_names || getPlayerNames(mail.to_list) || '',
                cc_list_ids: mail.cc_list || '',
                cc_list: mail.cc_list_names || getPlayerNames(mail.cc_list) || '',
                bcc_list_ids: mail.bcc_list || '',
                bcc_list: mail.bcc_list_names || getPlayerNames(mail.bcc_list) || '',
                has_attachments: !!mail.has_attachments,
                files: mail.attachments || [],
                platform_type: 'mail',
                event_id: mail.event?.id || filteredAndFormattedEvents[0]?.value,
                event_name: mail.event?.name || filteredAndFormattedEvents[0]?.name,
                event_color: '' // Add logic if there's a specific color mapping
            });
        });

        // Normalize socials
        socialState.forEach(social => {
            const files = [];
            if (social.image) {
                files.push({ name: '', link: social.image, type: getFileTypeFromLink(social.image) });
            }
            if (social.video) {
                files.push({ name: '', link: social.video, type: getFileTypeFromLink(social.video) });
            }
            unifiedArray.push({
                id: social.id,
                title: extractTextFromHTML(social.text) || '',
                body: social.text || '',
                date: social.timeSend || '',
                bodyText: extractTextFromHTML(social.text) || '',
                from_id: social.sender_by?.id || '',
                from: social.sender_by?.name || getPlayerName(social.sender_by?.id) || '',
                to_list_ids: '',
                to_list: getPlayerNames(social.to_list) || '',
                cc_list_ids: social?.to_list || '',
                cc_list: '',
                bcc_list_ids: '',
                bcc_list: '',
                has_attachments: files.length > 0,
                files: files,
                platform_type: 'sm',
                event_id: '',
                event_id: filteredAndFormattedEvents[0]?.value,
                event_name: filteredAndFormattedEvents[0]?.name,
            });
        });

        // Normalize chats
        chatsState.forEach(chat => {
            unifiedArray.push({
                id: chat.id,
                title: '',
                body: chat.message_body || '',
                date: chat.time || '',
                bodyText: extractTextFromHTML(chat.message_body) || '',
                from_id: chat.sender_id || '',
                from: getPlayerName(chat.sender_id) || '',
                to_list_ids: chat.recipient_id || '',
                to_list: chat?.group_id !== "0" ? getGroupName(chat.group_id) : getPlayerName(chat.recipient_id) || '',
                cc_list_ids: '',
                cc_list: '',
                bcc_list_ids: '',
                bcc_list: '',
                platform_type: 'chat',
                has_attachments: false,
                files: [],
                event_id: filteredAndFormattedEvents[0]?.value,
                event_name: filteredAndFormattedEvents[0]?.name,
            });
        });

        // Normalize news
        newsState.forEach(item => {
            const files = [];
            if (item.image) {
                files.push({ name: '', link: item.image, type: getFileTypeFromLink(item.image) });
            }

            unifiedArray.push({
                id: item.id,
                title: item.title || '',
                body: item.text || '',
                bodyText: item.text || '',
                date: item.time || '',
                from_id: '',
                from: '',
                to_list_ids: item.to_list || '',
                to_list: getPlayerNames(item.to_list) || '',
                cc_list_ids: '',
                cc_list: '',
                bcc_list_ids: '',
                bcc_list: '',
                has_attachments: files.length > 0,
                files: files,
                platform_type: 'news',
                event_id: filteredAndFormattedEvents[0]?.value,
                event_name: filteredAndFormattedEvents[0]?.name,
            });
        });

        return unifiedArray.sort((a, b) => {
            const dateA = formatToLocalTime(a.date)?.getTime() || 0;
            const dateB = formatToLocalTime(b.date)?.getTime() || 0;
            return dateA - dateB;
        });
    }, [filteredAndFormattedPlayers, mailsState, socialState, chatsState, newsState, filteredAndFormattedEvents]);

    const searchInData = (searchText) => {
        if (!searchText || !normalizeData.length) {
            setDataPrint(normalizeData); // מחזירים את כל הנתונים אם אין טקסט לחיפוש
            return;
        }

        const lowerCaseSearchText = searchText.toLowerCase();

        const filteredData = normalizeData.filter(item => {
            return (
                (item.title && item.title.toLowerCase().includes(lowerCaseSearchText)) ||
                (item.bodyText && item.bodyText.toLowerCase().includes(lowerCaseSearchText)) ||
                (item.from && item.from.toLowerCase().includes(lowerCaseSearchText)) ||
                (item.to_list && item.to_list.toLowerCase().includes(lowerCaseSearchText)) ||
                (item.event_name && item.event_name.toLowerCase().includes(lowerCaseSearchText))
            );
        });

        setDataPrint(filteredData); // עדכון הסטייט עם התוצאות המסוננות
    };

    // פונקציה שמבצעת סינון על הנתונים לפי הפילטרים
    const filterData = useCallback(() => {
        let filteredData = [...normalizeData];

        // סינון לפי פעולות
        // if (actionsFilterSelect.length > 0) {
        //     filteredData = filteredData.filter(item =>
        //         actionsFilterSelect.includes(item.type)
        //     );
        // }

        // סינון לפי פלטפורמה
        if (platformFilterSelect) {
            filteredData = filteredData.filter(item =>
                platformFilterSelect.includes(item.platform_type)
            );
        }

        // סינון לפי שחקנים
        if (playersFilterSelect) {
            filteredData = filteredData.filter(item =>
                playersFilterSelect?.some(playerId =>
                    !item.from_id && !item.to_list_ids && !item.cc_list_ids && !item.bcc_list_ids || // אם כל הערכים ריקים
                    item.from_id === playerId ||
                    (item.to_list_ids && item.to_list_ids.includes(playerId)) ||
                    (item.cc_list_ids && item.cc_list_ids.includes(playerId)) ||
                    (item.bcc_list_ids && item.bcc_list_ids.includes(playerId))
                )
            );
        }

        // סינון לפי אירועים
        if (eventsFilterSelect) {
            filteredData = filteredData.filter(item =>
                eventsFilterSelect.includes(item.event_id) || !item?.event_id
            );
        }

        setDataPrint(filteredData); // עדכון הסטייט של הנתונים המודפסים
    }, [normalizeData, actionsFilterSelect, playersFilterSelect, eventsFilterSelect, platformFilterSelect]);

    // מאזין לשינויים במסננים
    useEffect(() => {
        filterData();
    }, [filterData]); // מפעיל את הפונקציה בכל שינוי במשתנים

    return (
        <RawDataContext.Provider value={{
            rawDataFlag,
            setRawDataFlag,
            reset,
            removeItem,
            searchItem,
            addItem,

            searchInData,

            optionsPlatform,

            dataPrint,

            actionsFilter,
            actionsFilterSelect,
            actionsFilterSearch,

            platformFilter,
            platformFilterSelect,
            platformFilterSearch,

            eventsFilter,
            eventsFilterSelect,
            eventsFilterSearch,

            playersFilter,
            playersFilterSelect,
            playersFilterSearch
        }}>
            {props.children}
        </RawDataContext.Provider>
    );
}
