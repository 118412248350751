import { StyleSheet, View, Text as TextNative, Text } from 'react-native'
import React, { useState, useMemo, useRef } from 'react'
import UseResponsiveSize from '../../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import { t } from 'i18n-js';
import { useSelector } from 'react-redux';
import ShortenWord from '../../../../modules/ShortenWord';
import { useEffect } from 'react';

export default function TooltipStatistics({ tooltip, filterPlatform, btnRef, tableRef, top }) {
    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(width, height, heightS, widthS);

    const background = useSelector(state => state.style.background);
    const color = useSelector(state => state.style.color);
    const images = useSelector(state => state.style.images);

    const containerRef = useRef(null);

    const [tooltipState, setTooltipState] = useState();
    const [stylePopup, setStylePopup] = useState();

    const printColors = (type) => {
        switch (type) {
            case "mail":
                return "#6A0084";
            case "sent":
                return "#D98000";
            case "social":
                return "#FCF000";
            case "chat":
                return "#006ABC";
            case "challenges":
                return "#00DBAF";
        }
    }

    useEffect(() => {
        if (tooltip) {
            let newObj = { x: tooltip.x, y: tooltip.y };
            Object.keys(tooltip).forEach((e) => {
                if (tooltip[e] > 0) {
                    newObj[tooltip.score[e]] = tooltip[e];
                }
            });
            setTooltipState(newObj);
        }
    }, [tooltip]);

    const platform = (type) => {
        switch (type) {
            case "mail":
                return (
                    <View
                        style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                    >
                        <View style={styles.viewLeft}>
                            <View style={[styles.circle, { backgroundColor: "#6A0084" }]} />
                            <TextNative style={[styles.text, { color: color.text }]}>{t("incoming_items")} - </TextNative>
                        </View>
                        <TextNative style={[styles.textNum, { color: color.text }]}>{tooltipState?.mail || 0}</TextNative>
                    </View>)
            case "sent":
                return (
                    <View
                        style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                    >
                        <View style={styles.viewLeft}>
                            <View style={[styles.circle, { backgroundColor: "#D98000" }]} />
                            <TextNative style={[styles.text, { color: color.text }]}>{t("sent_items")} - </TextNative>
                        </View>
                        <TextNative style={[styles.textNum, { color: color.text }]}>{tooltipState?.sent || 0}</TextNative>
                    </View>
                )
            case "challenges":
                return (
                    <View
                        style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                    >
                        <View style={styles.viewLeft}>
                            <View style={[styles.circle, { backgroundColor: "#00DBAF" }]} />
                            <TextNative style={[styles.text, { color: color.text }]}>{t("challenges")} - </TextNative>
                        </View>
                        <TextNative style={[styles.textNum, { color: color.text }]}>{tooltipState?.challenges || 0}</TextNative>
                    </View>
                )
            case "chat":
                return (
                    <View
                        style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                    >
                        <View style={styles.viewLeft}>
                            <View style={[styles.circle, { backgroundColor: "#006ABC" }]} />
                            <TextNative style={[styles.text, { color: color.text }]}>{t("chat_messages")} - </TextNative>
                        </View>
                        <TextNative style={[styles.textNum, { color: color.text }]}>{tooltipState?.chat || 0}</TextNative>
                    </View>
                );
            case "social":
                return (
                    <View
                        style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                    >
                        <View style={styles.viewLeft}>
                            <View style={[styles.circle, { backgroundColor: "#FCF000" }]} />
                            <TextNative style={[styles.text, { color: color.text }]}>{t("social")} - </TextNative>
                        </View>
                        <TextNative style={[styles.textNum, { color: color.text }]}>{tooltipState?.social || 0}</TextNative>
                    </View>
                )
        }
    }

    useEffect(() => {
        if (btnRef && tableRef?.current && containerRef?.current) {
            let style = {};
            let btnHeight = btnRef?.getBoundingClientRect()?.height;
            let tooltipHeight = containerRef?.current?.getBoundingClientRect()?.height;
            if ((btnHeight - top) < tooltipHeight) {
                style["bottom"] = 0;
            } else {
                style["top"] = top;
            }
            let endTable = tableRef.current.getBoundingClientRect().x + tableRef.current.getBoundingClientRect().width;
            let endPopup = endTable - (btnRef.getBoundingClientRect().x + btnRef.getBoundingClientRect().width);
            if (endPopup < containerRef.current.getBoundingClientRect().width) {
                style["right"] = btnRef.getBoundingClientRect().width;
            } else {
                style["left"] = btnRef.getBoundingClientRect().width;
            }
            setStylePopup(style);
        }
    }, [btnRef, tableRef.current, containerRef.current, top])

    return (
        <View nativeID='files-des'
            ref={containerRef}
            style={[{
                position: "absolute",
                width: "max-content",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 3,
                transition: "all .5s",
                writingDirection: direction
            }, stylePopup]}
        >
            {/* <View style={{
                width: 0,
                height: 0,
                backgroundColor: "transparent",
                borderStyle: "solid",
                borderLeftWidth: widthS * 5,
                borderRightWidth: widthS * 5,
                borderBottomWidth: widthS * 7,
                borderLeftColor: "transparent",
                borderRightColor: "transparent",
                borderBottomColor: background.infoBox,
            }} /> */}
            <View
                style={{
                    flex: 1,
                    width: "100%",
                    borderRadius: widthS * 7,
                    backgroundColor: background.infoBox,
                    padding: widthS * 10,
                    flexDirection: "column",

                }}
            >
                <View
                    style={{
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                >
                    <Text style={[styles.textName, { color: color.text, }]}>{ShortenWord(tooltip.score.name, 12)}</Text>
                    <View>
                        {filterPlatform.split(",").map((e) => {
                            return platform(e);
                        })}
                    </View>
                </View>
            </View>
        </View>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        circle: {
            width: widthS * 6,
            height: widthS * 6,
            borderRadius: "50%",
            marginHorizontal: widthS * 3
        },
        textName: {
            fontSize: "0.8vw",
            textAlign: "center",
            fontFamily: "Catamaran_600SemiBold"
        },
        text: {
            fontSize: widthS * 7,
            textAlign: "center",
            fontFamily: "Catamaran_300Light"
        },
        textNum: {
            fontSize: widthS * 7,
            textAlign: "center",
            fontFamily: "Catamaran_700Bold",
            marginH: "1vh"
        },
        viewLeft: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
        }
    });
} 