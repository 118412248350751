import { Image, StyleSheet, Text, TouchableOpacity, View, Modal, Pressable } from 'react-native'
import React, { useMemo, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Svg, { SvgUri } from 'react-native-svg';
import { t } from 'i18n-js';
import { useState } from 'react';
import Chat from '../../chat/Chat';
import MailBox from "../../mailbox/MailBox";
import NewMailPopup from '../../mailbox/NewMailPopup';
import { Ionicons } from '@expo/vector-icons';
import { loading, minimizeDashbord } from '../../../../redux/actions';
import { MailContext } from '../../../../ContextData/Mail';

export default function InboxChat({permission}) {

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const minimizeMailState = useSelector((state) => state.minimize.minimizeDashboard);
  const styles = stylesR(background, color, direction);

  const dispatch = useDispatch();
  const { sentMail } = useContext(MailContext);

  const [platformType, setPlatformType] = useState("mail");
  const minimizeMail = useMemo(() => minimizeMailState, [minimizeMailState]);

  const closeAndSave = (id, edited) => {
    if (edited && Object.keys(edited)?.length > 0) {
      dispatch(loading(true));
      edited["is_draft_message"] = true;
      sentMail(edited).then(() => {
        dispatch(loading(false));
      }).catch(() => {
        dispatch(loading(false));
      });
    }
    dispatch(minimizeDashbord(false, false, id, true));
  };

  /// components

  const chatComponent = (
    <Chat flag={true} dashboard={true} permission={permission} />
  );

  const mailComponent = (
    <MailBox flag={true} permission={permission} dashboard={true} minimizeMail={minimizeMail} closeAndSave={closeAndSave} />
  )

  return (
    <>
      <View style={styles.container}>
        <View style={styles.nav}>
          <TouchableOpacity style={styles.iconBtn} onPress={() => setPlatformType("mail")}>
            <View style={styles.iconView}>
              <Image source={{ uri: images.mail }} style={styles.icon(platformType === "mail")} />
            </View>
            <Text style={styles.navText(platformType === "mail")}>{t("inbox").toUpperCase()}</Text>
          </TouchableOpacity>
          <Text style={styles.navLine}>|</Text>
          <TouchableOpacity style={styles.iconBtn} onPress={() => setPlatformType("chat")}>
            <View style={styles.iconView}>
              <Image source={{ uri: images.chat }} style={styles.icon(platformType === "chat")} />
            </View>
            <Text style={styles.navText(platformType === "chat")}>{t("chat").toUpperCase()}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.body}>
          {platformType === "mail" ? mailComponent : chatComponent}
        </View>
      </View>
      {minimizeMail?.map((e, i) => {
        return (
          <Modal key={e?.id} transparent={true} visible={e?.show}>
            <View style={styles.containerPopup}>
              <NewMailPopup
                dashboard={true}
                type={"mail"}
                setFlag={() => dispatch(minimizeDashbord(false, false, e?.id, true))}
                minimizeDashboard={(obj, message) => {
                  dispatch(minimizeDashbord(message, !obj?.show, obj.id))
                }}
                minimizeDashboardObject={e}
                isMinimizeDashboard={e?.show}
                message={e?.message ? e.message : false}
                messageType={e?.typeMessage ? e?.typeMessage : "dashboard"}
              />
            </View>
          </Modal>
        )
      })}
    </>
  )
}

const stylesR = (background, color, direction) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      zIndex: 2,
      writingDirection: direction,
    },
    nav: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    navLine: {
      fontSize: "1.2vw",
      lineHeight: "1.3vw",
      color: color.border,
      marginHorizontal: "1vw"
    },
    iconBtn: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.5vw",
    },
    iconView: {
      width: "1.4vw",
      height: "1.4vw",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      backgroundColor: background.infoBox,
    },
    icon: (active) => ({
      width: "0.8vw",
      height: "0.8vw",
      resizeMode: "contain",
      tintColor: active ? background.count : color.text2
    }),
    navText: (active) => ({
      color: active ? background.count : color.text2,
      fontSize: "1vw"
    }),
    body: {
      flex: 1,
      backgroundColor: background.popup1,
      borderRadius: "1vw",
      marginBottom: "1vw",
      marginTop: "0.5vw",
      maxHeight: "19.5vw",
      minHeight: "19.5vw"
    },
    containerPopup: {
      backgroundColor: "#00000075",
      height: "100vh",
      width: "100%",
      alignItems: "center",
      justifyContent: "center"
    },
  })
}