import { StyleSheet, Text, View, Image, Dimensions } from "react-native";
import { Pressable } from "react-native-web";
import React, { useState, useEffect, useRef } from "react";
import UseResponsiveSize from "../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../modules/UseResponsiveScreen";
import { useDispatch, useSelector } from "react-redux";
import Svg, { Path } from "react-native-svg";
import {
  editActionCreator,
  setTooltipAction,
} from "../../../../redux/actions/creatorActions";
import Zoom from "../../../modules/Zoom";
import { ZoomStatic } from "../../../modules/ZoomStatic";
import { ReturnTypeFlow } from "../../../modules/TypesFlowObject";

export default function Column({
  action,
  buildRef,
  index,
  actionsMetrixState,
  actionsArr,
  indexFlag,
  columnIndex,
  onRenderComplete,
  round,
  indexPlayer
}) {
  const { width: widthWindow, height: heightWindow } = Dimensions.get("window");
  const { width: widthScreen, height: heightScreen } = Dimensions.get("screen");

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS, Zoom);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const columnRef = useRef(null);

  const dispatch = useDispatch();

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);
  const realPlayers = useSelector((state) => state.players.realPlayers);
  const repPlayers = useSelector((state) => state.players.repPlayers);
  const playerCurrent = useSelector((state) => state.swimlane.playerCurrent);
  const playerHover = useSelector((state) => state.swimlane.playerHover);
  const zoomSize = useSelector((state) => state.swimlane.zoom);

  const [shapesRealState, setShapesRealState] = useState([]);
  const [shapesRepState, setShapesRepState] = useState([]);
  const [hover, setHover] = useState(false);
  const [arrows, setArrows] = useState("");
  const [actionsMetrix, setActionsMetrix] = useState([]);

  const [flag, setFlag] = useState(false);

  const printIcon = (type) => {
    switch (type) {
      case "mail":
        return (
          <Image
            source={{ uri: images.mail }}
            resizeMode="contain"
            style={[
              styles.icon,
              { position: action?.type_action === "3" ? "absolute" : "" },
            ]}
          />
        );
      case "news":
        return (
          <Image
            source={{ uri: images.news }}
            resizeMode="contain"
            style={[
              styles.icon,
              { position: action?.type_action === "3" ? "absolute" : "" },
            ]}
          />
        );
      case "sm":
        return (
          <Image
            source={{ uri: images.social }}
            resizeMode="contain"
            style={[
              styles.icon,
              { position: action?.type_action === "3" ? "absolute" : "" },
            ]}
          />
        );
    }
  };

  const checkSizeZoom = (zoom) => {
    switch (zoom) {
      case "100":
        return widthS * 0;
      case "75":
        return widthS * 3;
      case "50":
        return widthS * 5.5;
    }
  }

  const printShape = (playerId, count, indexShape) => {
    if (
      action?.to_list_ids?.indexOf(playerId) >= 0 &&
      action?.from_id?.indexOf(playerId) < 0
    ) {
      return {
        shape: (
          <Pressable
            onPress={() =>
              dispatch(
                editActionCreator({
                  platform_type: action.platform_type,
                  id: action.id,
                })
              )
            }
            ref={(ref) => {
              ref &&
                !actionsMetrix[index]?.find((e) => e === ref) &&
                actionsMetrix[index]?.push(ref);
            }}
            style={[
              styles.shape,
              styles.circle,
              {
                backgroundColor: action?.is_done
                  ? `${action?.eventColor}`
                  : `${action?.eventColor}85`,
              },
              !action?.is_done && styles.actionBorder,
              { borderColor: `${action?.eventColor}` },
            ]}
            onHoverIn={() => {
              if (!hover) {
                setHover(index);
                dispatch(
                  setTooltipAction({
                    platform: action.platform_type,
                    type: "2",
                    is_read: action.is_done,
                    title: action.subject,
                    x: actionsMetrix[index][
                      count
                    ]?.getBoundingClientRect().x - checkSizeZoom(zoomSize),
                    y: actionsMetrix[index][
                      count
                    ]?.getBoundingClientRect().y - checkSizeZoom(zoomSize),
                    message_id: Math.random(),
                  })
                );
              }
            }}
            onHoverOut={() => {
              setHover(false);
              dispatch(setTooltipAction(null));
            }}
          >
            <>
              {action?.is_kpi && indexShape === 0 && (
                <View
                  style={[
                    styles.kpi,
                    { backgroundColor: color.infoSaveBtn },
                  ]}
                >
                  <Text
                    style={[
                      styles.kpiText,
                      { fontFamily: "Catamaran_600SemiBold" },
                    ]}
                  >
                    KPI
                  </Text>
                </View>
              )}
              {printIcon(action?.platform_type)}
            </>
          </Pressable>
        ),
        type: "to",
      };
    } else if (action?.from_id?.indexOf(playerId) >= 0) {
      return {
        shape: (
          <>
            {action && action?.type_action !== "3" && (
              <Pressable
                onPress={() => {
                  dispatch(
                    editActionCreator({
                      platform_type: action.platform_type,
                      id: action.id,
                    })
                  )
                }
                }
                ref={(ref) => {
                  ref &&
                    !actionsMetrix[index]?.find((e) => e === ref) &&
                    actionsMetrix[index]?.push(ref)
                }
                }
                style={[
                  styles.shape,
                  action?.type_action === "1" && styles.circle,
                  action?.type_action === "2" && styles.squre,
                  {
                    backgroundColor: action?.is_done
                      ? `${action?.eventColor}`
                      : `${action?.eventColor}85`,
                  },
                  !action?.is_done && styles.actionBorder,
                  { borderColor: `${action?.eventColor}` },
                ]}
                onHoverIn={() => {
                  if (!hover) {
                    setHover(index);
                    dispatch(
                      setTooltipAction({
                        platform: action.platform_type,
                        type: "1",
                        is_read: action.is_done,
                        title: action.subject,
                        x: actionsMetrix[index][
                          count
                        ]?.getBoundingClientRect().x - checkSizeZoom(zoomSize),
                        y: actionsMetrix[index][
                          count
                        ]?.getBoundingClientRect().y - checkSizeZoom(zoomSize),
                      })
                    );
                  }
                }}
                onHoverOut={() => {
                  setHover(false);
                  dispatch(setTooltipAction(null));
                }}
              >
                <>
                  {action?.is_kpi && (
                    <View
                      style={[
                        styles.kpi,
                        { backgroundColor: color.infoSaveBtn },
                      ]}
                    >
                      <Text
                        style={[
                          styles.kpiText,
                          { fontFamily: "Catamaran_600SemiBold" },
                        ]}
                      >
                        KPI
                      </Text>
                    </View>
                  )}
                  {printIcon(action?.platform_type)}
                </>
              </Pressable>
            )}
            {action?.type_action === "3" && (
              <Pressable
                onPress={() =>
                  dispatch(
                    editActionCreator({
                      platform_type: action.platform_type,
                      id: action.id,
                    })
                  )
                }
                ref={(ref) =>
                  ref &&
                  !actionsMetrix[index]?.find((e) => e === ref) &&
                  actionsMetrix[index]?.push(ref)
                }
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 2,
                }}
                onHoverIn={() => {
                  if (!hover) {
                    setHover(index);
                    dispatch(
                      setTooltipAction({
                        platform: action.platform_type,
                        type: "1",
                        is_read: action.is_done,
                        title: action.subject,
                        x: actionsMetrix[index][
                          count
                        ]?.getBoundingClientRect().x - checkSizeZoom(zoomSize),
                        y: actionsMetrix[index][
                          count
                        ]?.getBoundingClientRect().y - checkSizeZoom(zoomSize),
                      })
                    );
                  }
                }}
                onHoverOut={() => {
                  setHover(false);
                  dispatch(setTooltipAction(null));
                }}
              >
                <>
                  <svg
                    viewBox={`0 0 ${widthS * ZoomStatic(70, zoomSize)} ${widthS * ZoomStatic(65, zoomSize)
                      }`}
                    width={`${widthS * ZoomStatic(37.5, zoomSize)}px`}
                    height={`${widthS * ZoomStatic(32.5, zoomSize)}px`}
                  >
                    <path
                      style={{ borderRadius: widthS * 3 }}
                      d={`
                                        m ${widthS * ZoomStatic(4, zoomSize)} ${widthS * ZoomStatic(32, zoomSize)
                        } 
                                        l ${widthS * ZoomStatic(15, zoomSize)
                        } ${widthS * ZoomStatic(-26, zoomSize)} 
                                        l ${widthS * ZoomStatic(32, zoomSize)
                        } 0 
                                        l ${widthS * ZoomStatic(15, zoomSize)
                        } ${widthS * ZoomStatic(26, zoomSize)} 
                                        l ${widthS * ZoomStatic(-15, zoomSize)
                        } ${widthS * ZoomStatic(26, zoomSize)} 
                                        l ${widthS * ZoomStatic(-32, zoomSize)
                        } 0 
                                        l ${widthS * ZoomStatic(-15, zoomSize)
                        } ${widthS * ZoomStatic(-26, zoomSize)
                        } z`}
                      stroke={`${action?.eventColor}`}
                      strokeWidth={`${widthS * ZoomStatic(3, zoomSize)}`}
                      fill={
                        action?.is_done
                          ? `${action?.eventColor}`
                          : `${action?.eventColor}85`
                      }
                    />
                  </svg>
                  {action?.is_kpi && (
                    <View
                      style={[
                        styles.kpi,
                        {
                          backgroundColor: color.infoSaveBtn,
                          right: widthS * -1,
                          top: widthS * -5,
                        },
                      ]}
                    >
                      <Text
                        style={[
                          styles.kpiText,
                          { fontFamily: "Catamaran_600SemiBold" },
                        ]}
                      >
                        KPI
                      </Text>
                    </View>
                  )}
                  {printIcon(action?.platform_type)}
                </>
              </Pressable>
            )}
          </>
        ),
        type: "from",
      };
    } else {
      return {
        shape: null,
        type: null,
      };
    }
  };

  const createShapesArr = () => {
    let shapesReal = [];
    let shapesRep = [];
    let count = 0;
    realPlayers?.length > 0 &&
      realPlayers.forEach((e, i) => {
        const { shape, type } = printShape(e.id, count, i);
        if (shape) {
          shapesReal.push({ id: e.id, shape: shape, type: type });
          count++;
        }
      });
    repPlayers?.length > 0 &&
      repPlayers.forEach((e, i) => {
        const { shape, type } = printShape(e.id, count, i);
        if (shape) {
          shapesRep.push({ id: e.id, shape: shape, type: type });
          count++;
        }
      });
    setShapesRealState([...shapesReal]);
    setShapesRepState([...shapesRep]);
  };

  const topArrow = (e) => {
    if (zoomSize === "50") {
      return (
        e.getBoundingClientRect().top -
        e.getBoundingClientRect().top -
        heightS * 13
      );
    } else if (zoomSize === "75") {
      return (
        e.getBoundingClientRect().top -
        e.getBoundingClientRect().top -
        heightS * 8
      );
    } else {
      return e.getBoundingClientRect().top - e.getBoundingClientRect().top - widthS * 3;
    }
  };

  const drawMainArrow = (startP, endP, arr, shapes) => {
    arr.shift();
    arr.pop();
    return (
      <Svg
        style={{
          position: "absolute",
          width: widthS * ZoomStatic(43, zoomSize),
          zIndex: 3,
          transtion: "all 0.5s",
          left:
            widthScreen < 1281
              ? widthS * ZoomStatic(-17, zoomSize)
              : widthS * ZoomStatic(-8, zoomSize),
          height: `${endP.getBoundingClientRect().top -
            startP.getBoundingClientRect().top +
            startP.getBoundingClientRect().height
            }px`,
          top: startP.getBoundingClientRect().y - columnRef.current?.getBoundingClientRect().y
        }}
      >
        <Path
          translate={[ZoomStatic(40, zoomSize), ZoomStatic(-5, zoomSize)]}
          d={`
                                M ${widthS * ZoomStatic(8, zoomSize)}, ${widthS * ZoomStatic(18, zoomSize)
            }
                                Q ${widthS * ZoomStatic(-2, zoomSize)}, ${widthS * ZoomStatic(18, zoomSize)
            } 
                                ${widthS * ZoomStatic(-2, zoomSize)}, ${widthS * ZoomStatic(25, zoomSize)
            }
                                L ${widthS * ZoomStatic(-1, zoomSize)}, ${endP.getBoundingClientRect().top -
            startP.getBoundingClientRect().top +
            heightS * ZoomStatic(6, zoomSize)
            }
                                Q ${widthS * ZoomStatic(-2, zoomSize)}, ${endP.getBoundingClientRect().top -
            startP.getBoundingClientRect().top +
            startP.getBoundingClientRect().height -
            heightS * ZoomStatic(18, zoomSize)
            } 
                                ${widthS * ZoomStatic(9, zoomSize)}, ${endP.getBoundingClientRect().top -
            startP.getBoundingClientRect().top +
            startP.getBoundingClientRect().height -
            heightS * ZoomStatic(18, zoomSize)
            }
                            `}
          stroke={hover === index ? "#a8a3b2" : "#60596c"}
          strokeWidth={width * ZoomStatic(1.3, zoomSize)}
          markerStart={
            shapes[0]?.type === "to"
              ? `url(#triangle${startP.__reactResponderId})`
              : null
          }
          markerEnd={
            shapes[shapes.length - 1]?.type === "to"
              ? `url(#triangle${endP.__reactResponderId})`
              : null
          }
          fill={"none"}
        />
        {arr.map((e, i) => {
          return (
            <Path
              key={i}
              translate={[ZoomStatic(45, zoomSize), topArrow(e)]}
              d={`
                                M ${widthS * ZoomStatic(-4, zoomSize)}, ${e.getBoundingClientRect().top -
                startP.getBoundingClientRect().top +
                heightS * 22
                }
                                H ${widthS * ZoomStatic(7, zoomSize)}
                            `}
              stroke={hover === index ? "#a8a3b2" : "#60596c"}
              strokeWidth={width * ZoomStatic(1.3, zoomSize)}
              fill={"none"}
              markerEnd={`url(#triangle${e.__reactResponderId})`}
            />
          );
        })}
        {arr.map((e, i) => {
          if (shapes[i + 1]?.type === "to") {
            return (
              <marker
                key={i}
                id={`triangle${e.__reactResponderId}`}
                viewBox="0 0 10 10"
                refX="5"
                refY="4"
                markerUnits="strokeWidth"
                markerWidth="5"
                markerHeight="5"
                orient="auto"
              >
                <path
                  d="M 0 0 L 6.9788 3.9442 L 0.0182 8.015 z"
                  fill={hover === index ? "#a8a3b2" : "#60596c"}
                />
              </marker>
            );
          }
        })}
        {shapes[0]?.type !== "from" && (
          <marker
            id={`triangle${startP.__reactResponderId}`}
            viewBox="0 0 10 10"
            refX="5"
            refY="4.5"
            markerUnits="strokeWidth"
            markerWidth="5"
            markerHeight="5"
            orient="auto"
          >
            <path
              d="M 7.9924 4.9998 L 7.9924 0.0042 L 0.0046 3.9728 L 7.9797 8.0427 z"
              fill={hover === index ? "#a8a3b2" : "#60596c"}
            />
          </marker>
        )}
        {shapes[shapes.length - 1]?.type !== "from" && (
          <marker
            id={`triangle${endP.__reactResponderId}`}
            viewBox="0 0 10 10"
            refX="5"
            refY="4"
            markerUnits="strokeWidth"
            markerWidth="5"
            markerHeight="5"
            orient="auto"
          >
            <path
              d="M 0 0 L 6.9788 3.9442 L 0.0182 8.015 z"
              fill={hover === index ? "#a8a3b2" : "#60596c"}
            />
          </marker>
        )}
      </Svg>
    );
  };
  const backgroundView = () => (
    <View
      style={{
        backgroundColor: color.infoBorder,
        height: heightS * ZoomStatic(45, zoomSize),
        width: index === 0 ? widthS * 180 : "100%",
        position: "absolute",
        zIndex: 1,
        right: 0,
      }}
    />
  );

  useEffect(() => {
    if (action?.type_action) {
      action.type_action = ReturnTypeFlow(action.type_action);
    }
  }, [action]);

  useEffect(() => {
    setTimeout(() => {
      setHover(!hover);
    }, 1);
    createShapesArr();
    setActionsMetrix([...actionsMetrixState.current]);
  }, [actionsArr, realPlayers, realPlayers, action, zoomSize, round]);

  useEffect(() => {
    if (realPlayers?.length > 0 || (repPlayers?.length > 0 && action)) {
      createShapesArr();
    }
    setTimeout(() => {
      setHover(!hover);
    }, 10);
  }, [actionsArr, realPlayers, repPlayers,]);

  useEffect(() => {
    setActionsMetrix([...actionsMetrixState.current]);
  }, [actionsMetrixState, actionsArr.length]);

  // useEffect(() => {
  //   // // הרנדור של הקומפוננטה הילד הושלם
  //  

  // }, []);

  useEffect(() => {
    if (onRenderComplete) {
      if (hover === false && (!action.to_list_ids && !action.from_id) || (shapesRealState.length > 0 || shapesRepState.length > 0)) {
        onRenderComplete();
      }
    }
  }, [hover, shapesRealState, shapesRepState, action]);

  return (
    <View style={[styles.container, { paddingBottom: widthS * Zoom(11), }]} ref={columnRef}>
      <View style={[styles.real, { zIndex: 3 }]} ref={ref1}>
        {realPlayers?.length > 0 &&
          realPlayers.map((e) => {
            return (
              <View style={styles.shapeView} key={e.id}>
                {(indexPlayer === e.id || playerCurrent.indexOf(e.id) >= 0) &&
                  backgroundView(e)}
                {shapesRealState.findIndex((shape) => shape.id === e.id) >= 0 &&
                  shapesRealState[
                    shapesRealState.findIndex((shape) => shape.id === e.id)
                  ].shape}
              </View>
            );
          })}
      </View>
      <View style={[styles.rep, { zIndex: 3 }]} ref={ref2}>
        {repPlayers?.length > 0 &&
          repPlayers.map((e) => {
            return (
              <View
                style={[styles.shapeView, { transtion: "all 0.5s" }]}
                key={e.id}
              >
                {(indexPlayer === e.id || playerCurrent.indexOf(e.id) >= 0) &&
                  backgroundView(e)}
                {shapesRepState.findIndex((shape) => shape.id === e.id) >= 0 &&
                  shapesRepState[
                    shapesRepState.findIndex((shape) => shape.id === e.id)
                  ].shape}
              </View>
            );
          })}
      </View>
      {/* {actionsMetrix[index]?.length > 0 &&
        drawMainArrow(
          actionsMetrix[index][0],
          actionsMetrix[index][actionsMetrix[index].length - 1],
          [...actionsMetrix[index]],
          [...shapesRealState, ...shapesRepState]
        )} */}
      {(() => {
        if (actionsMetrix[index]?.length > 0) {
          return drawMainArrow(
            actionsMetrix[index][0],
            actionsMetrix[index][actionsMetrix[index].length - 1],
            [...actionsMetrix[index]],
            [...shapesRealState, ...shapesRepState]
          )
        }
      })()}
    </View>
  );
}

const stylesR = (width, height, heightS, widthS, Zoom) => {
  return StyleSheet.create({
    container: {
      // marginTop: heightS * 9.5,
      width: "100%",
    },
    rep: {
      marginTop: heightS * Zoom(45),
    },
    shapeView: {
      height: heightS * Zoom(45),
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    actionBorder: {
      borderWidth: widthS * Zoom(2),
    },
    shape: {
      width: widthS * Zoom(30),
      height: widthS * Zoom(30),
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,
    },
    circle: {
      borderRadius: "50%",
    },
    squre: {
      borderRadius: widthS * Zoom(8),
    },
    octagonBar: {
      width: widthS * Zoom(30),
      height: widthS * Zoom(3),
      backgroundColor: "red",
    },
    kpi: {
      borderRadius: "50%",
      width: widthS * Zoom(15),
      height: widthS * Zoom(15),
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: widthS * Zoom(-5),
      right: widthS * Zoom(-9),
    },
    kpiText: {
      fontSize: width * Zoom(8),
    },
    icon: {
      width: widthS * Zoom(15),
      height: heightS * Zoom(20),
    },
  });
};
