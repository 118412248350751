export const BUILD_SIMULATION_FLAG = "BUILD_SIMULATION_FLAG";
export const BUILD_FLAG = "BUILD_FLAG";
export const INFO_FLAG = "INFO_FLAG";
export const INFO_BODY = "INFO_BODY";
export const DASHBOARD_FLAG = "DASHBOARD_FLAG";

export const OPEN_REAL_PLAYER = "OPEN_REAL_PLAYERS";
export const OPEN_REP_PLAYER = "OPEN_REP_PLAYERS";
export const SET_REAL_PLAYERS = "SET_REAL_PLAYERS";
export const SET_REP_PLAYERS = "SET_REP_PLAYERS";
export const CHANGE_POSITION_REAL_P = "CHANGE_POSITION_REAL_P";
export const CHANGE_POSITION_REP_P = "CHANGE_POSITION_REP_P";
export const SET_STATUS_PLAYERS = "SET_STATUS_PLAYERS";

export const SET_KPI = "SET_KPI";
export const SET_ACTION_CREATOR = "SET_ACTION_CREATOR";
export const ADD_ACTION_CREATOR = "ADD_ACTION_CREATOR";
export const DELETE_ACTION_CREATOR = "DELETE_ACTION_CREATOR";
export const CHANGE_TIME_UNITS = "CHANGE_TIME_UNITS";
export const SET_FLOW_FILTERS = "SET_FLOW_FILTERS";
export const CHANGE_FLOW_ZOOM = "CHANGE_FLOW_ZOOM";
export const SET_FLOW_TIME_COLUMNS = "SET_FLOW_TIME_COLUMNS";
export const SET_UNFINSHED_FLOW_COLUMNS = "SET_UNFINSHED_FLOW_COLUMNS";

export const SET_EVENTS_CREATOR = "SET_EVENTS_CREATOR";
export const ADD_EVENT = "ADD_EVENT";
export const ADD_EVENT_BETWEEN = "ADD_EVENT_BETWEEN";
export const DELETE_EVENT = "DELETE_EVENT";

export const MARK_PLAYER = "MARK_PLAYER";
export const MARK_PLAYER_FILTER = "MARK_PLAYER_FILTER";
export const MARK_PLAYER_HOVER = "MARK_PLAYER_HOVER";

export const CAN_START = "CAN_START";
export const SEND_ACTION_STATE = "SEND_ACTION_STATE";

export const SET_TOOLTIP_ACTION = "SET_TOOLTIP_ACTION";

export const CHANGE_COLUMN_POSITION = "CHANGE_COLUMN_POSITION";

export const SET_NEW_SCROLL = "SET_NEW_SCROLL";
export const EDIT_ACTION = "EDIT_ACTION";

// analytics
export const SHOW_ANALYTICS = "SHOW_ANALYTICS";
export const SHOW_ANALYTICS_EXPECTED = "SHOW_ANALYTICS_EXPECTED";
export const SHOW_ANALYTICS_REAL = "SHOW_ANALYTICS_REAL";
export const SHOW_ANALYTICS_COMPARED = "SHOW_ANALYTICS_COMPARED";

export const ANALYTICS_ACTIONS = "ANALYTICS_ACTIONS";
export const ANALYTICS_PLAYERS = "ANALYTICS_PLAYERS";
export const ANALYTICS_EVENTS = "ANALYTICS_EVENTS";

export const SET_SURVEY_ANALYTICS = "SET_SURVEY_ANALYTICS";

export const changeBuildSimulationFlag = (flag) => {
  return {
    type: BUILD_SIMULATION_FLAG,
    flag: flag,
  };
};

export const changeBuildFlag = (flag) => {
  return {
    type: BUILD_FLAG,
    flag: flag,
  };
};

export const changeInfoFlag = (flag) => {
  return {
    type: INFO_FLAG,
    flag: flag,
  };
};

export const infoBodyFlag = () => {
  return {
    type: INFO_BODY,
  };
};

export const changeDashboardFlag = (flag) => {
  return {
    type: DASHBOARD_FLAG,
    flag: flag
  }
}

export const openRealPlayer = (player, flag) => {
  return {
    type: OPEN_REAL_PLAYER,
    payload: player,
    flag: flag,
  };
};

export const openRepPlayer = (player, flag) => {
  return {
    type: OPEN_REP_PLAYER,
    payload: player,
    flag: flag,
  };
};

export const setRealPlayers = (playersArr) => {
  return {
    type: SET_REAL_PLAYERS,
    payload: playersArr,
  };
};
export const setReplPlayers = (playersArr) => {
  return {
    type: SET_REP_PLAYERS,
    payload: playersArr,
  };
};
export const changePositionRealPlayers = (index, newIndex) => {
  return {
    type: CHANGE_POSITION_REAL_P,
    index: index,
    newIndex: newIndex,
  };
};
export const changePositionRepPlayers = (index, newIndex) => {
  return {
    type: CHANGE_POSITION_REP_P,
    index: index,
    newIndex: newIndex,
  };
};
export const setStatusPlayers = (arrayId) => {
  return {
    type: SET_STATUS_PLAYERS,
    payload: arrayId
  }
}

export const setEventsCreator = (eventsArr) => {
  return {
    type: SET_EVENTS_CREATOR,
    payload: eventsArr,
  };
};

export const addEvent = (event) => {
  return {
    type: ADD_EVENT,
    payload: event,
  };
};

export const addEventBetween = (index) => {
  return {
    type: ADD_EVENT_BETWEEN,
    payload: index,
  };
};

export const deleteEvent = (id) => {
  return {
    type: DELETE_EVENT,
    payload: id,
  };
};
export const setKpi = (kpiArr) => {
  return {
    type: SET_KPI,
    payload: kpiArr,
  };
};
export const setActionCreator = (actionArr) => {
  return {
    type: SET_ACTION_CREATOR,
    payload: actionArr,
  };
};
export const addActionCreator = (actionObj, injectObj, type, flag) => {
  return {
    type: ADD_ACTION_CREATOR,
    payload: { ...actionObj },
    inject: { ...injectObj },
    typeP: type,
    flag: flag,
  };
};
export const deleteActions = (eventId, deleteOne) => {
  return {
    type: DELETE_ACTION_CREATOR,
    payload: eventId,
    deleteOne: deleteOne
  };
};

export const changeFlowTimeUnits = (newTime) => {
  return {
    type: CHANGE_TIME_UNITS,
    payload: newTime,
  };
};

export const setFlowTimeColumns = (infoObj, count) => {
  return {
    type: SET_FLOW_TIME_COLUMNS,
    payload: infoObj,
    count: count
  }
}

export const setUnfinishedActions = (missingByPlayers, missingActions) => {
  return {
    type: SET_UNFINSHED_FLOW_COLUMNS,
    missingByPlayers,
    missingActions
  }
}

export const setFlowFilters = (filterObj) => {
  return {
    type: SET_FLOW_FILTERS,
    payload: filterObj,
  };
};
export const changeFlowZoom = (newValue) => {
  return {
    type: CHANGE_FLOW_ZOOM,
    payload: newValue,
  };
};

export const markPlayer = (id, flag, all) => {
  return {
    type: MARK_PLAYER,
    payload: id,
    flag: flag,
    all: all,
  };
};
export const markPlayerFilter = (all) => {
  return {
    type: MARK_PLAYER_FILTER,
    payload: all,
  };
};
export const markPlayerHover = (id) => {
  return {
    type: MARK_PLAYER_HOVER,
    payload: id,
  };
};

export const canStart = () => ({
  type: CAN_START
});

export const sendActionState = (id) => ({
  type: SEND_ACTION_STATE,
  id: id
});

export const setTooltipAction = (obj) => {
  return {
    type: SET_TOOLTIP_ACTION,
    payload: obj,
  };
};

export const changeColumnPosition = (id, index) => {
  return {
    type: CHANGE_COLUMN_POSITION,
    id: id,
    index: index,
  };
};

export const setNewScroll = (num) => {
  return {
    type: SET_NEW_SCROLL,
    payload: num,
  };
};

export const editActionCreator = (obj) => {
  return {
    type: EDIT_ACTION,
    payload: obj
  }
}

// analytics \\
export const showAnalytics = (flag) => {
  return {
    type: SHOW_ANALYTICS,
    flag: flag
  }
}
export const showAnalyticsExpected = (flag) => {
  return {
    type: SHOW_ANALYTICS_EXPECTED,
    flag: flag
  }
}
export const showAnalyticsReal = (flag) => {
  return {
    type: SHOW_ANALYTICS_REAL,
    flag: flag
  }
}
export const showAnalyticsCompared = (flag) => {
  return {
    type: SHOW_ANALYTICS_COMPARED,
    flag: flag
  }
}

export const analyticsActions = (listIds) => {
  return {
    type: ANALYTICS_ACTIONS,
    payload: listIds
  }
}
export const analyticsPlayers = (listIds) => {
  return {
    type: ANALYTICS_PLAYERS,
    payload: listIds

  }
}
export const analyticsEvents = (listIds) => {
  return {
    type: ANALYTICS_EVENTS,
    payload: listIds
  }
}

export const setSurveyAnalytics = (arr) => {
  return {
    type: SET_SURVEY_ANALYTICS,
    payload: arr
  }
} 
