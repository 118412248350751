import React, { useState, useEffect, useCallback, useMemo, createContext } from 'react';
import { useSelector } from 'react-redux';
import { setTimeUnits } from '../../../../modules/createTimeColumns';

export const AnalyticstimesContext = createContext();

export default function AnalyticstimesProvider(props) {

    // selector

    const sendMailsSelector = useSelector(state => state.mailArr.sentMails);
    const mailsSelector = useSelector(state => state.mailArr.messages);
    const socialSelector = useSelector(state => state.socialMedia.socialMediaArr);
    const challengesSelector = useSelector(state => state.actionsArr.actionsArr);
    const chatSelector = useSelector(state => state.chatArr.allMessages);
    const actionsArrSelector = useSelector((state) => state.swimlane.actions);
    const gameInfoArr = useSelector(state => state.gameInfoArr.gameInfoArr);
    // select filter selector \\
    const actionSelectSelector = useSelector(state => state.analytics.filter.actions);
    const eventsSelectSelector = useSelector(state => state.analytics.filter.events);
    // end - selector\\
    const startDateGame = gameInfoArr?.start_time ? new Date(`${gameInfoArr?.start_time}`) : new Date(`${gameInfoArr?.schedule_start_time}`)

    // state - data
    const [data, setData] = useState({
        mails: [],
        challenges: [],
        social: [],
        chat: [],
        actions: [],
    });
    //\\
    // state - data to show \\
    // expected \\
    const [times, setTimes] = useState([]);
    // real \\
    const [timesReal, setTimesReal] = useState([]);
    const [timesCompared, setTimesCompared] = useState([]);
    // json
    const [timesJson, setTimesJson] = useState([]);
    // real \\
    const [timesRealJson, setTimesRealJson] = useState([]);
    //\\
    const [refresh, setRefresh] = useState(false);
    // const [maxCount, setCount] = useState();
    const [timeUnitsState, setTimeUnitsState] = useState("05m");
    const [position, setPosition] = useState(0);
    const [positionReal, setPositionReal] = useState(0);

    const [count, setCount] = useState({ start: 0 });
    const [countReal, setCountReal] = useState({ start: 0 });


    // select filter
    useEffect(() => {
        setData(prevData => ({ ...prevData, actions: actionsArrSelector }));
        setRefresh(!refresh);
    }, [actionsArrSelector]);

    useEffect(() => {
        const filteredMails = (sendMailsSelector.length > 0 ? sendMailsSelector : mailsSelector).filter(e =>
            e?.event?.name ? eventsSelectSelector.includes(e?.event?.id) : true
        );
        setData(prevData => ({ ...prevData, mails: filteredMails }));
        setRefresh(!refresh);
    }, [sendMailsSelector.length, mailsSelector.length, eventsSelectSelector]);

    useEffect(() => {
        if (challengesSelector.length > 0) {
            setData(prevData => ({ ...prevData, challenges: challengesSelector }));
            setRefresh(!refresh);
        }
    }, [challengesSelector, eventsSelectSelector]);

    useEffect(() => {
        if (socialSelector.length > 0) {
            setData(prevData => ({ ...prevData, social: socialSelector }));
            setRefresh(!refresh);
        }
    }, [socialSelector.length]);

    useEffect(() => {
        if (chatSelector.length > 0) {
            setData(prevData => ({ ...prevData, chat: chatSelector }));
            setRefresh(!refresh);
        }
    }, [chatSelector.length]);

    const createArrayTimes = useCallback((count, timeUnits, actions, mails, challenges, chat, social, typeArr) => {
        let timeArr = [];
        let arr1 = [];
        // expected
        if (actions.length > 0) {
            arr1 = [...createtimes([], actions)];
            arr1.reverse();
            arr1 = arr1.filter(function (item, pos, self) {
                return self.indexOf(item) == pos;
            });
            if (arr1.length > 0 && times.length < arr1.length) {
                // typeArr = typeArr > 0 ? typeArr : 1;
                
                let max = arr1.length;
                for (let i = count.start; i < max; i++) {
                    timeArr.push({ time: `${arr1[i]}`, date: `${arr1[i]}` });
                }
                if (count.start === 0) {
                    setTimes([...timeArr]);
                } else {
                    setTimes([...times, ...timeArr]);
                }
            }
        }
        // real
        let timesArr = createTimesCurrent(count, challenges, mails, chat, social, typeArr, null, timeUnits);
        if (timesArr?.length > 0 && timeUnits) {
            if (count.start === 0) {
                setTimesReal([...timesArr]);
            } else {
                setTimesReal([...timesReal, ...timesArr]);
            }
        }
        // compared
        arr1 = actions.filter(function (item, pos, self) {
            return self.findIndex((e) => item.time === e.time) === pos;
        });
        let lastStep = arr1[arr1.length - 1]
        // timesArr = createTimesCurrent(challenges, mails, challenges, timesArr, typeArr, type, timeUnitsS, lastStep[lastStep?.length - 1]?.time);
        timesArr = createTimesCurrent(count, challenges, mails, chat, social, typeArr, "compared", timeUnits, lastStep?.time);
        if (timesArr?.length > 0 && timeUnits) {
            if (count.start === 0) {
                setTimesCompared([...timesArr]);
            } else {
                setTimesCompared([...timesCompared, ...timesArr]);
            }
        }
    }, [count, times.length, timesReal, timesCompared]);

    const createArrayTimesForJson = useCallback((timeUnits, actions, mails, challenges, chat, social) => {
        let timeArr = [];
        let arr1 = [];
        // expected
        if (actions.length > 0) {
            actions.forEach((action) => {
                if (!timeArr.find((e) => +e.time === action.time)) {
                    timeArr.push({ time: `${action.time}`, date: `${action.time}` });
                }
            })
            setTimesJson([...timeArr]);
        }
        // real
        let { times } = createTimesReal(challenges, mails, chat, social, timeUnits);
        if (times?.length > 0 && timeUnits) {
            times.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            times = times.filter((e, i, arr) => {
                let anotherIndex = arr.findIndex((item) => item.date === e.date);
                if (anotherIndex !== i) {
                    return false;
                } else {
                    if (i > 0) {
                        return checkDate(timeUnits, e, arr[i - 1]);
                    }
                    return true;
                }
            });
            setTimesRealJson([...times.reverse()]);
        }
    }, []);

    const returnIfIsThefirstOrLast = (timeCurrent, time) => {
        if (!timeCurrent) {
            timeCurrent = new Date(time);
        } else if (time > timeCurrent) {
            timeCurrent = new Date(time);
        }
        return timeCurrent;
    }

    const findLastTime = (challengesP, mailsP, chatP, socialP, timeUnits) => {
        let allTimes = [];
        if (challengesP?.length > 0) {
            challengesP.reverse()?.forEach((e) => {
                if (allTimes?.length > 0) {
                    allTimes.push(new Date(`${e.action.time} utc`));
                } else {
                    allTimes.push(new Date(`${e.action.time} utc`));
                }
            });
        }
        if (mailsP?.length > 0) {
            mailsP?.reverse().forEach((e) => {
                if (allTimes?.length > 0) {
                    allTimes.push(new Date(`${e.time}`));
                } else {
                    allTimes.push(new Date(`${e.time}`));
                }
            });
        }
        if (socialP?.length > 0) {
            socialP.reverse().forEach((post) => {
                if (allTimes?.length > 0) {
                    allTimes.push(new Date(`${post.time} utc `));
                } else {
                    allTimes.push(new Date(`${post.time} utc `));
                }
            });
        }
        if (chatP?.length > 0) {
            chatP.reverse().forEach((chat) => {
                if (allTimes?.length > 0) {
                    allTimes.push(new Date(`${chat.time}`));
                } else {
                    allTimes.push(new Date(`${chat.time}`));
                }
            })
        };
        return {
            allTimes: allTimes?.sort((a, b) => a - b)
        };
    }

    const checkDate = (timeUnits, timeCurrent, lastTime, flag) => {
        let lastTimeDate = new Date(lastTime.date);
        let timeCurrentDate = new Date(timeCurrent.date);
        let count = parseInt(timeUnits, 10);
        if (flag) {
            if (timeUnits.indexOf("m") >= 0) {
                lastTimeDate.setMinutes(lastTimeDate.getMinutes() - count);
                if (timeCurrentDate.getTime() < lastTimeDate.getTime()) {
                    return true;
                } else {
                    return false;
                }
            }
            if (timeUnits.indexOf("h") >= 0) {
                lastTimeDate.setHours(lastTimeDate.getHours() - count);
                if (timeCurrentDate.getTime() < lastTimeDate.getTime()) {
                    return true;
                } else {
                    return false;
                }
            }
            if (timeUnits.indexOf("d") >= 0) {
                lastTimeDate.setDate(lastTimeDate.getDate() - count);
                if (timeCurrentDate.getTime() < lastTimeDate.getTime()) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            if (timeUnits.indexOf("m") >= 0) {
                lastTimeDate.setMinutes(lastTimeDate.getMinutes() + count);
                if (timeCurrentDate.getTime() >= lastTimeDate.getTime()) {
                    return true;
                } else {
                    return false;
                }
            }
            if (timeUnits.indexOf("h") >= 0) {
                lastTimeDate.setHours(lastTimeDate.getHours() + count);
                if (timeCurrentDate.getTime() >= lastTimeDate.getTime()) {
                    return true;
                } else {
                    return false;
                }
            }
            if (timeUnits.indexOf("d") >= 0) {
                lastTimeDate.setDate(lastTimeDate.getDate() + count);
                if (timeCurrentDate.getTime() >= lastTimeDate.getTime()) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    const createTimesReal = (challengesP, mailsP, chatP, socialP, timeUnits) => {
        let times = []
        if (challengesP?.length > 0) {
            challengesP?.forEach((e) => {
                times.push(addStepsToTimeArr(e.action.time, timeUnits));
            });
        }
        if (mailsP?.length > 0) {
            mailsP?.forEach((e) => {
                times.push(addStepsToTimeArr(e.time, timeUnits));
            });
        }
        if (socialP?.length > 0) {
            socialP.forEach((post) => {
                times.push(addStepsToTimeArr(post.time, timeUnits));
            });
        }
        if (chatP?.length > 0) {
            chatP.forEach((chat) => {
                times.push(addStepsToTimeArr(chat.time, timeUnits));
            })
        }
        return {
            times
        };
    }

    const addStepsToTimeArr = (timeCheck, timeUnits) => {
        let timeCheckDate = new Date(timeCheck);
        let item = {
            time: `${timeCheckDate.getHours()}`.padStart(2, "0") + ':' + `${timeCheckDate.getMinutes()}`.padStart(2, "0"),
            date: `${timeCheckDate.toLocaleDateString()} ${timeCheckDate.getHours()}:` + `${timeCheckDate.getMinutes()}`.padStart(2, "0")
        }
        if (timeUnits.indexOf("d") >= 0) {
            item.time = `${timeCheckDate.toLocaleDateString()}`;
        }
        return item
    }

    const createTimesCurrent = (count, challenges, mails, chat, social, typeArr, type, timeUnits, countStep) => {
        let { allTimes } = findLastTime(challenges, mails, chat, social, timeUnits);
        if (allTimes?.length > 0) {
            let timeArr = setTimeUnits(count, { timeUnits: timeUnits, time: new Date(startDateGame) }, allTimes, typeArr, +countStep);
            if (type === "compared" && timeArr?.length > 0) {
                timeArr.forEach((e, i, arr) => e.step = arr.length - i);
            }
            return timeArr;
        }

    }

    const createtimes = (times, arr, key, type) => {
        let arrN = [...times];
        if (arr.length > 0) {
            if (type === "chat") {
                arr?.forEach((e) => {
                    e.newMsg?.forEach((e) => {
                        arrN.push(e.time);
                    });
                    e.msgHistory?.forEach((e) => {
                        arrN.push(e.time);
                    });
                });
            } else {
                arr?.forEach((e) => {
                    arrN.push(e.time);
                });
            }
        }
        return arrN;
    }

    const resetPositionAndCount = () => {
        setCount({ start: 0 })
        setPosition(0);
    }

    useEffect(() => {
        createArrayTimes(count, timeUnitsState, data.actions, data.mails, data.challenges, data.chat, data.social, 1);
    }, [refresh, timeUnitsState, count, data]);

    // 
    useEffect(() => {
        if (position === count.start + 49) {
            setCount({ start: count.start + 50 });
        }
    }, [position, count.start]);

    useEffect(() => {
        if (positionReal === countReal.start + 49) {
            setCountReal({ start: countReal.start + 50 });
        }
    }, [positionReal, countReal.start]);

    useEffect(() => {
        createArrayTimesForJson(timeUnitsState, data.actions, data.mails, data.challenges, data.chat, data.social);
    }, [refresh, timeUnitsState, data]);

    return (
        <AnalyticstimesContext.Provider value={{
            times,
            position,
            setPosition,
            positionReal,
            setPositionReal,
            timesReal,
            timesCompared,
            refresh,
            setRefresh,
            timeUnitsState,
            setTimeUnitsState,
            resetPositionAndCount,
            count,
            countReal,
            createArrayTimesForJsonData: createArrayTimesForJson,
            timesJson,
            timesRealJson
        }}>
            {props.children}
        </AnalyticstimesContext.Provider>
    )
}
