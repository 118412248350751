import {
  ADD_EVENT,
  ADD_EVENT_BETWEEN,
  DELETE_EVENT,
  SET_EVENTS,
  SET_EVENTS_CREATOR,
} from "../actions/creatorActions";

import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';
import { colorForEvent } from "../../componnets/Elements/colorForEvent";

export const events = (
  state = { events: [{ id: `${Math.random()}$`, color: colorForEvent(0) }], allEvents: [] },
  action
) => {
  switch (action.type) {
    case SET_EVENTS_CREATOR: {
      let temp = [...action.payload]
        .filter((event) => event.name)
        .map((event, i) => {
          return {
            ...event,
            description: event?.description?.length > 0 ? checkIfIsEncodeText(event?.description, "line") : "",
            title: checkIfIsEncodeText(event.name, "line"),
            color: colorForEvent(i),
            name: checkIfIsEncodeText(event.name, "line"),
          };
        });
      temp.push({ id: `${Math.random()}$`, color: colorForEvent(0) });
      return {
        ...state,
        events: temp,
        allEvents: action.payload
      };
    }

    case ADD_EVENT: {
      const temp = [...state.events];
      const index = temp.findIndex((e) => e.id === action.payload.id);
      if (!temp[index].title && !temp[index].eventBetween) {
        temp.push({ id: `${Math.random()}$`, color: colorForEvent(1) });
      }
      temp.splice(index, 1, {
        ...action.payload,
        color: action.payload.color || colorForEvent(0),
        eventBetween: false,
        id: temp[index].eventBetween ? `${Math.random()}$` : temp[index].id,
      });
      // colors.shift();
      return {
        ...state,
        events: temp,
      };
    }
    case ADD_EVENT_BETWEEN: {
      const temp = [...state.events];
      temp.splice(action.payload, 0, {
        id: "",
        type: "event_between",
        color: colorForEvent(0),
        eventBetween: "+",
        position: action.payload,
      });
      return {
        ...state,
        events: temp,
      };
    }
    case DELETE_EVENT: {
      const temp = [...state.events];
      const index = state.events.findIndex((e) => e.id === action.payload);
      if (index > -1) temp.splice(index, 1);
      return { ...state, events: temp };
    }
    default: {
      return state;
    }
  }
};
