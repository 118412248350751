import { StyleSheet, Text, View, Animated, PanResponder, Pressable, Slider } from 'react-native';
import React, { useRef, useMemo } from 'react'
import UseResponsiveScreen from '../../../modules/UseResponsiveScreen';
// import { setNewScroll } from '../../../../redux/actions/creatorActions';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

export default function ScrollHorizontal({ flowRef, flowTable, newScroll, setNewScroll }) {

    const { widthS, heightS } = UseResponsiveScreen();
    const zoomSize = useSelector(state => state.swimlane.zoom);
    const buildFlag = useSelector(state => state.build.buildFlag);
    const dispatch = useDispatch();

    const [div, setDiv] = useState(document.getElementById("mydiv"));

    function dragElement(elmnt) {
        var pos1 = 0, pos3 = 0;
        elmnt.onmousedown = dragMouseDown;
        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos3 = e.clientX;
            // set the element's new position:
            let move = elmnt.offsetLeft - pos1;
            if (move < 0) {
                move = 0;
            }
            else if (move > flowRef - (flowRef / (flowTable / flowRef))) {
                move = flowRef - flowRef / (flowTable / flowRef);
            }
            elmnt.style.left = move + "px";
            // dispatch(setNewScroll(move * (flowTable / flowRef)));
            setNewScroll(move * (flowTable / flowRef));
        }

        function closeDragElement() {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }


    useMemo(() => {
        if (div) {
            dragElement(div);
        }
    }, [div, buildFlag]);

    useEffect(() => {
        setDiv(document.getElementById("mydiv"))
    });

    return (
        <View>
            <View nativeID='mydiv' style={{
                cursor: "pointer",
                height: heightS * 9,
                width: flowRef / (flowTable / flowRef),
                backgroundColor: "#473f4d", borderRadius: widthS * 6
            }} />
        </View>
    )
}

const styles = StyleSheet.create({})