import React from "react";
import { Pressable, StyleSheet, Image } from "react-native";
import { useSelector } from "react-redux";
import UseResponsiveScreen from "../../../../modules/UseResponsiveScreen";
import UseResponsiveSize from "../../../../modules/UseResponsiveSize";


export default function ActionIcon({ uri, onPress, active, disabled }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);
  
  const background = useSelector((state) => state.style.background);

  return (
    <Pressable
    disabled={!disabled}
      style={({ pressed, hovered, focused }) => [
        {
          backgroundColor:
            (hovered || active) && disabled ? background.infoSaveBtn : background.infoBody,
        },
        styles.actionIconContainer,
      ]}
      onPress={onPress}
    >
      <Image resizeMode="contain" style={styles.actionIcon} source={{ uri }} />
    </Pressable>
  );
}

const stylesR = (width,height, heightS, widthS) => StyleSheet.create({
  actionIconContainer: {
    borderRadius: "50%",
    width: widthS * 30,
    height: widthS * 30,
    marginHorizontal: widthS * 3,
    justifyContent: "center",
    alignItems: "center",
  },
  actionIcon: {
    width: widthS * 15,
    height: widthS * 15,
  },
});
