import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as FileSaver from "file-saver";
import XLSX from 'sheetjs-style';
import { Pressable, StyleSheet } from 'react-native';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import TextCustomize from '../../../Elements/TextCustomize';
import { useDispatch, useSelector } from 'react-redux';
import { AntDesign } from '@expo/vector-icons';
import { loading } from '../../../../redux/actions';
import { t } from 'i18n-js';
import { useRawDataContext } from '../../../../ContextData/RawData';

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const styleHeader = {
    alignment: {
        vertical: 'center',
        horizontal: 'center',
    },
    font: {
        bold: true,
    },
};

const styleCell = {
    alignment: {
        vertical: 'center',
        horizontal: 'center',
    },
};

const ExportButton = ({ data, fileName }) => {

    const dispatch = useDispatch();

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const styles = stylesR(background, color);

    const { optionsPlatform } = useRawDataContext();

    const [isPressed, setIsPressed] = useState(false);

    const handleExport = async () => {
        const formatToLocalTime = (dateString) => {
            // בדיקת פורמט: אם המחרוזת אינה מכילה "UTC" או "Z", נוסיף "Z" כדי להגדיר שזה UTC
            const isUTC = /Z|UTC|GMT/i.test(dateString);
            const formattedDateString = isUTC ? dateString : dateString + "Z";

            // יצירת אובייקט תאריך
            const date = new Date(formattedDateString);

            // בדיקת תקינות
            if (isNaN(date.getTime())) {
                return "Invalid date";
            }

            // בדיקת פורמט אזורי
            const options = {
                hour: "numeric",
                minute: "2-digit",
                hour12: !Intl.DateTimeFormat().resolvedOptions().locale.startsWith("en-US"), // AM/PM רק לאמריקאים
            };

            // החזרת הזמן המקומי בפורמט המתאים
            return date.toLocaleTimeString(undefined, options);
        };
        
        new Promise((resolve, reject) => {
            try {
                const headers = [
                    { key: 'date', label: t('date') },
                    { key: 'from', label: t('from') },
                    { key: 'to_list', label: t('to') },
                    { key: 'cc_list', label: t('cc') },
                    { key: 'bcc_list', label: t('bcc') },
                    { key: 'platform_type', label: t('platform') },
                    { key: 'event_name', label: t('event') },
                    { key: 'title', label: t('title') },
                    { key: 'bodyText', label: t('body') },
                    { key: 'files', label: t('attachments') },
                ];

                // הפורמט של השורות
                const formattedData = data.map(item =>
                    headers.map(header => {
                        if (header.key === 'files') {
                            return item[header.key]?.length
                                ? {
                                    t: 's',
                                    v: item[header.key]
                                        .map(file => file.name || 'View File')
                                        .join(', '),
                                    l: {
                                        Target: item[header.key][0]?.link || '',
                                    },
                                }
                                : { t: 's', v: '' };
                        }
                        if (header.key === 'platform_type') {
                            return { t: 's', v: optionsPlatform.find(option => option.value === item[header.key])?.name || '' };
                        }
                        if (header?.key === "date") {
                            return { t: 's', v: formatToLocalTime(item[header.key]) || '' };
                        }
                        return { t: 's', v: item[header.key] || '' };
                    })
                );

                // יצירת ה-AOA של הנתונים
                const sheetData = [
                    headers.map(header => ({ t: 's', v: header.label })), // כותרות
                    ...formattedData, // נתונים
                ];
                const ws = XLSX.utils.aoa_to_sheet(sheetData);

                // הגדרת פונקציית Hyperlink
                const setHyperlink = (sheet, cell, link, text) => {
                    sheet[cell] = {
                        t: 's',
                        v: text,
                        l: { Target: link },
                    };
                };

                // יישום ה-Hyperlinks
                formattedData.forEach((row, rowIndex) => {
                    row.forEach((cell, colIndex) => {
                        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex + 1, c: colIndex });
                        if (headers[colIndex].key === 'files' && cell.l) {
                            setHyperlink(ws, cellAddress, cell.l.Target, cell.v);
                        } else {
                            ws[cellAddress] = cell;
                        }
                    });
                });

                // עיצוב הכותרות והשורות
                Object.keys(ws).forEach((cell) => {
                    if (cell !== '!ref') {
                        const rowIndex = parseInt(cell.replace(/[^0-9]/g, ''), 10);
                        ws[cell].s = rowIndex === 1 ? styleHeader : styleCell;
                    }
                });

                // יצירת הקובץ
                const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(blob, fileName + fileExtension);

                resolve(); // הצלחה
            } catch (error) {
                reject(error); // כישלון
            }
        })
            .then(() => {
                dispatch(loading(false)); // הסרת מצב טעינה
                setIsPressed(false); // איפוס מצב הלחיצה
            })
            .catch((error) => {
                dispatch(loading(false)); // הסרת מצב טעינה גם במקרה של שגיאה
                setIsPressed(false); // איפוס מצב הלחיצה
                console.error('Export Failed:', error);
            });
    };


    return (
        <>
            <Pressable
                style={({ pressed }) => ([styles.btnExport])}
                onPress={async () => {
                    setIsPressed(true);
                    await dispatch(loading(true));
                    handleExport()
                }}
            >
                {isPressed && <TextCustomize fontSize={"1.2vw"} fontWeight={600} text={t("export").toUpperCase()} />}
                <AntDesign name="export" size={24} color={color.text2} />
            </Pressable>
            {<ReactTooltip
                anchorSelect={`#export`}
                content={<TextCustomize text={t("export").toUpperCase()} fontWeight={500} fontSize={"0.8vw"} />}
                style={{ fontFamily: "Catamaran_400Regular" }}
                place='top'
            />}
        </>

    );
};

ExportButton.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    fileName: PropTypes.string.isRequired,
};

export default ExportButton;

const stylesR = (background, color) => {
    return StyleSheet.create({
        btnExport: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.6vw",
            paddingHorizontal: "0.5vw",
            transition: "all 0.5s",
        },

    })
};
