import { StyleSheet, Text, View, Pressable, ScrollView, TextInput, Image } from 'react-native'
import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AntDesign } from '@expo/vector-icons';
import { t } from 'i18n-js';
import CheckBox from '../../../Elements/CheckBox';
import FullTextTooltip from '../../../Elements/FullTextTooltip';

export default function UniversalFilter({
    arrSelect,
    arr,
    arrSearch,
    searchItem,
    addItem,
    removeItem,
    refPopup,
    reset,
    options,
    type,
    keyType,
    zIndex,
    flagSelect,
    setFlagSelect,
    all,
    real
}) {


    const colors = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);
    const backgrounds = useSelector((state) => state.style.background);

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(backgrounds, colors, direction);

    const inputRef = useRef(null);
    const items = useMemo(() => arrSelect, [arrSelect]);

    const [flagFilter, setFlagFilter] = useState(true);

    return (
        <View style={[styles.filterView, { borderBottomColor: colors.infoBorder, zIndex: zIndex }]}>
            {/* top */}
            <View style={styles.topFilterView}>
                <Pressable onPress={() => setFlagFilter(!flagFilter)}>
                    <View style={{ alignItems: "center", flexDirection: "row" }}>
                        <Text style={{ color: colors.buildText, fontSize: "0.9vw", fontFamily: "Catamaran_400Regular" }}>
                            {t(keyType)} {items?.length > 0 && `(${items?.length})`}
                        </Text>
                        <AntDesign
                            name="caretdown"
                            style={{ marginLeft: "0.5vw", transform: [{ rotate: flagFilter ? "180deg" : "0deg" }] }}
                            size={"0.5vw"}
                            color={colors.buildText}
                        />
                    </View>
                </Pressable>
                {flagFilter && <Pressable onPress={() => reset(type)}>
                    <Text style={[styles.clear, { color: colors.placeholder, textDecorationColor: colors.placeholder }]}>{t("clear")} {t("all")}</Text>
                </Pressable>}
            </View>
            {/* main-actions-filter */}
            {flagFilter &&
                <View style={{ position: "relative" }}>
                    <View ref={refPopup} style={[styles.inputView, { backgroundColor: colors.infoBorder, transition: "all 1s", zIndex: 1 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <TextInput
                                placeholder={t("search")}
                                ref={inputRef}
                                placeholderTextColor={colors.buildText}
                                onChangeText={(txt) => { searchItem(txt, "action"); setFlagSelect((prev) => prev === type ? false : type) }}
                                style={[styles.input, { color: colors.buildText, outlineStyle: "none" }]}
                            />
                            <View style={{ position: "absolute", right: "0.3vw", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                                <Pressable onPress={() => setFlagSelect((prev) => prev === type ? false : type)}>
                                    <AntDesign
                                        name="caretdown"
                                        style={{ marginRight: "0.3vw", }}
                                        size={"0.5vw"}
                                        color={colors.buildText}
                                    />
                                </Pressable>
                                <Image source={{ uri: images.searchIcon }} style={{ width: "1.1vw", height: "1.1vw", resizeMode: "contain" }} />
                            </View>
                        </View>
                        {flagSelect === type &&
                            <View style={[styles.listView, styles.shadow, { backgroundColor: colors.infoBorder, zIndex: 20, }]}>
                                <ScrollView showsVerticalScrollIndicator={false} >
                                    {type === "player" && <>
                                        <Text style={styles.textHeaders}>{t("groups")}</Text>
                                        <Pressable style={({ hovered }) => styles.btnOption(hovered)}
                                            onPress={() => { addItem("all", type); setFlagSelect(false); inputRef.current.value = "" }}
                                        >
                                            <CheckBox
                                                checked={all}
                                                colorFill={backgrounds.count}
                                                background={backgrounds.infoBody}
                                            />
                                            <Text style={styles.text}>{t("all_players")}</Text>
                                        </Pressable>
                                        <Pressable style={({ hovered }) => styles.btnOption(hovered)}
                                            onPress={() => { addItem("real", type); setFlagSelect(false); inputRef.current.value = "" }}
                                        >
                                            <CheckBox
                                                checked={real}
                                                colorFill={backgrounds.count}
                                                background={backgrounds.infoBody}
                                            />
                                            <Text style={styles.text}>{t("real_players")}</Text>
                                        </Pressable>
                                        <Text style={styles.textHeaders}>{t("players")}</Text>
                                    </>}
                                    {arrSearch.map((action, index) => {
                                        // if (arrSelect.indexOf(action) >= 0) return;
                                        return (
                                            <Pressable key={index} style={({ hovered }) => [{ backgroundColor: hovered ? backgrounds.infoBox : "", paddingVertical: "0.3vw", paddingHorizontal: "0.3vw", direction: direction, flexDirection: "row", alignItems: "center", gap: "0.5vw" }]}
                                                onPress={() => { arrSelect.includes(action?.value) ? removeItem(action?.value, type) : addItem(action?.value, type); inputRef.current.value = "" }}
                                            >
                                                <CheckBox
                                                    checked={arrSelect.includes(action?.value)}
                                                    colorFill={backgrounds.count}
                                                    background={backgrounds.infoBody}
                                                />
                                                {(() => {
                                                    return <FullTextTooltip text={action?.name} style={{ flex: 1 }}>
                                                        <Text numberOfLines={1} style={{ color: colors.buildText, fontSize: "0.9vw", fontFamily: "Catamaran_400Regular" }}>{action?.name}</Text>
                                                    </FullTextTooltip>;
                                                })()}
                                            </Pressable>
                                        )
                                    })}
                                </ScrollView>
                            </View>
                        }
                    </View>
                    {arrSelect.length > 0 &&
                        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                            {arr.map((action, index) => {
                                if (!arrSelect.includes(action?.value)) return;
                                return (
                                    <View key={index}
                                        style={[styles.shadow, {
                                            backgroundColor: colors.infoBorder,
                                            paddingVertical: "0.3vw",
                                            paddingHorizontal: "0.3vw",
                                            maxWidth: "-webkit-fill-available",
                                            borderRadius: "0.6vw",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginVertical: "0.2vw",
                                            marginHorizontal: "0.15vw"
                                        }]}>
                                        {(() => {
                                            return <FullTextTooltip text={action?.name} style={{ flex: 1 }}>
                                                <Text numberOfLines={1} style={{ color: colors.buildText, fontSize: "0.9vw", fontFamily: "Catamaran_400Regular" }}>{action?.name}</Text>
                                            </FullTextTooltip>
                                        })()}
                                        <Pressable
                                            style={{ backgroundColor: backgrounds.realPlayersBackground, borderRadius: "50%", width: "1.2vw", height: "1.2vw", alignItems: "center", justifyContent: "center", marginLeft: "0.2vw" }}
                                            onPress={() => removeItem(action?.value, type)}>
                                            <Text style={{ color: colors.buildText, fontSize: "0.7vw", fontFamily: "Catamaran_400Regular" }}>X</Text>
                                        </Pressable>
                                    </View>
                                )
                            })}
                        </View>}
                </View>}
        </View>
    )
}

const stylesR = (backgrounds, colors, direction) => {
    return StyleSheet.create({
        filterView: {
            width: "100%",
            paddingHorizontal: "1vw",
            borderBottomWidth: "0.1vw",
            paddingVertical: "1vw"
        },
        topFilterView: {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        },
        clear: {
            fontSize: "0.8vw",
            fontFamily: "Catamaran_400Regular",
            textDecorationLine: "underline",
            marginRight: "1vw"
        },
        inputView: {
            borderRadius: "0.5vw",
            marginVertical: "0.5vw",
            position: "relative",
            justifyContent: "center"
        },
        input: {
            paddingHorizontal: "0.5vw",
            paddingVertical: "0.2vw",
            borderRadius: "0.5vw",
            fontSize: "0.9vw",
            // lineHeight: "0.8vw",
            fontFamily: "Catamaran_400Regular"
        },
        listView: {
            position: "absolute",
            top: "2.2vw",
            borderRadius: "0.5vw",
            width: "100%",
            maxHeight: "15vw",
        },
        btnOption: (hovered) => ({
            backgroundColor: hovered ? backgrounds.infoBox : "",
            paddingVertical: "0.3vw",
            paddingHorizontal: "0.3vw",
            paddingLeft: "1.5vw",
            direction: direction,
            flexDirection: "row", alignItems: "center", gap: "0.5vw"
        }),
        textHeaders: {
            color: colors.buildText,
            fontSize: "0.8vw",
            fontFamily: "Catamaran_500Medium",
            paddingLeft: "0.5vw",
            paddingVertical: "0.5vw",
            paddingHorizontal: "0.5vw"
        },
        text: {
            color: colors.buildText,
            fontSize: "1vw",
            fontFamily: "Catamaran_400Regular",
            writingDirection: direction
        },
        shadow: {
            shadowColor: "black",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.7,
            shadowRadius: 5
        }
    })
}