import { USER_GAME_NAME, USER_ID_IN_GAME } from "../../config.inc";

export const findCreateName = (id, players) => {
    let name = "";
    if(id === sessionStorage.getItem(USER_ID_IN_GAME)){
      name = sessionStorage.getItem(USER_GAME_NAME);
    }else{
      players.forEach((player) => {
        if(player.id === id){
          name = player.name_for_game;
        }
      });
    };
    return name;
  }