import React, { useEffect, useMemo, useRef, useState, useContext, useCallback } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  ScrollView,
  Image,
  Modal,
  FlatList,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { AntDesign, Entypo } from "@expo/vector-icons";
import { editDate } from "../../../EditDate";
import { t } from "i18n-js";
import { listenerAddBT, listenerBTAndPopup } from "../../../AddListener";
import { checkIfIsEncodeText } from '../../../componnets/modules/removeSpacialCherectersAndSpaes';
import { getContent } from "../../../componnets/modules/getContent";

import {
  changeArrangeMail,
  changeArrangeSystem,
  markMail,
  msgFlagMail,
} from "../../../redux/actions";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { MailContext } from "../../../ContextData/Mail";
import {
  USER_GAME_NAME,
  USER_ID_IN_GAME,
  USER_ID_IN_GAME_OBSERVER,
  _NAME_FOR_GAME_,
} from "../../../config.inc";
import AnimationLoading from "../../modules/AnimationLoading";
import FullTextTooltip from "../../Elements/FullTextTooltip";
import TextCustomize from "../../Elements/TextCustomize";
import PlayerImages from "./PlayerImages";
import MemoizedMessageItem from "./MemoizedMessageItem";


export default function MailBox_message({
  scrollMessages,
  messagesArr,
  index,
  inboxSentDrafts,
  setIndex,
  arrangeInboxName,
  arrangeSentName,
  arrangeDraftsName,
  setShowFullMessage,
  type,
  dashboard,
  setActiveMessageDashboard
}) {
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(heightS, widthS, background, color, direction, dashboard);

  const { markMailAsReadMail, changeFlagData } = useContext(MailContext);

  const popupRef = useRef(null);
  const popupRefBtn = useRef(null);

  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const playerPermission = useSelector((state) => state.gameInfoArr.permission);
  const images = useSelector((state) => state.style.images);
  const indexCurrent = useSelector((state) => state.minimize.indexCurrent);
  const minimizePopups = useSelector((state) => state.minimize.minimize);
  const events = useSelector((state) => state.events.events);
  const players =
    useSelector((state) => state.gameInfoArr.gameInfoArr.players) || [];

  const [messages, setMessages] = useState([]);
  const [showPopupArrangeFlag, setShowPopupArrangeFlag] = useState(false);
  const [loading, setLoading] = useState(true);

  const viewabilityConfig = {
    itemVisiblePercentThreshold: 1, // נגדיר באחוזים כמה מהפריט צריך להיות גלוי כדי להיחשב כגלוי
  };

  const changeTypeOrder = (value, name) => {
    switch (type) {
      case "mail":
        dispatch(changeArrangeMail(inboxSentDrafts, value, name));
        return;
      case "system":
        dispatch(changeArrangeSystem(inboxSentDrafts, value, name));
        return;
    }
  };

  const showPopupArrange = (
    inboxArrange,
    sentArrange,
    draftsArrange,
    typeNav
  ) => {
    let options = [
      { value: "From", name: "from" },
      { value: "To", name: "to" },
      { value: "Subject", name: "subject" },
      { value: "Event", name: "event" },
      { value: "Body", name: "body" },
      { value: "Status", name: "status" },
      { value: "Flag", name: "flag" },
      { value: "Attachments", name: "attachments" },
      { value: "Retime", name: "retime" },
      { value: "Date", name: "date" },
    ];
    if (typeNav !== "Inbox") options.shift();
    else options.splice(1, 1);
    return (
      <View
        ref={popupRef}
        style={[
          styles.popup_container,
          { backgroundColor: background.mailPopupArrange },
        ]}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          stickyHeaderIndices={[0]}
          style={styles.popup_main}
        >
          <Pressable
            onPress={() => {
              setShowPopupArrangeFlag(false);
            }}
            style={[
              styles.popup_press_header,
              {
                borderBottomColor: color.border,
                writingDirection: lang === "he" ? "rtl" : "ltr",
                backgroundColor: background.mailPopupArrange,
              },
            ]}
          >
            <Text
              selectable={false}
              style={[styles.popup_press_text, { color: color.mailText }]}
            >
              {typeNav === "Inbox"
                ? t(inboxArrange)
                : typeNav === "Sent"
                  ? t(sentArrange)
                  : t(draftsArrange)}
            </Text>
            <AntDesign
              selectable={false}
              name="caretdown"
              color={color.mailText}
              size={widthS * 6}
            />
          </Pressable>
          {options.map((e, i) => {
            let arrangeType =
              typeNav === "Inbox"
                ? inboxArrange
                : typeNav === "Sent"
                  ? sentArrange
                  : draftsArrange;
            if (e.name !== arrangeType) {
              return (
                <Pressable
                  key={i}
                  onPress={() => {
                    changeTypeOrder(e.value, e.name);
                    setShowPopupArrangeFlag(false);
                    setMessages([...messages]);
                  }}
                  style={({ hovered }) => [
                    styles.popup_press,
                    {
                      backgroundColor: hovered
                        ? background.mailPopupArrangeHover
                        : "",
                    },
                  ]}
                >
                  <Text
                    selectable={false}
                    style={[styles.popup_press_text, { color: color.mailText }]}
                  >
                    {t(e.name)}
                  </Text>
                </Pressable>
              );
            }
          })}
        </ScrollView>
      </View>
    );
  };

  const checkMinimize = (typePopup) => {
    if (minimizePopups.find((e) => e.name === typePopup && !e.show)) {
      return true;
    }
    return false;
  }

  const printFlagOrReadCircle = useCallback((e, i, dasboard) => {
    switch (type) {
      case "system":
        return (
          inboxSentDrafts === "Inbox" && (
            <View
              style={[
                styles.circle_flag,
                {
                  backgroundColor:
                    (e.read_or_not === "0" || !e.read_or_not) && e?.id !== index
                      ? "orange"
                      : "",
                },
              ]}
            />
          )
        );
      case "mail":
        if (e.flag) {
          return (
            <Pressable
              onPress={() => { dispatch(msgFlagMail(e.id, inboxSentDrafts)); changeFlagData(e.id, inboxSentDrafts, true); }}
            >
              <Image
                style={styles.img_flag(dasboard)}
                source={{ uri: images.systemFlagOrange }}
              />
            </Pressable>
          );
        } else {
          return (
            <Pressable
              onPress={() => { dispatch(msgFlagMail(e.id, inboxSentDrafts)); changeFlagData(e.id, inboxSentDrafts, false); }}
            >
              <Image
                style={styles.img_flag(dasboard)}
                source={{ uri: images.systemFlagGray }}
              />
            </Pressable>
          );
        }
    }
  }, [inboxSentDrafts, msgFlagMail, changeFlagData]);

  const printDetailes = (e, i) => {
    switch (type) {
      case "system":
        return (
          <FullTextTooltip text={e.subject}>
            <Text
              numberOfLines={1}
              selectable={false}
              style={[
                { width: widthS * 90 },
                e.read_or_not || inboxSentDrafts !== "Inbox" || e?.id === index
                  ? [styles.sub_read, { color: color.mailItemRead }]
                  : [styles.sub, { color: color.mailText }],
              ]}
            >
              {e.subject}
            </Text>
          </FullTextTooltip>
        );
      case "mail":
        let name = (inboxSentDrafts === "Inbox"
          ? e.sender?.name ? e.sender.name :
            e.sender?.id === (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME))
              ? sessionStorage.getItem(USER_GAME_NAME)
              : players.find((player) => player.id === e.sender?.id)
                ?.name_for_game
          : e.to_list_names ||
          e.to_list
            .split(",")
            .map(
              (id) =>
                players.find((player) => player.id === id)?.name_for_game
            ).filter(name => name)
            .join(",")) || "";
        return (
          <FullTextTooltip text={name}>
            <Text
              numberOfLines={1}
              selectable={false}
              style={[
                {},
                e.read_or_not || inboxSentDrafts !== "Inbox" || e?.id === index ?
                  styles.name_read(inboxSentDrafts !== "Inbox")
                  :
                  styles.name(inboxSentDrafts !== "Inbox"),
              ]}
            >
              {name}
            </Text>
          </FullTextTooltip>
        );
    }
  };

  const onPress = useCallback((e, i) => {
    setIndex(e?.id);
    if (dashboard) {
      setActiveMessageDashboard(messages[i]);
      if (!messages.find((e, index) => index === i)?.read_or_not &&
        (playerPermission === "1" || messages.find((e) => e.id === i)?.sender?.id !== (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME))) &&
        inboxSentDrafts === "Inbox"
      ) {
        markMailAsReadMail(e.id);
        dispatch(markMail(e.id))
      }
    }
  }, [setIndex, dashboard, setActiveMessageDashboard, messages, playerPermission, inboxSentDrafts, markMail, markMailAsReadMail, dispatch]);

  useEffect(() => {
    if (messagesArr) {
      setMessages([...messagesArr]);
      setTimeout(() => {
        setLoading(false);
        setShowFullMessage(true);
      }, 600);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [messagesArr]);

  useEffect(() => {
    document.addEventListener(
      "mousedown",
      listenerAddBT(popupRef, popupRefBtn, showPopupArrangeFlag, () =>
        setShowPopupArrangeFlag(false)
      )
    );
  }, [showPopupArrangeFlag]);

  useEffect(() => {
    if (messages && messages.length > 0 && !messages.find((e) => e.id === index)?.read_or_not &&
      indexCurrent === 9 && !checkMinimize("mail") &&
      messages.find((e) => e.id === index)?.sender?.id !== (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME)) &&
      inboxSentDrafts === "Inbox"
    ) {
      markMailAsReadMail(index);
      dispatch(markMail(index))
    }
  }, [index, messages?.length, indexCurrent]);

  const arrangeComponent = (
    <>
      {lang === "he" ? (
        <View
          ref={popupRefBtn}
          style={[
            styles.arrange,
            {
              backgroundColor: background.mailBodyHeader,
              borderBottomColor: color.border,
              paddingRight: widthS * 5,
            },
          ]}
        >
          <Pressable
            onPress={() => setShowPopupArrangeFlag(!showPopupArrangeFlag)}
            style={[
              styles.arrange_select,
              {
                backgroundColor: background.mailPopupArrange,
                marginLeft: widthS * 4,
              },
            ]}
          >
            <AntDesign
              selectable={false}
              name="caretdown"
              color={color.mailText}
              size={widthS * 6}
              style={[styles.caret]}
            />
            <Text
              selectable={false}
              style={[styles.arrange_text_type, { color: color.mailText }]}
            >
              {inboxSentDrafts === "Inbox"
                ? t(arrangeInboxName)
                : inboxSentDrafts === "Sent"
                  ? t(arrangeSentName)
                  : t(arrangeDraftsName)}
            </Text>
            {showPopupArrangeFlag &&
              showPopupArrange(
                arrangeInboxName,
                arrangeSentName,
                arrangeDraftsName,
                inboxSentDrafts
              )}
          </Pressable>
          <Text
            selectable={false}
            style={[styles.arrange_text, { flex: 1, color: color.mailText }]}
          >
            {t("arrange-by")}:{" "}
          </Text>
        </View>
      ) : (
        <View
          ref={popupRefBtn}
          style={[
            styles.arrange,
            {
              backgroundColor: background.mailBodyHeader,
              borderBottomColor: color.border,
              paddingLeft: widthS * 5,
            },
          ]}
        >
          <Text
            selectable={false}
            style={[styles.arrange_text, { flex: 1, color: color.mailText }]}
          >
            {t("arrange-by")}:{" "}
          </Text>
          <Pressable
            onPress={() => setShowPopupArrangeFlag(!showPopupArrangeFlag)}
            style={[
              styles.arrange_select,
              {
                backgroundColor: background.mailPopupArrange,
                marginRight: widthS * 4,
              },
            ]}
          >
            <Text
              selectable={false}
              style={[styles.arrange_text_type, { color: color.mailText }]}
            >
              {inboxSentDrafts === "Inbox"
                ? t(arrangeInboxName)
                : inboxSentDrafts === "Sent"
                  ? t(arrangeSentName)
                  : t(arrangeDraftsName)}
            </Text>
            <AntDesign
              selectable={false}
              name="caretdown"
              color={color.mailText}
              size={widthS * 6}
              style={[styles.caret]}
            />
            {showPopupArrangeFlag &&
              showPopupArrange(
                arrangeInboxName,
                arrangeSentName,
                arrangeDraftsName,
                inboxSentDrafts
              )}
          </Pressable>
        </View>
      )}
    </>
  );

  const imageProfile = (id) => {
    let player = players.find((e) => e.id === id);
    return (
      <View style={styles.imgView}>
        <Image
          source={player?.profile_image ? player.profile_image : images.user_image}
          style={styles.logo}
        />
      </View>
    )
  }

  return (
    <View style={{ flex: 1 }} ref={containerRef}>
      {!dashboard && arrangeComponent}
      <FlatList
        style={{
          borderBottomLeftRadius: dashboard ? "1vw" : "0",
          maxHeight: dashboard ? "100%" : "93%",
          height: dashboard ? "100%" : "93%",
          backgroundColor: background.mailBodyHeader,
          writingDirection: dashboard ? "ltr" : "rtl",
          marginLeft: dashboard ? "0.2vw" : ""
        }}
        disableVirtualization={true}
        initialNumToRender={30}
        maxToRenderPerBatch={20}
        windowSize={20}
        getItemLayout={(data, index) => ({
          length: 50, // גובה כל פריט (בפיקסלים)
          offset: 50 * index, // מיקום הפריט ברשימה
          index,
        })}
        nativeID={dashboard ? "mail" : "mail-messages"}
        ref={scrollMessages}
        contentContainerStyle={{ minHeight: "101%" }}
        data={messages}
        keyExtractor={(item, index) => item?.id.toString()}
        ListEmptyComponent={loading && messages.length === 0 ? <AnimationLoading flag={true} widget={true} /> : null}
        renderItem={({ item: e, index: i }) => (
          <MemoizedMessageItem
            flag={e?.flag}
            e={e}
            i={i}
            index={index}
            onPress={onPress}
            dashboard={dashboard}
            messagesLength={messages.length}
            styles={styles}
            background={background}
            color={color}
            images={images}
            type={type}
            inboxSentDrafts={inboxSentDrafts}
            printFlagOrReadCircle={printFlagOrReadCircle}
            printDetailes={printDetailes}
            t={t}
            checkIfIsEncodeText={checkIfIsEncodeText}
            editDate={editDate}
            widthS={widthS}
            containerRef={containerRef}
            players={players}
            events={events}
            imageProfile={imageProfile}
          />
        )}
      />

    </View>
  );
}
const stylesR = (heightS, widthS, background, color, direction, dashboard) =>
  StyleSheet.create({
    arrange: {
      display: "flex",
      flexDirection: "row",
      borderBottomWidth: 1,
      borderStyle: "solid",
      alignItems: "center",
      justifyContent: "space-between",
      paddingVertical: heightS * 3,
      position: "relative",
      zIndex: 2,
    },
    arrange_text: {
      fontFamily: "Catamaran_700Bold",
      fontSize: widthS * 10,
      paddingLeft: widthS * 12,
    },
    arrange_text_type: {
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 10,
      textAlign: "center",
      marginHorizontal: widthS * 6,
    },
    arrange_select: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "52%",
      position: "relative",
      borderRadius: widthS * 5,
    },
    caret: {
      marginHorizontal: widthS * 6,
    },
    popup_container: {
      position: "absolute",
      height: heightS * 150,
      top: heightS * 2,
      width: "100%",
      zIndex: 3,
      borderRadius: widthS * 5,
    },
    popup_main: {
      borderRadius: widthS * 5,
    },
    popup_press_header: {
      borderBottomWidth: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: widthS * 8,
      paddingVertical: heightS * 3,
    },
    popup_press: {
      paddingHorizontal: widthS * 8,
      paddingVertical: heightS * 3,
    },
    popup_press_text: {
      fontSize: widthS * 9.5,
      fontFamily: "Catamaran_400Regular",
    },
    container_msg: (flag, zIndex) => ({
      borderStyle: "solid",
      minHeight: flag ? "2.2vw" : "3vw",
      maxHeight: flag ? "3.5vw" : "",
      display: "flex",
      ...(flag && { zIndex: zIndex, }),
      alignItems: "center",
      justifyContent: "center",
      paddingVertical: flag ? "0.3vw" : "0.5vw",
      paddingHorizontal: widthS * 4.5,
    }),
    message: (flag, zIndex) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: flag ? "center" : "",
      gap: flag ? "0.5vw" : "",
      width: "100%",
      direction: direction
    }),
    message_system: {
      display: "flex",
      flexDirection: "row",
      // alignItems: "center",
      width: "100%",
    },
    circle_flag: {
      //////////////
      width: widthS * 4,
      height: widthS * 4,
      borderRadius: 15,
      marginTop: heightS * 5,
      marginRight: widthS * 3,
    }, ///////////////////////////
    img_flag: (flag) => ({
      width: flag ? "0.8vw" : "1vw",
      height: flag ? "0.8vw" : "1vw",
      resizeMode: "contain",
      marginTop: heightS * -0.5,
      ...(direction !== "rtl" ? { marginRight: widthS * 2, } : { marginLeft: widthS * 2, }),
    }),
    img_open: (flag) => ({
      resizeMode: "contain",
      width: flag ? "0.9vw" : "1.1vw",
      height: flag ? "0.9vw" : "1.1vw",
      ...(direction !== "rtl" ? { marginRight: widthS * 3, } : { marginLeft: widthS * 3, }),
      marginTop: heightS * -1,
    }),
    img_close: (flag) => ({
      resizeMode: "contain",
      width: flag ? "0.9vw" : "1.1vw",
      height: flag ? "0.9vw" : "1.1vw",
      ...(direction !== "rtl" ? { marginRight: widthS * 3, } : { marginLeft: widthS * 3, }),
    }),
    img_open_sent: {
      resizeMode: "contain",
      width: widthS * 4,
      height: widthS * 4,
      marginTop: heightS * 2,
      marginRight: widthS * 3,
    },
    imgView: {
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      padding: "0.1vw",
      marginHorizontal: "0.5vw",
      backgroundColor: background.chatCreatorBorder
    },
    logo: {
      width: "1.8vw",
      height: "1.8vw",
      resizeMode: "cover",
      borderRadius: "2vw",
    },
    sub: {
      //////////////////////
      fontSize: widthS * 11,
      fontFamily: "Catamaran_700Bold",
      lineHeight: heightS * 14,

      // lineHeight: 20,
    }, //////////////////////
    sub_read: {
      fontSize: widthS * 11,
      fontFamily: "Catamaran_600SemiBold",
      lineHeight: heightS * 14,

      // lineHeight: 20,
    }, ////////////////////
    name: (flag, flag1) => ({
      ...(
        direction !== "rtl" ?
          { marginRight: flag1 ? "1vw" : "4vw", } :
          { marginLeft: flag1 ? "1vw" : "4vw", }
      ),
      fontSize: flag1 ? "0.9vw" : "1.1vw",
      fontFamily: "Catamaran_700Bold",
      lineHeight: heightS * 14,
      color: color.mailText,
      minHeight: "1.3vw",
      width:
        flag !== "Inbox" && !flag1
          ? "10vw"
          : !flag1 ? "8vw" : "8vw",
      writingDirection: direction,
    }),
    name_read: (flag, flag1) => ({
      ...(
        direction !== "rtl" ?
          { marginRight: flag1 ? "1vw" : "4vw", } :
          { marginLeft: flag1 ? "1vw" : "4vw", }
      ),
      fontSize: flag1 ? "0.9vw" : "1.1vw",
      fontFamily: "Catamaran_600SemiBold",
      lineHeight: heightS * 14,
      color: color.mailItemRead,
      minHeight: "1.3vw",
      width:
        flag !== "Inbox" && !flag1
          ? "10vw"
          : !flag1 ? "8vw" : "8vw",
      writingDirection: direction,
    }),
    subject_dashboard_read: (flag) => ({
      fontSize: "0.9vw",
      fontFamily: "Catamaran_700Bold",
      lineHeight: heightS * 14,
      color: color.mailText,
      width: flag ? "15em" : "24vw",
      writingDirection: direction,
    }),
    subject_dashboard: (flag) => ({
      fontSize: "0.9vw",
      fontFamily: "Catamaran_600SemiBold",
      lineHeight: heightS * 14,
      color: color.mailItemRead,
      width: flag ? "15em" : "24vw",
      writingDirection: direction,
    }),
    event_color_view: (color) => ({
      height: "0.8vw",
      borderRadius: "0.3vw",
      width: "0.15vw",
      ...(
        direction !== "rtl" ? { marginRight: "0.2vw" } : { marginLeft: "0.2vw" }
      ),
      backgroundColor: color,
    }),
    event_text: (flag) => ({
      fontSize: "0.9vw",
      fontFamily: "Catamaran_600SemiBold",
      width: "10vw",
      ...(
        direction !== "rtl" ? { marginRight: "2vw" } : { marginLeft: "2vw" }
      ),
      color: flag ? color.mailItemRead : color.mailItemRead,
      writingDirection: direction,
    }),
    sub_mail: (flag, flag1) => ({
      fontFamily: flag ? "Catamaran_700Bold" : "Catamaran_400Regular",
      fontSize: widthS * 10,
      lineHeight: heightS * 12,
      color: color.mailText,
      width: flag1 ? "15em" : "12vw",
      writingDirection: direction,
    }),
    sub_mail_read: (flag, flag1) => ({
      fontFamily: flag ? "Catamaran_700Bold" : "Catamaran_400Regular",
      fontSize: widthS * 10,
      lineHeight: heightS * 12,
      color: color.mailItemRead,
      width: flag1 ? "15em" : "12vw",
      writingDirection: direction,
    }),
    time_view: {
      position: "absolute",
      top: 0,
      ...((direction !== "rtl" || dashboard) ? { right: widthS * 7 } : { left: widthS * 7 }),
    },
    time_view_system: {
      position: "absolute",
      bottom: heightS * -13,
      right: widthS * 10,
    },
    time: {
      lineHeight: heightS * 10,
      fontSize: widthS * 9,
      fontFamily: "Catamaran_700Bold",
    },
    time_read: {
      lineHeight: heightS * 10,
      fontSize: widthS * 9,
      fontFamily: "Catamaran_600SemiBold",
    },
    attach_icon: {
      width: widthS * 8,
      height: widthS * 8,
      resizeMode: "contain",
      position: "absolute",
      bottom: 0,
      right: widthS * 5,
    },
    /// tooltip
    tooltipMain: {
      maxHeight: "10vw",
      gap: "0.5vw",
    },
    tooltip: (x, y) => ({
      // position: 'absolute',
      // top: y, // Adjust as needed
      // left: x,
      // width: "15vw",
      // backgroundColor: background.infoBox,
      // paddingHorizontal: "1vw",
      // paddingVertical: "0.5vw",
      // borderRadius: "0.5vw",
      // shadowColor: '#000',
      // shadowOffset: { width: 0, height: 2 },
      // shadowOpacity: 0.5,
      // shadowRadius: 8,
      // // zIndex: 100,
      // writingDirection: direction
    }),
    tooltipText: {
      fontSize: "0.9vw",
      writingDirection: direction,
      color: color.text2,
    },
  });
