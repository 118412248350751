import React, { useMemo, useCallback, memo } from 'react';
import { View, Image, Text, StyleSheet, Pressable, ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { vwToPx } from '../../modules/ConvertPXToVw';
import { Tooltip } from 'antd';
import { FlatList } from 'react-native-web';

const PlayerImages = memo(({ players, e, index, containerRef, onPress }) => {

  const to_list_names_all = useMemo(() => players.filter((plr) => e.to_list?.indexOf(plr?.id) !== -1), [players, e, index]);
  const extraPlayers = to_list_names_all.length - 4;

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(background, color);

  const getInitials = useCallback((name) => {
    if (typeof name !== 'string' || name.trim() === '') {
      return ''; // במקרה שהקלט אינו תקין
    }

    // החלפת כל סוגי הרווחים או מקפים מרובים למפריד אחיד
    const cleanedName = name.replace(/[\s-]+/g, ' ').trim();

    const nameParts = cleanedName.split(' ');

    if (nameParts.length === 1) {
      const firstLetter = nameParts[0][0]?.toUpperCase() || '';
      const secondLetter = nameParts[0][1]?.toUpperCase() || '';
      return firstLetter + secondLetter; // החזרת שתי אותיות אם קיימות
    }

    const firstInitial = nameParts[0][0]?.toUpperCase() || ''; // האות הראשונה
    const secondInitial = nameParts[1][0]?.toUpperCase() || ''; // האות השנייה
    return firstInitial + secondInitial;
  }, []);

  const tooltipContent = (
    <View style={{ maxWidth: '15vw', maxHeight: '10vw', textAlign: direction, overflow: 'auto', padding: "0.3vw", }}>
      {/* <ScrollView contentContainerStyle={{ padding: '0.5vw' }}>
        {to_list_names_all?.map((item) => {
          return <View key={item.id.toString()} style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: '0.3vw' }}>
            <Text style={{
              fontSize: "0.9vw",
              writingDirection: direction,
              color: color.text2,
            }}>{item.name_for_game}</Text>
          </View>
        })}
      </ScrollView> */}
      <FlatList
        data={to_list_names_all}
        keyExtractor={(player, index) => index}
        // initialNumToRender={50} // הצגת 100 פריטים ראשונים
        // maxToRenderPerBatch={50} // 50 פריטים בכל טעינה
        // windowSize={5}
        renderItem={({ item, index }) => (
          <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: '0.3vw' }}>
            <Text style={{
              fontSize: "0.9vw",
              writingDirection: direction,
              color: color.text2,
            }}>{index} - {item.name_for_game}</Text>
          </View>
        )}
        contentContainerStyle={{ padding: '0.5vw' }}
      />
    </View>
  );

  return (
    // <Tooltip
    //   title={tooltipContent}
    //   overlayStyle={{
    //     width: "15vw",
    //     backgroundColor: background.infoBox, // רקע מותאם אישית
    //     paddingHorizontal: "1vw",
    //     paddingVertical: "0.5vw",
    //     borderRadius: "0.5vw",
    //     boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.5)',
    //     writingDirection: direction,
    //   }}
    //   getPopupContainer={() => document.body}
    //   arrow={false}
    //   placement="bottom"
    //   trigger={['hover']}
    //   destroyTooltipOnHide={true}
    // >
    <Pressable
      onPress={onPress}
      style={styles.container}
    >
      <>
        {to_list_names_all.slice(0, 4).map((player, index) => (
          <View
            style={[
              styles.imgView,
              {
                ...(direction === "ltr"
                  ? { marginLeft: index > 0 ? '-0.2vw' : 0 }
                  : { marginRight: index > 0 ? '-0.2vw' : 0 }),
                // zIndex: displayedPlayers.length - index
              }
            ]}
            key={index}
          >
            {player?.profile_image ? (
              <Image
                source={player.profile_image}
                style={styles.logo}
              />
            ) : (
              <View style={styles.initialsView}>
                <Text style={styles.initialsText}>{getInitials(player.name_for_game)}</Text>
              </View>
            )}
          </View>
        ))}
        {extraPlayers > 0 && (
          <View style={direction === "ltr" ? { marginLeft: '0.3vw' } : { marginRight: "0.3vw" }}>
            <Text style={styles.extraText}>+{extraPlayers}</Text>
          </View>
        )}
      </>
    </Pressable>
    // </Tooltip>
  );
});

const stylesR = (background, color) => StyleSheet.create({
  container: {
    width: '12vw',
    maxWidth: '12vw',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  imgView: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '0.1vw',
    backgroundColor: background.chatCreatorBorder,
    overflow: 'hidden',
    width: '2vw',
    height: '2vw',
  },
  logo: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderRadius: '50%',
  },
  initialsView: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: background.infoBody,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  initialsText: {
    color: color.text2,
    fontWeight: "500",
    fontSize: '0.9vw',
  },
  extraView: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: background.chatCreatorBorder,
  },
  extraText: {
    color: color.text2,
    fontSize: '0.9vw',
  },
});

export default PlayerImages;
