import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { t } from "i18n-js";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesome } from "@expo/vector-icons";
import CheckBox from "../../Elements/CheckBox";
import InputRadio from "../../../InputRadio";
import { MailContext } from "../../../ContextData/Mail";
import { USER_ID_IN_GAME } from "../../../config.inc";

export default function QuestionairePlayer({ questionObj, isDoneSent }) {

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);

  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

  const styles = stylesR(background, color);

  const { sendQuestionnaire } = useContext(MailContext);

  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);

  const dispatch = useDispatch();

  const [isDone, setIsDone] = useState(isDoneSent);

  const [question, setQuestion] = useState({});

  useEffect(() => {
    try {
      let qes = [];
      if (questionObj) {
        //test for the different objects
        if ("answers" in questionObj) {
          qes = questionObj
        } else {
          qes = questionObj
        }
        setQuestion({ ...qes });
      }
    } catch (e) { console.log(e) }

  }, [questionObj]);

  const printAnserws = (arr, type, questIndex) => {
    if (arr != undefined) {
      return arr.map((e, i) => {
        return (
          <Pressable
            key={i}
            disabled={isDone}
            style={styles.btnAnswer}
            onPress={() => {
              if (type === "single") {
                question.questions[questIndex].answers.forEach((item, index) => {
                  item.checked = index === i ? true : false;
                });
                setQuestion({ ...question });
              } else {
                question.questions[questIndex].answers[i].checked = !e.checked;
                setQuestion({ ...question });
              }
            }}
          >
            {type === "single" ?
              <InputRadio
                borderWidth={"1vw"}
                background={background.count}
                isDone={isDone}
                marginLeft={false}
                checkd={e.checked}
                circleWidth={"0.5vw"}
                backgroundBorder={background.infoBody}
              />
              :
              <View style={{ ...(direction === "rtl" ? { paddingLeft: "0.5vw" } : { paddingRight: "0.5vw" }) }}>
                <CheckBox
                  checked={e.checked}
                  colorFill={background.count}
                  isDone={isDone}
                  background={background.infoBody}
                /></View>}
            <Text style={styles.textAnswer(isDone)}>{e.text}</Text>
          </Pressable>
        )
      })
    }

  }

  const questionaireItemsComponent = (arr) => {
    if (null != arr && Object.keys(arr).length > 0) {
      return <ScrollView style={styles.scrollQuest(direction)} contentContainerStyle={{ gap: "2vw" }} nativeID="questionaire-scroll">
        {Object.keys(arr).map((key, index) => {
          if (arr[key]?.type != null) {
            return (
              <View key={index} style={styles.quest}>
                <Text
                  style={styles.headersText(false, true)}>{t("question")} {`${index + 1}`.padStart(2, '0')} </Text>
                <Text
                  style={styles.questionText(isDone)}>{arr[key]?.question != null ? arr[key]?.question : ""}</Text>
                {!isDone && <Text
                  style={styles.headersText(color.socialBorder)}>({t(arr[key]?.type === "single" ? "single_note" : "multiple_note")})</Text>}
                <View style={styles.answersView}>
                  {printAnserws(arr[key]?.answers, arr[key]?.type, index)}
                </View>
              </View>
            )
          }
        })}
      </ScrollView>
    }
  }

  return isDone ?
    <>
      {questionaireItemsComponent(question)}
    </>
    :
    (
      <Modal visible={!isDone} animationType="fade" transparent={true}>
        <View style={styles.container}>
          <View style={styles.containerChild}>
            <View style={[styles.header, styles.borderBottom]}>
              <View style={styles.logoView}>
                <FontAwesome name="question" style={styles.logoIcon} />
              </View>
              <Text style={styles.headerText}>{t("questionaire")}</Text>
            </View>
            <View style={styles.body}>
              <View style={[styles.bodyTop, styles.borderBottom]}>
                <View style={styles.topChild}>
                  <Text style={styles.headersText()}>{t("from")}</Text>
                  <Text style={styles.headersTextWhite}>{t("admin")}</Text>
                </View>
                <View style={styles.topChild}>
                  <Text style={styles.headersText()}>{t("subject")}</Text>
                  <Text style={styles.headersTextWhiteSkin}>{question.subject}</Text>
                </View>
              </View>
              <View style={[styles.bodyMain, styles.borderBottom]}>
                {questionaireItemsComponent(question.questions)}
              </View>
              <View style={styles.bodyFooter}>
                <TouchableOpacity style={styles.btnSave}
                  onPress={() => {
                    let flag = 0;
                    question.questions.forEach((e) => {
                      let answerFlag = false;
                      e.answers.forEach((answer) => {
                        if (answer.checked) {
                          answerFlag = true;
                        }
                      });
                      if (answerFlag) {
                        flag += 1
                      };
                    })
                    if (flag === question.questions.length) {
                      sendQuestionnaire(question);
                    }
                  }}
                >
                  <Text style={styles.btnText}>{t("save")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    );
}
const stylesR = (background, color) =>
  StyleSheet.create({
    container: {
      backgroundColor: "#00000075",
      width: "100%",
      height: "100vh",
      alignItems: "center",
    },
    containerChild: {
      marginTop: "5vh",
      backgroundColor: background.popup2,
      borderRadius: "0.5vw",
      width: "35vw",
      height: "84vh",
      paddingHorizontal: "1vw"
    },
    header: {
      paddingHorizontal: "1.5vw",
      paddingVertical: "0.5vw",
      flexDirection: "row",
      alignItems: "center"
    },
    logoView: {
      backgroundColor: background.mailLogoColor,
      width: "2.3vw",
      height: "2.3vw",
      borderRadius: "100%",
      alignItems: "center",
      justifyContent: "center"
    },
    logoIcon: {
      color: "white",
      fontSize: "1.5vw"
    },
    headerText: {
      color: "white",
      fontSize: "1.5vw",
      fontFamily: "Catamaran_700Bold",
      marginLeft: "1.5vw"
    },
    borderBottom: {
      borderBottomColor: color.border,
      borderBottomWidth: "0.1vw"
    },
    body: {
      paddingHorizontal: "1.5vw",
      flex: 1
    },
    bodyTop: {
      paddingVertical: "0.3vw"
    },
    bodyMain: {
      // paddingTop: "0.5vw",
      flex: 1
    },
    topChild: {
      marginVertical: "0.6vw"
    },
    headersText: (color, flag) => ({
      color: color ? color : background.count,
      fontSize: "0.8vw",
      fontFamily: !color ? "Catamaran_300Light" : "Catamaran_200ExtraLight",
      lineHeight: flag ? "" : "0.8vw",
      marginBottom: flag ? "0.5vw" : "",
    }),
    headersTextWhiteSkin: {
      color: "white",
      fontSize: "1.3vw",
      fontFamily: "Catamaran_500Medium",
      lineHeight: "1.3vw"
    },
    headersTextWhite: {
      color: "white",
      fontSize: "1.2vw",
      fontFamily: "Catamaran_300Light",
      lineHeight: "1.2vw"
    },
    questionText: (isDone) => ({
      color: isDone ? "" : "white",
      fontSize: "1.2vw",
      fontFamily: "Catamaran_200ExtraLight",
      lineHeight: "1.2vw"
    }),
    headersTextBold: {
      color: "white",
      fontSize: "1.3vw",
      fontFamily: "Catamaran_700Bold",
      lineHeight: "1.3vw"
    },
    scrollQuest: (direction) => ({
      paddingVertical: "0.7vw",
      direction: direction,
      ...(direction === "rtl" ? { paddingLeft: "1vw" } : { paddingRight: "1vw" })
    }),
    btnAnswer: {
      flexDirection: "row",
      alignItems: "center",
      marginVertical: "0.1vw",
      outlineStyle: "none"
    },
    answersView: {
      marginVertical: "1vw",
      gap: "0.5vw"
    },
    textAnswer: (isDone) => ({
      color: isDone ? "" : "white",
      fontSize: "1vw",
      marginLeft: "0.5vw",
      lineHeight: "1.1vw",
      fontFamily: "Catamaran_200ExtraLight",
    }),
    bodyFooter: {
      alignItems: "flex-end",
      paddingVertical: "0.7vw"
    },
    btnSave: {
      paddingHorizontal: "1.2vw",
      paddingVertical: "0.2vw",
      backgroundColor: background.loginFooter,
      borderRadius: "0.5vw"
    },
    btnText: {
      color: "white",
      fontSize: "0.9vw",
      fontFamily: "Catamaran_500Medium",
    },
  });
