import { StyleSheet, Text, View, Dimensions, Modal } from 'react-native'
import React from 'react';
import LottieView from 'lottie-react';
import UseResponsiveSize from './UseResponsiveSize';
import UseResponsiveScreen from './UseResponsiveScreen';

export default function AnimationLoading({ flag, widget, action, list, styleView }) {
    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);
    return widget && flag ? (
        <View
            style={[styles.widgetView, action && styles.actionLoading]}
        >
            <LottieView
                animationData={require("../../images/animation.json")}
                autoPlay={false}
                loop={true}
                style={{
                    width: widthS * 170,
                    height: heightS * 100,
                    marginLeft: widthS * 15,
                }}
            />
        </View>
    )
        : list && flag ? (
            <View style={[styles.viewList, styleView]}>
                <LottieView
                    animationData={require("../../images/animation.json")}
                    autoPlay={false}
                    loop={true}
                    style={{
                        width: styleView ? "2vw" : widthS * 170,
                        height: "3vw",
                        marginLeft: styleView ? "" : widthS * 15,
                    }}
                />
            </View>
        ) : (
            <Modal visible={flag} nativeID="popup" animationType="fade" transparent={true} style={{ zIndex: 100000 }}>
                <View
                    style={{ width: "100%", height: Dimensions.get("window").height, backgroundColor: "#01010161", alignItems: "center", justifyContent: "center" }}
                >
                    <LottieView
                        animationData={require("../../images/animation.json")}
                        autoPlay={false}
                        loop={true}
                        style={{
                            width: widthS * 170,
                            height: heightS * 100,
                            marginLeft: widthS * 15,
                        }}
                    />
                </View>
            </Modal>
        )
}

const stylesR = () => {
    return StyleSheet.create({
        widgetView: {
            height: "100%",
            width: "100%",
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
        },
        viewList: {
            width: "100%",
        },
        actionLoading: {
            borderRadius: "1vw",
            backgroundColor: "#01010161",
        }
    })
}