import React, { useState, useEffect, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Pressable, StyleSheet, Image, TextInput, ScrollView } from 'react-native';
import TextCustomize from '../../../../Elements/TextCustomize';
import { AntDesign } from '@expo/vector-icons';
import { listenerAddBT } from '../../../../../AddListener';
import { useContext } from 'react';
import { SwimlaneChartContext } from '../../../../../ContextData/Swimlanechart';
import { colorForEvent } from "../../../../Elements/colorForEvent";
import { useMemo } from 'react';
import { useCallback } from 'react';
import FullTextTooltip from '../../../../Elements/FullTextTooltip';

const EventCell = ({ type, isDone, cellStyle, index, isBottom, setIsPopupOpen, updateAction, isNew, popupRef, setPopupRef }) => {

    const dispatch = useDispatch();

    const { addEventContext } = useContext(SwimlaneChartContext);

    const { images, background, color, events } = useSelector(state => ({
        images: state.style.images,
        background: state.style.background,
        color: state.style.color,
        events: state.events.events
    }));

    const eventsState = useMemo(() => events, [events]);

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = useMemo(() => stylesR(background, color), [background, color]);

    const cellRef = useRef(null);
    const btnRef = useRef(null);

    const [showOptions, setShowOptions] = useState(false);
    const [eventOptions, setEventOptions] = useState([]);
    const [showAdd, setShowAdd] = useState(false);

    const addEvent = useCallback((name) => {
        addEventContext({ title: name, id: false })
            .then((res) => {
                let update = {
                    event_id: res.id,
                    event: res.name,
                    event_color: colorForEvent(eventOptions.length)
                }
                updateAction(index, update, true, isNew);
                setShowOptions(false);
                setShowAdd(false);
            }).catch(() => { });
    }, [eventOptions]);

    useEffect(() => {
        if (eventsState?.length > 0) {
            let temp = [];
            eventsState.forEach((e) => {
                if (e.name?.length === 0 || !e.name) return;
                temp.push({ value: e.id, label: e.name, color: e.color });
            });
            setEventOptions(temp);
        }
    }, [eventsState]);

    useEffect(() => {
        window.addEventListener("mousedown", listenerAddBT(popupRef, btnRef, showOptions, () => { setShowOptions(false); setIsPopupOpen("") }));
        return () => {
            window.removeEventListener("mousedown", listenerAddBT(popupRef, btnRef, showOptions, () => { setShowOptions(false); setIsPopupOpen("") }));
        }
    }, [showOptions, popupRef.current]);

    return (
        <View style={cellStyle("11vw", showOptions)} ref={cellRef}>
            <Pressable disabled={isDone} ref={btnRef} style={[styles.actionTypeCell]} onPress={() => { setShowOptions(!showOptions); setIsPopupOpen(index) }}>
                <View style={styles.textName}>
                    <View style={styles.eventColor(eventOptions?.find((e) => e.value === type)?.color, direction)} />
                    <View style={{ maxWidth: "90%", width: "90%" }}>
                        <FullTextTooltip text={eventOptions?.find((e) => e.value === type)?.label}>
                            <TextCustomize numberOfLines={1} fontSize={"0.9vw"} text={eventOptions?.find((e) => e.value === type)?.label} />
                        </FullTextTooltip>
                    </View>
                </View>
                <AntDesign name="caretdown" style={styles.icon} />
            </Pressable>
            {showOptions &&
                <View ref={setPopupRef} style={styles.popup(showOptions, isBottom && isNew)}>
                    <ScrollView nativeID='popup-share' style={styles.eventsScroll}>
                        {eventOptions?.map((e, i) => {
                            if (e.value === type) return;
                            return (
                                <Pressable
                                    key={e?.value}
                                    style={({ hovered }) => [styles.optionBtn(hovered)]}
                                    onPress={() => {
                                        updateAction(index, {
                                            event_id: e.value,
                                            event: e.label,
                                            event_color: e.color
                                        }, !isNew, isNew);
                                        setShowOptions(false);
                                    }}>
                                    <View style={styles.eventColor(e.color, direction)} />
                                    <FullTextTooltip text={e.label}>
                                        <TextCustomize text={e.label} numberOfLines={1} fontSize={"0.9vw"} style={{ width: "max-content" }} />
                                    </FullTextTooltip>
                                </Pressable>
                            )
                        })}
                    </ScrollView>
                    <Pressable onPress={() => setShowAdd(true)} style={styles.btnAdd}>
                        {!showAdd ? <>
                            <Image source={{ uri: images.plusIcon }} style={styles.plusIcon} />
                            <TextCustomize fontSize={"0.9vw"} keyText={"add_new_event"} />
                        </> : <TextInput style={styles.input(direction)} onBlur={(e) => addEvent(e.target?.value)} />}
                    </Pressable>
                </View>}
        </View>
    )
};

const stylesR = (background, color) => StyleSheet.create({
    actionTypeCell: {
        justifyContent: "space-around",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    icon: {
        fontSize: "0.5vw",
        color: color.text
    },
    popup: (flag, isBottom) => ({
        width: "12vw",
        position: "absolute",
        backgroundColor: background.infoBody,
        borderRadius: "0.8vw",
        display: flag ? "" : "none",
        opacity: flag ? 1 : 0,
        transition: "all 2s",
        shadowColor: "black",
        shadowOffset: { height: 0, width: 0 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
        ...(!isBottom ? {
            top: '0.8vw',
            bottom: 'auto'
        } : {
            top: 'auto',
            bottom: '0.8vw'
        }),
        paddingVertical: "1vw"
    }),
    eventsScroll: {
        maxHeight: "12vw"
    },
    optionBtn: (hovered) => ({
        paddingVertical: "0.3vw",
        paddingHorizontal: "1vw",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: hovered ? background.lineHover : ""
    }),
    textName: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "100%",
        minWidth: "100%"
    },
    eventColor: (color, direction) => ({
        backgroundColor: color,
        width: "0.2vw",
        height: "0.9vw",
        ...(direction === "rtl" ? { marginLeft: "0.5vw" } : { marginRight: "0.5vw" })
    }),
    btnAdd: {
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: "1vw",
        borderTopColor: color.border,
        borderTopWidth: "0.1vw",
        paddingTop: "1vw",
        outlineStyle: "none"
    },
    plusIcon: {
        width: "0.6vw",
        height: "0.6vw",
        tintColor: color.text
    },
    input: (direction) => ({
        color: color.text,
        fontSize: "0.8vw",
        outlineStyle: "none",
        writingDirection: direction
    })
});
export default memo(EventCell);