import { Animated, Dimensions, PanResponder, View } from 'react-native'
import React, { useEffect, useRef, useState } from 'react';
import UseResponsiveSize from './UseResponsiveSize';
import UseResponsiveScreen from './UseResponsiveScreen';

export default function Dragged({ x, y, index, items, changePositionApp, checkPlaceX, checkPlaceY, renderItem, backgroundRef }) {

    const { width: widthScreen, height: heightScreen } = Dimensions.get("screen");
    const { width: widthWindow, height: heightWindow } = Dimensions.get("window");

    const { width, height } = UseResponsiveSize();
    const { heightS, widthS } = UseResponsiveScreen();

    const pan = useRef(new Animated.ValueXY()).current;
    const [opacity, setOpacity] = useState(false);

    const calculatX = (state) => {
        let move = state;
        let newMove = 0;
        if (move !== undefined && move > 0) {
            if (move > (widthS * 70)) {
                let steps = move / (widthS * 70);
                steps = Math.round(steps);
                newMove += steps;
            } else {
                if (move >= (widthS * 45)) {
                    newMove += 1;
                }
            }
            if (newMove === null || newMove === undefined) {
                return { x: 0, type: "right" };
            } else {
                return { x: newMove, type: "right" };
            }
        } else {
            move = move - (move * 2);
            if (move > (widthS * 70)) {
                let steps = move / (widthS * 70);
                steps = Math.round(steps);
                newMove += steps;
            } else {
                if (move >= (widthS * 45)) {
                    newMove += 1;
                }
            }
            if (newMove === null) {
                return 0;
            } else {
                return newMove > 0 ? { x: newMove, type: "left" } : { x: 0, type: "left" };
            }
        }
    }

    const calculatY = (state) => {
        let move = state;
        let newMove = 0;
        if (move > 0) {
            if (move > (heightS * 74)) {
                let steps = move / (heightS * 74);
                steps = Math.round(steps);
                newMove += steps;
            } else {
                if (move >= (heightS * 60)) {
                    newMove += 1;
                }
            }
            if (newMove === null) {
                return 0;
            } else {
                return { y: newMove, type: "down" };
            }
        } else {
            move = move - (move * 2);
            if (move > (heightS * 74)) {
                let steps = move / (heightS * 74);
                steps = Math.round(steps);
                newMove += steps;
            } else {
                if (move >= (heightS * 60)) {
                    newMove += 1;
                }
            }
            if (newMove === null) {
                return 0;
            } else {
                return newMove > 0 ? { y: newMove, type: "up" } : { y: 0, type: "up" };
            }
        }
    }

    const responder = useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: () => true,
            onPanResponderGrant: () => {
                setOpacity(true);
                pan.setOffset({
                    x: pan.x._value,
                    y: pan.y._value
                });
            },
            onPanResponderMove: Animated.event(
                [
                    null,
                    { dx: pan.x, dy: pan.y },
                ],
                {
                    useNativeDriver: true
                }
            ),
            onPanResponderEnd: () => setOpacity(false),
            onPanResponderRelease: (event, state) => {
                let xNew = calculatX(state.dx);
                let yNew = calculatY(state.dy);
                xNew = checkPlaceX(xNew, index);
                xNew = xNew > 0 ? xNew : 0;
                yNew = checkPlaceY(yNew, index);
                yNew = yNew > 0 ? yNew : 0;
                let xValue = (widthS * 70) * xNew;
                let yValue = (heightS * 74) * yNew;
                pan.setValue({ x: xValue, y: yValue });
                pan.flattenOffset();
                changePositionApp(xNew, yNew, index);
                setOpacity(false);
            }
        })
    ).current;
    const checkScreenWidth = (size, count) => {
        if (backgroundRef?.current) {
            let widthCurrent = backgroundRef.current.getBoundingClientRect().width - widthS * 50;
            for (let i = count; i > -8; i--) {
                if ((size * i) < widthCurrent) {
                    return (size * i);
                } else {
                    continue;
                }
            }
        } else {
            return size * count;
        }
    }

    useEffect(() => {
        pan.setValue({ x: checkScreenWidth(widthS * 70, x), y: (heightS * 74) * y });
    }, [x, y, items, width, height, widthWindow]);

    return (
        <Animated.View
            style={{
                transform: [{ translateX: pan.x }, { translateY: pan.y }],
                position: "absolute",
            }}
            {...responder.panHandlers}
        >
            <View
                style={{ opacity: opacity ? 0.5 : 1 }}
            >
                {renderItem}
            </View>
        </Animated.View>
    );
}
