export const addOrReplaceItemInArr = (arrCurrent, newItem, challengesFlag, social, mail) => {
    let flag = false;
    let index = arrCurrent.findIndex((e) => e.id === newItem.id);
    if (index >= 0) {
        if (newItem?.status === "0" || !newItem?.status) {
            arrCurrent.splice(index, 1);
        } else if (challengesFlag) {
            arrCurrent.splice(index, 1);
        } else if (social) {
            arrCurrent.splice(index, 1, newItem);
        }
        else if (mail) {
            arrCurrent.splice(index, 1, newItem);
        }
    } else {
        if (newItem?.status === "1" || (newItem?.status && newItem?.status !== "0")) {
            arrCurrent.push(newItem);
            flag = true;
        } else if (challengesFlag) {
            arrCurrent.push(newItem);
            flag = true;
        }
    }
    return {
        arrCurrent,
        addFlag: flag
    };
}