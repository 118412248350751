import React, { useMemo, useState } from "react";
import { StyleSheet, View, Text, Pressable, ScrollView } from "react-native";
import { t } from "i18n-js";
import { useSelector } from "react-redux";
import Share from "./Share";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";

export default function Step4({
  actionLength,
  addNewChallenge,
  newChallenge,
  showShare,
  setShowShare,
  setShare,
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, direction);

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const [step, setStep] = useState(1);

  const footerButtons = () => {
    switch (step) {
      case 1:
        return (
          <View style={styles.buttons}>
            <Text
              style={[
                styles.text,
                { fontSize: widthS * 12, ...(direction === "rtl" ? { marginLeft: widthS * 6 } : { marginRight: widthS * 6, }) },
              ]}
            >
              {t("new-action_q4")}
            </Text>
            <Pressable
              style={({ pressed, hovered }) => [
                [styles.btn_back, { borderColor: color.actionBtnBackBorder }],
                { width: widthS * 40 },
              ]}
              onPress={() => setShowShare(true)}
            >
              <Text style={styles.btn_text}>{t("yes")}</Text>
            </Pressable>
            <Pressable
              style={({ pressed, hovered }) => [
                [styles.btn_back, { borderColor: color.actionBtnBackBorder }],
                { width: widthS * 40, ...(direction !== "rtl" ? { marginLeft: widthS * 4 } : { marginRight: widthS * 4, }) },
              ]}
              onPress={() => setStep(step + 1)}
            >
              <Text style={styles.btn_text}>{t("no")}</Text>
            </Pressable>
          </View>
        );
      case 2:
        return (
          <View style={styles.buttons}>
            <Text
              style={[
                styles.text,
                { fontSize: widthS * 12, ...(direction === "rtl" ? { marginLeft: widthS * 6 } : { marginRight: widthS * 6, }) },
              ]}
            >
              {t("new-action_q5")}
            </Text>
            <Pressable
              style={({ pressed, hovered }) => [
                [styles.btn_back, { borderColor: color.actionBtnBackBorder }],
                { width: widthS * 40 },
              ]}
              onPress={() => addNewChallenge(true)}
            >
              <Text style={styles.btn_text}>{t("yes")}</Text>
            </Pressable>
            <Pressable
              style={({ pressed, hovered }) => [
                [styles.btn_back, { borderColor: color.actionBtnBackBorder }],
                { width: widthS * 40, ...(direction !== "rtl" ? { marginLeft: widthS * 4 } : { marginRight: widthS * 4, }) },
              ]}
              onPress={() => addNewChallenge(false)}
            >
              <Text style={styles.btn_text}>{t("no")}</Text>
            </Pressable>
          </View>
        );
    }
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          borderBottomWidth: 2,
          borderBottomColor: color.border,
          marginHorizontal: widthS * 5,
        }}
      >
        <Text style={styles.text_header}>{t("almost-done")}</Text>
      </View>
      <View style={{ width: "100%", flex: 1 }}>
        <ScrollView
          style={{
            height: heightS * 200,
            // height: height > 600 ? "400%" : "118%",
            paddingHorizontal: widthS * 5,
          }}
          nativeID="action"
        >
          <View style={[styles.top, { borderBottomColor: color.border }]}>
            <Text style={[styles.headers, { color: color.actionTh }]}>
              {t("your-challenge")}
            </Text>
            <Text style={styles.text}>
              <Text>
                {newChallenge &&
                  newChallenge.action &&
                  newChallenge.action.action_body}
              </Text>
            </Text>
            <Text style={[styles.headers, { color: color.actionTh }]}>
              {t("related-event")}
            </Text>
            <Text style={styles.text}>
              {newChallenge?.action?.event_name}
            </Text>
          </View>
          <Text style={[styles.headers, { color: color.actionTh }]}>
            {t("decision")}
          </Text>
          <View style={styles.solution_view}>
            {newChallenge &&
              newChallenge.solutions &&
              newChallenge.solutions
                .sort((a, b) => a.order - b.order)
                .map((e, i) => {
                  return (
                    <View style={styles.solutions} key={i}>
                      <View
                        style={
                          i === newChallenge.decision - 1
                            ? [
                              styles.solution_active,
                              {
                                borderColor: color.actionItemHover,
                                backgroundColor: background.actionSelect,
                              },
                            ]
                            : styles.solution
                        }
                      >
                        <Text style={styles.num}>{`${i + 1}.  `}</Text>
                        <Text style={styles.text}> {e.action_body}</Text>
                      </View>
                    </View>
                  );
                })}
          </View>
        </ScrollView>
      </View>
      <View style={[styles.footer, { borderTopColor: color.border }]}>
        {footerButtons()}
      </View>
      {showShare && (
        <Share
          setShare={(list) => {
            setShare(list);
            setStep(step + 1);
          }}
          setFlag={() => setShowShare(false)}
          flag={showShare}
        />
      )}
    </View>
  );
}
const stylesR = (width, height, heightS, widthS, direction) =>
  StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      // minHeight: heightS * 295,
      flex: 1,
      justifyContent: "space-between",
    },
    text_header: {
      color: "white",
      fontSize: widthS * 13,
      fontFamily: "Catamaran_300Light",
      marginBottom: heightS * 25,
      paddingLeft: widthS * 3,
    },
    top: {
      borderBottomWidth: 2,
      borderStyle: "solid",
      paddingLeft: widthS * 3,
      paddingRight: widthS * 6,
      paddingBottom: heightS * 10,
    },
    headers: {
      fontSize: widthS * 8,
      marginTop: heightS * 10,
      fontFamily: "Catamaran_300Light",
      writingDirection: direction,
    },
    text: {
      color: "white",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      writingDirection: direction,
    },
    num: {
      color: "white",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
      writingDirection: direction,
    },
    main: {
      paddingLeft: widthS * 10,
      paddingRight: widthS * 6,
      paddingBottom: heightS * 8,
      position: "relative",
      maxHeight: heightS * 135,
      left: widthS * -8,
    },
    solution_view: {
      minHeight: heightS * 80,
      writingDirection: direction,
    },
    solutions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    solution: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
      minHeight: heightS * 24,
      marginTop: heightS * 1.5,
      marginBottom: heightS * 1.5,
      paddingHorizontal: widthS * 6,
      width: "100%",
      minHeight: heightS * 23.7,
    },
    solution_active: {
      borderWidth: widthS * 0.5,
      borderStyle: "solid",
      borderRadius: 2,
      minHeight: heightS * 23.7,
      marginTop: heightS * 1.35,
      marginBottom: heightS * 1.35,
      paddingHorizontal: widthS * 5.4,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
    },
    footer: {
      borderTopWidth: 2,
      borderStyle: "solid",
      display: "flax",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      // paddingTop: heightS * 10,
      // paddingBottom: heightS * 10,
      height: heightS * 40,
      writingDirection: direction,
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    btn_next: {
      borderWidth: 1,
      width: widthS * 72,
      height: heightS * 20,
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 5,
    },
    btn_back: {
      borderWidth: 1,
      width: widthS * 72,
      height: heightS * 20,
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 5,
    },
    btn_text: {
      color: "white",
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
    },
  });
