import {
    OPEN_REAL_PLAYER, OPEN_REP_PLAYER,
    SET_REAL_PLAYERS,
    SET_REP_PLAYERS,
    CHANGE_POSITION_REAL_P,
    CHANGE_POSITION_REP_P,
    SET_STATUS_PLAYERS
} from "../actions/creatorActions";
import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';

const checkStatusPlayer = (status) => {
    switch (status) {
        case "false":
            return "#A99BA8";
        case "true":
        case "inactive":
            return "#de82d8";
        case "active":
            return "#25af94";
    }
}


export const Players = (state = { flagReal: false, flagRep: false, player: null, realPlayers: [], repPlayers: [] }, action) => {
    switch (action.type) {

        case OPEN_REAL_PLAYER: {

            if (action.payload) {
                return { ...state, flagReal: action.flag, player: action.payload, flagRep: false }
            } else {
                return { ...state, flagReal: action.flag, player: null, flagRep: false }
            }
        }
        case OPEN_REP_PLAYER: {

            if (action.payload) {
                return { ...state, flagRep: action.flag, player: action.payload, flagReal: false }
            } else {
                return { ...state, flagRep: action.flag, flagReal: false }
            }
        }
        case SET_REAL_PLAYERS: {
            action.payload.forEach(element => {
                element.name_for_game = checkIfIsEncodeText(element.name_for_game, "string");
                element.color_status = checkStatusPlayer(element?.active);
                element.player_description = element.player_description ? checkIfIsEncodeText(element.player_description, "string") : "";
            });
            return { ...state, realPlayers: [...action.payload] }
        }
        case SET_REP_PLAYERS: {
            action.payload.forEach(element => {
                element.name_for_game = checkIfIsEncodeText(element.name_for_game, "string");
                element.player_description = element.player_description ? checkIfIsEncodeText(element.player_description, "string") : "";
            });
            return { ...state, repPlayers: [...action.payload] }
        }
        case CHANGE_POSITION_REAL_P: {
            let newItems = [...state.realPlayers].filter((e, i) => i !== action.index);
            let item = state.realPlayers[action.index];

            newItems.splice(action.newIndex, 0, item);
            return { ...state, realPlayers: [...newItems] };
        }
        case CHANGE_POSITION_REP_P: {
            let newItems = [...state.repPlayers].filter((e, i) => i !== action.index);
            let item = state.repPlayers[action.index];
            newItems.splice(action.newIndex, 0, item);
            return { ...state, repPlayers: [...newItems] };
        }
        case SET_STATUS_PLAYERS: {
            try {
                state.realPlayers.forEach((e) => {
                    console.log(`looking at id ${e.id}`);
                    if (action.payload[0]?.indexOf(e.id) !== -1) {
                        console.log(`BBBBBBBBBBBBBBBB found  id BBBBBBBBBBBBBBBB ${e.id}`);
                        console.log(`setting to active`);
                        e.status = "active";
                        console.log(e.status);
                        console.log(`setting color`);
                        e.color_status = checkStatusPlayer("active");
                        console.log(e.color_status);
                    } else {
                        if (e.status === "active") {
                            e.status = "inactive";
                            e.color_status = checkStatusPlayer("inactive");
                        }
                    }
                });
            } catch (f) {
                console.log(f)
            }
            return { ...state, realPlayers: [...state.realPlayers] };
        }
        default: return state;
    }
} 