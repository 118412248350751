import React, { useRef, useEffect, createContext, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CheckTimeZone } from "../../../../modules/CheckTimeZone";
import { setTimeUnits } from '../../../../modules/createTimeColumns';

export const HeatMapFuncContext = createContext();

export default function HeatMapFuncProvider(props) {

    const playersRef = useRef([]);
    const playersRefMaximize = useRef([]);
    // selector
    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

    const sendMailsSelector = useSelector(state => state.mailArr.sentMails);
    const mailsSelector = useSelector(state => state.mailArr.messages);
    const socialSelector = useSelector(state => state.socialMedia.socialMediaArr);
    const challengesSelector = useSelector(state => state.actionsArr.actionsArr);
    const chatSelector = useSelector(state => state.chatArr.allMessages);
    const actionsArrSelector = useSelector((state) => state.swimlane.actions);
    const eventsSelector = useSelector((state) => state.events.events);
    // select filter selector \\
    const actionSelectSelector = useSelector(state => state.analytics.filter.actions);
    const playersSelectSelector = useSelector(state => state.analytics.filter.players);
    const eventsSelectSelector = useSelector(state => state.analytics.filter.events);

    // end - selector \\
    // state - data
    const [mails, setMails] = useState([]);
    //\\
    const [refresh, setRefresh] = useState(false);

    const challenges = useMemo(() => {
        setRefresh(!refresh);
        return [...challengesSelector.filter((e) => {
            if (e?.action?.event_id) {
                if (eventsSelectSelector.indexOf(e?.action?.event_id) >= 0) {
                    return true;
                }
                return false;
            } else {
                return true;
            }
        })]
    }, [challengesSelector, eventsSelectSelector]);

    const social = useMemo(() => { setRefresh(!refresh); return socialSelector; }, [socialSelector]);

    const chat = useMemo(() => { setRefresh(!refresh); return chatSelector; }, [chatSelector]);

    const players = useMemo(() => {
        if (gameInfo?.players?.length > 0) {
            return gameInfo.players.filter((e) => playersSelectSelector.indexOf(e.id) >= 0 && e.permission_for_game === "15");
        }
        return [];
    }, [gameInfo, playersSelectSelector]);

    const events = useMemo(() => { return eventsSelector; }, [eventsSelector]);

    const actions = useMemo(() => { return actionsArrSelector; }, [actionsArrSelector]);

    // \\
    // state - data to show \\
    // expected \\
    const [arrScore, setArrScore] = useState([]);
    const [maxCount, setMaxCount] = useState(0);
    // real \\
    const [arrScoreReal, setArrScoreReal] = useState([]);
    const [maxCountReal, setMaxCountReal] = useState(0);
    // dashboard \\
    const [arrScoreDashboard, setArrScoreDashboard] = useState([]);

    const [filterPlatform, setFilterPlatform] = useState("mail,sent,chat,social,challenges,");

    const [excelJson, setExcelJson] = useState();
    const [excelJsonReal, setExcelJsonReal] = useState();

    const [excelJsonScore, setExcelJsonScore] = useState();
    const [excelJsonRealScore, setExcelJsonRealScore] = useState();// select filter

    useEffect(() => {
        if (Array.isArray(sendMailsSelector) && sendMailsSelector.length > 0) {
            setMails([...sendMailsSelector.filter((e) => {
                if (e?.event?.name) {
                    if (eventsSelectSelector.indexOf(e?.event?.id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })]);
            setRefresh(!refresh);
        }
    }, [sendMailsSelector.length, eventsSelectSelector]);

    useEffect(() => {
        console.log("B");
        if (Array.isArray(mailsSelector) && mailsSelector.length > 0) {
            setMails([...mailsSelector.filter((e) => {
                if (e?.event?.name) {
                    if (eventsSelectSelector.indexOf(e?.event?.id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })]);
            setRefresh(!refresh);
        }

    }, [mailsSelector.length, eventsSelectSelector]);

    useEffect(() => {
        setRefresh(!refresh);
    }, [filterPlatform, actionSelectSelector, players]);

    const createArrStatistics = (players, events, actions, mails, challenges, chat, social, type, real, timeArr, timeUnits, json) => {
        let statistics = [];
        switch (type) {
            case "expected":
                if (actions.length > 0) {
                    if (players.length > 0) {
                        timeArr.forEach((time, indexTime) => {
                            let arr = [];
                            players.forEach((e, i) => {
                                arr.push({
                                    name: e.name_for_game,
                                    id: e.id,
                                    score: {
                                        sent: 0,
                                        decision: 0,
                                        mail: 0
                                    },
                                    date: time
                                });
                            });
                            statistics.push(arr);
                        });
                        return addScoreToPlayerExpected(statistics, actions, json);
                        // return addScoreToPlayers(statistics, mails, social, chat, currentDate);
                    } else {
                        setMaxCount(0);
                        return setArrScore([]);
                    }
                } else {
                    setMaxCount(0);
                    return setArrScore([]);
                }
            case "real":
            case "dashboard":
                if (timeArr) {
                    if (players.length > 0 && timeArr.length > 0) {
                        let statistics = [];
                        timeArr.forEach((time, index) => {
                            let arr = [];
                            players.forEach((e, i) => {
                                arr.push({
                                    name: e.name_for_game,
                                    id: e.id,
                                    score: {
                                        sent: 0,
                                        decision: 0,
                                        mail: 0
                                    },
                                    date: time
                                });
                            });
                            statistics.push(arr);
                        });
                        return addScoreToPlayers(statistics, mails, challenges, chat, social, timeUnits, json, type);
                    } else {
                        setMaxCountReal(0);
                        return setArrScoreReal([]);
                    }
                } else {
                    setMaxCountReal(0);
                    return setArrScoreReal([]);
                }
            case "compared":
                if (timeArr) {
                    if (players.length > 0) {
                        if (real) {
                            let statistics = [];
                            timeArr.forEach((time, index) => {
                                let arr = [];
                                players.forEach((e, i) => {
                                    arr.push({
                                        name: e.name_for_game,
                                        id: e.id,
                                        score: {
                                            sent: 0,
                                            decision: 0,
                                            mail: 0
                                        },
                                        date: time
                                    });
                                });
                                statistics.push(arr);
                            });
                            return addScoreToPlayers(statistics, mails, challenges, chat, social, timeUnits, json);
                        } else {
                            let statistics = [];
                            timeArr.forEach((time, index) => {
                                let arr = [];
                                players.forEach((e, i) => {
                                    arr.push({
                                        name: e.name_for_game,
                                        id: e.id,
                                        score: {
                                            sent: 0,
                                            decision: 0,
                                            mail: 0
                                        },
                                        date: time
                                    });
                                });
                                statistics.push(arr);
                            });
                            return addScoreToPlayerExpected(statistics, actions, json);
                        }

                    } else {
                        setMaxCountReal(0);
                        return setArrScoreReal([]);
                    }
                } else {
                    setMaxCountReal(0);
                    return setArrScoreReal([]);
                }
        }
    }

    const createTimesCurrent = (challenges, mails, chat, social, typeArr, type, timeUnits, countStep) => {
        let { firstTime, endTime } = findLastTime(challenges, mails, chat, social);
        let timeArr = setTimeUnits({ timeUnits: timeUnits, time: firstTime }, endTime, typeArr, +countStep);
        if (type === "compared" && timeArr?.length > 0) {
            addStepsToTimeArr(timeArr, timeUnits);
            timeArr.forEach((e, i) => e.step = i + 1);
        }
        return timeArr;
    }

    const addStepsToTimeArr = (arr, timeUnits) => {
        switch (timeUnits) {
            case findTypeCountUnits("m"):
            case findTypeCountUnits("h"):
            case findTypeCountUnits("d"):
        }
    }

    const findTypeCountUnits = (timeUnits, type) => {
        return timeUnits.indexOf(type) >= 0;
    }

    const checkDate = (timeUnits, currentDate1, currentDate2, messageDate, isZero) => {
        messageDate = CheckTimeZone(messageDate);
        currentDate2?.setSeconds(0);

        if (timeUnits.indexOf("h") >= 0) {
            messageDate.setMinutes(0);
        }
        if (timeUnits.indexOf("d") >= 0) {
            messageDate.setMinutes(0);
            messageDate.setHours(0);
        }
        if (currentDate1) {
            if (messageDate > currentDate1 && messageDate <= currentDate2) {
                return true;
            } else {
                return false;
            }
        } else {
            if (messageDate <= currentDate2) {
                return true;
            } else {
                return false;
            }
        }
    }

    const addScoreToPlayers = (statistics, mails, challenges, chat, social, timeUnits, json, type) => {
        let maxCount = 0;
        if (statistics?.length > 0) {
            let countLocal = 0;
            let flag = false;
            statistics.forEach((item, indexItemTime) => {
                item.forEach((playerItem, index) => {
                    let count = 0;
                    let dateCurrent2 = new Date(playerItem.date.date);
                    let dateCurrent1 = indexItemTime + 1 < statistics?.length ? new Date(statistics[indexItemTime + 1][0].date.date) : null;
                    if (Array.isArray(mails) && mails.length > 0) {
                        let countMailSent = 0;
                        let countMail = 0;
                        mails.forEach((mail, index) => {
                            flag = checkDate(timeUnits, dateCurrent1, dateCurrent2, mail.time, indexItemTime === 0);
                            if (mail.sender.id === playerItem.id && filterPlatform.indexOf("sent") >= 0 && flag && actionSelectSelector.indexOf("Sent Items") >= 0) {
                                countMailSent += 4;
                            }
                            flag = checkDate(timeUnits, dateCurrent1, dateCurrent2, mail.time, indexItemTime === 0);
                            if (filterPlatform.indexOf("mail") >= 0 && flag && actionSelectSelector.indexOf("Incoming Items") >= 0) {
                                if (mail?.to_list?.indexOf(playerItem?.id) >= 0 || mail?.to_list?.indexOf(playerItem?.name) >= 0 ||
                                    mail?.cc_list?.indexOf(playerItem?.id) >= 0 || mail?.cc_list?.indexOf(playerItem?.name) >= 0 ||
                                    mail?.bcc_list?.indexOf(playerItem?.id) >= 0 || mail?.bcc_list?.indexOf(playerItem?.name) >= 0
                                ) {
                                    countMail += 2;
                                }
                            }
                        });
                        if (filterPlatform.indexOf("sent") >= 0) {
                            playerItem.score.sent += countMailSent;
                            count += countMailSent;

                        }
                        if (filterPlatform.indexOf("mail") >= 0) {
                            playerItem.score.mail += countMail;
                            count += countMail;
                        }
                    }
                    if (Array.isArray(challenges) && challenges.length > 0 && filterPlatform.indexOf("challenges") >= 0 && actionSelectSelector.indexOf("Decisions") >= 0) {
                        let countChallenges = 0;
                        challenges.forEach((action, index) => {
                            flag = checkDate(timeUnits, dateCurrent1, dateCurrent2, action.action.time, indexItemTime === 0);
                            if (flag) {
                                if (action.action.creator_id === playerItem.id) {
                                    countChallenges += 6;
                                } else if (action.action.share_list?.indexOf(playerItem?.id) >= 0) {
                                    if (filterPlatform.indexOf("mail") >= 0) {
                                        playerItem.score.mail += 4;
                                        count += 4;
                                    }
                                }
                            }
                        });
                        playerItem.score.decision += countChallenges;
                        count += countChallenges;
                    }
                    // social
                    if (Array.isArray(social) && social.length > 0 && filterPlatform.indexOf("social") >= 0) {
                        let countSocialSent = 0;
                        let countSocial = 0;
                        social.forEach((post, index) => {
                            flag = checkDate(timeUnits, dateCurrent1, dateCurrent2, post.time, indexItemTime === 0);
                            if (post.sender_by?.id === playerItem.id && flag && actionSelectSelector.indexOf("Sent Items") >= 0) {
                                countSocialSent += 2;
                            }
                            // flag = checkDate(timeUnits, dateCurrent1, dateCurrent2, post.time, indexItemTime === 0);
                            // if ((post.to_list.indexOf(playerItem.id) >= 0 || post.to_list.indexOf(playerItem.name) >= 0) && flag) {
                            //     countMail += 2;
                            // }
                        });
                        playerItem.score.sent += countSocialSent;
                        count += countSocialSent;
                        // playerItem.score.mail = countSocial;
                        // count += countSocial;
                    }
                    // chat
                    if (Array.isArray(chat) && chat.length > 0 && filterPlatform.indexOf("chat") >= 0 && actionSelectSelector.indexOf("Chat Messages") >= 0) {
                        let countChatSent = 0;
                        let countChat = 0;
                        chat.forEach((chatItem, index) => {
                            flag = checkDate(timeUnits, dateCurrent1, dateCurrent2, chatItem.time, indexItemTime === 0);
                            if (chatItem.sender_id === playerItem.id && flag) {
                                countChatSent += 1;
                            }
                        });
                        playerItem.score.sent += countChatSent;
                        count += countChatSent;
                        playerItem.score.mail += countChat;
                        count += countChat;
                    }
                    playerItem["total"] = count;
                    if (playerItem["total"] && countLocal < playerItem["total"]) {
                        countLocal = playerItem["total"];
                    }
                    playerItem["total"] = count;
                    if (playerItem["total"] && countLocal < playerItem["total"]) {
                        countLocal = playerItem["total"];
                    }
                    playerItem["total"] = count;
                    if (playerItem["total"] && countLocal < playerItem["total"]) {
                        countLocal = playerItem["total"];
                    }
                })
            });
            if (json) {
                setExcelJsonRealScore(statistics);
                return;
            }
            if (type === "dashboard") {
                return setArrScoreDashboard([...statistics]);
            } else {
                setMaxCountReal(countLocal);
                return setArrScoreReal([...statistics]);
            }
        }
    }

    // decision-to = 4
    // decision-from = 6
    // sent = 4
    // incoming = 2
    // social (to/from) = 2 
    // chat (from) = 2

    const addScoreToPlayerExpected = (statistics, actions, json) => {
        let mails = [];
        let social = [];
        let decitions = [];
        if (Array.isArray(statistics) && actions.length > 0) {
            actions.forEach((e) => {
                if (e.platform_type === "sm") {
                    social.push(e);
                } else if ((e.platform_type === "mail" || e.platform_type === "")) {
                    mails.push(e);
                }
            });
            let countLocal = 0;
            statistics.forEach((item, indexItemTime) => {
                item.forEach((playerItem, index) => {
                    let count = 0;
                    let dateCurrent = playerItem.date.step ? +playerItem.date.step : +playerItem.date.date;
                    if (Array.isArray(mails) && mails.length > 0) {
                        let countMailSent = 0;
                        let countMail = 0;
                        let countDecision = 0;
                        mails.forEach((mail, index) => {
                            if (mail.from_id === playerItem.id && filterPlatform.indexOf("sent") >= 0 && +mail.time === dateCurrent && eventsSelectSelector.indexOf(mail?.event_id) >= 0) {
                                if (mail.type_action === "Decision") {
                                    if (actionSelectSelector.indexOf("Decisions") >= 0) {
                                        countDecision += 6;
                                    }
                                } else {
                                    if (actionSelectSelector.indexOf("Sent Items") >= 0) {
                                        countMailSent += 4;
                                    }
                                }
                            }
                            else if (actionSelectSelector.indexOf("Sent Items") >= 0 && filterPlatform.indexOf("mail") >= 0 && +mail.time === dateCurrent && eventsSelectSelector.indexOf(mail?.event_id) >= 0) {
                                if (mail.to_list_ids.indexOf(playerItem.id) >= 0 || mail.to_list.indexOf(playerItem.name) >= 0 ||
                                    mail.cc_list_ids.indexOf(playerItem.id) >= 0 || mail.cc_list.indexOf(playerItem.name) >= 0 ||
                                    mail.bcc_list_ids.indexOf(playerItem.id) >= 0 || mail.bcc_list.indexOf(playerItem.name) >= 0
                                ) {
                                    if (mail.type_action === "Decision") {
                                        countMail += 4;
                                    } else {
                                        countMail += 2;
                                    }
                                }
                            }
                        });
                        playerItem.score.sent = countMailSent;
                        count += countMail;

                        playerItem.score.mail = countMail;
                        count += countMailSent;

                        playerItem.score.decision = countDecision;
                        count += countDecision;
                    }
                    if (Array.isArray(social) && social.length > 0) {
                        let countSocialSent = 0;
                        let countSocial = 0;
                        social.forEach((post, index) => {
                            if (post.from_id === playerItem.id && filterPlatform.indexOf("sent") >= 0 && +post.time === dateCurrent && actionSelectSelector.indexOf("Sent Items") >= 0 && eventsSelectSelector.indexOf(post?.event_id) >= 0) {
                                countSocialSent += 2;
                            }
                        });
                        playerItem.score.sent = countSocialSent + playerItem.score.sent;
                        count += countSocialSent;

                        playerItem.score.mail = countSocial + playerItem.score.mail;
                        count += countSocial;
                    }
                    if (countLocal === 0) {
                        countLocal = count;
                    } else if (count > 0 && count < countLocal) {
                        countLocal = count;
                    }
                })
            });
            if (json) {
                setExcelJsonScore(statistics);
                return;
            }
            setMaxCount(countLocal);
            return setArrScore(statistics);
        }
    }

    const createtimes = (times, arr, key, type) => {
        let arrN = [...times];
        if (arr.length > 0) {
            if (type === "chat") {
                arr?.forEach((e) => {
                    e.newMsg?.forEach((e) => {
                        arrN.push(e.time);
                    });
                    e.msgHistory?.forEach((e) => {
                        arrN.push(e.time);
                    });
                });
            } else {
                arr?.forEach((e) => {
                    arrN.push(e.time);
                });
            }
        }
        return arrN;
    }

    const returnIfIsThefirstOrLast = (timeCurrent, time, type) => {
        switch (type) {
            case "start":
                if (!timeCurrent) {
                    timeCurrent = new Date(time);
                } else if (time < timeCurrent) {
                    timeCurrent = new Date(time);
                }
                return timeCurrent;
            case "end":
                if (!timeCurrent) {
                    timeCurrent = new Date(time);
                } else if (time > timeCurrent) {
                    timeCurrent = new Date(time);
                }
                return timeCurrent;
        }
    }

    const findLastTime = (challengesP, mailsP, chatP, socialP) => {
        let firstTime;
        let endTime;
        if (challengesP?.length > 0) {
            challengesP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.action.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.action.time}`), 'end');
            });
        }
        if (mailsP?.length > 0) {
            mailsP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time}`), 'end');
            });
        }
        if (socialP?.length > 0) {
            socialP.forEach((post) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${post.time} gmt`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${post.time} gmt`), 'end');
            });
        }
        if (chatP?.length > 0) {
            chatP.forEach((chat) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${chat.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${chat.time}`), 'end');
            })
        }
        return {
            firstTime,
            endTime
        };
    }


    const filterStatistics = (timeUnits, type, real, timeArr, json) => {
        if (type === "expected" && timeArr?.length) {
            if (actions.length > 0) {
                createArrStatistics(
                    players,
                    events,
                    actions,
                    mails,
                    challenges,
                    chat,
                    social,
                    type,
                    real,
                    timeArr,
                    timeUnits,
                    json
                )
            } else {
                setMaxCount(0);
                setArrScore([]);
            }
        } else if (((type === "real" || type === "compared") && timeArr?.length || type === "dashboard")) {
            if ((mails.length > 0 || chat.length > 0 || social.length > 0 || challenges.length > 0)) {
                createArrStatistics(
                    players,
                    events,
                    actions,
                    mails,
                    challenges,
                    chat,
                    social,
                    type,
                    real,
                    timeArr,
                    timeUnits,
                    json
                )
            } else {
                setMaxCountReal(0);
                setArrScoreReal([]);
            }
        }
    }

    const createExcelJson = (arrScoreState, setExcelJson, players) => {
        let jsonObj = [];
        if (arrScoreState.length > 0 && players?.length > 0) {
            // players.forEach((player) => {
            //     let name = player.name_for_game;
            //     jsonObj.push({
            //         0: name
            //     });
            // });
            // let times = [...arrScoreState.map((score, i) => score[0]?.date?.time)];
            // arrScoreState.forEach((column, i) => {
            //     column.forEach((player, index) => {
            //         let score = 0;
            //         Object.keys(player.score).forEach((key) => {
            //             score += player.score[key];
            //         });
            //         if (jsonObj[index]) {
            //             jsonObj[index][`${player.date.time}`] = `${score}`;
            //         }
            //     });
            // })
            const times = arrScoreState.flatMap(d => d[0].date.time)
                .filter((time, index, self) => self.indexOf(time) === index);

            // 2. Build header row
            const header = ["", ...times];

            // 3. Build data rows
            const rows = [];
            arrScoreState.forEach(round => {
                round.forEach((player, i) => {
                    if (!rows[i]) {
                        rows[i] = [player.name];
                    }
                    let score = 0;
                    Object.keys(player.score).forEach((key) => {
                        score += player.score[key];
                    });
                    rows[i].push(score);
                });
            });

            // 4. Combine to 2D array
            jsonObj = [header, ...Object.values(rows)];
        }
        setExcelJson(jsonObj);
    }

    useEffect(() => {
        if (excelJsonScore) {
            createExcelJson(excelJsonScore, setExcelJson, players);
        }
        if (excelJsonRealScore) {
            createExcelJson(excelJsonRealScore, setExcelJsonReal, players);
        }
    }, [excelJsonScore, excelJsonRealScore, players]);


    return (
        <HeatMapFuncContext.Provider value={{
            arrScore,
            arrScoreReal,
            arrScoreDashboard,
            players,
            maxCount,
            maxCountReal,
            filterPlatform,
            setFilterPlatform,
            filterStatistics,
            refresh,
            playersRef,
            playersRefMaximize,
            setRefresh,
            excelJson,
            setExcelJson,
            excelJsonReal,
            setExcelJsonReal
        }}>
            {props.children}
        </HeatMapFuncContext.Provider>
    )
}
