import { StyleSheet, Text, View, Dimensions, Image, Pressable } from 'react-native';
import React, { useCallback, useMemo, useState } from 'react';
import { t } from 'i18n-js';
import { useSelector } from 'react-redux';
import SimulationInfoBox from './SimulationInfoBox';
import StrategyBox from './StrategyBox';
import BackgroundStoryBox from './BackgroundStoryBox';
import Filesbox from './Filesbox';
import UseResponsiveSize from '../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../modules/UseResponsiveScreen';

export default function InfoSimulationBody({ infoObj, setLoad, setObject, permission, err, setErr, editGameInfo, setIsEdit }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);


    const color = useSelector((state) => state.style.color);
    const background = useSelector((state) => state.style.background);
    const images = useSelector((state) => state.style.images);

    const setObjectFunc = useCallback((value, key) => {
        setObject((currentInfoObj) => {
            return { ...currentInfoObj, [key]: value };
        });
    }, [setObject]);

    return (
        <View style={styles.container}>
            <View style={[styles.view, { zIndex: 4, flex: 0.8 }]}>
                <SimulationInfoBox setIsEdit={setIsEdit} permission={permission} infoObj={infoObj} setInfoObj={(obj) => setObject(obj)} err={err} setErr={setErr} />
                <StrategyBox setIsEdit={setIsEdit} permission={permission} game_goals={infoObj.game_goals} game_objectives={infoObj.game_objectives} setInfoObj={setObjectFunc} err={err} setErr={setErr} />
            </View>
            <View style={[styles.view, { zIndex: 3, flex: 0.7, marginTop: heightS * 20 }]}>
                <BackgroundStoryBox setIsEdit={setIsEdit} permission={permission} infoObj={infoObj} setInfoObj={(obj) => setObject(obj)} />
                <Filesbox permission={permission} infoObj={infoObj} setInfoObj={(obj) => setObject(obj)} editGameInfo={editGameInfo} />
            </View>
        </View>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingRight: widthS * 30,
            paddingLeft: widthS * 20,
            paddingTop: heightS * 12,
            paddingBottom: heightS * 20
        },
        view: {
            display: 'flex',
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
        },
    });
};