import React, { useState, useMemo } from "react";
import { StyleSheet, View, Text, Pressable, ScrollView } from "react-native";
import { t } from "i18n-js";
import { useSelector } from "react-redux";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";

export default function Step3({
  nextStep,
  newChallengeProp,
  actionLength,
  addSolutionsFlag,
  chooseSolution,
  setChooseSolution,
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, direction);

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const [colorSolutionsErr, setColorSolutionsErr] = useState(false);
  const [newChallenge, setNewChallenge] = useState();

  const addSolutionsFlagStep = () => {
    if (chooseSolution === false) {
      setColorSolutionsErr(true);
      return;
    }
    addSolutionsFlag(chooseSolution);
    // nextStep("+")
  };

  useMemo(() => {
    setNewChallenge(newChallengeProp);
  }, [newChallengeProp, newChallengeProp?.solutions?.length]);

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <View style={{ borderBottomColor: color.border, borderBottomWidth: 2 }}>
          <Text style={styles.text_header}>{t("new-action_q3")}</Text>
        </View>
        <View style={{ width: "100%", flex: 1 }}>
          <ScrollView
            style={{
              maxHeight: heightS * 185,
              paddingHorizontal: widthS * 5,
            }}
            nativeID="action"
          >
            <View style={[styles.top, { borderBottomColor: color.border }]}>
              <Text style={[styles.headers, { color: color.actionTh }]}>
                {t("your-challenge")}
              </Text>
              <Text style={styles.text}>
                <Text>
                  {newChallenge &&
                    newChallenge.action &&
                    newChallenge.action.action_body}
                </Text>
              </Text>
              <Text style={[styles.headers, { color: color.actionTh }]}>
                {t("related-event")}
              </Text>
              <Text style={styles.text}>
                {newChallenge?.action?.event_name}
              </Text>
            </View>
            <View style={styles.main}>
              <Text style={[styles.headers, { color: color.actionTh }]}>
                {" "}
                {t("decision")}
              </Text>
              <View style={styles.solution_view}>
                {newChallenge &&
                  newChallenge.solutions &&
                  newChallenge.solutions
                    .map((e, i) => {
                      return (
                        <View style={styles.solutions} key={i}>
                          <Pressable
                            style={({ pressed, hovered }) =>
                              i === chooseSolution || hovered
                                ? [
                                  styles.solution,
                                  {
                                    borderColor: color.actionItemHover,
                                    backgroundColor: background.actionSelect,
                                  },
                                ]
                                : [styles.solution]
                            }
                            onPress={() => {
                              setChooseSolution((value) => (value = i));
                              setColorSolutionsErr(false);
                              return;
                            }}
                          >
                            <Text
                              style={[
                                styles.num,
                                {
                                  color: colorSolutionsErr ? "red" : "white",
                                },
                              ]}
                            >{`${i + 1}.  `}</Text>
                            <Text
                              style={[
                                styles.text_solution,
                                {
                                  color: colorSolutionsErr ? "red" : "white",
                                },
                              ]}
                            >
                              {e.action_body}
                            </Text>
                          </Pressable>
                        </View>
                      );
                    })}
              </View>
            </View>
          </ScrollView>
        </View>
        <View style={[styles.footer, { borderTopColor: color.border }]}>
          <Pressable
            style={[
              styles.btn_back,
              { borderColor: color.actionBtnBackBorder },
            ]}
            onPress={() => nextStep("-")}
          >
            <Text style={styles.btn_text}>{t("back")}</Text>
          </Pressable>
          <Pressable
            style={[
              styles.btn_next,
              {
                backgroundColor: background.actionButtonAdd,
                borderColor: background.actionButtonAdd,
              },
            ]}
            onPress={() => addSolutionsFlagStep()}
          >
            <Text style={styles.btn_text}>{t("save")}</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS, direction) =>
  StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      // minHeight: height > 600 ? "142%" : "126%",
      flex: 1,
      justifyContent: "space-between",
    },
    text_header: {
      color: "white",
      fontSize: widthS * 13,
      fontFamily: "Catamaran_300Light",
      marginBottom: heightS * 20,
      paddingLeft: widthS * 3,
    },
    top: {
      borderBottomWidth: 2,
      borderStyle: "solid",
      paddingLeft: widthS * 3,
      paddingRight: widthS * 6,
      paddingBottom: heightS * 10,
    },
    headers: {
      fontSize: widthS * 8,
      marginTop: heightS * 10,
      fontFamily: "Catamaran_300Light",
    },
    text: {
      color: "white",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      writingDirection: direction,
    },
    num: {
      color: "white",
      fontSize: widthS * 10,
      ...(direction === "rtl" ? { marginLeft: widthS * 4, } : { marginRight: widthS * 4, }),
      fontFamily: "Catamaran_400Regular",
      writingDirection: direction,
    },
    main: {
      // paddingLeft: widthS * 10,
      // paddingRight: widthS * 6,
      paddingBottom: heightS * 8,
      position: "relative",
      // MinHeight: heightS * 135,
    },
    solution_view: {
      left: widthS * -2,
      writingDirection: direction,
      // minHeight: heightS * 80,
    },
    solutions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    solution: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
      marginTop: heightS * 1.35,
      marginBottom: heightS * 1.35,
      paddingHorizontal: widthS * 5.4,
      width: "100%",
      borderWidth: widthS * 0.5,
      borderStyle: "solid",
      borderRadius: 2,
      borderColor: "transparent",
      minHeight: heightS * 23.7,
    },
    solution_active: {
      borderWidth: widthS * 0.5,
      borderStyle: "solid",
      borderRadius: 2,
      minHeight: heightS * 23.7,
      marginTop: heightS * 1.35,
      marginBottom: heightS * 1.35,
      paddingHorizontal: widthS * 5.4,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
    },
    text_solution: {
      color: "white",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
      paddingVertical: heightS * 4,
      writingDirection: direction,
    },
    footer: {
      borderTopWidth: 2,
      borderStyle: "solid",
      display: "flax",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      // paddingTop: heightS * 13,
      // paddingBottom: heightS * 15,
      height: heightS * 50,
      writingDirection: direction,
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      alignSelf: "flex-start",
      alignItems: "center",
    },
    btn_next: {
      borderWidth: 1,
      width: widthS * 72,
      height: heightS * 20,
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 5,
    },
    btn_back: {
      borderWidth: 1,
      width: widthS * 72,
      height: heightS * 20,
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 5,
    },
    btn_text: {
      color: "white",
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
    },
  });
