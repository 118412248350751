import {
  Dimensions,
  Modal,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import {
  USER_ID_IN_GAME,
} from "../../../config.inc";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import InputRadio from "../../../InputRadio";
import { t } from "i18n-js";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import { useClickOutside } from "../../modules/useClickOutside";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";

export default function Share({ setShare, flag, setFlag }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, direction);

  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);

  const shareRef = useRef(null);

  const allPlayers = useMemo(() => gameInfo?.players.filter((e) => e.permission_for_game === "15"), [gameInfo]);
  const realPlayers = useMemo(() => gameInfo?.players.filter((e) => e.permission_for_game === "15" && e.is_represented === "0"), [gameInfo]);

  const [listShare, setListShare] = useState("");

  const shareAll = useMemo(() => allPlayers.length === listShare.split(",").filter((e) => e.trim()).length, [listShare, allPlayers]);
  const shareReal = useMemo(() => realPlayers.every((player) => listShare.includes(player.id)), [listShare, realPlayers]);

  const addPlayer = (id) => {
    if (listShare.length > 0) {
      setListShare((list) => (list += `, ${id}`));
    } else {
      setListShare((list) => (list += `${id}`));
    }
  };

  const removePlayer = (id) => {
    setListShare((list) => (list = list.replace(`${id}, `, "")));
  };

  const addAll = () => {
    let ids = "";
    allPlayers.forEach((e, i, a) => {
      ids += `${e.id},`;
    });
    setListShare(ids);
  };

  const addReal = (removeFlag) => {
    let ids = listShare;
    if (removeFlag) {
      // ממיר את המחרוזת של ids למערך
      let idsArray = ids.split(",");
      // סינון רק את המספרים שלא נמצאים במשתנה realPlayers
      let filteredIds = idsArray.filter((id) => !realPlayers.some((p) => p.id === id));
      // ממיר את המערך החדש שקיבלת חזרה למחרוזת
      let newIds = filteredIds.join(",");
      // עדכון של ערך המשתנה setListShare
      setListShare(newIds);
    } else {
      realPlayers.forEach((e, i, a) => {
        if (ids.indexOf(e.id) === -1) {
          ids += `${e.id},`;
        }
      });
      setListShare(ids);
    }
  };

  useClickOutside(shareRef, flag, setFlag);

  return (
    <Modal
      transparent={true}
      animationType="none"
      visible={true}
    >
      <View style={styles.container}>
        <View
          ref={shareRef}
          style={[
            styles.main,
            { backgroundColor: background.popup1, borderColor: color.border },
          ]}
        >
          <View
            style={[
              styles.players_view,
              { backgroundColor: background.actionSelect },
            ]}
          >
            <ScrollView
              nativeID="action"
              style={styles.players_scroll}
              contentContainerStyle={styles.players_scroll_main}
            >
              {allPlayers.length > 0 &&
                allPlayers.map((e, i) => {
                  if (sessionStorage.getItem(USER_ID_IN_GAME) == e.id) return
                  return (
                    <Pressable
                      key={e.id}
                      style={styles.player}
                      onPress={() => {
                        if (listShare.indexOf(e.id) >= 0) {
                          removePlayer(e.id);
                        } else {
                          addPlayer(e.id);
                        }
                      }}
                    >
                      <InputRadio
                        background={background.actionLogo}
                        backgroundBorder={background.popup1}
                        border={background.popup1}
                        borderWidth={widthS * 10}
                        checkd={listShare.indexOf(e.id) >= 0}
                        circleWidth={widthS * 6.8}
                        key={i}
                      />
                      <Text style={styles.player_text}>{e.name_for_game}</Text>
                    </Pressable>
                  );
                })}
            </ScrollView>
            <View style={{ borderTopWidth: 2, borderTopColor: color.border }}>
              <Pressable
                style={[styles.all_players,]}
                onPress={() => {
                  if (!shareAll) {
                    addAll();
                  } else {
                    setListShare("");
                  }
                }}
              >
                <InputRadio
                  background={background.actionLogo}
                  backgroundBorder={background.popup1}
                  border={background.popup1}
                  borderWidth={widthS * 10}
                  checkd={shareAll}
                  circleWidth={widthS * 6.8}
                />
                <Text style={styles.player_text}>{t("all_players")}</Text>
              </Pressable>
              <Pressable
                style={[styles.all_players, { borderTopColor: color.border }]}
                onPress={() => {
                  if (!shareReal) {
                    addReal();
                  } else {
                    addReal(true);
                  }
                }}
              >
                <InputRadio
                  background={background.actionLogo}
                  backgroundBorder={background.popup1}
                  border={background.popup1}
                  borderWidth={widthS * 10}
                  checkd={shareReal}
                  circleWidth={widthS * 6.8}
                />
                <Text style={styles.player_text}>{t("real_players")}</Text>
              </Pressable>
            </View>
          </View>
          {allPlayers.length > 0 && (
            <Pressable
              style={[
                styles.btn_share,
                {
                  backgroundColor: background.actionButtonAdd,
                  borderColor: background.actionButtonAdd,
                },
              ]}
              onPress={() => {
                if (listShare.length > 0) {
                  setShare(listShare);
                  setFlag();
                }
              }}
            >
              <Text style={styles.text_btn}>{t("share")}</Text>
            </Pressable>
          )}
        </View>
      </View>
    </Modal>
  );
}

const stylesR = (width, height, heightS, widthS, direction) =>
  StyleSheet.create({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#00000",
      height: "100%",
      fontFamily: "Roboto",
    },
    main: {
      // maxHeight: heightS * 220,
      minWidth: widthS * 200,
      borderRadius: widthS * 11,
      shadowColor: "black",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.5,
      shadowRadius: 5.6,
      elevation: 100,
      paddingHorizontal: widthS * 3,
      paddingVertical: heightS * 6,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      position: "absolute",
      left: "28%",
    },
    players_view: {
      borderRadius: widthS * 5,
      marginTop: heightS * 5,
    },
    players_scroll: {
      maxHeight: heightS * 140,
      width: widthS * 160,
      writingDirection: direction,
    },
    players_scroll_main: {
      paddingVertical: heightS * 3,
      paddingHorizontal: widthS * 3,
    },
    player: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      marginVertical: heightS * 2,
    },
    all_players: {
      paddingVertical: heightS * 6,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: widthS * 3,
      width: "100%",
      writingDirection: direction,
    },
    player_text: {
      color: "white",
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
    },
    btn_share: {
      width: widthS * 74,
      display: "flex",
      height: heightS * 20,
      alignItems: "center",
      justifyContent: "center",
      borderColor: "#00628a",
      backgroundColor: "#00628a",
      borderWidth: 1,
      paddingTop: 2,
      paddingBottom: heightS * 2,
      paddingHorizontal: widthS * 15,
      borderRadius: widthS * 5,
      marginTop: heightS * 10,
    },
    text_btn: {
      color: "white",
      fontSize: widthS * 8,
      fontFamily: "Catamaran_600SemiBold",
    },
  });
