export const createDate = (date) => {
      if (date == null) return;
      let space = date.indexOf("-");
      let year = date.slice(0, space);
      date = date.slice(space + 1);
      space = date.indexOf("-");
      let month = date.slice(0, space);
      date = date.slice(space + 1);
      space = date.indexOf(" ");
      let day = date.slice(0, space);
      date = date.slice(space + 1);
      space = date.indexOf(":");
      let hour = date.slice(0, space);
      date = date.slice(space + 1);
      let minuts = date.slice(0, space);
      date = date.slice(space + 1);
      let second = date;
      // החסרת 1 מהחודש כדי להתאים לאינדקס המתחיל מ-0
      return new Date(year, month - 1, day, hour, minuts, second).toString();
}
export const editDate = (dateString, typeDate, typeResult) => {
      let date = dateString;
      let timeZone = new Date().getTimezoneOffset()
      if (date == null) return;
      const days = ["Monday", "Sunday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let space = date.indexOf(" ");
      let dayWeek
      if (typeDate !== "mail") {
            dayWeek = date.slice(0, space);
            date = date.slice(space + 1);
            space = date.indexOf(" ");
      }
      let month = date.slice(0, space);
      date = date.slice(space + 1);
      space = date.indexOf(" ");
      let dayMonth = date.slice(0, space);
      date = date.slice(space + 1);
      space = date.indexOf(" ");
      let year = date.slice(0, space);
      date = date.slice(space + 1);
      space = date.indexOf(":");
      let hour = date.slice(0, space);
      date = date.slice(space + 1);
      space = date.indexOf(":");
      let minuts = date.slice(0, space);
      date = date.slice(space + 1);
      space = date.indexOf(" ");
      let seconds = date.slice(0, space);

      days.forEach((e) => {
            if (e.indexOf(dayWeek) >= 0) {
                  dayWeek = e;
            }
      });
      months.forEach((e) => {
            if (e.indexOf(month) >= 0) {
                  month = e;
            }
      });
      if (typeResult == "clock") {
            const timeString12hr = new Date(dateString)
                  .toLocaleTimeString(timeZone,
                        { hour12: false, hour: '2-digit', minute: '2-digit' }
                  );
            return timeString12hr;
      } else if (typeResult == "mail") {
            return `${month} ${dayMonth} ${year}`;
      } else {
            const timeString12hr = new Date(dateString)
                  .toLocaleTimeString(timeZone,
                        { hour12: false, hour: '2-digit', minute: '2-digit' }
                  );
            return `${dayWeek}, ${timeString12hr}, ${dayMonth} ${month} ${year}`
      }
}


export const getDateTimeObject = (inputDate) => {
      const dateObject = new Date(inputDate);
      const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
      };
      const timeString = dateObject.toLocaleTimeString('en-US', timeOptions);

      const options = {
            month: 'long',
            day: '2-digit',
            year: 'numeric'
      };
      const dateString = dateObject.toLocaleDateString('en-US', options);

      // מחזיר את התאריך והשעה בפורמט הנדרש
      return {
            time: timeString, // מסיר פסיק אם קיים בתוך השעה
            date: dateString.replace(',', '') // מסיר פסיק אם קיים בסוף התאריך
      };
};