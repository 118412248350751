import { StyleSheet, Text, View, Dimensions, Pressable, Image, RootTagContext, ScrollView } from 'react-native';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showFile, loading, setErrorMessage } from "../../../../redux/actions/index";
import { l, t } from "i18n-js";
import * as DocumentPicker from "expo-document-picker";
import UseResponsiveSize from '../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../modules/UseResponsiveScreen';
import { useContext } from 'react';
import { GameInfoContext } from '../../../../ContextData/GameInfo';
import { createFileObject } from '../../../modules/CreateFileObject';
import FileLimitPopup from '../../../Elements/FileLimitPopup';
import ShortenWord from "../../../modules/ShortenWord";
import { convertByte } from '../../../modules/ConvertByte';
import { MAX_FILES_SIZE } from "@env";

export default function Filesbox({ infoObj, setInfoObj, editGameInfo, permission }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const { addCountDownVideo, editGame, deleteBackgroundFile, removeCountdownVideoData } = useContext(GameInfoContext);

    const dispatch = useDispatch();

    const color = useSelector((state) => state.style.color);
    const background = useSelector((state) => state.style.background);
    const images = useSelector((state) => state.style.images);

    const [preVideo, setPreVideo] = useState();
    const [files, setFiles] = useState(infoObj?.files ? infoObj?.files : []);
    const [new_files, setNew_Files] = useState([]);
    const [newFile, setNewFile] = useState(false);
    const [hover, setHover] = useState(false);
    const [index, setIndex] = useState();
    const [showFileLimit, setShowFileLimit] = useState({ file: false, video: false });
    const videoRef = useRef(null);
    const fileRef = useRef(null);
    const refItem = useRef([]);
    const popupRef = useRef(null);

    const openAndCloseAnimation = (func, data, data1) => {
        dispatch(loading(true));
        func(data, data1).then(() => {
            dispatch(loading(false));
        }).catch((err) => {
            dispatch(loading(false));
        })
    }

    const addPreVideo = () => {
        let types = "video/mp4"
        DocumentPicker.getDocumentAsync({
            type: types,
        }).then((res) => res.assets[0]).then((res) => {
            let size = convertByte(res.size);
            if (size < MAX_FILES_SIZE) {
                if (types.indexOf(res.mimeType) >= 0) {
                    setPreVideo(res);
                    editGameInfo();
                    openAndCloseAnimation(addCountDownVideo, res.file);
                } else {
                    dispatch(setErrorMessage(true, false, t("game_info_err_countdown")));
                }
            } else {
                dispatch(setErrorMessage(true, false, t("file_size_limit")));
            }
        });
    };

    const removeVideo = () => {
        editGameInfo();
        openAndCloseAnimation(removeCountdownVideoData);
    }

    const openFilesBrowser = () => {
        // Unable to upload your file as the file type is unsupported. Please upload a supported file. The simulation background files support PDF, jpg, mp3, mp4, xls, xlsx, doc, docs, txt, tex files only.
        let types = "image/png, image/jpg, image/jpeg, audio/mp3, video/mp4, text/csv, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword";
        DocumentPicker.getDocumentAsync({
            type: types,
        }).then((res) => res.assets[0]).then(async (res) => {
            let size = res.size > 0 ? convertByte(res.size) : 0;
            if (size < MAX_FILES_SIZE) {
                if (res.mimeType?.length > 0 && types.indexOf(res.mimeType) >= 0) {
                    setNewFile(true);
                    setFiles([...files, res]);
                    setNew_Files([...new_files, res.file]);
                    setInfoObj({ ...infoObj, files: files });
                } else {
                    dispatch(setErrorMessage(true, false, t("game_info_err_background")));
                }
            } else {
                dispatch(setErrorMessage(true, false, t("file_size_limit")));
            }
        });
    };

    useEffect(() => {
        if (new_files.length > 0 && newFile) {
            editGameInfo();
            openAndCloseAnimation(editGame, { id: infoObj.id, files: new_files }, ["files"]);
            setNewFile(false);
            setNew_Files([]);
        }
    }, [new_files?.length])

    useEffect(() => {
        setFiles(infoObj.files);
    }, [infoObj?.files?.length])

    const printIcon = (type) => {
        if ((type.indexOf("jpg") >= 0) || (type.indexOf("png") >= 0) || (type.indexOf("jpeg") >= 0)) {
            return images.filePhotoIcon;
        } else if ((type.indexOf("mp4") >= 0)) {
            return images.fileVideoIcon;
        } else if ((type.indexOf("mp3") >= 0)) {
            return images.fileSoundIcon;
        } else {
            return images.fileDocIcon;
        }
    }

    useEffect(() => {
        if (infoObj?.video) {
            let obj = createFileObject(false, infoObj.video);
            setPreVideo(obj);
        }
    }, [infoObj?.video]);

    const deleteDoc = (id) => {
        editGameInfo();
        openAndCloseAnimation(deleteBackgroundFile, id);
    }

    const checkVideoIsExist = (video) => {
        if (video?.link) {
            video = video.link
            let length = video?.length;
            length = length - video.lastIndexOf(".");
            if (length === 4 || length === 5) {
                return true;
            } else {
                return false;
            }
        } else {
            if (video?.file) {
                return true;
            } else {
                return false;
            }
        }
    }

    const fileDesc = (i) => {
        // let t = parseInt(refItem?.current[i]?.getBoundingClientRect()?.top - popupRef.current?.getBoundingClientRect()?.top);
        // let l = parseInt(popupRef?.current?.getBoundingClientRect()?.right - refItem?.current[i]?.getBoundingClientRect()?.right);
        return (
            <View nativeID="files-des" style={[styles.des_container, {
                // top: t + refItem?.current[i]?.getBoundingClientRect().height / 2,
                // right: l + refItem?.current[i]?.getBoundingClientRect().width / 2,
            }]}>
                <View
                    style={[
                        styles.traingular,
                        { borderBottomColor: background.filesDes },
                    ]}
                />
                <View
                    style={[
                        styles.des_view,
                        { backgroundColor: background.filesDes },
                    ]}
                >
                    <Text
                        selectable={false}
                        numberOfLines={1}
                        style={[styles.des_text, { color: color.filesText }]}
                    >
                        {"Name: " + (files[i]?.file ? files[i]?.file?.name : files[i]?.name)}
                    </Text>
                    <Text
                        selectable={false}
                        numberOfLines={1}
                        style={[styles.des_text, { color: color.filesText }]}
                    >
                        {"Type: " + (files[i]?.file ? files[i]?.mimeType : files[i]?.type)}
                    </Text>
                </View>
            </View>
        )
    }


    return (
        <View style={styles.container} ref={popupRef}>
            <View style={[styles.fileIntro, { backgroundColor: background.infoBox }]}>
                <Pressable
                    style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
                    disabled={!checkVideoIsExist(preVideo)}
                    onPress={() => {
                        if (preVideo) {
                            dispatch(showFile({ name: t("_video"), type: preVideo.mimeType ? preVideo.mimeType : preVideo.type, link: preVideo.uri ? preVideo.uri : preVideo.link }));
                        }
                    }}
                >
                    <Image source={{ uri: images.fileVideoIcon }} style={styles.videoIcon} />
                    <Text style={[styles.headers1, { color: color.infoHeaderNavText, margin: 0 }]}>{preVideo?.name ? preVideo.name : t("pre_video")}</Text>
                </Pressable>
                {(checkVideoIsExist(preVideo) && permission) &&
                    <Pressable
                        style={[styles.xIconView, { backgroundColor: background.infoBody }]}
                        onPress={async () => { removeVideo(); setPreVideo() }}>
                        <Image source={{ uri: images.plusIcon }} style={[styles.xIcon]} />
                    </Pressable>}
                {(!checkVideoIsExist(preVideo) && permission) &&
                    <Pressable
                        ref={videoRef}
                        style={[styles.plusIconView, { backgroundColor: background.infoBody }]}
                        onPress={async () => addPreVideo()}
                        onHoverIn={() => { setShowFileLimit({ ...showFileLimit, video: true }) }}
                        onHoverOut={() => { setShowFileLimit({ ...showFileLimit, video: false }) }}>
                        {({ hovered }) => (<>
                            <Image source={{ uri: images.plusIcon }} style={styles.plusIcon} />
                            {hovered && (
                                <FileLimitPopup
                                    refObj={videoRef}
                                />
                            )}
                        </>)}
                    </Pressable>}
            </View>
            <View style={styles.addFilesView}>
                <Text style={[styles.headers, { color: color.infoHeaderNavText, marginLeft: 0 }]}>{t("background_files")}</Text>
                {permission && <Pressable
                    ref={fileRef}
                    style={[styles.plusIconView, { backgroundColor: background.infoBox }]}
                    onPress={() => openFilesBrowser()}
                    onHoverIn={() => { setShowFileLimit({ ...showFileLimit, file: true }) }}
                    onHoverOut={() => { setShowFileLimit({ ...showFileLimit, file: false }) }}>
                    <Image source={{ uri: images.plusIcon }} style={styles.plusIcon}
                    />
                    {showFileLimit.file && (
                        <FileLimitPopup
                            refObj={fileRef}
                        />
                    )}
                </Pressable>}
            </View>
            <ScrollView nativeID='file' style={[styles.filesBox, { backgroundColor: background.infoBox }]} contentContainerStyle={styles.filesBoxMain}>
                {files && files?.map((file, i) => {
                    let name = file?.file?.name ? ShortenWord(file?.file?.name, 12, true) : ShortenWord(file.name, 12, true);
                    return (
                        <>
                            <Pressable
                                ref={(ref) => refItem.current[i] = ref}
                                style={({ hovered }) => [styles.fileItem, { zIndex: hovered ? 2 : 1 }]}
                                key={i}
                                // onHoverIn={() => { setHover(true); setIndex(i) }}
                                // onHoverOut={() => setHover(false)}
                                onPress={() => {
                                    if (file.file) {
                                        dispatch(showFile({ name: file.file.name, type: file.mimeType, link: file.uri }));
                                    } else {
                                        dispatch(showFile({ name: file.name, type: file.type, link: file.link }));
                                    }
                                }}>
                                {({ hovered }) => (<> {permission && <Pressable style={[styles.delete_circle]} onPress={() => { deleteDoc(file?.id) }}>
                                    <Image source={{ uri: images.plusIcon }} style={styles.x_button_text} />
                                </Pressable>}
                                    <Image source={{ uri: printIcon(file?.file?.type ? file?.file?.type : file.type) }} style={styles.icon} />
                                    <Text style={[styles.fileName, { color: color.infoHeaderNavText }]}>{name}</Text>
                                    {hovered && fileDesc(i)}
                                </>)}
                            </Pressable>
                        </>
                    )
                })}
            </ScrollView>
            {/* {hover && index >= 0 && fileDesc(index)} */}
        </View>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            height: "100%",
            width: "45%",
        },
        des_container: {
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            width: "3vw",
            height: "4vh",
            zIndex: 15,
        },
        traingular: {
            width: 0,
            height: 0,
            borderLeftWidth: widthS * 8,
            borderRightWidth: widthS * 8,
            borderBottomWidth: widthS * 8,
            borderStyle: "solid",
            backgroundColor: "transparent",
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            zIndex: 4,
            marginLeft: widthS * 22,
        },
        des_view: {
            marginTop: height - 2,
            width: "10vw",
            padding: "0.5vw",
            borderRadius: widthS * 3,
            minHeight: "4vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 11,
        },
        des_text: {
            textAlign: "left",
            fontSize: widthS * 7,
            width: "90%",
            fontFamily: "Catamaran_400Regular",
        },
        fileIntro: {
            height: heightS * 50,
            borderRadius: widthS * 13,
            marginTop: heightS * 22,
            marginBottom: heightS * 7,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: widthS * 17,
            paddingRight: widthS * 10
        },
        addFilesView: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: widthS * 8,
            marginRight: widthS * 10,
            marginTop: heightS * 8,
            marginBottom: heightS * 3,
            zIndex: 1
        },
        filesBox: {
            height: heightS * 125,
            borderRadius: widthS * 13,
            zIndex: -1,
        },
        filesBoxMain: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: "wrap",
        },
        headers: {
            fontSize: widthS * 12,
            marginLeft: widthS * 20,
            fontFamily: "Catamaran_500Medium",
            textAlign: "center"
        },
        headers1: {
            fontSize: widthS * 12,
            marginLeft: widthS * 20,
            marginBottom: heightS * 3,
            fontFamily: "Catamaran_500Medium"
        },
        videoIcon: {
            width: widthS * 15,
            height: heightS * 15,
            resizeMode: "contain",
            marginRight: widthS * 8
        },
        plusIconView: {
            padding: widthS * 5,
            borderRadius: "50%",
        },
        plusIcon: {
            width: widthS * 8,
            height: widthS * 8,
            resizeMode: "contain",
        },
        fileItem: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingHorizontal: "0.5vw",
            height: "5vw",
            justifyContent: "flex-end",
            width: "6vw",
        },
        icon: {
            width: "1.3vw",
            height: "1.3vw",
            resizeMode: "contain",
            marginBottom: heightS * 2,
        },
        fileName: {
            fontSize: widthS * 9,
            fontFamily: "Catamaran_400Regular",
            textAlign: "center"
        },
        delete_circle: {
            borderRadius: "1vw",
            marginHorizontal: "0.2vw",
            position: "relative",
            top: "0.6vw",
            left: "0.8vw",
            zIndex: "2",
            width: "1vw",
            height: "1vw",
            backgroundColor: "black",
            alignItems: "center",
            justifyContent: "center",
        },
        x_button_text: {
            width: "0.6vw",
            height: "0.6vw",
            transform: [{ rotate: "45deg" }]
        },
        xIconView: {
            padding: widthS * 5,
            borderRadius: "50%",
        },
        xIcon: {
            width: widthS * 8,
            height: widthS * 8,
            resizeMode: "contain",
            transform: "rotate(45deg)",
        },
        item_view: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingVertical: heightS * 9,
            paddingHorizontal: widthS * 6,
            position: "relative",
        },
        item_text: {
            width: widthS * 55,
            fontSize: widthS * 9,
            textAlign: "center",
            fontFamily: "Catamaran_400Regular",
            lineHeight: heightS * 10,
        },
    })
}