import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/actions';
import { URL_PATH, CHECK_SESSION } from "@env";
import { F_PHPSESSID, USER, USER_ID_IN_GAME, _F_PHPSESSID_ } from '../config.inc';

export const UserContext = createContext();

export default function UserProvider(props) {
    const dispatch = useDispatch();

    const setDataUser = () => {
        if (sessionStorage.getItem(USER)) {

        }
    }

    const changeUserdetails = (user) => {
        const body = new FormData();
        if (user.firstName) {
            body.append("first_name", user.firstName);
        }
        if (user.lastName) {
            body.append("last_name", user.lastName);
        }
        if (user.phone) {
            body.append("phone", user.phone);
        }
        if (user.jobTitle) {
            body.append("job_title", user.jobTitle);
        }
        if (user.company) {
            body.append("company", user.company);
        }
        const url = ``;
        const requestOption = {
            method: "POST",
            body: body
        }
        try {
            fetch(url, requestOption)
                .then((res) => res.json())
                .then((res) => {
                    res = JSON.parse(res);
                })
                .catch((err) => {

                })
        } catch (e) {

        }
    }

    const checkSession = () => {
        return new Promise((resolve, reject) => {
            const url = `${URL_PATH}${CHECK_SESSION}`;
            const body = new FormData();
            body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
            const requestOption = {
                method: "POST",
                body: body
            }
            fetch(url, requestOption)
                .then((res) => res.json())
                .then((res) => {
                    if (res?.status !== "false") {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    }

    useEffect(() => {
        setDataUser();
    }, []);
    return (
        <UserContext.Provider value={{ setDataUser, changeUserdetails, checkSession }}>
            {props.children}
        </UserContext.Provider>
    );
}
