import { StyleSheet, Text, View, Modal, Image, Pressable, get, TextInput } from 'react-native';
import React, { useState, useRef, useEffect } from 'react';
import UseResponsiveScreen from '../modules/UseResponsiveScreen';
import { useDispatch, useSelector } from 'react-redux';
import * as DocumentPicker from "expo-document-picker";
import { t } from 'i18n-js';
import SvgImageDisplay from "../Elements/SvgImageDisplay";
import MouseCursor from '../Elements/MouseCursor';
import { getPixelColor } from "../modules/GetColorPixel";
import { setCostumColors, setErrorMessage } from '../../redux/actions';
import Wheel from '@uiw/react-color-wheel';
import ShadeSlider from '../Elements/ShadeSlider';
import { LightenDarkenColor } from '../modules/LightenDarkenColor';
import { convertByte } from "../modules/ConvertByte";
import { adjustColor } from '../modules/ChangeColorToMiddle';
import ColorPicker from '../../componnets/react-native-wheel-color-picker';
import { createFileObject } from '../modules/CreateFileObject';
import { MAX_FILES_SIZE } from "@env";
import DropFiles from '../Elements/DropFiles';
import { useClickOutside } from "../modules/useClickOutside"


export default function AddLogo({ flag, setFlag, addLogoFlag, infoObj, setInfoObj, newGame }) {

    const imgRef = useRef(null);
    const dropRef = useRef(null);
    const displayRef = useRef(null);
    const dispatch = useDispatch();

    const color = newGame ? useSelector((state) => state.style.colorClient) : useSelector((state) => state.style.color);
    const background = newGame ? useSelector((state) => state.style.backgroundClient) : useSelector((state) => state.style.background);
    const { heightS, widthS } = UseResponsiveScreen();
    const styles = stylesR(heightS, widthS, background, color);

    const images = useSelector((state) => state.style.images);
    const [image, setImage] = useState();
    const [showPicker, setShowPicker] = useState(true);
    const [primaryColors, setPrimaryColors] = useState([
        {
            value: null, value1: null, name: "Primary Color", current: null
        },
        { value: null, value1: null, name: "Secondary Color", current: null },
        { value: null, value1: null, name: "Additional Color", current: null }]);
    const [position, setPosition] = useState(null);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [colorPrimary, setColorPrimary] = useState(primaryColors[position - 1]?.value);
    const [showMouse, setShowMouse] = useState(false);
    const [drag, setDrag] = useState(false);

    const openFilesBrowser = () => {
        const types =
            "image/png, image/jpeg";
        const typesArray = types.split(", ");
        DocumentPicker.getDocumentAsync({
            type: types,
            multiple: false
        }).then((res) => res.assets[0])
            .then((res) => {
                let size = convertByte(res.size);
                if (typesArray.indexOf(res.mimeType) === -1) {
                    return dispatch(
                        setErrorMessage(true, false, t("new_game_err_file"))
                    );
                } else if (size > MAX_FILES_SIZE) {
                    return dispatch(setErrorMessage(true, false, t("err_fileSize_mail")))
                }
                setImage(res);
            });
    };

    useEffect(() => {
        if (position && addLogoFlag && imgRef.current && image) {
            imgRef.current.addEventListener("mouseenter", () => {
                setShowMouse(true);
            });
            imgRef.current.addEventListener("mouseleave", () => {
                setShowMouse(false);
            });
            imgRef.current.addEventListener("mousemove", (e) => {
                if (image) {
                    setX(e.layerX);
                    setY(e.layerY);
                    setColorPrimary(getPixelColor(e.layerX, e.layerY));
                }
            });
        }

    }, [position, imgRef.current, image]);

    useEffect(() => {
        if (!addLogoFlag) {
            setTimeout(() => {
                setColorPrimary(primaryColors[position - 1]?.value);
            }, 1);
        }
    }, [position, primaryColors]);

    // useEffect(() => {
    //     return () => {
    //         setInfoObject();
    //     }
    // }, []);

    useEffect(() => {
        if (infoObj?.game_logo) {
            let logo;
            if (infoObj.game_logo?.file) {
                logo = infoObj.game_logo;
            } else if (typeof infoObj.game_logo !== 'string' && infoObj.game_logo.link) {
                logo = infoObj.game_logo;
            } else {
                logo = createFileObject(false, infoObj.game_logo?.uri || infoObj.game_logo);
            }
            setImage(logo);
        }
        if (infoObj?.game_colors) {
            let arr = infoObj?.game_colors?.split(",");
            if (arr?.length > 0) {
                primaryColors.forEach((colorP, i) => {
                    colorP.value = `#${arr[i]}`;
                    colorP.value1 = `#${arr[i]}`;
                    colorP.current = `#${arr[i]}`;
                });
                setPrimaryColors([...primaryColors]);
            }
        }
    }, [infoObj]);

    useClickOutside(displayRef, position !== null, () => setPosition(null));

    const imageDisplay = (
        <View style={[styles.image, { backgroundColor: background.loginInput }]}>
            <Pressable onPress={(e) => {
                if (!image) {
                    openFilesBrowser()
                } else if (position) {
                    primaryColors[position - 1].current = colorPrimary;
                    setPrimaryColors([...primaryColors]);
                    setPosition();
                }
            }}
                ref={imgRef}
                style={{
                    alignItems: "center",
                    position: "relative",
                    justifyContent: "center",
                    flex: 1,
                    opacity: drag ? 0.3 : 1,
                    cursor: position && image ? "none" : ""
                }}>
                {image && imgRef.current ?
                    <>
                        <Pressable
                            style={[styles.xIconView, { backgroundColor: background.popupLogin }]}
                            onPress={() => setImage()}>
                            <Image source={{ uri: images.plusIcon }} style={[styles.xIcon]} />
                        </Pressable>
                        <SvgImageDisplay src={image?.uri || image?.link} width={imgRef?.current?.offsetWidth} height={imgRef?.current?.offsetHeight} />
                        {position && showMouse && <MouseCursor x={x} y={y} src={image?.uri || image?.link} color={colorPrimary} />}
                    </>
                    : <>
                        <DropFiles typesProps={"image/png, image/jpg, image/jpeg"} errorTypeProps={"new_game_err_file"} dropRef={dropRef} setDrag={setDrag} setImage={setImage} />
                        <Image source={{ uri: images.filePhotoIcon }} style={{ resizeMode: "contain", width: widthS * 55, height: widthS * 55 }} />
                        <Text style={[styles.browseText, { color: color.createNewGame }]}>{t("browse_or_drop")}</Text>
                    </>}
            </Pressable>
        </View>
    );

    const isColor = (strColor) => {
        const s = new Option().style;
        s.color = strColor;
        return s.color !== '';
    }

    const pickerDisplay = (
        <View ref={displayRef} style={[styles.image, { backgroundColor: background.loginInput }]}>
            <Pressable onPress={(e) => {
            }}
                style={{
                    alignItems: "center",
                    position: "relative",
                    justifyContent: "center",
                    flex: 1,
                    opacity: drag ? 0.3 : 1,
                    cursor: position && image && addLogoFlag ? "none" : ""
                }}>
                {showPicker &&
                    <ColorPicker
                        color={isColor(primaryColors[position - 1]?.value) ? primaryColors[position - 1]?.value : "#fff"}
                        onColorChange={(e) => {
                            if (position) {
                                primaryColors[position - 1].value = e;
                                primaryColors[position - 1].current = e;
                                primaryColors[position - 1].value1 = LightenDarkenColor(e, 0);
                                setPrimaryColors([...primaryColors]);
                            }
                        }}
                        row={true}
                        noSnap={false}
                        thumbSize={15}
                        sliderSize={40}
                        swatches={false}
                    />}
            </Pressable>
        </View>
    );

    const colorsPrimary = (
        <View ref={displayRef} style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "75%", marginTop: heightS * 12 }}>
            {primaryColors.map((colorP, i) => {
                return (
                    <Pressable key={i + 1} onPress={() => setPosition(i + 1)} style={[styles.primaryItem, { backgroundColor: background.infoBody, borderColor: position === (i + 1) ? background.loginFooter : background.infoBody, justifyContent: colorP.value ? "space-between" : "center" }]}>
                        <View style={styles.primaryTextView}>
                            <Text style={styles.primaryTextInput(false)}>#</Text>
                            <TextInput style={[styles.primaryTextInput(true)]}
                                onFocus={() => setShowPicker(false)}
                                onBlur={() => setShowPicker(true)}
                                onChangeText={(e) => {
                                    if (position) {
                                        primaryColors[position - 1].value = `#${e}`;
                                        primaryColors[position - 1].current = `#${e}`;
                                        primaryColors[position - 1].value1 = LightenDarkenColor(`#${e}`, 0);
                                        setPrimaryColors([...primaryColors]);
                                    }
                                }}
                                maxLength={6} value={colorP.current ? colorP.current.replace("#", "") : colorP.name.replace("#", "")} />
                        </View>
                        {colorP.current && <View style={{ width: widthS * 10, height: heightS * 10, borderRadius: widthS * 1, backgroundColor: colorP.current }} />}
                    </Pressable>
                )
            })}
        </View>
    )

    const footerButtons = (
        <View style={{ position: "relative", marginTop: heightS * 15 }}>
            <Pressable
                style={[styles.btn, { backgroundColor: background.loginFooter }]}
                disabled={primaryColors.find((e) => e.value === null)}
                onPress={() => {
                    let temp = [...primaryColors];
                    // temp.forEach((e, i) => {
                    //     temp[i].value = `#${adjustColor(temp[i].value.slice(1))}`;
                    // });
                    if (addLogoFlag) {
                        setInfoObj({ ...infoObj, game_logo: image, game_colors: `${temp[0].current?.replace("#", "")},${temp[1].current?.replace("#", "")},${temp[2].current?.replace("#", "")}` });
                    } else {
                        setInfoObj({ ...infoObj, game_colors: `${temp[0].current?.replace("#", "")},${temp[1].current?.replace("#", "")},${temp[2].current?.replace("#", "")}` });

                    }
                    // dispatch(setCostumColors({
                    //     color1: temp[0].value,
                    //     colorShade1: LightenDarkenColor(temp[0].value, 0),
                    //     colorCurrent1: temp[0].value,
                    //     color2: temp[1].value,
                    //     colorShade2: LightenDarkenColor(temp[1].value, 0),
                    //     colorCurrent2: temp[1].value,
                    //     color3: temp[2].value,
                    //     colorShade3: LightenDarkenColor(temp[2].value, 0),
                    //     colorCurrent3: temp[2].value,
                    // }));
                    setFlag();
                    setImage();
                }
                }
            >
                {
                    <Text style={[{ color: color.text2 }, styles.btn_text]}>
                        {`${t("create")}`}
                    </Text>
                }
            </Pressable>
            <Pressable
                style={[styles.btn, { position: "absolute", left: widthS * -70 }]}
                onPress={() => { setFlag(); setImage() }}
            >
                {
                    <Text style={[{ color: "#534d58" }, styles.btn_text]}>
                        {`${t("cancel")}`}
                    </Text>
                }
            </Pressable>
        </View>
    )

    return (
        <Modal transparent={true} visible={flag}>
            <View style={styles.container}>
                <View style={[styles.main, { backgroundColor: background.popupLogin }]}>
                    {/* image */}
                    {addLogoFlag ?
                        imageDisplay
                        :
                        pickerDisplay
                    }
                    {/* primary colors */}
                    {colorsPrimary}
                    {/* buttons */}
                    {footerButtons}
                </View>
            </View>
        </Modal>
    )
}

const stylesR = (heightS, widthS, background, color) => {
    return StyleSheet.create({
        container: {
            backgroundColor: "#0000004a",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000
        },
        main: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "33vw",
            minHeight: "30vw",
            borderRadius: widthS * 25,
        },
        image: {
            width: "75%",
            marginTop: heightS * 40,
            height: heightS * 180,
            borderRadius: widthS * 10
        },
        browseText: {
            fontSize: widthS * 12,
            fontWeight: "normal",
            textAlign: "center",
            marginVertical: heightS * 5,
            fontFamily: "Catamaran_400Regular"
        },
        primaryItem: {
            height: heightS * 28,
            borderRadius: widthS * 8,
            borderWidth: widthS * 1,
            paddingHorizontal: widthS * 5,
            width: "32%",
            fontSize: widthS * 12,
            fontWeight: "300",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            position: "relative",
            zIndex: 2,
        },
        primaryTextView: {
            flexDirection: "row",
            alignItems: "center"
        },
        primaryTextInput: (flag, focused) => ({
            fontSize: "0.85vw",
            fontWeight: "normal",
            textAlign: "center",
            fontFamily: "Catamaran_400Regular",
            color: color.text2,
            width: flag ? "3.2vw" : "",
            outlineStyle: "none",
            marginRight: !flag ? "0.2vw" : "",
        }),
        btn: {
            borderStyle: "solid",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: heightS * 6,
            width: widthS * 90,
            height: heightS * 28,
            borderRadius: widthS * 7.3,
        },
        btn_text: {
            fontSize: widthS * 11,
            fontWeight: "normal",
            fontFamily: "Catamaran_400Regular",
        },
        xIconView: {
            position: "absolute",
            right: "1vw",
            top: "1vw",
            padding: widthS * 5,
            borderRadius: "50%",
        },
        xIcon: {
            width: widthS * 8,
            height: widthS * 8,
            resizeMode: "contain",
            transform: "rotate(45deg)",
        },
    });
}


