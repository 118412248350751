import React, { useEffect, useState, useRef } from "react";
import { StyleSheet, View, Text, Pressable, Modal, Image } from "react-native";
import {
  AntDesign,
  Ionicons,
  Entypo,
  MaterialIcons,
  FontAwesome,
} from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLangFlag,
  changeLangType,
  destroySession,
  setLogin,
  settingFlag,
  setToken,
} from "../../redux/actions";
import {
  USER_GAME_NAME,
} from "../../config.inc";
import { t } from "i18n-js";
import { listenerAdd, listenerAddBT } from "../../AddListener";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';
import useExitGame from "../modules/ExitGame";
import { getLang } from "../../getLang";


export default function FirstItem({ closeWebSocket }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const exitGame = useExitGame();

  const divRef = useRef(null);
  const dispatch = useDispatch();
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const langFlag = useSelector((state) => state.setting.flags.changeLang);
  const lang = useSelector((state) => state.setting.setting.lang);
  const images = useSelector((state) => state.style.images);
  const userImage = useSelector(
    (state) => state.user.user.profile_image
  );
  const [showDropDownPopup, setShowDropDownPopup] = useState(false);
  const flags = [
    { name: "English", value: "english" },
    { name: "Deutsch", value: "german" },
    { name: "Español", value: "spanish" },
    { name: "Français", value: "french" },
    { name: "Hebrew", value: "hebrew" },
    { name: "Italiano", value: "italian" },
    { name: "Thai", value: "thai" }
  ];


  const closeDropDownPopup = () => {
    dispatch(changeLangFlag(false));
    setShowDropDownPopup(!showDropDownPopup);
  };
  useEffect(() => {
    document.addEventListener(
      "mousedown",
      listenerAdd(divRef, showDropDownPopup, closeDropDownPopup)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        listenerAddBT(divRef, showDropDownPopup, closeDropDownPopup)
      );
    };
  }, [showDropDownPopup]);

  return (
    <View style={[styles.container, { borderBottomColor: color.border }]}>
      <View
        style={[
          styles.circle,
          { borderColor: color.rightMenuGameNameAndUserName },
        ]}
      >
        <Image
          source={{ uri: userImage ? userImage : images.user_image }}
          style={styles.image_circle}
        />
      </View>
      <View style={styles.left}>
        <View>
          <Text
            selectable={false}
            style={[styles.textTop, { color: "#fefefe" }]}
          >
            {t("right-menu_player-name")}
          </Text>
        </View>
        <View>
          <Pressable style={styles.bottom} onPress={() => closeDropDownPopup()}>
            <Text
              selectable={false}
              style={[styles.textBottom, { color: "#a29ca2" }]}
            >
              {checkIfIsEncodeText(sessionStorage.getItem(USER_GAME_NAME), "string")}
            </Text>
            <AntDesign
              name="caretdown"
              color={"#a29ca2"}
              size={"0.7vw"}
              style={{ alignSelf: "center" }}
            />
          </Pressable>
        </View>
      </View>
      <Modal
        animationType="none"
        transparent={true}
        visible={showDropDownPopup}
      >
        <View style={styles.model}>
          <View style={styles.modelView}>
            <View
              style={[
                styles.traingular,
                { borderBottomColor: background.rightMenuPopupSetting },
              ]}
            />
            <View
              style={[
                styles.popup,
                { backgroundColor: background.rightMenuPopupSetting },
              ]}
              ref={divRef}
            >
              {/* <Pressable
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? background.rightMenuPopupSettingHover
                      : "",
                  },
                  styles.popupPress,
                ]}
                onPressIn={() => {
                  dispatch(settingFlag());
                  closeDropDownPopup();
                }}
              >
                <Ionicons
                  name="settings-sharp"
                  color="#dcdcdc"
                  size={widthS * 10}
                />
                <Text selectable={false} style={styles.popupText}>
                  {t("settings")}
                </Text>
              </Pressable> */}
              <Pressable
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? background.rightMenuPopupSettingHover
                      : "",
                  },
                  styles.popupPress,
                ]}
                onPress={() => {
                  exitGame();
                }}
              >
                <Entypo name="cycle" color="#dcdcdc" size={widthS * 10} />
                <Text selectable={false} style={styles.popupText}>
                  {t("switch-game")}
                </Text>
              </Pressable>
              {/* removed help button until we get the new help page */}
              {/* <Pressable
								style={({ hovered }) => [
									{ backgroundColor: hovered ? background.rightMenuPopupSettingHover : "" },
									styles.popupPress,
								]}
								onPressIn={() => {dispatch(helpFlag()); closeDropDownPopup()}}
							>
								<Entypo name="help" color="#dcdcdc" size={widthS * 10} />
								<Text selectable={false} style={styles.popupText}>{t("help")}</Text>
							</Pressable> */}
              {/* <Pressable
                style={({ hovered }) => [
                  {
                    backgroundColor:
                      hovered || langFlag
                        ? background.rightMenuPopupSettingHover
                        : "",
                  },
                  styles.popupPress,
                ]}
                onPressIn={() => {
                  dispatch(changeLangFlag(!langFlag));
                }}
              >
                <MaterialIcons
                  name="language"
                  color="#dcdcdc"
                  size={widthS * 10}
                />
                <Text selectable={false} style={styles.popupText}>
                  {t("language")}
                </Text>
              </Pressable> */}
              {langFlag && (
                <View style={{ marginVertical: widthS * 1 }}>
                  {flags.map((e, i) => {
                    return (
                      <Pressable
                        key={i}
                        style={({ hovered }) => [
                          {
                            backgroundColor:
                              hovered || lang === e.value
                                ? background.rightMenuPopupSettingHover
                                : "",
                          },
                          styles.popupPressLang,
                        ]}
                        onPress={() => {
                          dispatch(changeLangType(getLang(e.value)));
                          dispatch(changeLangFlag());
                        }}
                      >
                        <Text
                          selectable={false}
                          style={styles.lang_option_text}
                        >
                          {e.name}
                        </Text>
                      </Pressable>
                    );
                  })}
                </View>
              )}
              <Pressable
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? background.rightMenuPopupSettingHover
                      : "",
                  },
                  styles.popupPress,
                ]}
                onPress={() => {
                  exitGame(true);
                }}
              >
                <FontAwesome
                  name="sign-out"
                  color="#dcdcdc"
                  size={widthS * 10}
                />
                <Text selectable={false} style={styles.popupText}>
                  {t("sign-out")}
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const stylesR = (width, height, heightS, widthS) => {
  return StyleSheet.create({
    container: {
      borderBottomWidth: 2,
      borderStyle: "solid",
      padding: widthS * 10,
      display: "flex",
      flexDirection: "row",
      paddingLeft: widthS * 20,
      alignItems: "center",
      position: "relative",
    },
    circle: {
      borderWidth: 2,
      borderStyle: "solid",
      textAlign: "center",
      borderRadius: "50%",
      justifyContent: "center",
    },
    image_circle: {
      width: widthS * 19,
      height: widthS * 19,
      borderRadius: "50%"
    },
    left: {
      marginLeft: widthS * 5,
    },
    textTop: {
      fontFamily: "Catamaran_600SemiBold",
      fontWeight: "600",
      lineHeight: "1.3vw",
      fontSize: widthS * 8.5,
    },
    bottom: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    textBottom: {
      lineHeight: "1.1vw",
      fontSize: "1.1vw",
      marginRight: 4,
      fontWeight: "400",
      fontFamily: "Catamaran_400Regular",
    },
    containerPopup: {
      position: "absolute",
      top: 45,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      right: widthS * -60,
      zIndex: 1,
      elevation: 100,
    },
    model: {
      position: "relative",
    },
    modelView: {
      position: "absolute",
      right: widthS * 70,
      top: heightS * 28,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    traingular: {
      width: 0,
      height: 0,
      borderLeftWidth: widthS * 10,
      borderRightWidth: widthS * 10,
      borderBottomWidth: widthS * 10,
      borderStyle: "solid",
      backgroundColor: "transparent",
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      zIndex: 3,
    },
    popup: {
      marginTop: -1,
      padding: widthS * 3,
      width: widthS * 135,
      zIndex: 1,
      borderRadius: widthS * 7,
    },
    popupPress: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: widthS * 4,
    },
    popupPressLang: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: widthS * 4,
      paddingVertical: heightS * 2,
    },
    popupText: {
      color: "#dcdcdc",
      fontSize: widthS * 8,
      fontFamily: "Catamaran_400Regular",
      marginLeft: widthS * 5,
    },
    lang_option_text: {
      color: "#dcdcdc",
      fontSize: widthS * 7,
      marginLeft: widthS * 16,
      fontFamily: "Catamaran_400Regular",
    },
  });
};
