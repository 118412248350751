import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { View, Pressable, StyleSheet, Image } from 'react-native';
import { t } from 'i18n-js';
import { useMemo } from 'react';
import { useCallback } from 'react';

const PlatformTypeCell = ({ type, cellStyle, index, updateAction, isDone, typeAction, isNew }) => {

    const { images, background, color } = useSelector(state => ({
        images: state.style.images,
        background: state.style.background,
        color: state.style.color
    }));

    const styles = stylesR(background, color);

    const platformTypes = useMemo(() => [
        { label: t("mailbox"), value: "mail", icon: images.mail },
        { label: t("social-media"), value: "sm", icon: images.social },
        { label: t("news"), value: "news", icon: images.news }], [images, t]);

    const onClick = useCallback((btn) => {
        const updates = {};
        if (btn.value === "news") {
            updates.from_id = sessionStorage.getItem("id_in_game");
        }
        updates.platform_type = btn.value;
        updateAction(index, updates, !isNew, isNew);
    }, [index, isNew]);

    return (
        <View style={cellStyle("7vw")}>
            <View style={styles.main}>
                {platformTypes.map((btn, i) => {
                    return (<Pressable
                        disabled={isDone || typeAction !== "Message" && btn.value !== "mail"}
                        key={i}
                        onPress={() => onClick(btn)}
                        style={styles.btn(type === btn.value, typeAction !== "Message" && btn.value !== "mail")}>
                        <Image source={{ uri: btn.icon }} style={styles.icon} />
                    </Pressable>)
                })}
            </View>
        </View>
    )
};

const stylesR = (background, color) => StyleSheet.create({
    main: {
        flexDirection: "row",
        alignItems: "center",
        columnGap: "0.3vw",
        justifyContent: "center",
        flex: 1
    },
    btn: (flag, opacity) => ({
        backgroundColor: flag ? color.logoColor : background.infoBody,
        width: "1.8vw",
        height: "1.8vw",
        borderRadius: "100%",
        alignItems: "center",
        opacity: opacity ? 0.4 : 1,
        justifyContent: "center",
    }),
    icon: {
        width: "0.9vw",
        height: "0.9vw",
        tintColor: color.text,
        resizeMode: "contain"
    }
});
export default memo(PlatformTypeCell);