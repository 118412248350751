import { StyleSheet, Text, View, Animated, PanResponder } from 'react-native';
import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeColumnPosition, changePositionRealPlayers, changePositionRepPlayers } from '../../redux/actions/creatorActions';
import { ZoomStatic } from './ZoomStatic';
import { SwimlaneChartContext } from '../../ContextData/Swimlanechart';

export default function DraggedColumn({ children, x, y, index, indexFlag, setIndexflag, type, id, arrLength, itemRef, flowTableSteps }) {

    const pan = useRef(new Animated.ValueXY()).current;
    const actionsArrState = useSelector((state) => state.swimlane.actions);
    const { addAction } = useContext(SwimlaneChartContext);

    const zoomSize = useSelector(state => state.swimlane.zoom);
    y = ZoomStatic(y, zoomSize);
    x = ZoomStatic(x, zoomSize);
    const dispatch = useDispatch();
    const [opacity, setOpacity] = useState(false);

    const responder = useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: () => true,
            onPanResponderGrant: () => {
                setOpacity(true);
                if (type) {
                    pan.setOffset({
                        y: index === indexFlag && pan.y._value,
                    });
                } else {
                    pan.setOffset({
                        x: index === indexFlag && pan.x._value,
                    });
                }
            },
            onPanResponderMove: (evt, gesture) => {
                return Animated.event(
                    [
                        null,
                        { dx: pan.x, dy: pan.y },
                    ],
                    {
                        useNativeDriver: true
                    }
                )(evt, gesture)
            },
            onPanResponderEnd: () => setOpacity(false),
            onPanResponderRelease: (event, state) => {

                if (type) {
                    if (state.dy > 0 && Math.floor(state.dy / y) > 0) {
                        let move1 = Math.floor(state.dy / y) + index;
                        move1 = move1 > arrLength ? arrLength : move1;
                        dispatch(type === "real" ? changePositionRealPlayers(index, move1) : changePositionRepPlayers(index, move1));
                    }
                    if (state.dy < 0 && Math.floor((state.dy - (state.dy * 2)) / y) > 0) {
                        let move2 = state.dy - (state.dy * 2);
                        move2 = index - Math.floor(move2 / y);
                        move2 = move2 < 0 ? 0 : move2;
                        dispatch(type === "real" ? changePositionRealPlayers(index, move2) : changePositionRepPlayers(index, move2));
                    }
                } else {
                    if (state.dx > 0 && Math.floor(state.dx / x) > 0) {
                        let move1 = Math.floor(state.dx / x) + index;
                        // move1 = move1 > arrLength ? arrLength : move1;
                        changePosition(index, move1, actionsArrState, flowTableSteps).then(() => {
                            // dispatch(changeColumnPosition(index, move1));

                        });
                    }
                    if (state.dx < 0 && Math.floor((state.dx - (state.dx * 2)) / x) > 0) {
                        let move2 = state.dx - (state.dx * 2);
                        move2 = index - Math.floor(move2 / x);
                        move2 = move2 < 0 ? 0 : move2;
                        changePosition(index, move2, actionsArrState, flowTableSteps).then(() => {
                            // dispatch(changeColumnPosition(index, move2));
                        });
                    }
                }
                pan.setValue({ x: 0, y: 0 });
                pan.flattenOffset();
                setIndexflag();
            }
        })
    ).current;

    const changePosition = (index, move, actionsArrState, flowTableSteps) => {
        return new Promise((reslove, reject) => {
            actionsArrState.forEach((e) => {
                if (+e.time === flowTableSteps[index]) {
                    let current = e;
                    current.time = flowTableSteps[move];
                    addAction(current, actionsArrState.length, "edit")
                        .then(() => { })
                        .catch(() => {
                            reject(false);
                        });
                }
            })
            reslove(true);
        });
    }

    useMemo(() => {
        pan.setValue({ x: 0, y: 0 });
    }, [index]);

    return index === indexFlag ? (
        <Animated.View
            ref={itemRef}
            style={[{
                transform: [type ? { translateY: pan.y } : { translateX: id && pan.x }],
                width: x,
                // height: type ? "fit-content" : y,
                zIndex: opacity ? 10 : 1,
            }, !type && {
                shadowColor: "black",
                shadowOffset: { width: 0, height: 0 },
                shadowOpacity: 0.8,
                shadowRadius: 10
            }]}
            {...responder.panHandlers}
        >
            {children}
        </Animated.View>
    ) : (
        <Animated.View
            ref={itemRef}
            style={{
                transform: [{ translateX: 0 }],
                width: x,
                // height: type ? "fit-content" : y,
                zIndex: opacity ? 10 : 1,
            }}
        >
            {children}
        </Animated.View>
    )
}

const styles = StyleSheet.create({})