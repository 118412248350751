import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	View,
	Text,
	TextInput,
	Pressable,
	Linking,
} from "react-native";
import { connect, useDispatch, useSelector } from "react-redux";
import { setErrorMessage, setLogin } from "../../redux/actions";

import i18n, { t } from 'i18n-js';
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import { _TOKEN_, _PASSWORD_, _EMAIL_, _NEW_PASSWORD_, min_password_length, max_password_length, ignore_characters, _DOMAIN_ } from "../../config.inc";
import { URL_PATH, RESET_PASSWORD, CHECK_TOKEN_EXPIRATION } from "@env";

export default function UpdatePassword() {

	const { heightS, widthS } = UseResponsiveScreen();
	const color = useSelector(state => state.style.colorClient);
	const background = useSelector(state => state.style.backgroundClient);
	const styles = stylesR(color, background, heightS, widthS);

	const dispatch = useDispatch();
	const [emailId, setEmailId] = useState();
	const [password, setPassword] = useState();
	const [token, setToken] = useState();
	const [err, setErr] = useState(false);

	const setPasswordFunction = (e) => {
		setPassword(e);
	}
	const updatePassword = () => {
		if (!password || password.length < min_password_length || password.match(ignore_characters) !== null) {
			return setErr(true);
		}
		checkToken().then((res) => {
			if (!res) {
				Linking.getInitialURL().then(domainUrl => {
					let baseUrl = domainUrl.split("/forgotPassword")[0];
					const url = `${URL_PATH}${RESET_PASSWORD}`;
					const body = new FormData();
					body.append(_EMAIL_, emailId);
					body.append(_NEW_PASSWORD_, password);
					body.append(_TOKEN_, token);
					body.append(_DOMAIN_, baseUrl);
					try {
						fetch(url, { method: "POST", body: body })
							.then((res) => res.text())
							.then((res) => {
								res = JSON.parse(res);
								if (res.status === "true") {
									dispatch(setErrorMessage(true, "", t("new_password_success"), false, () => {
										sessionStorage.removeItem("token_password");
										window.location.replace(location.origin);
									}));
								} else {
									dispatch(setErrorMessage(true, "", t("new_password_error"), false, () => {
									}));
								}


							})
							.catch((err) => { });
					} catch (e) { }
				});
			}
		});

	}

	const checkToken = () => {
		const queryParams = new URLSearchParams(window.location.search);
		return new Promise((resolve, reject) => {
			try {
				let token = queryParams.get('token')
				const url = `${URL_PATH}${CHECK_TOKEN_EXPIRATION}`;
				const body = new FormData();
				body.append(_TOKEN_, token);
				fetch(url, { method: "POST", body: body })
					.then((res) => res.text())
					.then((res) => {
						res = JSON.parse(res);
						if (res?.is_token_expired) {
							resolve(true);
							dispatch(setErrorMessage(true, "", t("err_link_password_expired"), false, () => {
								window.location.href = '/';
							}));
						} else {
							setToken(token);
							setEmailId(queryParams.get('email_id'));
							resolve(false);
						}
					})
					.catch((err) => { });
			} catch (e) {

			}

		});
	}

	useEffect(() => {
		checkToken();
	}, []);
	return (
		<View style={[styles.body_main, { backgroundColor: background.popupLogin }]}>
			<View style={styles.body_main_top}>
				<Text style={[styles.body_main_textA, { color: color.text }]}>
					{i18n.t("new_password")}
				</Text>
				<Text style={[styles.body_main_textB, { color: color.text }]}>
					{i18n.t("update_password")}
				</Text>
			</View>
			<Text style={styles.err}>{i18n.t("err_update_password")}</Text>
			<View style={[styles.body_main_bottom]}>
				<View>
					<TextInput
						placeholder={i18n.t("password")}
						placeholderTextColor={color.loginPlaceholder}
						style={[styles.inputs, { backgroundColor: background.loginInput, color: color.text }, err && styles.errBorder]}
						textContentType="password"
						secureTextEntry={true}
						onChangeText={setPasswordFunction}
						maxLength={max_password_length}
					/>
					<Pressable onPress={() => updatePassword()}
						style={[
							styles.btn,
							{
								backgroundColor:
									password?.length >= min_password_length
										? background.buttonLoginHover
										: "",
								borderColor: color.text2,
								borderWidth:
									!password || password.length < min_password_length
										? widthS * 1.5
										: 0,
							},
						]}
					>
						{
							<Text
								style={[
									{ color: color.text2 },
									styles.btn_text,
								]}
							>
								{i18n.t("submit")}
							</Text>
						}
					</Pressable>
				</View>
				<Pressable onPress={() => window.location.replace(location.origin)}>
					{({ pressed, hovered, focused }) => (
						<Text
							style={[
								{ textDecorationLine: hovered ? "underline" : "none" },
								styles.text_forgot,
							]}
						>
							{i18n.t("cancel")}
						</Text>
					)}
				</Pressable>
			</View>
		</View>
	);
}

const stylesR = (color, background, heightS, widthS) => {
	return StyleSheet.create({
		body_main: {
			display: "flex",
			flexDirection: "column",
			width: widthS * 420,
			height: heightS * 300,
			borderRadius: widthS > 1600 ? 34 : 25,
		},
		body_main_top: {
			marginTop: heightS * 24,
			flex: 0.3
		},
		body_main_textA: {
			fontSize: widthS * 18,
			fontWeight: "normal",
			textAlign: "center",
			fontFamily: "Catamaran_400Regular",
		},
		body_main_textB: {
			marginTop: heightS * 6,
			fontWeight: "300",
			lineHeight: heightS * 12,
			fontSize: widthS * 10,
			textAlign: "center",
			fontFamily: "Catamaran_400Regular",
		},
		body_main_bottom: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			justifyContent: "space-between",
			flex: 1,
		},
		inputs: {
			width: widthS * 220,
			height: heightS * 28,
			borderRadius: widthS * 7.3,
			fontSize: widthS * 9.5,
			fontWeight: "300",
			outlineStyle: "none",
			fontFamily: "Catamaran_400Regular",
			paddingLeft: "1.5vw"
		},
		btn: {
			padding: widthS * 4,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginVertical: heightS * 10.2,
			width: widthS * 220,
			height: heightS * 28,
			borderRadius: widthS * 7.3,
		},
		btn_text: {
			fontSize: widthS * 11,
			fontWeight: "normal",
			fontFamily: "Catamaran_400Regular",
		},
		text_forgot: {
			marginBottom: heightS * 31,
			alignSelf: "flex-end",
			color: color.text2,
			fontSize: widthS * 11.2,
			fontWeight: "normal",
			textDecorationStyle: "solid",
			textDecorationColor: "#a69ba6",
			textDecorationLine: "underline",
			fontFamily: "Catamaran_400Regular",
		},
		err: {
			fontSize: widthS * 9,
			minHeight: widthS * 9,
			lineHeight: widthS * 14,
			marginHorizontal: "3vw",
			marginVertical: "0.5vw",
			textAlign: "center",
			color: "#d2c935",
			fontFamily: "Catamaran_400Regular",
		},
		errBorder: {
			borderWidth: 1,
			borderColor: "red",
		}
	});
}
