import { checkIfIsEncodeText } from "../../componnets/modules/removeSpacialCherectersAndSpaes";
import { SHOW_ANALYTICS, SHOW_ANALYTICS_EXPECTED, SHOW_ANALYTICS_REAL, SHOW_ANALYTICS_COMPARED, ANALYTICS_ACTIONS, ANALYTICS_PLAYERS, ANALYTICS_EVENTS, SET_SURVEY_ANALYTICS } from "../actions/creatorActions";


export const Analytics = (state = {
    flags: {
        flag: false,
        expected: true,
        real: false,
        compared: false,
    },
    surveyArr: [],
    filter: {
        actions: "Incoming Items, Chat Messages, Sent Items, Decisions",
        players: "",
        events: ""
    },

}, action) => {
    switch (action.type) {
        case SHOW_ANALYTICS:
            return { ...state, flags: { ...state.flags, flag: action.flag } };
        case SHOW_ANALYTICS_EXPECTED:
            return { ...state, flags: { ...state.flags, expected: action.flag } };
        case SHOW_ANALYTICS_REAL:
            return { ...state, flags: { ...state.flags, real: action.flag } };
        case SHOW_ANALYTICS_COMPARED:
            return { ...state, flags: { ...state.flags, compared: action.flag } };
        case ANALYTICS_ACTIONS:
            return { ...state, filter: { ...state.filter, actions: action.payload } };
        case ANALYTICS_PLAYERS:
            return { ...state, filter: { ...state.filter, players: action.payload } };
        case ANALYTICS_EVENTS:
            return { ...state, filter: { ...state.filter, events: action.payload } };
        case SET_SURVEY_ANALYTICS:
            let arr = [];
            action?.payload?.forEach((e) => {
                let obj = e;
                obj.title = checkIfIsEncodeText(obj.title, "line")
                e.all_answers?.forEach((ans, i) => {
                    if (e.answers.find((e) => e.answer === ans)) return;
                    obj?.answers.splice(i, 0, {
                        answer: ans,
                        players: null
                    })
                })
                arr.push(obj);
            })
            return { ...state, surveyArr: arr };
        default:
            return state;
    }
}