import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  TextInput,
  Image,
  ScrollView,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import i18n, { t } from "i18n-js";
import TimePicker from "../modules/TimePicker";
import DatePicker from "../modules/DatePicker";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { useClickOutside } from "../modules/useClickOutside";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import AddLogo from "./AddLogo";
import { GameInfoContext } from "../../ContextData/GameInfo";
import { GameListContext } from "../../ContextData/GamesList";
import { _F_PHPSESSID_ } from "../../config.inc";
import { getLang } from "../../getLang";
import moment from 'moment-timezone';

export default function CreateNewGame() {
  const dispatch = useDispatch();

  const { width, height } = UseResponsiveSize();
  const { heightS, widthS } = UseResponsiveScreen();

  const lang = useSelector((state) => state.setting.setting.lang);
  const color = useSelector((state) => state.style.colorClient);
  const background = useSelector((state) => state.style.backgroundClient);
  const styleState = useSelector(state => state.style.costumColorClient);
  const styles = stylesR(width, height, heightS, widthS, background, color);

  const images = useSelector((state) => state.style.images);
  const clientsState = useSelector((state) => state.gameInfoArr.clients);

  const { getGameInfoData, getClientsUser } = useContext(GameInfoContext);
  const { addNewGame } = useContext(GameInfoContext);
  const { chooseGame } = useContext(GameListContext);

  const dateRef = useRef(null);
  const timeRef = useRef(null);
  const typesRef = useRef(null);
  const zoneRef = useRef(null);

  const [game, setGame] = useState({
    isDemo: false,
    // game_colors: `${styleState?.color1?.replace("#", "")},${styleState?.color2?.replace("#", "")},${styleState?.color3?.replace("#", "")}`,
    // game_logo: null,
    client: null,
    lang: lang,
    game_time_zone: moment.tz.guess(),
  });
  const [showDate, setShowDate] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showTypes, setShowTypes] = useState(false);
  const [showZone, setShowZone] = useState(false);
  const [showClient, setShowClients] = useState(false);
  // const [showAddLogo, setShowAddLogo] = useState(false);
  // const [showColorPicker, setShowColorPicker] = useState(false);
  const [err, setErr] = useState("");
  const clients = useMemo(() => clientsState, [clientsState]);



  const isCompleted =
    game.name && game.type && game.date && game.time ? true : false;

  const checkErr = () => {
    let errText = err;
    if (game?.name?.length > 1) {
      errText = errText?.replace("name,", "");
      setErr(errText);
    } else if (errText.indexOf("name,") < 0) {
      errText += "name,";
      setErr(errText);
    }

    if (game?.type?.length > 0) {
      errText = errText?.replace("type,", "");
      setErr(errText);
    } else if (errText.indexOf("type,") < 0) {
      errText += "type,";
      setErr(errText);
    }

    if (game?.date?.length > 0) {
      errText = errText?.replace("date,", "");
      setErr(errText);
    } else if (errText.indexOf("date,") < 0) {
      errText += "date,";
      setErr(errText);
    }
    if (clients?.length > 1) {
      if (clients && game?.client?.length > 0) {
        errText = errText?.replace("client,", "");
        setErr(errText);
      } else if (clients && errText.indexOf("client,") < 0) {
        errText += "client,";
        setErr(errText);
      }
    }

    if (game?.time?.length > 0 && new Date() < new Date(`${game.date} ${game.time}`)) {
      errText = errText?.replace("time,", "");
      setErr(errText);
    } else if (errText.indexOf("time,") < 0) {
      errText += "time,";
      setErr(errText);
    }
    if (isCompleted && errText.length === 0) {
      return true;
    }
  }

  const types = [
    { name: "Financial", value: "1" },
    { name: "Aviation Safety", value: "2" },
    { name: "Aviation Security", value: "3" },
    { name: "HLS", value: "4" },
    { name: "Cyber", value: "5" },
    { name: "Product Recall", value: "6" },
    { name: "Academia", value: "7" },
    { name: "Natural Disaster", value: "8" },
    { name: "Other", value: "9" },
  ]

  useClickOutside(dateRef, showDate, () => setShowDate(false));
  useClickOutside(timeRef, showTime, () => setShowTime(false));
  useClickOutside(typesRef, showTypes, () => setShowTypes(false));
  useClickOutside(zoneRef, showZone, () => setShowZone(false));
  useClickOutside(typesRef, showClient, () => setShowClients(false));

  const selectOptions = (arr, flag, setFlag, type, type2, current, zIndex, text) => {
    return (
      <Pressable
        onPress={() => setFlag(!flag)}
        style={[
          styles.btnType(zIndex),
          { backgroundColor: background.loginInput, position: "relative", justifyContent: "space-between", flexDirection: "row", alignItems: "center" },
          err?.indexOf(type) >= 0 && styles.error,
        ]}
      >
        <Text style={[
          styles.btnText,
          { color: current ? color.text : color.createNewGame },
        ]}>{current ? arr.find((e) => e?.value === current || e?.id === current).name : text}</Text>
        <AntDesign
          name="caretdown"
          size={widthS * 6}
          color={color.infoHeaderNavText}
        />
        {flag && <View ref={typesRef} style={[
          styles.popupType,
          { backgroundColor: background.loginInput, },
        ]}>
          <Text style={[
            styles.btnText,
            { color: color.text, paddingVertical: widthS * 6, paddingLeft: widthS * 12, borderBottomColor: color.border, borderBottomWidth: widthS * 1 },
          ]}>{current ? arr.find((e) => e?.value === current || e?.id === current).name : text}</Text>
          <ScrollView
            style={{ maxHeight: heightS * 150, }}
            nativeID="game-list"
            contentContainerStyle={{ paddingVertical: widthS * 6, }}
          >
            {arr.map((typeItem, i) => {
              if (current && (current === typeItem.value || current === typeItem.id)) return;
              return (
                <Pressable key={i} onPress={() => { setGame({ ...game, [type]: typeItem.value || typeItem.id, ...(type2 && typeItem.colors ? { [type2]: typeItem.colors } : {}) }); setFlag(false) }}>
                  <Text style={[
                    styles.btnText,
                    { color: color.text, paddingLeft: widthS * 12, paddingVertical: heightS * 3 },
                  ]}>{typeItem.name || typeItem.client}</Text>
                </Pressable>
              )
            })}
          </ScrollView>
        </View>}
      </Pressable>)
  }

  useEffect(() => {
    setGame({ ...game, lang: getLang(lang, false, true) });
  }, [lang]);

  useEffect(() => {
    if (clients?.length === 1) {
      setGame({ ...game, client: clients[0]?.id });
    }
  }, [clients]);

  useEffect(() => {
    getClientsUser();
  }, []);

  return (
    <>
      <View
        style={[styles.body_main, { backgroundColor: background.popupLogin }]}
      >
        <View style={styles.container}>
          <View style={styles.body_main_top}>
            <Text
              style={[styles.body_main_textA, { color: color.createNewGame }]}
            >
              {`${i18n.t("create_new_simulation")}`}
            </Text>
            <Text
              style={[styles.body_main_textB, { color: color.createNewGame }]}
            >
              {`${i18n.t("create_game_header_2")}`}
            </Text>
          </View>
          <View style={styles.inputs}>
            <View style={styles.checkBoxContainer}>
              <Pressable
                onPress={() =>
                  setGame((prev) => {
                    return { ...prev, isDemo: !prev.isDemo };
                  })
                }
                style={styles.checkBox}
              >
                {game.isDemo && <View style={[styles.checkBoxInner, { backgroundColor: background.count }]}></View>}
              </Pressable>
              <Text style={[styles.checkBoxText, { color: color.createNewGame }]}>
                {i18n.t("demo")}
              </Text>
              {<Text style={styles.errText}>{err?.indexOf("name") >= 0 ? t("new_game_err_name") : (err?.indexOf("date") >= 0 || err?.indexOf("time") >= 0) ? t("new_game_err_date") : null}</Text>}
            </View>
            <TextInput
              placeholder={`${i18n.t("simulation_name")}`}
              placeholderTextColor={color.createNewGame}
              style={[
                styles.input,
                { backgroundColor: background.loginInput, color: color.text },
                err?.indexOf("name") >= 0 && styles.error,
              ]}
              onChangeText={(e) =>
                setGame((game) => {
                  return { ...game, name: e };
                })
              }
            />
            {clients && clients?.length > 1 && selectOptions(clients, showClient, setShowClients, "client", "game_colors", game.client, 6, `${i18n.t("simulation_client")}`)}
            {selectOptions(types, showTypes, setShowTypes, "type", null, game?.type, 5, `${i18n.t("simulation_type")}`)}
            {/* <Pressable
              style={[styles.btnType(4),
              { backgroundColor: background.loginInput, position: "relative", justifyContent: "space-between", flexDirection: "row", alignItems: "center" },
              err?.indexOf("zone") >= 0 && styles.error]}
              ref={zoneRef}
              onPress={() => setShowZone(!showZone)}
            >
              <Text
                //  style={[styles.headers2, { color: color.infoHeaderNavText }]}
                style={[
                  styles.btnText,
                  { color: game?.game_time_zone ? color.text : color.createNewGame },
                ]}
              >{game?.game_time_zone ? game?.game_time_zone : t("time_zone")}</Text>
              <AntDesign name="caretdown" size={widthS * 6} color={color.infoHeaderNavText} />
              {showZone && <View style={[styles.timePicker, { backgroundColor: background.infoBody }]}>
                <Pressable style={[styles.selectTop, { borderBottomColor: color.border }]} onPress={() => setShowZone(false)}>
                  <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{game?.game_time_zone ? game?.game_time_zone : t("time_zone")}</Text>
                  <AntDesign name="caretdown" size={widthS * 6} color={color.infoHeaderNavText} />
                </Pressable>
                <ScrollView style={{ maxHeight: "10vw", flex: 1, width: "100%" }} id='chat'>
                  {TimesZone.game_time_zone.map((zone, index) => {
                    if (game?.game_time_zone?.toLowerCase()?.indexOf(zone?.toLowerCase()) >= 0) return;
                    return (
                      <Pressable key={index} style={styles.selectItemsLang} onPress={() => {
                        setGame({ ...game, game_time_zone: zone });
                        setShowZone(false);
                      }}>
                        <Text style={[styles.headers2, { color: color.infoHeaderNavText }]}>{zone}</Text>
                      </Pressable>
                    )
                  })}
                </ScrollView>
              </View>}
            </Pressable> */}
          </View>
          <View style={styles.dateAndtimeView}>
            <Pressable
              onPress={() => !showDate && setShowDate(true)}
              style={[
                styles.btnSelect,
                {
                  backgroundColor: background.infoBody,
                  width: showDate ? "100%" : "49%",
                },
                err?.indexOf("date") >= 0 && styles.error,
              ]}
            >
              <Text
                style={[
                  styles.headers2,
                  {
                    color: game.date
                      ? color.infoHeaderNavText
                      : color.createNewGame,
                  },
                ]}
              >
                {game.date ? game.date : i18n.t("start_date")}
              </Text>
              <Image
                style={styles.calendar}
                source={{ uri: images.calendarIcon }}
              />
              {showDate && (
                <View
                  ref={dateRef}
                  style={[
                    styles.datePicker,
                    { backgroundColor: background.infoBody },
                  ]}
                >
                  <DatePicker
                    onChange={(date) =>
                      setGame((game) => {
                        return { ...game, date: date };
                      })
                    }
                    colorText={color.infoHeaderNavText}
                    colorTextCurrent={background.infoSaveBtn}
                    background={background.infoBox}
                    borderColor={color.border}
                    calendarClassName={'calender-new'}
                    value={game.date}
                    close={() => setShowDate(false)}
                  />
                </View>
              )}
            </Pressable>
            <Pressable
              style={[
                styles.btnSelect,
                {
                  backgroundColor: background.infoBody,
                  width: "49%",
                  display: showDate ? "none" : ""
                },
                err?.indexOf("time") >= 0 && styles.error,
              ]}
              onPress={() => setShowTime(true)}
            >
              <Text
                style={[
                  styles.headers2,
                  {
                    color: game.time
                      ? color.infoHeaderNavText
                      : color.createNewGame,
                  },
                ]}
              >
                {game.time ? game.time : i18n.t("start_time")}
              </Text>
              <AntDesign
                name="caretdown"
                size={widthS * 6}
                color={color.infoHeaderNavText}
              />
              {showTime && (
                <View
                  style={[
                    styles.timePicker,
                    { backgroundColor: background.infoBody },
                  ]}
                  ref={timeRef}
                >
                  <TimePicker
                    onChange={(time) =>
                      setGame((game) => {
                        return { ...game, time: time };
                      })
                    }
                    colorText={color.infoHeaderNavText}
                    colorTextCurrent={background.infoSaveBtn}
                    background={background.infoBox}
                    borderColor={color.border}
                    value={game.time}
                  />
                </View>
              )}
            </Pressable>
          </View>
          {/* costum colors */}
          {/* <View style={[styles.colorsView, {
            borderTopColor: "#2c2732",
            borderTopWidth: widthS * 1,
          }]}>
            <Text style={{ color: "gray", fontWeight: "300", marginBottom: heightS * 5, fontSize: widthS * 8 }}>{t("optinal")}</Text>
            <View style={styles.colorsViewChild}>
              <Pressable
                style={[
                  styles.btnSelect,
                  {
                    backgroundColor: background.infoBody,
                    width: "49%",
                  },
                ]}
                onPress={() => setShowAddLogo(true)}
              >
                <Text
                  style={[
                    styles.headers2,
                    {
                      color: game.time
                        ? color.infoHeaderNavText
                        : color.createNewGame,
                    },
                  ]}
                >
                  {game?.game_logo ? game.game_logo?.name : t("add_logo")}
                </Text>
                <Image source={{ uri: images.filePhotoIcon }} style={{ resizeMode: "contain", width: widthS * 12, height: widthS * 12 }} />
              </Pressable>
              <Pressable
                style={[
                  styles.btnSelect,
                  {
                    backgroundColor: background.infoBody,
                    width: "49%",
                  },
                ]}
                onPress={() => setShowColorPicker(true)}
              >
                {game?.game_colors ?
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    {game.game_colors.split(",").map((e, i) => {
                      return (
                        <View key={i} style={{ borderRadius: "0.4vw", padding: "0.4vw", marginRight: "0.3vw", backgroundColor: background.infoBox }}>
                          <View style={{ backgroundColor: `#${e}`, width: "1.2vw", height: "1.2vw" }} />
                        </View>
                      )
                    })}
                  </View>
                  :
                  <Text
                    style={[
                      styles.headers2,
                      {
                        color: game.time
                          ? color.infoHeaderNavText
                          : color.createNewGame,
                      },
                    ]}
                  >
                    {t("costomize_colors")}
                  </Text>}
                <MaterialIcons name="colorize" size={widthS * 12} color="white" />
              </Pressable>
            </View>
          </View> */}
          <View style={styles.createBtnContainer}>
            <Pressable
              onPress={() => {
                if (checkErr() && isCompleted) {
                  addNewGame(game).then((res) => {
                    if (res) {
                      chooseGame(res.id, getGameInfoData, res.isDemo);
                    }
                  })
                }
              }}
              style={[styles.btn, { backgroundColor: background.loginFooter }]}
            >
              {
                <Text style={[{ color: color.text2 }, styles.btn_text]}>
                  {`${i18n.t("create")}`}
                </Text>
              }
            </Pressable>
          </View>
        </View>
      </View>
      {/* {showAddLogo && <AddLogo flag={showAddLogo} setFlag={() => setShowAddLogo(false)} addLogoFlag={true} infoObj={game} setInfoObj={setGame} newGame={true} />}
      {showColorPicker && <AddLogo flag={showColorPicker} setFlag={() => setShowColorPicker(false)} addLogoFlag={false} infoObj={game} setInfoObj={setGame} newGame={true} />} */}
    </>
  );
}
const stylesR = (width, height, heightS, widthS, background, color) => {
  return StyleSheet.create({
    body_main: {
      display: "flex",
      flexDirection: "column",
      width: widthS * 420,
      minHeight: heightS * 300,
      borderRadius: widthS * 25,
    },
    container: {
      width: "74%",
      marginHorizontal: "auto",
    },
    body_main_top: {
      marginTop: heightS * 24,
    },
    body_main_textA: {
      fontSize: widthS * 15.7,
      fontWeight: "normal",
      textAlign: "center",
      marginVertical: heightS * 5,
      fontFamily: "Catamaran_400Regular"
    },
    body_main_textB: {
      fontWeight: "300",
      lineHeight: heightS * 12,
      fontSize: widthS * 10,
      textAlign: "center",
      fontFamily: "Catamaran_400Regular",
    },
    body_main_bottom: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "flex-start",
      flex: 1,
    },
    btn: {
      borderStyle: "solid",
      padding: widthS * 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: heightS * 6,
      width: widthS * 108,
      height: heightS * 30,
      borderRadius: widthS * 7.3,
    },
    btn_text: {
      fontSize: widthS * 12,
      fontWeight: "normal",
      fontFamily: "Catamaran_400Regular",
    },
    inputs: {
      paddingTop: heightS * 10,
      zIndex: 5
    },
    btnType: (zIndex) => ({
      height: heightS * 30,
      marginTop: heightS * 6,
      borderRadius: widthS * 8,
      padding: widthS * 12,
      zIndex: zIndex
    }),
    popupType: {
      marginTop: heightS * 6,
      borderRadius: widthS * 8,
      position: "absolute",
      width: "100%",
      top: - widthS * 6,
      left: - widthS * 0
    },
    btnText: {
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
    },
    input: {
      height: heightS * 30,
      fontSize: widthS * 10,
      marginTop: heightS * 6,
      borderRadius: widthS * 8,
      padding: widthS * 12,
      outlineStyle: "none",
      fontFamily: "Catamaran_400Regular",
    },
    checkBoxContainer: {
      flexDirection: "row",
      alignItems: "center",
      paddingBottom: heightS * 5,
    },
    checkBox: {
      marginRight: 7 * width,
      width: 13 * width,
      height: 13 * width,
      backgroundColor: background.infoBody,
      borderRadius: 3,
      justifyContent: "center",
      alignItems: "center",
    },
    checkBoxInner: {
      width: 8 * width,
      height: 8 * width,
      backgroundColor: background.count,
      borderRadius: widthS * 1,
    },
    checkBoxText: {
      fontSize: 13 * width,
      fontFamily: "Catamaran_400Regular",
    },
    errText: {
      position: "absolute",
      top: "1.7vw",
      color: color.infoSaveBtn,
      fontSize: "0.8vw",
      fontFamily: "Catamaran_400Regular"
    },
    createBtnContainer: {
      paddingVertical: heightS * 20,
      alignItems: "center",
    },
    dateAndtimeView: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      zIndex: 3,
      paddingTop: heightS * 6
    },
    btnSelect: {
      height: heightS * 32,
      borderRadius: widthS * 10,
      paddingHorizontal: widthS * 12,
      fontSize: widthS * 12,
      fontWeight: "300",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      position: "relative",
      zIndex: 2,
    },
    headers: {
      fontSize: widthS * 12,
      marginLeft: widthS * 20,
      marginBottom: heightS * 3,
      fontWeight: "300",
      fontFamily: "Catamaran_400Regular",
    },
    headers2: {
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular"
    },
    headers3: {
      fontSize: widthS * 9.6,
      fontWeight: "300",
      fontFamily: "Catamaran_400Regular",
    },
    calendar: {
      width: widthS * 12,
      height: widthS * 12,
      resizeMode: "contain",
    },
    datePicker: {
      width: "100%",
      zIndex: 5,
      position: "absolute",
      minHeight: heightS * 70,
      borderRadius: widthS * 10,
      display: "flex",
      left: 0,
      top: 0,
      alignItems: "center",
      justifyContent: "center",
      shadowColor: "#000000",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.3,
      shadowRadius: 4.4,
    },
    timePicker: {
      width: "100%",
      zIndex: 5,
      position: "absolute",
      minHeight: heightS * 130,
      borderRadius: widthS * 10,
      display: "flex",
      left: 0,
      top: 0,
      alignItems: "center",
      justifyContent: "center",
      shadowColor: "#000000",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.3,
      shadowRadius: 4.4,
    },
    select: {
      width: "40%",
      zIndex: 5,
      position: "absolute",
      minHeight: heightS * 70,
      borderRadius: widthS * 10,
      display: "flex",
      left: 0,
      top: 0,
      alignItems: "center",
      justifyContent: "center",
      shadowColor: "#000000",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.3,
      shadowRadius: 4.4,
    },
    selectTop: {
      borderBottomWidth: 1,
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: widthS * 10,
      height: heightS * 32,
      paddingTop: heightS * 5,
    },
    selectItems: {
      paddingHorizontal: widthS * 10,
      minHeight: heightS * 28,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      position: "relative",
      paddingTop: heightS * 6,
    },
    selectItemsLang: {
      paddingHorizontal: widthS * 10,
      minHeight: heightS * 28,
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      position: "relative",
      paddingTop: heightS * 6,
    },
    colorsView: {
      marginTop: heightS * 12,
      paddingTop: heightS * 6,
      flexDirection: "column",
    },
    colorsViewChild: {
      flexDirection: "row",
      justifyContent: "space-between"
    },
    error: {
      borderColor: "red",
      borderWidth: "0.1vw",
      borderRadius: widthS * 8,
    }
  });
};
