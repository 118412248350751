import { StyleSheet, Text, View } from 'react-native'
import React, { useState } from 'react'
import { useEffect } from 'react';

export default function Rangeslider({
    id,
    className,
    min,
    max,
    step,
    value,
    onChange,
    width
}) {

    const [valueNew, setValueNew] = useState(value);

    useEffect(() => {
        var styleElement = document.querySelector(`[data='${className}']`);
        if (!styleElement) {
            styleElement = document.createElement("style");
            styleElement.type = "text/css";
            styleElement.dataset.id = className;
            document.head.appendChild(styleElement);
        }
        if (width && styleElement.innerHTML.indexOf(className) < 0) {
            styleElement.innerHTML = `
            .${className}::-webkit-slider-thumb { background-position: center; background-size: 3.6vw; width: ${width}px; height: 2.6vw;}
            .${className}::-moz-range-thumb { background-position: center; background-size: 3.6vw; width: ${width}px; height: 2.6vw;}
            `;
        }
    }, [width, className]);

    useEffect(() => {
        setValueNew(value);
    }, [value]);
  

    return (
        <>
            <style data={className} type="text/css"></style>
            <input
                id={id}
                // ref={inputRef}
                className={className}
                min={min}
                // disabled={disabled}
                max={max}
                step={step}
                type={'range'}
                value={valueNew}
                onChange={(e) => setValueNew(e.target.value)} // עדכון המצב בזמן תזוזת הידית
                onMouseUp={async (e) => {
                    // הפונקציה תתבצע כאשר המשתמש שוחרר את העכבר
                    let current = +e.target.value;
                    onChange(current);
                    setValueNew(value)
                }}
            />
        </>
    )
}

const styles = StyleSheet.create({})